import { useEffect, useState } from "react";
import { ActivityIndidicator } from "../../components/CircularProgres/CircularProgress";
import { PaginationControlled } from "../../components/CustomPagination/CustomPaginacion";
import { CustomSelect } from "../../components/customSelect/customSelect";
import { amountPerPagePagination } from "../../context/constants";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		"& > * + *": {
			marginTop: theme.spacing(2),
		},
	},
}));

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#b2dfdb',
  },
  barColorPrimary: {
    backgroundColor: '#0d6efd',
  },
})(LinearProgress);


function CustomDataList(props) {
	const classes = useStyles();
	let filterObject = {
		type_order: "",
		field_order: "",
	};

	const [changeNumberPageSelect, setChangeNumberPageSelect] = useState({
		field_id: "per_page",
		selectTittle: "",
		styles: props.styles,
		defaultOptionText: "",
		itemArray: [
			// { id: '2', name: '2' },
			{ id: amountPerPagePagination, name: amountPerPagePagination },
			{ id: "25", name: "25" },
			{ id: "50", name: "50" },
			{ id: "75", name: "75" },
			{ id: "100", name: "100" },
			{ id: "200", name: "200" },
		],
		defaultValue: amountPerPagePagination,
	});

	const onChangeSelectPage = (event) => {
		setChangeNumberPageSelect({
			...changeNumberPageSelect,
			defaultValue: event.value,
		});
		console.log({ event })
		props.onChangePageEvent(event);
	};

	let currentHeader = "";
	useEffect(() => {	
		if (props.hasOwnProperty("itemFilters") && props.itemFilters.length > 0) {
			props.itemFilters.map((filter) => {
				if (Object.keys(filterObject).includes(filter.field_id)) {
					filterObject[filter.field_id] = filter.value;
				}
			});
	
			currentHeader = props.tableHeadersToOrderFilter[filterObject.field_order] || "";
		}	
	}, [props]);

	const sortField = props.itemFilters?.find((c) =>  c.field_id == 'field_order')?.value
	const typeField = props.itemFilters?.find((c) =>  c.field_id == 'type_order')?.value

	return (
		<>
			{true ? (
				<>
					{!props.dontShowPerPageSelect && (
						<div className="row mt-3">
							<div className="mt-1" style={{ width: "20%" }}>
								<div
									className="input-group"
									style={{ flexWrap: "nowrap" }}
								>
									<div
										className="input-group-text"
										id="btnGroupAddon"
										style={{
											border: "none",
											background: "none",
											paddingLeft: "0",
										}}
									>
										Show
									</div>
									<CustomSelect
										field_id={
											changeNumberPageSelect.field_id
										}
										selectTittle={
											changeNumberPageSelect.selectTittle
										}
										styles={changeNumberPageSelect.styles}
										defaultOptionText={
											changeNumberPageSelect.defaultOptionText
										}
										itemArray={
											changeNumberPageSelect.itemArray
										}
										onChangeEvent={onChangeSelectPage}
										selectWidth={"100%"}
										defaultValue={
											changeNumberPageSelect.defaultValue
										}
										dontDefaultOption={true}
										selectClass="form-select form-select-sm"
									/>
									<div
										className="input-group-text"
										id="btnGroupAddon"
										style={{
											border: "none",
											background: "none ",
										}}
									>
										Entries
									</div>
								</div>
							</div>
						</div>
					)}
					<div className="row mt-3">
						{true && (
							<>
								{props.isLoading &&
									props.showLinearIndicator && (
										<div className="d-flex justify-content-start align-items-center mt-3 mb-3">
											<div className={classes.root}>
												<ColorLinearProgress />
											</div>
										</div>
									)}
									<div className="table-responsive">
										<table className="table">
											{/* <table className="w-100 table-responsive"> */}
											<thead>
												<tr>
													{props.tableHeaders.map(
														(header, index) => {
															const toSort = props.dataFieldsToOrderFilter && props.dataFieldsToOrderFilter[props.tableHeadersToDataFields[header.name]] || '-'
															return (
																<th
																className="text-nowrap p-1"
																style={{
																	width: `${String(header.name).toLowerCase() === 'actions' ? '100px' : header.width || ""}`,
																	verticalAlign: "middle",
																	minInlineSize: String(header.name).toLowerCase() === 'actions' ? '100px' : 'auto',
																}}
																key={index}
															>
																<div
																	className=""
																	style={{
																		textAlign: 'left',
																		display: 'flex',
																		gap: '8px',
																		alignItems: 'center',
																		textAlign: String(header.name).toLowerCase() === 'actions' ? 'center' : 'left',
																		...header?.style
																	}}
																>
																	{props.showOrderArrows &&
																		header.hasOwnProperty("showOrderActions") &&
																		header.showOrderActions && (
																			<div
																				className=""
																				style={{
																					display: 'flex',
																					flexDirection: 'column',
																					gap: '0.15rem'
																				}}
																			>
																				{(typeField == "asc" && sortField == toSort) ? (
																					<FontAwesomeIcon
																						hidden={false}
																						style={{ color: "rgb(10 84 184)", margin: "0", fontSize: '.7rem' }}
																						icon={faArrowUp}
																						className="sort-arrow"
																						onClick={() => props.onChangeOrderArrow(props.dataFieldsToOrderFilter,props.tableHeadersToDataFields, header.name, "asc")}
																						role="button"
																					/>
																				) : (
																					<FontAwesomeIcon
																						hidden={false}
																						style={{ color: "grey", margin: "0", fontSize: '.7rem' }}
																						icon={faArrowUp}
																						className="sort-arrow"
																						onClick={() => props.onChangeOrderArrow(props.dataFieldsToOrderFilter,props.tableHeadersToDataFields, header.name, "asc")}
																						role="button"
																					/>
																				)}
																				{(typeField == "desc" && sortField == toSort) ? (
																					<FontAwesomeIcon
																						hidden={false}
																						style={{ color: "rgb(10 84 184)",margin: "0", alignSelf:"flex-end", fontSize: '.7rem' }}
																						icon={faArrowDown}
																						className="sort-arrow"
																						onClick={() => props.onChangeOrderArrow(props.dataFieldsToOrderFilter,props.tableHeadersToDataFields, header.name, "desc")}
																						role="button"
																					/>
																				) : (
																					<FontAwesomeIcon
																						hidden={false}
																						style={{ color: "grey",margin: "0", alignSelf:"flex-end", fontSize: '.7rem' }}
																						icon={faArrowDown}
																						className="sort-arrow"
																						onClick={() => props.onChangeOrderArrow(props.dataFieldsToOrderFilter,props.tableHeadersToDataFields, header.name, "desc")}
																						role="button"
																					/>
																				)}
																			</div>
																		)}
																		<div>
																			{header.name}
																		</div>
																</div>
															</th>
															)
														},
													)}
												</tr>
											</thead>
											{!props.showDataInLoading && (
												<tbody>
													{!props.isLoading &&
														props.dataList.length > 0 &&
														props.dataList.map(
															props.render,
														)}
													{!!props.renderAddAfterBottom && props.renderAddAfterBottom()}
												</tbody>
											)}
											{props.showDataInLoading && (
												<tbody>
													{props.dataList.length > 0 &&
														props.dataList.map(
															props.render,
														)}
													{!!props.renderAddAfterBottom && props.renderAddAfterBottom()}
												</tbody>
											)}
										</table>
									</div>
							</>
						)}
						{props.isLoading && !props.showLinearIndicator && (
							<div className="mt-2 d-flex justify-content-center align-items-center">
								<ActivityIndidicator />
							</div>
						)}
						{!(props.dataList.length > 0) && !props.isLoading && (
							<div className="d-flex justify-content-center align-items-center mt-3">
								<p>No items found</p>
							</div>
						)}
					</div>
					{props.itemPagination && !props.isLoading && (
						<div className="row">
							<PaginationControlled
								itemPagination={props.itemPagination}
								handlePagination={props.handlePagination}
								customClasses="padding-child"
							/>
						</div>
					)}
				</>
			) : (
				<ActivityIndidicator />
			)}
		</>
	);
}

export { CustomDataList };
