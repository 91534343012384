import React, { useState } from "react";
import createHttp from "../../../../lib/http";
import { useHistory, useLocation } from "react-router-dom";

function EditExpenseCategories() {
	const history = useHistory();
	const location = useLocation();

	const [name, setName] = useState(location.state.data.name);

	function updateExpenses() {
		let item = { name };
		updateEditExpensesEndpoint(item);
	}

	function updateEditExpensesEndpoint(items) {
		const headers = {
			"Content-Type":
				"application/json, application/x-www-form-urlencoded",
			Accept: "application/json",
			"X-CSRF-TOKEN": "jmYydI75VCrZfAQIDrrjb6074EwpPSs47Y4au8Yl7AE=",
		};
		createHttp
			.patch("api/expense-category/" + location.state.data.id, items, {
				headers: headers,
			})
			.then((res) => { })
			.catch((err) => { });
	}

	return (
		<article className="contenedor container-fluid">
			<div className="container-title">
				<h1 className="container__title">Edit Expenses categories</h1>
			</div>
			<div className="card mt-3" style={{ padding: "20px" }}>
				<div className="">
					<h6>Expenses categories</h6>
				</div>
				<div className="row">
					<div className="mt-3 col-12 col-sm-6 col-lg-2">
						<label className="form-label">Name</label>
						<input
							className="form-control"
							required
							value={name}
							onChange={(e) => {
								setName(e.target.value);
							}}
						></input>
					</div>
					<div className="mt-5 col-12 col-sm-6 col-lg-2">
						<button
							className="btn btn-success button-save__user "
							style={{ width: "100%" }}
							onClick={updateExpenses}
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</article>
	);
}

export { EditExpenseCategories };
