import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import _, { debounce, sortBy } from "lodash";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import { useItemsVariantsV2 } from "../../customHooks/useItemsVariantsV2";
import { useFile } from "../../customHooks/useFile";
import UploadImagePreview from "../../assets/img/upload_image.jpg";
import { handleErrorResponse } from "../../utils";
import GlobalAdapter from '../../utils/global.adapter'
import Dialog from "../Dialog";
import DialogTitle from "../Dialog/DialogTitle";
import MaterialUI from "../MaterialUI";
import CustomImageViewer from "../CustomImageViewer/CustomImageViewer";
import { ImageInput } from "../image_input";
import NumberFormat from "../../utils/number.format";

Swal.default.noFocus = true;

const QUESTION_DELETE_IMAGE = {
	title: "Are you sure?",
	text: "you want to delete the image",
	icon: "warning",
	showCancelButton: true,
	confirmButtonColor: "#041731",
	cancelButtonColor: "#FD6461",
	confirmButtonText: "Yes, delete it!",
	allowOutsideClick: true,
}

export const DIVIDER_IDENTIFIER = `-|-`

export function getUniqueProductIdForFile(product, datetime) {
	const color = product.color || 'uniqueColor'
	const size = product.size || 'uniqueSize'
	// const id = product.id
	// const identifiers = [id, color, size]
	const identifiers = [color, size]
	if (datetime) identifiers.push(datetime)
	return identifiers.join(DIVIDER_IDENTIFIER).toLocaleUpperCase()
}

const initStateProductDetail = ({ item_id = "", location_id = "", warehouse_id = "" }) => ({
	id: crypto.randomUUID(),
	sku: "",
	upc: "",
	color: "",
	size: "",
	min_stock: 0,
	max_stock: 0,
	shipping_charge: "",
	quantity: "",
	cost: 0,
	price: "",
	item_id,
	location_id,
	warehouse_id,
})

const initFilters = {
	location_id: "",
	warehouse_id: "",
	sku: "",
	upc: "",
	color: "",
	size: "",
}

export const SIZES = { '275': '275', '1000': '1000' }

const ProductDetailFilters = ({ onFilters, locations, warehouses }) => {
	const [filters, setFilters] = useState({ ...initFilters })
	const handleChange = (key) => (newValue) => (setFilters((prev) => ({ ...prev, [key]: newValue })))
	
	useEffect(() => {
		const emitUpdate = debounce(() => (onFilters && onFilters instanceof Function) && onFilters(filters), 400)
		emitUpdate()
		return () => emitUpdate.cancel()
	}, [filters])

	return (
		<tr className="no-hover">
			<td style={{ padding: '2px' }}>
				<select className="form-select form-select-sm" value={filters.location_id} onChange={(ev) => handleChange('location_id')(ev.target.value)}>
					<option value="">All</option>
					{!!locations.length && locations.map((c, index) => (<option key={index} value={c.id}>{c.name}</option>))}
				</select>
			</td>
			<td style={{ padding: '2px' }}>
				<select className="form-select form-select-sm" value={filters.warehouse_id} onChange={(ev) => handleChange('warehouse_id')(ev.target.value)}>
					<option value="">All</option>
					{!!warehouses.length && warehouses.map((c, index) => (<option key={index} value={c.id}>{c.name}</option>))}
				</select>
			</td>
			<td style={{ padding: '2px' }}>
				<input type="text" tabIndex="-1" placeholder="Search" className="form-control form-control-sm w-100" value={filters.sku} onChange={(ev) => handleChange('sku')(ev.target.value)} />
			</td>
			<td style={{ padding: '2px' }}>
				<input type="text" tabIndex="-1" placeholder="Search" className="form-control form-control-sm w-100" value={filters.upc} onChange={(ev) => handleChange('upc')(ev.target.value.toLocaleUpperCase())} />
			</td>
			<td style={{ padding: '2px' }}>
				<input type="text" tabIndex="-1" placeholder="Search" className="form-control form-control-sm w-100" value={filters.color} onChange={(ev) => handleChange('color')(ev.target.value.toLocaleUpperCase())} />
			</td>
			<td style={{ padding: '2px' }}>
				<input type="text" tabIndex="-1" placeholder="Search" className="form-control form-control-sm w-100" value={filters.size} onChange={(ev) => handleChange('size')(ev.target.value.toLocaleUpperCase())} />
			</td>
			<td style={{ padding: '2px' }} colSpan={6}></td>
		</tr>
	)
}

export const ProductDetail = ({
	storeInline: storeInlineId,
	removeImageDatabase,
	hideHeader = false,
	addImageDatabase,
	warehouses = [], 
	locations = [],
	items = [],
	onUpdate,
	setItems,
 }) => {
	const [form, setForm] = useState({});
	const { store, update, destroy: destroyItemVariant } = useItemsVariantsV2()
	const { storeFile, destroyFile } = useFile()
	const [loadingForm, setLoadingForm] = useState(false)
	const [indexEdited, setIndexEdited] = useState(-1)
	const $firstInputForm = useRef(null)
	const currVariantIndex = useRef(undefined)
	const currVariant = useRef(undefined)
	// 👉 Upload File
	const [imageIdDelete, setImageIdDelete] = useState()
	const [uploadingSize, setUploadingSize] = useState()
	const [showDialog, setShowDialog] = useState(false)

	const [previewSize, setPreviewSize] = useState({
    [SIZES['275']]: UploadImagePreview,
    [SIZES['1000']]: UploadImagePreview
  });
  const changePreview = (size, newImagePreview) => {
    if(!size) return
    setPreviewSize((prevState) => ({ ...prevState, [size]: newImagePreview }))
  }
	
	const [itemImageToAdd, setItemImageToAdd] = useState([]);
	const [itemImageArray, setItemImageArray] = useState([]);

	const [errors, setErrors] = useState({});
	const onChangeForm = (key) => value => {
		setForm((prev) => ({ ...prev, [key]: value }))
		setErrors((prev) => {
			const newErrors = {...prev}
			if(newErrors[key]) {
				delete newErrors[key]
			}
			return newErrors
		})
	}
	
	useEffect(() => {
		if(!!warehouses.length && !!locations.length) {
			resetForm()
		}
	}, [locations, warehouses])
	
	const isValid = () => {
		const _errors = {}
		if(!form.sku.trim().length) _errors.sku = "Required"
		if(!form.upc.trim().length) _errors.upc = "Required"
		if(!String(form.location_id).trim().length) _errors.location_id = "Required"
		if(!String(form.warehouse_id).trim().length) _errors.warehouse_id = "Required"
		if(!String(form.color).trim().length) _errors.color = "Required"
		if(!String(form.size).trim().length) _errors.size = "Required"
		if(form.quantity == "0" || !Number(form.quantity)) _errors.quantity = "Required"
		setErrors(_errors)
		return !Object.keys(_errors).length
	}
	
	function showToast(type) {
		let title = 'Created'
		if (type == 'UPDATED') title = 'Updated'

		const Toast = Swal.mixin({
			toast: true,
			position: "top-end",
			showConfirmButton: false,
			timer: 1500,
			timerProgressBar: true,
			background: '#29A746',
			color: 'white',
			didOpen: (toast) => {
				toast.onmouseenter = Swal.stopTimer;
				toast.onmouseleave = Swal.resumeTimer;
			}
		});

		Toast.fire({
			title: `${title} successfully`,
			icon: "success",
		});
	}
	
	const updateList = async () => {
		try {
			setLoadingForm(true)
			setErrors({})
			if(!isValid()) return
			let payload = {...form}
			if (!storeInlineId) {
				form.id = `${form.sku}-${form.upc}`
				payload.id = `${form.sku}-${form.upc}`
			}
			const exists = itemsFilteredSorted.findIndex(c => c.id == form.id) != -1
			if(!exists) {
				if(storeInlineId) {
					delete payload.id
					const { data } = await store(payload)
					showToast()
					payload = data
				}
				const newList = [...itemsFilteredSorted, payload]
				setItems(newList)
				$firstInputForm.current?.focus()
			} else {
				if(storeInlineId) {
					const { id, ...formData } = payload
					const { data } = await update(id, formData)
					showToast('UPDATED')
					payload = data
				}
				const newList = [...itemsFilteredSorted]
				const index = newList.findIndex((c) => !!form.id && c.id == form.id)
				if(index !== -1) newList[index] = payload
				setItems(newList)
			}
			resetForm()
			if(onUpdate && onUpdate instanceof Function) onUpdate(itemsFilteredSorted)
		} catch (error) {
			handleErrorResponse(error)
		} finally {
			setLoadingForm(false)
		}
	}

	const onEdit = (index) => {
		currVariantIndex.current = index
		const record = itemsFilteredSorted[index]
		currVariant.current = record
		setIndexEdited(index)
		setForm({
			...record,
			location_id: record.location_id || locations.find(c => Boolean(c.default_location))?.id || '',
			warehouse_id: record.warehouse_id || warehouses[0]?.id || '',
			min_stock: record.min_stock || '',
			max_stock: record.max_stock || '',
			quantity: record.quantity || '',
			shipping_charge: record.shipping_charge || '',
		})
	}

	const destroy = async (index) => {
		try {
			const data = itemsFilteredSorted[index]
			if(!!data.id) {
				const { isConfirmed } = await Swal.fire({
					title: "Are you sure?",
					text: "You won't be able to revert this!",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#041731",
					cancelButtonColor: "#FD6461",
					confirmButtonText: "Yes, delete it!",
					allowOutsideClick: true,
				})
				if(!isConfirmed) return
				if(!!storeInlineId) await destroyItemVariant(data.id)
				Swal.fire({
					title: "Deleted!",
					text: "Your record has been deleted.",
					icon: "success",
					allowOutsideClick: true,
				});
			}
			const newItems = itemsFilteredSorted.filter((ifs) => ifs.id != data.id)
			setItems(newItems)
		} catch (err) {
			handleErrorResponse(err)
		}
	}

	const resetForm = () => {
		currVariant.current = undefined
		currVariantIndex.current = -1
		setErrors({})
		setIndexEdited(-1)
		setForm(initStateProductDetail({
			item_id: storeInlineId,
			location_id: locations.find(c => Boolean(c.default_location))?.id ?? '',
			warehouse_id: warehouses[0]?.id ?? ''
		}))
	}

	const [filters, setFilters] = useState({ ...initFilters })
	const fieldsFill = Object.values(filters).filter(Boolean)
	const itemsFiltered = !fieldsFill.length ? items : items.filter(applyFilters)
	const itemsFilteredSorted = sortBy(itemsFiltered, ['color', 'size'])

	function applyFilters(item) {
		const results = Object.entries(filters).filter(c => Boolean(c[1])).map(([key, value]) => {
			if(key === 'location_id' || key === 'warehouse_id') {
				return item[key] == value
			} else {
				return String(item[key]).toLocaleUpperCase().includes(String(value).toLocaleUpperCase())
			}
		})
		return results.every(Boolean)
	}

	const locationsIndexed = !locations.length ? {} : locations.reduce((acc, item) => {
		if(!acc[item.id]) acc[item.id] = item
		return acc
	}, {})

	const warehousesIndexed = !warehouses.length ? {} : warehouses.reduce((acc, item) => {
		if(!acc[item.id]) acc[item.id] = item
		return acc
	}, {})
	
	// 👉 Upload File
	const onOpenModalUploadFile = (index) => {
		const currItem = itemsFilteredSorted[index]
		const imagesAdapted = (Array.isArray(currItem.images) ? currItem.images : []).map(GlobalAdapter.objectImageToImage)

    const imagesSmalls = imagesAdapted.filter((ia) => ia.size == SIZES['275'])
    const imagesLarge = imagesAdapted.filter((ia) => ia.size != SIZES['275'])

    setPreviewSize({
      [SIZES['275']]: !!imagesSmalls.length ? imagesSmalls[0].path : UploadImagePreview,
      [SIZES['1000']]: !!imagesLarge.length ? imagesLarge[0].path : UploadImagePreview
    })

		setShowDialog(true)
		setItemImageArray(imagesAdapted)
    
		currVariant.current = currItem
		currVariantIndex.current = index
	}
	
	const onCloseModalUploadFile = () => {
		if (!!imageIdDelete || !!uploadingSize) return
		currVariantIndex.current = -1
		currVariant.current = undefined
		setShowDialog(false)
	}
	
	const uploadFileInline = async (event, size = SIZES['1000']) => {
		try {
			setUploadingSize(size)
			const formData = new FormData()
			formData.append('item_id', currVariant.current.item_id)
			formData.append('item_variant_id', currVariant.current.id)
			formData.append('file', event.target.files[0])
			formData.append('size', size)
			const resp = await storeFile(formData)
			setUploadingSize(undefined)
			const newImage = GlobalAdapter.objectImageToImage(resp.data.data, resp.data?.data?.size || undefined)
			setItemImageArray((prev) => [...prev, newImage])
			const newItems = [...itemsFilteredSorted].map((ps, index) => {
				const item = { ...ps }
				if(index == currVariantIndex.current) {
					item.images.push({ ...newImage, size })
				}
				return item
			})
			setItems(newItems)
			changePreview(size, newImage.path);
		} catch (err) {
			handleErrorResponse(err)
			setUploadingSize(undefined)
		} finally {
			setUploadingSize(undefined)
		}
	}
		
	const addFileToList = (event, size = SIZES['1000']) => {
		const fileIdGenerated = getUniqueProductIdForFile(currVariant.current)
		const currentFile = event.target.files[0]
		const urlPreview = URL.createObjectURL(currentFile)
		const newIdTime = (new Date()).getTime()
		const newImage = GlobalAdapter.createObjectToImage({
			fileId: getUniqueProductIdForFile(currVariant.current, newIdTime),
			size,
			selectedSize: currVariant.current?.size,
			selectedColor: currVariant.current?.color,
			file: currentFile,
			path: urlPreview,
			id: newIdTime,
		})
		const newItems = [...itemsFilteredSorted].map((ps, index) => {
			const item = { ...ps }
			if(index == currVariantIndex.current) {
				if (!item.images) item.images = []
				item.images.push({ ...newImage, size })
			}
			return item
		})
		addImageDatabase(fileIdGenerated, newImage)
		changePreview(size, urlPreview);
		setItemImageArray((prev) => [...prev, newImage])
		setItems(newItems)
	}
	
	const setItemImageToUpdate = async (event, size = SIZES['1000']) => {
		if (!currVariant.current) return
		if (!isNaN(storeInlineId)) {
			uploadFileInline(event, size)
		} else {
			addFileToList(event, size)
		}
	};

	const onDeleteImage = async (image, size = SIZES['1000']) => {
		const currURL = image.url || image.path
		try {
			const { isConfirmed } = await Swal.fire(QUESTION_DELETE_IMAGE)
			if(!isConfirmed) return
			const imageId = itemImageArray.findIndex((i) => i.path == currURL)
			if(imageId !== -1) {
				setImageIdDelete(image.id)
				await destroyFile(image.id)
				setImageIdDelete(undefined)
				
				setItemImageArray((prevState) => prevState.filter((ps) => (ps.url || ps.path) != currURL))

				const newItems = [...itemsFilteredSorted].map((ps, index) => {
					const item = { ...ps }
					if (index == currVariantIndex.current) {
						const newImages = item.images.filter((ps) => (ps.url || ps.path) != currURL)
						item.images = newImages
						if (previewSize[size] == currURL) {
							const currImage = item.images.find((i) => i.size == size)
							if (currImage) {
                changePreview(size, currImage.path)
							} else {
                changePreview(size, UploadImagePreview)
							}
						}
					}
					return item
				})
				setItems(newItems)
			}
		} catch (error) {
			setImageIdDelete(undefined)
			handleErrorResponse(error)
		}
	}
	
	const onDeleteImageInline = async (image, size = SIZES['1000']) => {
		const currURL = image.path
		try {
			const { isConfirmed } = await Swal.fire(QUESTION_DELETE_IMAGE)
			if(!isConfirmed) return
			const imageId = itemImageArray.findIndex((i) => i.path == currURL)
			if(imageId !== -1) {	
				setItemImageArray((prevState) => prevState.filter((ps) => (ps.path || ps.path) != currURL))
				const newItems = [...itemsFilteredSorted].map((ps, index) => {
					const item = { ...ps }
					if (index == currVariantIndex.current) {
						const newImages = item.images.filter((ps) => (ps.path || ps.path) != currURL)
						item.images = newImages
						if (previewSize[size] == currURL) {
							const currImage = item.images.find((i) => i.size == size)
							if (currImage) {
                changePreview(size, currImage.path)
							} else {
                changePreview(size, UploadImagePreview)
							}
						}
					}
					return item
				})
				removeImageDatabase(image)
				setItems(newItems)
			}
		} catch (error) {
			setImageIdDelete(undefined)
			handleErrorResponse(error)
		}
	}
	
	const viewDeleteImageModal = async (image, size = SIZES['1000']) => {
		if (!isNaN(storeInlineId)) {
			await onDeleteImage(image, size)
		} else {
			onDeleteImageInline(image, size)
		}
	};

	if(!itemsFilteredSorted && !setItems || !Object.keys(form).length) return null
		
	return (
		<>
			<Dialog maxWidth={'md'} fullWidth onClose={onCloseModalUploadFile} open={showDialog} title={<DialogTitle onClose={onCloseModalUploadFile} children={'Upload File'} />} >
				{(onClose) => (<>
					<div>
						<Typography variant="caption" className="text-secondary">SKU: {currVariant.current?.sku}</Typography>
						<div className="d-flex gap-1">
							<div className="w-50">
								<Typography variant="caption" className="text-secondary">Size <span className="fw-bold text-primary">275 x 275</span></Typography>
								<ImageInput
									onElementChange={(ev) => setItemImageToUpdate(ev, SIZES['275'])}
									preview_image={previewSize[SIZES['275']]}
									itemImageToAdd={itemImageToAdd}
									itemImageArray={itemImageArray}
									className="mt-2 mb-2"
									from_form={false}
									element_index={0}
									isEdition={false}
									isCenter={false}
									element={{}}
								/>
								{(!!itemImageArray.filter((ia) => ia.size == SIZES['275']).length || uploadingSize === SIZES['275']) ? (<>
									<div style={{ height: '0px' }}></div>
									<Box sx={{ overflowX: "auto", overflowY: "hidden", height: "150px" }} className="mx-1 mt-4">
										<CustomImageViewer
											itemData={itemImageArray.filter((ia) => ia.size == SIZES['275'])}
											onClickImage={(url) => changePreview(SIZES['275'], url)}
											onDeleteImage={(img) => viewDeleteImageModal(img, SIZES['275'])}
											uploading={uploadingSize === SIZES['275']}
											imageIdDelete={imageIdDelete}
											stopPropagation
											returnResource
										/>
									</Box>
								</>) : null}
							</div>
							<div style={{ width: '1px', background: 'lightgray', minHeight: 'max-content' }}></div>
							<div className="w-50">
								<Typography variant="caption" className="text-secondary">Size <span className="fw-bold text-primary">1000 x 1000</span></Typography>
								<ImageInput
									onElementChange={(ev) => setItemImageToUpdate(ev, SIZES['1000'])}
									preview_image={previewSize[SIZES['1000']]}
									itemImageToAdd={itemImageToAdd}
									itemImageArray={itemImageArray}
									className="mt-2 mb-2"
									from_form={false}
									element_index={0}
									isEdition={false}
									isCenter={false}
									element={{}}
								/>
								{(!!itemImageArray.filter((ia) => ia.size != SIZES['275']).length || uploadingSize === SIZES['1000']) ? (<>
									<div style={{ height: '0px' }}></div>
									<Box sx={{ overflowX: "auto", overflowY: "hidden", height: "150px" }} className="mx-1 mt-4">
										<CustomImageViewer
											itemData={itemImageArray.filter((ia) => ia.size != SIZES['275'])}
											onClickImage={(url) => changePreview(SIZES['1000'], url)}
											onDeleteImage={(img) => viewDeleteImageModal(img, SIZES['1000'])}
											uploading={uploadingSize === SIZES['1000']}
											imageIdDelete={imageIdDelete}
											stopPropagation
											returnResource
										/>
									</Box>
								</>) : null}
							</div>
						</div>
					</div>
					<div className="mt-2 text-end">
						<MaterialUI.Button.Secondary disabled={!!imageIdDelete || !!uploadingSize} onClick={onClose}>Close</MaterialUI.Button.Secondary>
					</div>
				</>)}
			</Dialog>
			<h6 className="sub__title mb-0 mt-3 text-primary">Form Variants</h6>
			<div className="row mb-2">
				<div className={`col-12`}>
					<div className="row align-items-end gap-0">
						<div className={`${!hideHeader ? 'col-6 col-md-2' : 'col-4'}`}>
							<label htmlFor="detail_location">Location</label>
							<select id="detail_location" className="form-select" value={form.location_id} onChange={(ev) => onChangeForm('location_id')(ev.target.value)}>
								<option value="">Select</option>
								{locations.map((item, index) => (
									<option key={index} value={item.id}>{item.name}</option>
								))}
							</select>
							{!!errors.location_id && <span className="fs-small text-danger">{errors.location_id}</span>}
						</div>
						<div className={`${!hideHeader ? 'col-6 col-md-2' : 'col-4'}`}>
							<label htmlFor="detail_warehouse">Warehouse</label>
							<select id="detail_warehouse" className="form-select" value={form.warehouse_id} onChange={(ev) => onChangeForm('warehouse_id')(ev.target.value)}>
								<option value="">Select</option>
								{warehouses.map((item, index) => (
									<option key={index} value={item.id}>{item.name}</option>
								))}
							</select>
							{!!errors.warehouse_id && <span className="fs-small text-danger">{errors.warehouse_id}</span>}
						</div>
						<div className={`${!hideHeader ? 'col-6 col-md-2' : 'col-4'}`}>
							<label htmlFor="detail_sku">Sku</label>
							<input type="text" ref={$firstInputForm} autoComplete="off" id="detail_sku" className={`form-control`} value={form.sku} onChange={(ev) => onChangeForm('sku')(ev.target.value)} />
							{!!errors.sku && <span className="fs-small text-danger">{errors.sku}</span>}
						</div>
						<div className={`${!hideHeader ? 'col-6 col-md-2' : 'col-4'}`}>
							<label htmlFor="detail_upc">Upc</label>
							<input type="text" autoComplete="off" id="detail_upc" className={`form-control`} value={form.upc} onChange={(ev) => onChangeForm('upc')(ev.target.value)} />
							{!!errors.upc && <span className="fs-small text-danger">{errors.upc}</span>}
						</div>
						<div className={`${!hideHeader ? 'col-6 col-md-2' : 'col-4'}`}>
							<label htmlFor="detail_color">Color</label>
							<input type="text" autoComplete="off" id="detail_color" className={`form-control`} value={form.color} onChange={(ev) => onChangeForm('color')(ev.target.value.toLocaleUpperCase())} />
							{!!errors.color && <span className="fs-small text-danger">{errors.color}</span>}
						</div>
						<div className={`${!hideHeader ? 'col-6 col-md-2' : 'col-4'}`}>
							<label htmlFor="detail_size">Size</label>
							<input type="text" autoComplete="off" id="detail_size" className={`form-control`} value={form.size} onChange={(ev) => onChangeForm('size')(ev.target.value.toLocaleUpperCase())} />
							{!!errors.size && <span className="fs-small text-danger">{errors.size}</span>}
						</div>
						<div className={`${!hideHeader ? 'col-6 col-md-1' : 'col-4'}`}>
							<label htmlFor="detail_quantity">Quantity</label>
							<input type="number" autoComplete="off" id="detail_quantity" className={`no-appearance form-control`} value={form.quantity} onChange={(ev) => onChangeForm('quantity')(ev.target.value.toLocaleUpperCase())} />
							{!!errors.quantity && <span className="fs-small text-danger">{errors.quantity}</span>}
						</div>
						<div className={`${!hideHeader ? 'col-6 col-md-1' : 'col-4'}`}>
							<label htmlFor="detail_cost">Cost</label>
							<input type="number" autoComplete="off" id="detail_cost" className={`no-appearance form-control`} value={form.cost} onChange={(ev) => onChangeForm('cost')(ev.target.value.toLocaleUpperCase())} />
							{!!errors.cost && <span className="fs-small text-danger">{errors.cost}</span>}
						</div>
						<div className={`${!hideHeader ? 'col-6 col-md-1' : 'col-4'}`}>
							<label htmlFor="detail_price">Price</label>
							<input type="number" autoComplete="off" id="detail_price" className={`no-appearance form-control`} value={form.price} onChange={(ev) => onChangeForm('price')(ev.target.value.toLocaleUpperCase())} />
							{!!errors.price && <span className="fs-small text-danger">{errors.price}</span>}
						</div>
						<div className={`${!hideHeader ? 'col-6 col-md-1' : 'col-4'}`}>
							<label htmlFor="detail_shipping_charge">Shipping Charge</label>
							<input type="number" autoComplete="off" id="detail_shipping_charge" className={`no-appearance form-control`} value={form.shipping_charge} onChange={(ev) => onChangeForm('shipping_charge')(ev.target.value.toLocaleUpperCase())} />
							{!!errors.shipping_charge && <span className="fs-small text-danger">{errors.shipping_charge}</span>}
						</div>
						{indexEdited !== -1 && (<>
							<div className={`${!hideHeader ? 'col-6 col-md-1' : 'col-md-4'}`}>
								<div>
									<label htmlFor="detail_min_stock">Min Stock</label>
									<input type="text" autoComplete="off" id="detail_min_stock" className={`form-control`} value={form.min_stock} onChange={(ev) => onChangeForm('min_stock')(ev.target.value)}/>
									{!!errors.min_stock && <span className="fs-small text-danger">{errors.min_stock}</span>}
								</div>
							</div>
							<div className={`${!hideHeader ? 'col-6 col-md-1' : 'col-md-4'}`}>
								<div>
									<label htmlFor="detail_max_stock">Max Stock</label>
									<input type="text" autoComplete="off" id="detail_max_stock" className={`form-control`} value={form.max_stock} onChange={(ev) => onChangeForm('max_stock')(ev.target.value)}/>
									{!!errors.max_stock && <span className="fs-small text-danger">{errors.max_stock}</span>}
								</div>
							</div>
						</>)}
						<div className={`${!hideHeader ? `col-6 col-md-2` : 'col-4'}`}>
							<div className="form-group mt-2 mt-md-0 text-end">
								<div className="d-flex w-full gap-1">
									<MaterialUI.Button.Darkblue style={{ display: 'block', paddingInline: 0, inlineSize: '100%' }} onClick={updateList} disabled={loadingForm}>
										<span className="inline-block text-xs ps-1">{indexEdited !== -1 ? 'Update Item' : 'Add Item'}</span>
									</MaterialUI.Button.Darkblue>
									{indexEdited !== -1 && (
										<MaterialUI.Button.Danger style={{ display: 'block', paddingInline: 0, inlineSize: '100%', maxWidth: '70px !important', padding: '0px' }} onClick={resetForm} disabled={loadingForm}>
											<span className="inline-block text-xs ps-1">Cancel</span>
										</MaterialUI.Button.Danger>
									)}
								</div>
								{(!!errors.sku && !!errors.upc && !!errors.color && !!errors.size && !!errors.quantity) && <span>&nbsp;</span>}
							</div>
						</div>
					</div>
				</div>
				<div className={`col-12 mt-2 font-inter`}>
					<div className="table-responsive">
						<table className="table table-sm">
							<thead>
								<tr>
									<th style={{ minInlineSize: '100px' }}>Location</th>
									<th style={{ minInlineSize: '100px' }}>Warehouse</th>
									<th style={{ minInlineSize: '130px', inlineSize: '130px', maxInlineSize: '130px' }}>SKU</th>
									<th style={{ minInlineSize: '130px', inlineSize: '130px', maxInlineSize: '130px' }}>UPC</th>
									<th style={{ minInlineSize: '100px' }}>Color</th>
									<th style={{ minInlineSize: '100px' }}>Size</th>
									<th style={{ minInlineSize: '100px' }}>Shipping Charge</th>
									<th style={{ minInlineSize: '100px' }}>Cost</th>
									<th style={{ minInlineSize: '100px' }}>Qty</th>
									<th style={{ minInlineSize: '100px' }}>Price</th>
									<th className="text-center" style={{ minInlineSize: '100px', inlineSize: '100px', maxInlineSize: '100px' }}>Min/Max Stock</th>
									<th className="text-center" style={{ inlineSize: '70px' }}>Actions</th>
								</tr>
							</thead>
							<tbody>
								<ProductDetailFilters onFilters={setFilters} locations={locations} warehouses={warehouses} />
								{!!itemsFilteredSorted.length ? itemsFilteredSorted.map((item, index) => (
									<tr key={index} className={`align-middle ${form.id == item.id ? 'bg-primary bg-opacity-25' : ''}`}>
										<td style={{ fontSize: '12px' }}>{locationsIndexed[item.location_id]?.name || '-'}</td>
										<td style={{ fontSize: '12px' }}>{warehousesIndexed[item.warehouse_id]?.name || '-'}</td>
										<td style={{ fontSize: '12px' }}>{item.sku}</td>
										<td style={{ fontSize: '12px' }}>{item.upc}</td>
										<td style={{ fontSize: '12px' }}>{item.color}</td>
										<td style={{ fontSize: '12px' }}>{item.size}</td>
										<td style={{ fontSize: '12px' }}>{!!item.shipping_charge ? NumberFormat.currency(item.shipping_charge) : '-'}</td>
										<td style={{ fontSize: '12px' }}>{NumberFormat.currency(Number(item.cost || '0'))}</td>
										<td style={{ fontSize: '12px' }}>{item.quantity}</td>
										<td style={{ fontSize: '12px' }}>{NumberFormat.currency(Number(item.price || '0'))}</td>
										<td className="text-center" style={{ fontSize: '12px' }}>{item.min_stock ?? '0'}/{item.max_stock ?? '0'}</td>
										<td>
											<div className="d-flex gap-1">
												{!!(item.images || []).length ? (
													<MaterialUI.Button.Primary tooltip="UPDATE IMAGES" tabIndex="-1" style={{ minWidth: '32px', paddingInline: '8px' }} onClick={() => onOpenModalUploadFile(index)} disabled={loadingForm || indexEdited !== -1}>
														<i className="fa-regular fa-images"></i>
													</MaterialUI.Button.Primary>
												) : (
													<MaterialUI.Button.Success tooltip="ADD IMAGES" tabIndex="-1" style={{ minWidth: '32px', paddingInline: '8px' }} onClick={() => onOpenModalUploadFile(index)} disabled={loadingForm || indexEdited !== -1}>
														<i className="fa-solid fa-folder-plus"></i>
													</MaterialUI.Button.Success>
												)}
												<MaterialUI.Button.Warning tabIndex="-1" style={{ minWidth: '32px', paddingInline: '8px' }} onClick={() => onEdit(index)} disabled={loadingForm || indexEdited !== -1}>
													<i className="fas fa-edit"></i>
												</MaterialUI.Button.Warning>
												<MaterialUI.Button.Danger tabIndex="-1" style={{ minWidth: '32px', paddingInline: '8px' }} onClick={() => destroy(index)} disabled={loadingForm || indexEdited !== -1}>
													<i className="fas fa-trash"></i>
												</MaterialUI.Button.Danger>
											</div>
										</td>
									</tr>
								)) : null}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}