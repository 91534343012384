import Swal from 'sweetalert2'
import axios from 'axios'

export const getFullPathImage = (image, defaultPath = "no-path-image") => {
  if (!image) return defaultPath
  if (image.url) return image.url
  const newPath = String(image.path || '').startsWith('http') ? image.path : image.url
  return newPath
}

export const handleErrorResponse = (error) => {
  let errorMessage
  if(axios.isAxiosError(error)) {
    errorMessage = error.response.data?.error || 'Error'
  } else if (error instanceof Error) {
    errorMessage = error.message
  } else {
    errorMessage = "Something went wrong"
  }
  Swal.fire({ icon: 'error', title: 'Error', text: errorMessage })
}

export const getPreviewFile = (file) => new Promise((resolve) => {
  if (file instanceof Blob) {
    const reader = new FileReader()
    reader.onload = (ev) => resolve(ev.target.result)
    reader.readAsDataURL(file)
  }
})

export const toUpper = (str, trimmer = true) => {
  if (!trimmer) return str.toLocaleUpperCase()
  return str.toLocaleUpperCase().trim()
}

export const toLower = (str, trimmer = true) => {
  if (!trimmer) return str.toLocaleLowerCase()
  return str.toLocaleLowerCase().trim()
}

export const groupBy = (array, key) => {
  return array.reduce((groups, item) => {
    const keyValue = item[key];
    (groups[keyValue] ||= []).push(item);
    return groups
  }, {})
}

export const buildProductId = (productId, selectedColor = 'standard', selectedSize = 'standard') => {
  return `${productId}-${selectedColor}-${selectedSize || 'unique'}`.toLocaleUpperCase()
}

export const getImageBySize = (images = [], size, productName) => {
  if (!size) return images
  if (!Array.isArray(images)) return []
  const imagesFiltered = images.filter((img) => img.size == size)
  if (!imagesFiltered.length) {
    // let message = `There are no images of size ${size}`
    // if (productName) message += `, Product ${productName}`
    // console.info(`%c${message}`, 'color: #FDBE34')
    return images
  }
  return imagesFiltered
}