import createHttp from "../lib/http";
import { useState, useEffect, useCallback } from "react";

import { amountPerPagePagination } from "../context/constants";

function useApiRequestExpensive(
	manageResponseErrors,
	requestVendorList = true,
	requestExpenseCategories = true,
) {
	const [isLoading, setIsLoading] = useState(false);
	const [sincronizedItems, setSincronizedItems] = useState(false);
	const [sincronizedExpenseCategories, setSincronizedExpenseCategories] = useState(false);
	const [filtered_data, setFilteredData] = useState([]);
	const [expensiveCategory, setExpensiveCategory] = useState([]);
	const [itemFilters, setItemFilters] = useState([
		{ field_id: "page", value: "1" },
		{ field_id: "per_page", value: amountPerPagePagination },
	]);
	const [itemPagination, setItemPagination] = useState([]);

	useEffect(async () => {
		const getList = async () => {
			setIsLoading(true);

			let params = {};

			itemFilters
				.filter((filter) => filter.value)
				.map((filter) => (params[filter.field_id] = filter.value));

			if (!sincronizedItems) {
				await createHttp
					.get("api/expenses", { params })
					.then((response) => {
						if (response.data.status) {
							setFilteredData(response.data.data);
							setItemPagination(response.data.pagination);
						} else {
							manageResponseErrors(
								response.data.message,
								"Expensive",
								"deleting",
							);
						}
						setSincronizedItems(true);
						setIsLoading(false);
					})
					.catch((response) => {
						setSincronizedItems(true);
						setIsLoading(false);
						manageResponseErrors(
							"An error has occurred",
							"Expensive",
							"deleting",
						);
					});
			}
			setIsLoading(false);
		};
		if (requestVendorList) {
			getList();
		}
		return () => {};
	}, [sincronizedItems]);

	const fetchExpenseCategories = useCallback(() => {
		if(sincronizedExpenseCategories) return;
		createHttp
			.get("api/expense-category")
			.then((response) => {
				if (response.data.status) {
					setExpensiveCategory(response.data.data);
					setSincronizedExpenseCategories(true);
				} else {
					manageResponseErrors(
						response.data.message,
						"Category Expensive",
						"listing",
					);
				}
			})
			.catch((response) => {});
	}, [sincronizedExpenseCategories]);
	
	useEffect(() => {
		if(requestExpenseCategories) fetchExpenseCategories();
	}, []);

	const deleteExpenses = async (id) => {
		setIsLoading(true);
		await createHttp
			.delete("api/expenses/" + id)
			.then((response) => {
				if (response.data.status) {
					setSincronizedItems(false);
				} else {
					manageResponseErrors(
						response.data.message,
						"Expensive",
						"deleting",
					);
				}
			})
			.catch((response) => {
				manageResponseErrors(
					"An error has occurred",
					"Expensive",
					"deleting",
				);
			});
		setIsLoading(false);
	};

	const createExpensePayment = async (initDataPaymentState, dataId) => {
		let detail_form = new FormData();

		detail_form.append("expense_id", dataId);
		detail_form.append("date_paid", initDataPaymentState.date_paid);
		detail_form.append(
			"payment_account_id",
			parseInt(initDataPaymentState.payment_account_id),
		);
		detail_form.append(
			"type_payment_id",
			parseInt(initDataPaymentState.type_payment_id),
		);
		detail_form.append("payment_info", initDataPaymentState.payment_info);

		return await createHttp.post("/api/expense-payment", detail_form);
	};

	const createExpense = async (item, initDataPaymentState) => {
		setIsLoading(true);
		let expenseId = null;
		await createHttp
			.post("api/expenses/", item)
			.then(async (response) => {
				if (response.data.status) {
					// setSincronizedItems(false)
					expenseId = response.data.data.id;
					await createExpensePayment(
						initDataPaymentState,
						response.data.data.id,
					)
						.then((response) => {
							if (response.data.status) {
								// manageSuccessfullMesssage(
								//     'Expensive',
								//     'created');
							} else {
								setIsLoading(false);
								manageResponse(
									response,
									"creating",
									"Expensive Payment",
								);
							}
						})
						.catch((error) => {
							setIsLoading(false);
							manageResponseErrors(
								"An error has occurred",
								"Expensive Payment",
								"creating",
							);
						});
				} else {
					setIsLoading(false);
					manageResponse(response, "creating", "Expensive");
				}
			})
			.catch((error) => {
				setIsLoading(false);
				manageResponseErrors(
					"An error has occurred",
					"Expensive",
					"creating",
				);
			});
		return expenseId;
	};

	const composedFields = {
		expense_category: "expense_category_id",
		warehouse: "warehouse_id",
		vendor: "vendor_id",
	};

	const mapResponse = (response) => {
		let object = {};
		Object.keys(response).map((key) => {
			if (!Object.keys(composedFields).includes(key)) {
				object[key] = response[key];
			} else {
				object[composedFields[key]] = response[key]?.id.toString();
			}
		});
		return object;
	};

	const getExpense = async (expenseId, normalResponse = false) => {
		return await createHttp
			.get(`api/expenses/${expenseId}`)
			.then((response) => {
				if (response.data.status) {
					let { data } = response.data;
					if(normalResponse) return data;
					return mapResponse(data);
				} else {
					// manage error
				}
			})
			.catch((error) => {});
	};

	const getExpensePayment = async (expenseId) => {
		return await createHttp
			.get(`api/expense-payment`, { params: { expensive_id: expenseId } })
			.then((response) => {
				if (response.data.status) {
					let { data } = response.data;
					return data;
				} else {
					// manage error
				}
			})
			.catch((error) => {});
	};

	const updateExpensePayment = async (
		expensePaymentId,
		initDataPaymentState,
	) => {
		return await createHttp.put(
			"api/expense-payment" + "/" + expensePaymentId,
			initDataPaymentState,
		);
	};

	const updateExpense = async (
		expenseId,
		bodyRequest,
		initDataPaymentState,
	) => {
		setIsLoading(true);
		const response = await createHttp
			.put("api/expenses/" + expenseId, bodyRequest)
			.then(async (response) => {
				if (response.data.status) {
					let expensePayment = await updateExpensePayment(
						initDataPaymentState.id,
						initDataPaymentState,
					)
						.then((response) => {
							if (response.data.status) {
								// manageSuccessfullMesssage(
								//     'Expensive',
								//     'updated');
								return response.data.data;
							} else if (response.status === 500) {
								manageResponse(
									"An error has occurred",
									"updating",
									"Expensive Payment",
								);
							} else {
								manageResponse(
									response,
									"updating",
									"Expensive Payment",
								);
							}
						})
						.catch((error) => {
							manageResponseErrors(
								"An error has occurred",
								"updating",
								"Expensive Payment",
							);
						});

					return {
						expenseResponse: response.data.data,
						expensePayment: expensePayment,
					};
				} else {
					manageResponse(response, "updating", "Expensive");
				}
			})
			.catch((error) => {
				manageResponseErrors(
					"An error has occurred",
					"updating",
					"Expensive",
				);
			});
		setIsLoading(false);
		return response;
	};

	const manageResponse = (response, action, model) => {
		const { data } = response;

		if (data.hasOwnProperty("errors")) {
			Object.keys(data.errors).map((errorList) => {
				if (data.errors[errorList].length > 0) {
					manageResponseErrors(
						data.errors[errorList][0],
						model,
						action,
					);
				}
			});
		}
	};

	return {
		isLoading,
		setIsLoading,
		sincronizedItems,
		setSincronizedItems,
		filtered_data,
		setFilteredData,
		expensiveCategory,
		setExpensiveCategory,
		deleteExpenses,
		itemFilters,
		setItemFilters,
		itemPagination,
		createExpense,
		getExpense,
		getExpensePayment,
		updateExpense,

		fetchExpenseCategories,
		setSincronizedExpenseCategories,
		sincronizedExpenseCategories,
	};
}

export { useApiRequestExpensive };
