import * as React from "react";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from '@material-ui/icons/Delete';
import img from "./../../assets/img/standard_icon.png";
import http from 'axios';
import Spinner from 'react-bootstrap/Spinner';
// import http from './../../lib/http';

//styles
import "./CustomImageViewer.css"
import { Typography } from "@material-ui/core";

export default function CustomImageViewer(props) {
	const onDownloadImage = (imagePath, format = "file") => {
		const ext = imagePath.split('.').pop().toLowerCase();		
		http({
			method: 'GET',
      url: imagePath,
      responseType: 'blob',
			headers: {
				'Accept': '*/*'
			}
		})
		  .then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `file.${ext}`);
				document.body.appendChild(link);
				link.click();
        link.parentNode.removeChild(link);
			});
	};

	const pathImage = (itemPath) =>
		!!props.showDefaultPdfIcon ? img : itemPath;

	const images = props.itemData || []
	
	return (
		<ImageList sx={{ width: 500, height: 450 }} cols={12} rowHeight={'auto'}
			style={{ gap: '.25rem', display: 'inline-flex', flexWrap: 'nowrap', marginInline: '2px' }}
			className="custom__image__list__preview"
		>
			{ !!props.children ? props.children : null }
			{images.map((item, key) => (
				<ImageListItem key={key} className="p-0 w-auto">
					{props.imageIdDelete == item.id ? (
						<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="image__list__preview__list__item" component="div">
							<div className="d-flex flex-column justify-content-center align-content-center gap-1">
								<Spinner animation="border" role="status" size="sm" style={{ margin: 'auto', color: '#9c9c9c' }}>
									<span className="visually-hidden">Deleting...</span>
								</Spinner>
								<Typography variant="caption" style={{ color: "#9c9c9c" }}>Deleting...</Typography>
							</div>
						</Box>
					) : (
						<Box
							component="div"
							sx={{ background: `url(${pathImage(item.path)}) no-repeat center center`, backgroundSize: 'cover' }}
							onClick={() => props.onClickImage(item.path)}
							className="image__list__preview__list__item"
						>
							{!!props.showSize && (
								<Chip
									style={{ fontSize: '.7rem' }}
									label={`${item.size || item.id} x ${item.size || item.id}`}
									color="primary"
									size="small"
								/>
							)}
							{!!props.showDowloadAction && parseInt(item.id) > 0 && (
								<Chip 
									onClick={() => onDownloadImage(item.path, item.format)}
									icon={<GetAppIcon className="ms-0" />}
									style={{ fontSize: '.75rem' }}
									size="small"
									color="primary"
									className="download_img_icon"
								/>
							)}
							<Chip
								onClick={(ev) => {
									if(props.stopPropagation) {
										ev.stopPropagation()
									}
									if(props.returnResource) {
										props.onDeleteImage(item)
									} else {
										props.onDeleteImage(item.path)
									}
								}}
								icon={<DeleteIcon fontSize="small" style={{ fontSize: '1rem' }} />}
								label=""
								className="delete_img_icon"
								color="secondary"
							/>
						</Box>
					)}
				</ImageListItem>
			))}
			{props.uploading && (
				<ImageListItem className="p-0 w-auto">
					<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="image__list__preview__list__item" component="div">
						<div className="d-flex flex-column justify-content-center align-content-center gap-1">
							<Spinner animation="border" role="status" size="sm" style={{ margin: 'auto', color: '#9c9c9c' }}>
								<span className="visually-hidden">Loading...</span>
							</Spinner>
							<Typography variant="caption" style={{ color: "#9c9c9c" }}>Loading...</Typography>
						</div>
					</Box>
				</ImageListItem>
			)}
		</ImageList>
	);
}

export { CustomImageViewer };
