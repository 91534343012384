import React from 'react'

import PosDesktopProductDetail from './PosDesktopProductDetail'
import { usePos } from '../../../context/PosDesktopContext'
import Dialog from '../../../components/Dialog'

const PosDesktopRenderModal = () => {
  const { posDesktopState, onSelectProduct } = usePos()
  const { selectedProduct } = posDesktopState

  const onCloseHandler = () => {
    onSelectProduct(undefined)
  }
  
  return (
    <>
      <Dialog onClose={onCloseHandler} maxWidth="sm" fullWidth={true} title={'Product'} open={!!selectedProduct}>
        {(onClose) => !!selectedProduct && <PosDesktopProductDetail onClose={onClose} />}
      </Dialog>
    </>
  )
}

export default PosDesktopRenderModal