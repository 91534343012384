import React, { useContext, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, makeStyles } from '@material-ui/core';
import { RemoveRounded, AddRounded, DeleteRounded } from '@material-ui/icons';
import { FaDropbox } from "react-icons/fa";
import clsx from 'clsx'

import { PosDesktopContext, usePos } from "../../context/PosDesktopContext";

import MaterialUI from '../../components/MaterialUI';

import NoImage from '../../assets/img/no-image.jpg';
import { getFullPathImage, getImageBySize } from '../../utils';
import NumberFormat from '../../utils/number.format';
import { SIZES } from '../../components/Product/ProductDetail';

const useStyles = makeStyles({
  table: {
		padding: 0,
    minWidth: 400,
  },
	container: {
		maxHeight: '58vh',
		// height: '58vh',
	}
});

const PosDesktopCart = () => {
	const classes = useStyles();
  const { totalQuantity, totalAmount, posDesktopState, removeProductCart, updateQuantityCart } = usePos();
  const { productsCart } = posDesktopState

  const handleUpdateQuantityCartClick = (product, quantity) => {
    updateQuantityCart({
      selectedColor: product.selectedColor,
      selectedSize: product.selectedSize,
      productId: product.id,
      quantity
    })
  }

  const handleRemoveProductCartClick = (product) => {
    removeProductCart({
      selectedColor: product.selectedColor,
      selectedSize: product.selectedSize,
      productId: product.id
    })
  }
  
  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader className={clsx(classes.table, !totalQuantity && 'h-100' )} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell className="fs-small" align="left">Product</TableCell>
            <TableCell className="fs-small" align="center" style={{ width: '80px', paddingInline: '.75rem' }}>Unit. P.</TableCell>
            <TableCell className="fs-small" align="center" style={{ inlineSize: '80px' }}>Quantity</TableCell>
            <TableCell className="fs-small" align="center" style={{ width: '80px', paddingInline: '.75rem' }}>SubTotal</TableCell>
            <TableCell className="fs-small cart__column__remove" align="center"><DeleteRounded fontSize="small"/></TableCell>
          </TableRow>
        </TableHead>
        {productsCart.length ? (<>
          <TableBody className='no-bordered'>
            {productsCart.map((item, index) => (
              <TableRow key={`cart-product-${index}`}>
                <TableCell className="px-1 fs-small" align="left" component="th" scope="row">
                  <div className='d-flex gap-2' style={{ minBlockSize: '50px', alignItems: 'center' }}>
                    <figure className='table__product__image'>
                      <img src={getFullPathImage(getImageBySize(item.images, SIZES['275'], item.name)[0], NoImage)} alt={item.name} onError={(ev) => ev.target.src = NoImage} />
                    </figure>
                    <div className='w-100'>
                      <div>
                        <span className="fs-small text-uppercase">{String(item.name).toLowerCase()}</span>
                      </div>
                      <div className='d-flex flex-column flex-wrap' style={{ lineHeight: '1.1' }}>
                        {/* <div className="card__pos__product__property text-uppercase">Sku: {item?.sku}</div> */}
                        {/* {!!item.brand_id?.name && <span className="card__pos__product__property text-uppercase">Brand: {item.brand_id.name}</span>} */}
                        {/* {!!item.category_id?.name && <span className="card__pos__product__property text-uppercase">Cat.: {item.category_id.name}</span>} */}
                        <span className='card__pos__product__property text-uppercase'>
                          {[item.selectedColor, item.selectedSize].filter(Boolean).join(' / ')}
                        </span>
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell className="px-1 fs-small" align="center" component="th" scope="row">
                  <span className="fs-small text-capitalize">{NumberFormat.currency(Number(item.selectedPrice))}</span>
                </TableCell>
                <TableCell className="px-1 fs-small" align="center">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <MaterialUI.Button.Dark size="small" disabled={Number(item.quantityPurchase) <= 1} onClick={() => handleUpdateQuantityCartClick(item, -1)} className="mw-auto p-1">
                      <RemoveRounded style={{ fontSize: '1rem' }} />
                    </MaterialUI.Button.Dark>
                    <span className="fs-small cart__counter">{item.quantityPurchase}</span>
                    <MaterialUI.Button.Dark size="small" disabled={Number(item.quantityPurchase) >= 10} onClick={() => handleUpdateQuantityCartClick(item, 1)} className="mw-auto p-1">
                      <AddRounded style={{ fontSize: '1rem' }} />
                    </MaterialUI.Button.Dark>
                  </div>
                </TableCell>
                <TableCell className="px-1 fs-small" align="center">
                  <span className="fs-small">{NumberFormat.currency(Number(item.selectedPrice) * Number(item.quantityPurchase))}</span>
                </TableCell>
                <TableCell align="center" className='cart__column__remove'>
                  <MaterialUI.Button.Danger size="small" className="mw-auto" onClick={() => handleRemoveProductCartClick(item)}>
                    <DeleteRounded fontSize="small"/>
                  </MaterialUI.Button.Danger>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter style={{ position: 'sticky', bottom: 0 }}>
            <TableRow>
              <TableCell colSpan={2} className='table__text_footer'>General information</TableCell>
              <TableCell className="fs-xs px-1 table__text_footer font-inter fw-bold" align="center">{totalQuantity}</TableCell>
              <TableCell className="fs-xs px-1 table__text_footer font-inter fw-bold" align="center">{NumberFormat.currency(totalAmount)}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableFooter>
        </>) : (<>
          <TableBody>
            <TableRow className='no-hover'>
              <TableCell  colSpan={5} className='text-center text-muted py-5'>
                <FaDropbox size={80} className='mb-2' />
                <div className="text-center font-inter">
                  <span className="fs-small">Cart is empty</span>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </>)}
      </Table>
    </TableContainer>
  )
};

export default PosDesktopCart;