import { useState, useCallback } from "react"

import Http from "../lib/http"

const LOADING = { FETCHING: 'FETCHING' }

const initialFilters = {
	status: 'All',
	search_text: '',
	type_order: 'desc',
	field_order: 'id',
	only_not_paid: 0,
	date_until: '',
	date_from: '',
	page: 1,
}

const OPTIONS = [
	{ label: 'All', value: 'All' },
	{ label: 'Paid', value: 'Paid' },
	{ label: 'Active', value: 'active' },
]

function useInvoiceOnlineSale() {
	const [loading, setLoading] = useState(undefined)
	const [pagination, setPagination] = useState()
	const [invoices, setInvoices] = useState([])

	const fetchAll = useCallback(async (filters = {}) => {
		if (loading === LOADING.FETCHING) return
		try {
      setLoading(LOADING.FETCHING)
			const params = {
				...filters,
				per_page: 20,
			}
			if (params.status === 'All') delete params.status

			if (!params.date_from) delete params.date_from
			if (!params.date_until) delete params.date_until

			const { data: values, status } = await Http.get('/api/invoices-online-sales', { params })
			if (status == 200 && values?.status) {
				setPagination(values.pagination)
				setInvoices(values.data)
			}
      return {
				pagination: values.pagination,
				invoices: values.data,
			}
    } catch (error) {
      throw error
    } finally {
      setLoading(undefined)
    }
	}, [loading])

	return {
		fetchAll,
		setInvoices,
		invoices,

		pagination,

		setLoading,
		loading,
	}
}

export {
	useInvoiceOnlineSale,
	initialFilters,
	LOADING,
	OPTIONS
}
