import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import Box from "@material-ui/core/Box";

// components
import { CustomForm } from "../../components/CustomForm/CustomForm";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { CustomImageViewer } from "../../components/CustomImageViewer/CustomImageViewer";
import { ImageInput } from "../../components/image_input";
import UploadImagePreview from "../../assets/img/upload_image.jpg";
import Panel from '../../components/Panel'
import CategoryForm from '../categories/CategoryForm'
import BrandForm from '../brand/BrandForm'
import UomForm from '../uoms/UomForm'
import MaterialUI from "../../components/MaterialUI";
import ColorForm from "../Colors/ColorForm";
import { VendorForm } from "../Vendor/vendorForm/Form";

//custom hooks
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiCustomRequest } from "../../customHooks/useApiCustomRequest";
import { typesAndValues as typesAndValuesFields, initData as initDataFields, PanelNames } from "./initForm";
import { setValueInArray } from "../../context/constants";

import "./ProductForm.css";
import { ExpensiveContextProvider } from "../../context/ExpensiveContext";
import Summernote from "../../components/Summernote";
import { ProductDetail, DIVIDER_IDENTIFIER, getUniqueProductIdForFile, SIZES } from "../../components/Product/ProductDetail"
import { useFile } from "../../customHooks/useFile";

function ProductForm(props = {}) {
	const { withResources = true, hideHeaderOnly = false, disabledSiblings = false } = props
	const history = useHistory();
	
	const {
		showSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		customMessageModal,
		manageResponseErrors,
		manageResponse,
		manageSuccessfullMesssage,
	} = useModalHook();

	const { storeProductImage, storeFile } = useFile()

	const { setIsLoading, isLoading, storeItem: createProduct, getListItemWithCache } = useApiCustomRequest(manageResponseErrors, "items", "Item", manageResponse, manageSuccessfullMesssage, true);
	const [initData, setInitData] = useState({});
	const [typesAndValues, setTypesAndValues] = useState([]);
	const [locations, setLocations] = useState([]);
	const [warehousesList, setWarehouseList] = useState([]);
	const [items, setItems] = useState([]);
	const bigDescriptionRef = useRef(null)
	const [selectedSize, setSelectedSize] = useState("xl");

	useEffect(async () => {
		let fields = [...typesAndValuesFields];
		if (disabledSiblings) {
			fields.map((field) => {
				if (field.nextSiblingConfig) {
					delete field.nextSiblingConfig
				}
				return field
			})
		}
		// disabledSiblings
		let initDataFieldsCopy = { ...initDataFields };
		let params = { without_data: !props.withoutData ? 0 : 1 };
		if (withResources) params.without_data = 0

		const resources = await getListItemWithCache("get_resource_data", "Resources", params );
		const { brands, categories, locations, warehouses, vendors } = resources;
		setLocations(locations)
		setWarehouseList(warehouses)
		if (!!brands?.length) fields = setValueInArray(fields, "brand_id", "values", brands)
		if (!!categories?.length) fields = setValueInArray(fields, "category_id", "values", categories)
		if (!!vendors?.length) fields = setValueInArray(fields, "vendor_id", "values", vendors)
		initDataFieldsCopy = {
			...initDataFieldsCopy,
			brand_id: !!brands?.length ? brands[0]?.id : "",
			category_id: !!categories?.length ? categories[0]?.id : "",
			vendor_id: !!vendors?.length ? vendors[0]?.id : "",
			sku: props.hasOwnProperty("skuNumber") && props?.skuNumber ? props?.skuNumber : "",
		};
		setInitData(initDataFieldsCopy);
		setTypesAndValues(fields);
	}, []);

	const onChangeFieldsInitDataPartNumber = (ev) => setInitData({ ...ev })
	const onHandleSubmit = (ev) => {};
	const buttonClick = () => {};

	// image input function
	const [preview_image, setImagePreview] = useState(UploadImagePreview);
	const [itemImageToAdd, setItemImageToAdd] = useState([]);
	const [itemImageToRemove, setItemImageToRemove] = useState([]);
	const [itemImageArray, setItemImageArray] = useState([]);
	const [currentImageToDelete, setCurrentImageToDelete] = useState(null);
	const [showDeleteImageModal, setShowDeleteImageModal] = useState(false);
	// 👉 Images
	const [imageDatabase, setImageDatabase] = useState({})
	const addImageDatabase = (productId, productImage) => {
		setImageDatabase((prev) => {
			const newState = { ...prev }
			if (!newState[productId]) newState[productId] = [] 
			if (Array.isArray(newState[productId])) newState[productId].push(productImage)
			return newState
		})
	}
	const removeImageDatabase = (dataImage) => {
		try {
			if (!dataImage) {
				console.warn("The image no found")
				return
			}
			const { fileId } = dataImage
			const productId = fileId.split(DIVIDER_IDENTIFIER).slice(0, 3).join(DIVIDER_IDENTIFIER)
			setImageDatabase((prev) => {
				const newState = { ...prev }
				if (!newState[productId]) newState[productId] = [] 
				if (Array.isArray(newState[productId])) {
					const images = newState[productId].filter((img) => img.id != dataImage.id)
					newState[productId] = images
				}
				return newState
			})
		} catch (error) {
			console.error(error)
		}
	}

	const setItemImageToUpdate = (event, index) => {
		let preview = URL.createObjectURL(event.target.files[0]);
		setImagePreview(preview);
	};

	const onClickImage = (imgUrl, deleteImage = false) => {
		setImagePreview(imgUrl);
	};

	const viewDeleteImageModal = (imgUrl) => {
		setCurrentImageToDelete(imgUrl);
		setShowDeleteImageModal(true);
	};

	const hideDeleteImageModal = () => {
		setShowDeleteImageModal(false);
		setCurrentImageToDelete(null);
	};

	const deleteImageConfirm = () => {
		onDeleteImage(currentImageToDelete);
		setShowDeleteImageModal(false);
		setCurrentImageToDelete(null);
	};

	const onDeleteImage = (imgUrl) => {
		let deletedPath = "";

		const copyImages = [...itemImageArray];

		const copyImagesFiles = [...itemImageToAdd];

		const imageIndex = copyImages.findIndex((image) => image.path === imgUrl);

		const imageFileIndex = copyImagesFiles.findIndex((file) => file.name === copyImages[imageIndex].id);

		if (imageFileIndex >= 0) {
			copyImagesFiles.splice(imageFileIndex, 1);
		}
		setItemImageToAdd([...copyImagesFiles]);

		if (imageIndex >= 0) {
			deletedPath = copyImages[imageIndex].path;
			setItemImageToRemove([ ...itemImageToRemove, copyImages[imageIndex] ]);
			copyImages.splice(imageIndex, 1);
		}

		setItemImageArray([...copyImages]);
		if (preview_image === deletedPath) {
			setImagePreview(copyImages[0]?.path || UploadImagePreview);
		}
	};

	// const transformArrayToObject = (array) => array.reduce((accumulator, value) => ({ ...accumulator, ...value }), {})

	const uploadImages = async (productId, images, newSizes) => {
		if (!images.length) return
		try {
			const formData = new FormData()
			formData.append('product_id', productId)
			formData.append('item_id', productId)
			formData.append('size', newSizes)
			Array.from(images).forEach((image, index) => {
				formData.append(`images[${index}]`, image)
				formData.append(`file[${index}]`, image)
			});
			await storeProductImage(formData)
		} catch (error) {}
	}

	const uploadImagesItemVariants = async (productId, variants, endRedirect = true) => {
		if (!Object.entries(imageDatabase).length) {
			if (endRedirect) {
				history.push("/product_list");
			}
			return;
		}
		try {
			const keys = variants.reduce((acc, item) => {
				const productId = getUniqueProductIdForFile({
					color: item.color,
					size: item.size,
				});
				if (!acc[productId]) acc[productId] = item.id;
				return acc;
			}, {});
			const promises = Object.entries(imageDatabase).flatMap(
				([variantKey, images]) => {
					if (!!keys[variantKey]) {
						return images.map((img) =>
							uploadFileItemVariant({
								item_variant_id: keys[variantKey],
								size: img.size || SIZES["1000"],
								item_id: productId,
								file: img.file,
							}),
						);
					}
				},
			);
			await Promise.allSettled(promises).then((responses) => {
				if (endRedirect) {
					history.push("/product_list");
				}
			});
		} catch (error) {
			console.error("Catch Error", error);
		}
	};

	const uploadFileItemVariant = async ({ item_id, item_variant_id, file, size }) => {
		try {
			const formData = new FormData()
			formData.append('item_id', item_id)
			formData.append('item_variant_id', item_variant_id)
			formData.append('file', file)
			formData.append('size', size)
			const response = await storeFile(formData)
			return response
		} catch (err) {
			console.error(err)
		}
	}
	
	//onSubmit
	const childFunc = useRef(null);
	const onCreateProduct = async () => {
		if (isLoading) return;
		const errors = childFunc.current(initData);
		if(!!errors.length) return
		let imagesArray = itemImageToAdd.map((itemFile, index) => {
			if (itemFile instanceof File) {
				return {[`images[${index}]`]: itemFile};
			}
		});
		const barcodes = items.map(({id, item_id, ...structuredCleaned}) => structuredCleaned)
		const request = {
			...initData,
			is_online: initData.is_online == 'false' ? '0' : '1',
			big_description: bigDescriptionRef.current.getValue(),
			barcodes
		}
		if(initData.vendor_id) {
			request.vendors = [initData.vendor_id]
		} else {
			request.vendors = []
		}
		if(imagesArray.length) {
			if (selectedSize == 'xl') {
				request.newSizes = 1000
			} else if (selectedSize == 'sm') {
				request.newSizes = 275
			}
		}

		let response = await createProduct(request, "Product", {}, true, undefined, undefined, undefined, false)
		if (response && response.hasOwnProperty("id")) {
			if (!props.dontReloadToMainPage) {
				await uploadImages(response.id, itemImageToAdd, request.newSizes)
				uploadImagesItemVariants(response.id, response.item_variants, true)
			}
			if (props.hasOwnProperty("onResponse")) {
				await uploadImages(response.id, itemImageToAdd, request.newSizes)
				await uploadImagesItemVariants(response.id, response.item_variants, false)
				props.onResponse(response)
			}
			if (props.hasOwnProperty("onHandleCancelButton")) props.onHandleCancelButton(initData.sku, initData.cost);
		}
	};

	const goBack = () => history.push('/product_list');

	// 👉 Siblings
	const handleSiblings = (prop = {}) => {
		switch (prop.panel) {
			case PanelNames.PANEL_CATEGORY:
				togglePanel('category')
				break;
			case PanelNames.PANEL_BRAND:
				togglePanel('brand')				
				break;
			case PanelNames.PANEL_COLOR:
				togglePanel('colors')				
				break;
			case PanelNames.PANEL_UOM:
				togglePanel('uom')
				break;
			default:
				togglePanel(PanelNames.PANEL_VENDOR)
				break;
		}
	}
	
	// 👉 Panels
	const [panels, setPanels] = useState({ category: false, brand: false, uom: false, colors: false, vendor: false });
  const togglePanel = (panel) => setPanels((prev) => ({ ...prev, [panel]: !prev[panel] }));

	const addRecordToCollection = (field_name, newRecord) => {
		const index = typesAndValues.findIndex((c) => c.field_name === field_name);
		if(index === -1) return;
		setTypesAndValues((prev) => {
			prev[index]['values'] = [...prev[index]['values'], newRecord];
			return prev;
		});
		setInitData((prev) => ({ ...prev, [field_name]: newRecord.id }));
	}
	
	const captureResponseCategory = (newRecord) => {
    if(!newRecord) return;
		addRecordToCollection('category_id', newRecord);
		togglePanel('category');
  }

	const captureResponseBrand = (newRecord) => {
    if(!newRecord) return;
		addRecordToCollection('brand_id', newRecord);
		togglePanel('brand');
  }

	const captureResponseUom = (newRecord) => {
    if(!newRecord) return;
		addRecordToCollection('uom_id', newRecord);
		togglePanel('uom');
  }

	const captureResponseVendor = ({ data }) => {
    const vendor = data.data;
    if(!vendor) return;
		addRecordToCollection('vendor_id', vendor);
		togglePanel(PanelNames.PANEL_VENDOR);
  }
	
	// ACTION: string CREATE, DESTROY
	const onUpdateImageHandler = ({ ACTION, payload }) => {
		const { item_variant_id, image } = payload
		switch (ACTION) {
			case "CREATE":
				
				break;
			case "DESTROY":
				
				break;
			default:
				break;
		}
	}
	
	return (
		<>
			<ExpensiveContextProvider>
				<div className={`contenedor container-fluid`}>
					{!props.hideHeader ? (
						!hideHeaderOnly && (
							<div className="d-flex gap-2 align-items-center">
								<MaterialUI.Button.Dark onClick={() => !!props.handleCloseAdd ? props.handleCloseAdd() : goBack()}>
									<ArrowBackIosRoundedIcon className="me-1" style={{ fontSize: '1rem' }} /> Back
								</MaterialUI.Button.Dark>
								<div className="container-title">
									<h1 className="container__title mb-0">{"Create Product"}</h1>
								</div>
								<MaterialUI.Button.Success onClick={onCreateProduct}>
									<SaveOutlinedIcon fontSize="small" className="me-1" /> Save
								</MaterialUI.Button.Success>
							</div>
						)
					) : !props.showCancelButton ? (<div className="d-flex gap-2 align-items-center">
						<MaterialUI.Button.Dark onClick={() => !!props.handleCloseAdd ? props.handleCloseAdd() : goBack()}>
							<ArrowBackIosRoundedIcon className="me-1" style={{ fontSize: '1rem' }} /> Back
						</MaterialUI.Button.Dark>
						<div className="container-title">
							<h1 className="container__title mb-0">{"Create Product"}</h1>
						</div>
					</div>) : null }
					<div className="row mb-2">
						<div className={ !props.hideHeader ? `col-12 col-lg-10` : 'col-12'}>
							{typesAndValues.length > 0 &&
								Object.keys(initData).length > 0 && (
									<>
										<CustomForm
											onHandleFormClose={() => {}}
											onHandleSubmit={onHandleSubmit}
											typesAndValuesFields={typesAndValues}
											initData={initData}
											formTittle={""}
											dontShowCancelButton={true}
											id={null}
											getUrlPath={""}
											inputConf={{
												marginTop: "1",
												marginBottom: "0",
												stringCol:"col-6 col-sm-4"
											}}
											buttonClick={buttonClick}
											onChangeFields={onChangeFieldsInitDataPartNumber}
											initOnChangeFields={onChangeFieldsInitDataPartNumber}
											dontShowSubmitButton={true}
											childFunc={childFunc}
											dontShowErrosinOnChange={true}
											getObjectInfoOnChange={() => {}}
											notifyChange={() => {}}
											handlerSiblings={handleSiblings}
										>
											<div>
												<label className="form-label mb-0" htmlFor="">Full description</label>
												<Summernote ref={bigDescriptionRef} state={initData.big_description} />
											</div>
										</CustomForm>
									</>
								)}
							<ProductDetail
								hideHeader={props.hideHeader}
								addImageDatabase={addImageDatabase}
								removeImageDatabase={removeImageDatabase}
								setItems={setItems}
								items={items}
								locations={locations}
								warehouses={warehousesList}
								onUpdateImage={onUpdateImageHandler}
							/>
							{props.showFooterButton && (
								<div className="text-end d-none d-md-block">
									<MaterialUI.Button.Success onClick={onCreateProduct} disabled={isLoading}>
										<SaveOutlinedIcon fontSize="small" className="me-1" /> <span>{isLoading ? 'Sending' : 'Save'}</span>
									</MaterialUI.Button.Success>
								</div>
							)}
						</div>
						<div className={ !props.hideHeader ? `col-12 col-lg-2` : 'col-12'}>
							<select className="form-select form-select-sm text-center" style={{ marginTop: '28px', marginTop: '28px', maxWidth: '300px', marginInline: 'auto' }} value={selectedSize} onChange={(ev) => setSelectedSize(ev.target.value)}>
								<option value="" disabled>Select Size</option>
								<option value="sm">275 x 275</option>
								<option value="xl">1000 x 1000</option>
							</select>
							<ImageInput
								from_form={true}
								className="mt-2 mb-2"
								element_index={0}
								onElementChange={setItemImageToUpdate}
								element={{}}
								isEdition={false}
								preview_image={preview_image}
								setImagePreview={setImagePreview}
								setItemImageToAdd={setItemImageToAdd}
								itemImageToAdd={itemImageToAdd}
								setItemImageArray={setItemImageArray}
								itemImageArray={itemImageArray}
							/>
							{itemImageArray.length && !props.hideHeader ? (
								<Box sx={{ overflowX: "auto", overflowY: "hidden", height: "150px" }}>
									<CustomImageViewer
										itemData={itemImageArray}
										onClickImage={onClickImage}
										onDeleteImage={viewDeleteImageModal}
									/>
								</Box>
							) : null }
						</div>
						{props.showFooterButton && (
							<div className="text-end d-block d-md-none">
								<MaterialUI.Button.Success onClick={onCreateProduct} disabled={isLoading}>
									<SaveOutlinedIcon fontSize="small" className="me-1" /> <span>{isLoading ? 'Sending' : 'Save'}</span>
								</MaterialUI.Button.Success>
							</div>
						)}
					</div>
				</div>
				<Panel headerTitle="Create Category" open={panels.category} togglePanel={() => togglePanel('category')}>
					<CategoryForm handleBehavior={captureResponseCategory}/>
				</Panel>
				<Panel headerTitle="Create Brand" open={panels.brand} togglePanel={() => togglePanel('brand')}>
					<BrandForm handleBehavior={captureResponseBrand}/>
				</Panel>
				<Panel headerTitle="Create Size" open={panels.uom} togglePanel={() => togglePanel('uom')}>
					<UomForm handleBehavior={captureResponseUom}/>
				</Panel>
				<Panel headerTitle="Create Color" open={panels.colors} togglePanel={() => togglePanel('colors')}>
					<ColorForm />
				</Panel>
				<Panel headerTitle="Create Vendor" open={panels.vendor} togglePanel={() => togglePanel(PanelNames.PANEL_VENDOR)}>
					<VendorForm behavior="NO_REDIRECT" handleBehavior={captureResponseVendor} />
				</Panel>
				<CustomModal
					show={showDeleteImageModal}
					customTittleText={"Delete image"}
					customMessageModal={"Are you sure you want to delete this image?"}
					onHandleClose={hideDeleteImageModal}
					onHandleSuccess={deleteImageConfirm}
					isLoading={isLoading}
					showSuccessButton={true}
				/>
				<CustomModal
					show={isLoading}
					customTittleText={""}
					customMessageModal={""}
					onHandleClose={() => {}}
					isLoading={isLoading}
				/>
				<CustomModal
					show={showModal}
					customTittleText={customTittleText}
					customMessageModal={customMessageModal}
					onHandleClose={() => setShowModal(false)}
					onHandleSuccess={() => {}}
					isLoading={isLoading}
					showSuccessButton={showSuccessButton}
				/>
			</ExpensiveContextProvider>
		</>
	);
}

export { ProductForm, ProductDetail };
