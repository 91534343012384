import { useAuthentication } from "../../customHooks/useAuthentication";
import Login from "../../views/login/Login";
import ErrorBoundary from '../ErrorBoundary'

function WithAuthenticationListener(WrappedComponent) {
	return function WrappedComponentWithAuthentication(props) {
		const { currentToken } = useAuthentication();

		return (
			<>
				{currentToken ? (
					<ErrorBoundary>
						<WrappedComponent>{props.children}</WrappedComponent>
					</ErrorBoundary>
				) : (
					<ErrorBoundary>
						<Login />
					</ErrorBoundary>
				)}
			</>
		);
	};
}

export { WithAuthenticationListener };
