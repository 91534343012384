import { useCallback, useState } from 'react';
import ExpensiveCategoryService from '../services/ExpensiveCategoryService';

class Adapter {
  static response(data) {
    return data;
  }
}

const initialState = {
  id: '',
  name: '',
}

const useApiRequestExpensiveCategory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [expensiveCategory, setExpensiveCategory] = useState({ ...initialState });
  const [errors, setErrors] = useState({});
  
  const store = useCallback(async(payload) => {
    try {
      setErrors({})
      setIsLoading(true);
      const resp = await ExpensiveCategoryService.store(payload);
      if(resp.status) {
        setExpensiveCategory(() => Adapter.response(resp.data));
        return resp.data;
      } else {
        setErrors({ name: `The name "${payload.name}" is already in use` });
        throw new Error("Error Store Expensive Category");
      }
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    isLoading,
    expensiveCategory,
    errors,

    store,
    setErrors,
    setExpensiveCategory,
  }
}

export default useApiRequestExpensiveCategory;