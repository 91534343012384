import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

//style
import "./CostumerForm.css";
// components
import { CustomForm } from "../../components/CustomForm/CustomForm";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { CustomButton } from "../../components/CustomButton/CustomButton";

// custom Hooks
// import { useApiRequest } from '../../../customHooks/useApiRequest'
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiCustomRequest } from "../../customHooks/useApiCustomRequest";

// form configuration
import {
	typesAndValues,
	initData as initDataValues,
	typesAndValuesCreditInfo,
	initDataCreditInfo as initDataCreditInfoValues,
} from "./initForm";
import MaterialUI from "../../components/MaterialUI";

function CostumerForm(props) {
	const location = useLocation();
	const history = useHistory();

	const [goToMainPage, setGoToMainPage] = React.useState(false);

	const {
		showSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		customMessageModal,
		manageResponseErrors,
		manageResponse,
		manageSuccessfullMesssage,
		customCurrentItem,
		setCustomCurrentItem,
	} = useModalHook();

	const { isLoading, createItem } = useApiCustomRequest(
		manageResponseErrors,
		"clients",
		"Clients",
		manageResponse,
		manageSuccessfullMesssage,
		true,
		setGoToMainPage,
		false,
		() => {},
		setCustomCurrentItem,
	);

	const [initData, setInitData] = useState(null);
	const [initDataCreditInfo, setInitDataCreditInfo] = useState(null);

	useEffect(() => {
		setInitData({ ...initDataValues });
		if(!props.handleResponse) {
			setInitDataCreditInfo({ ...initDataCreditInfoValues });
		}
	}, []);

	const onChangeField = async (event) => {
		setInitDataCreditInfo({ ...event });
	};

	const onChangeFieldsInitData = async (event) => {
		setInitData({ ...event });
	};

	const childFunc = useRef(null);
	const childFunc2 = useRef(null);
	const onHandleSubmit = async (event) => {
		if (isLoading) return;

		const errorsOne = childFunc.current(initData);
		let errorsTwo = [];
		if(!props.handleResponse) {
			errorsTwo = childFunc2.current(initDataCreditInfo);
		}

		if (!(errorsOne.length > 0) && !(errorsTwo.length > 0)) {
			let bodyRequest = {};

			const body = {
				...initData,
				...initDataCreditInfo,
			};

			Object.keys(body).map((key) => {
				if (body[key] != "" && body[key] != null) {
					bodyRequest[key] = body[key];
				}
				if (typeof body[key] == "boolean") {
					bodyRequest[key] = body[key] ? "1" : "0";
				}
			});

			if(props.handleResponse && typeof props.handleResponse === 'function') {
				const resp = await createItem(bodyRequest, "Client", {}, true);
				props.handleResponse(resp);
			} else {
				await createItem(bodyRequest, "Client");
			}

		}
	};

	const onHandleResponseModal = () => {
		setShowModal(false);
		if (goToMainPage) {
			if (
				location.hasOwnProperty("data") &&
				location?.data.hasOwnProperty("redirectTo") &&
				location?.data?.redirectTo
			) {
				let redirectObject = {
					pathname: location.data.redirectTo,
					data: { customerData: { ...customCurrentItem } },
				};

				let keyId = location?.data.hasOwnProperty("keyName")
					? location?.data?.keyName
					: "invoice_id";

				if (
					location?.data.hasOwnProperty("invoice_id") ||
					location?.data.hasOwnProperty("keyName")
				) {
					redirectObject.search = `?${keyId}=${location.data[keyId]}`;
				}

				history.push(redirectObject);
			} else {
				history.push("/costumer_list");
			}
		}
	};

	const [typesAndValuesFields, setTypesAndValuesFields] = React.useState([]);

	useEffect(() => {
		const fieldsAndValuesCopy = [...typesAndValues];

		const idFieldIndex = fieldsAndValuesCopy.findIndex(
			(item) => item.field_name === "id",
		);
		let fieldId = fieldsAndValuesCopy[idFieldIndex];
		fieldId.hidden = !location?.state?.id ? true : false;
		fieldsAndValuesCopy[idFieldIndex] = fieldId;
		if(props.handleResponse) {
			setTypesAndValuesFields(() =>
				fieldsAndValuesCopy
					.filter(field => field.required)
					.map(item => ({ ...item, col: 'col-12' }))
			);
		} else {
			setTypesAndValuesFields(fieldsAndValuesCopy);
		}
	}, [location?.state?.id]);

	// onSubmit implementation
	const gotBack = () => history.push('/costumer_list');

	return (
		<>
			<div className="contenedor container-fluid">
				{!!props.actionTittle && (<div className="row">
					<div className="col-12">
						<div className="d-flex gap-2 align-items-center">
							<MaterialUI.Button.Dark onClick={gotBack}>
								<ArrowBackIosRoundedIcon className="me-1" style={{ fontSize: '1rem' }} /> Back
							</MaterialUI.Button.Dark>
							<div className="container-title w-100">
								<h1 className="container__title m-0">
									{props.actionTittle + " " + props.model}
								</h1>
							</div>
						</div>
					</div>
				</div>)}
				<div className="row mt-1 pl-0 ml-0">
					<div className="col-12 pl-0 ml-0">
						{initData && typesAndValuesFields && (
							<CustomForm
								onHandleFormClose={() => {}}
								onHandleSubmit={() => {}}
								typesAndValuesFields={typesAndValuesFields}
								initData={initData}
								formTittle={""}
								dontShowCancelButton={true}
								id={
									(location?.state?.id &&
										location?.state?.id.toString()) ||
									null
								}
								getUrlPath={null}
								inputConf={{
									marginTop: "0",
									marginBottom: "0",
									stringCol: !!props.handleResponse ? 'col-12 col-md-6' : 'col-12 col-md-4'
								}}
								dontShowSubmitButton={true}
								onChangeFields={onChangeFieldsInitData}
								initOnChangeFields={onChangeFieldsInitData}
								childFunc={childFunc}
								dontShowErrosinOnChange={true}
							/>
						)}
					</div>
					{!props.handleResponse && (
						<div className="col-12">
							<h6 className="sub__title mt-3 pb-0 mb-0">Credit Information</h6>
						</div>
					)}
					<div className="col-12">
						{initDataCreditInfo && typesAndValuesCreditInfo && (
							<CustomForm
								// formTittle={"Credit Info"}
								onHandleFormClose={() => {}}
								onHandleSubmit={onHandleSubmit}
								typesAndValuesFields={typesAndValuesCreditInfo}
								initData={initDataCreditInfo}
								dontShowCancelButton={true}
								id={
									(location?.state?.id &&
										location?.state?.id.toString()) ||
									null
								}
								getUrlPath={null}
								inputConf={{
									marginTop: "1",
									marginBottom: "0",
									stringCol:"col-12"
								}}
								dontShowSubmitButton={true}
								onChangeFields={onChangeField}
								initOnChangeFields={onChangeField}
								childFunc={childFunc2}
								dontShowErrosinOnChange={true}
							/>
						)}
					</div>
				</div>
				<div className="row">
					<div className="col-12 text-end">
						<CustomButton
							buttonName={<><SaveOutlinedIcon fontSize="small" className="me-2" />Save</>}
							buttonOnClick={onHandleSubmit}
							dontShowIcon
							className="btn btn-sm btn-success px-3"
							icon={""}
							disabled={false}
						/>
					</div>
				</div>
			</div>

			{/* loading modal */}
			<CustomModal
				show={isLoading}
				customTittleText={""}
				customMessageModal={""}
				onHandleClose={() => {}}
				isLoading={isLoading}
			/>

			{/* response modal */}
			<CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={onHandleResponseModal}
				onHandleSuccess={onHandleResponseModal}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
			/>
		</>
	);
}

export { CostumerForm };
