import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { CustomDataList } from "../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../components/CustomDataListItem/CustomDataListItem";
import { PositionedMenu } from "../../components/CustomMenu/CustomMenu";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import MenuItem from "@material-ui/core/MenuItem";
import no_image from "../../assets/img/no_image.png";
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';

//CustomHooks
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiRequestWareHouse } from "../../customHooks/useApiRequestWareHouse";
import { useMenuActionHook } from "../../customHooks/useMenuActionHook";

import {
	styles,
	tableHeadersWareHouse as tableHeaders,
	dataFieldsWareHouse as dataFields,
} from "../../context/constants";
import MaterialUI from "../../components/MaterialUI";
import { Box, Grid, useMediaQuery } from "@material-ui/core";

function WareHouse(props) {
	const { anchorEl, setAnchorEl, onCloseModal, currentId, setCurrentId } = useMenuActionHook();
	const isMobile = useMediaQuery('(max-width:600px)');
	const {
		showModal,
		setShowModal,
		customTittleText,
		customMessageModal,
		customCurrentItem,
		setCustomCurrentItem,
		showSuccessButton,
		manageResponseErrors,
		manageDeleteMessage,
		setCustomMessageModal,
		cancelButtonText,
		sucessButtonText,
	} = useModalHook();

	const {
		warehouse,
		isLoading,
		deleteWareHouse,
		setSincronizedItems,
	} = useApiRequestWareHouse(manageResponseErrors);

	const history = useHistory();

	//custom data list implementation
	const deleteItem = async () => {
		setShowModal(false);
		return await deleteWareHouse(currentId);
	};

	const onMenuActions = async (event, element) => {
		setCurrentId(element.id);
		setCustomCurrentItem(element);
		setAnchorEl(event.currentTarget);
	};

	const onDelete = (_, item) => {
		setAnchorEl(null);
		manageDeleteMessage("WareHouse");
		setCustomMessageModal(`Are you sure you want to delete item #${item?.id || currentId}?`);
		setShowModal(true);
	};

	const onEdit = (_, item) => {
		const row = item || customCurrentItem;
		setAnchorEl(null);
		history.push({
			pathname: "/warehouse/" + row.id,
			state: { data: row },
		});
	};

	const actionsDefinition = [
		{
			name: "Edit",
			func: onEdit,
			icon: faEdit,
		},
		{
			name: "Delete",
			func: onDelete,
			icon: faTrashAlt,
		},
	];

	const onCreateWareHouse = () => {
		localStorage.removeItem("warehouse.name");
		history.push("/create/warehouse");
	};

	const [warehouseFiltered, setWarehouseFiltered] = useState([]);	
	const filterInputChange = (ev) => {
		const value = ev;
		const key = props.keyToFind || "name";

		if (!value) setSincronizedItems(false);

		const updatedList = warehouse.filter(Boolean).filter((item) => item[key].toLowerCase().search(value.toLowerCase()) !== -1);
		setWarehouseFiltered([...updatedList]);
		// setWareHouse([...updatedList]);
	};

	React.useEffect(() => {
		setWarehouseFiltered(warehouse);
	}, [warehouse])


	return (
		<div className="container-fluid contenedor px-0 px-md-2">
			<CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={() => setShowModal(false)}
				onHandleSuccess={deleteItem}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
				actionsFooterStyle={true}
				cancelText={cancelButtonText}
				successText={sucessButtonText}
			/>
			<div className="container-title mb-3">
				<h1 className="container__title mb-0">WareHouse</h1>
			</div>
			<div
				className={`${!!props.showSimpleInputTextFilter && "d-flex justify-content-between" } ${!props.showSimpleInputTextFilter && "row"}`}>
				{!!props.showSimpleInputTextFilter && (
					<CustomInput
						inputId={"inputPassword6"}
						describedByArea={"passwordHelpInline"}
						spanText={"Enter a letter to filter in items"}
						labelText={"Filter"}
						inputOnChange={filterInputChange}
					/>
				)}
				<div
					className={`${!!props.showSimpleInputTextFilter && ""} ${!props.showSimpleInputTextFilter && "col-12"}`}>
					<MaterialUI.Button.Primary size="small" onClick={onCreateWareHouse}>
						<AddTwoToneIcon fontSize="small" className="me-1"/>Add
					</MaterialUI.Button.Primary>
				</div>
			</div>
			<div>
				{!isMobile ? (
					<CustomDataList
						onChangePageEvent={() => {}}
						style={styles}
						isLoading={isLoading}
						dataList={warehouseFiltered}
						tableHeaders={tableHeaders}
						itemPagination={null}
						handlePagination={() => {}}
						dontShowPerPageSelect={true}
						showLinearIndicator={true}
						showDataInLoading={true}
						render={(element, index) => (
							<CustomDataListItem
								key={index}
								dataFields={dataFields}
								imageFields={[]}
								composedFields={[]}
								arrayFields={[]}
								element={element}
								index={index}
								no_image={no_image}
								renderActions={() => (
									<>
										<PositionedMenu
											title={"Actions"}
											anchorEl={anchorEl}
											setAnchorEl={setAnchorEl}
											handleClose={onCloseModal}
											handleClick={(event) =>
												onMenuActions(event, element)
											}
											actionsDefinition={actionsDefinition}
											renderActions={(action, index) => (
												<MenuItem
													sx={{boxShadow: "none" }}
													key={index}
													onClick={action.func}
												>
													<div
														className="items-option"
														style={{
															padding: "0 5px",
															color: "grey",
														}}
													>
														<FontAwesomeIcon
															style={{
																fontSize: '.8rem',
																margin: "4px",
																marginRight: "10px",
															}}
															className={
																	action.name === "Edit" 
																		? "text-primary"
																		: action.name === "Delete"
																			? 'text-danger'
																			: 'inherit'
																}
															icon={action.icon}
														/>
														<p className="mb-0 fs-small">{action.name}</p>
													</div>
												</MenuItem>
											)}
										></PositionedMenu>
									</>
								)}
							/>
						)}
					/>
				) : (
					<Grid container className="mb-3" spacing={2}>
						<Grid item xs={12}>
							{!!warehouseFiltered.length && (
								<Box className="table__mobile">
									{warehouseFiltered.map((row) => (
										<Box className="table__mobile__row" key={row.id}>
											<div className="fs-small">
												<div className="d-flex align-items-center"><strong>ID: </strong><span className="ps-1">{row.id}</span></div>
												<div className="d-flex align-items-center"><strong>Name: </strong><span className="ps-1">{row.name}</span></div>
												<div className="d-flex align-items-center"><strong>Phone: </strong><span className="ps-1">{row.phone_number}</span></div>
												<div className="d-flex align-items-center"><strong>Address: </strong><span className="ps-1">{row.address}</span></div>
											</div>
											<div className="text-end fs-small">
												<div className="d-flex align-items-center justify-content-end"><strong>City: </strong><span className="ps-1 text-capitalize">{String(row.city).toLowerCase()}</span></div>
												<div className="d-flex align-items-center justify-content-end"><strong>Sate: </strong><span className="ps-1">{row.state}</span></div>
												<div className="d-flex align-items-center justify-content-end"><strong>Zip Code: </strong><span className="ps-1">{row.zip_code}</span></div>
												<div className="d-flex align-items-center justify-content-end">
													<strong className="text-primary" role="button" onClick={() => onEdit(null, row)}>
														Edit
													</strong><span className="px-1">|</span>
													<strong className="text-primary" role="button" onClick={() => { setCurrentId(row.id); onDelete(null, row) }}>
														Delete
													</strong>
												</div>
											</div>
										</Box>
									))}
								</Box>
							)}
						</Grid>
					</Grid>
				)}
			</div>
		</div>
	);
}

export { WareHouse };
