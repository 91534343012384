import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import Routes from "../Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { AppContextProvider } from "../context/AppContext";
import { PermissionContextProvider } from "../context/PermissionContext";

import LangEN from '../lang/en.json';
import LangES from '../lang/es.json';

function App(props) {
	const [locale, setLocale] = useState("en");

	const messages = React.useMemo(() => {
		switch (locale) {
			case 'es': return LangES;
			default: return LangEN;
		}
	}, [locale]);

	useEffect(() => {
		console.info('%cVersion 1.0.11', 'color: #3EFFA2; font-weight: bold')
	}, [])
	
	return (
		<AppContextProvider>
			<PermissionContextProvider>
				<IntlProvider locale={locale} messages={messages}>
					<Router>
						<Routes token={props} setLocale={setLocale} />
					</Router>
				</IntlProvider>
			</PermissionContextProvider>
		</AppContextProvider>
	);
}

export default App;
