import React, { useContext, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import { LaravelErrorsToObject } from '../../../utils/errors.laravel';

import { useApiRequestVendor } from "../../../customHooks/useApiRequestVendor";

// form configuration
import { initData, BEHAVIOR } from "../initForm";
import { FormGroup, Grid } from "@material-ui/core";
import MaterialUI from "../../../components/MaterialUI";
import Panel from "../../../components/Panel";
import PaymentMethodForm from "../../PaymentMethod/PaymentMethodForm";
import { ExpensiveContext } from "../../../context/ExpensiveContext";
import Adapter from '../vendor.adapter';

function VendorForm(props) {
	const {fetchTypesPayment, payments, setPayments} = useContext(ExpensiveContext);
	const {state} = useLocation();
	const history = useHistory();

  const [errors, setErrors] =  React.useState({});
	const [form, setForm] = React.useState({ ...initData });

	const { updateVendor, createVendor, fetchVendor } = useApiRequestVendor(() => {}, false);
	useEffect(() => {
		fetchTypesPayment();
	}, []);

	useEffect(() => {
		if(state?.id) {
			fetchVendor(state.id)
				.then((resp) => {
					setForm(() => Adapter.responseToForm(resp));
				})
		}
	}, [state?.id]);
	
	const handleChange = (key, value) => {
		setForm((prev) => ({ ...prev, [key]: value }));
		setErrors((prev) => ({ ...prev, [key]: '' }));
	}

	const handleSubmit = async (ev) => {
		ev.preventDefault();
		try {
			const { id, ...bodyExt } = { ...form, type_payment_id: form.type_payment_id?.id || null };
			let response = {};
			if(id) response = await updateVendor(id, bodyExt);
			else response = await createVendor(bodyExt);
			
			if (response.hasOwnProperty("status") && response.status === 201) {
				if(props?.handleBehavior && typeof props?.handleBehavior === 'function') {
					props?.handleBehavior(response);
				} else {
					history.push("/vendors_list");
				}
			}
			if(response?.data?.errors) {
				setErrors(LaravelErrorsToObject(response?.data?.errors))
			}
		} catch (error) {}
	}

	const paymentList = useMemo(() => {
    if (!Array.isArray(payments)) return [];
		return payments.map(Adapter.responsePaymentToAutocomplete);
	}, [payments]);


	const gridPanel = !props.behavior ? { xs: 12, md: 6, lg: 4 } : { xs: 12, md: 6 };

	const [panels, setPanels] = React.useState({ paymentMethod: false });
	const togglePanel = (panel) => {
		setPanels((prev) => ({ ...prev, [panel]: !prev[panel] }));
  };
	const captureResponsePaymentMethodModal = (newPaymentMethod) => {
		if (!newPaymentMethod) return;
		setPayments((prev) => [...prev, newPaymentMethod]);
		setForm((prev) => ({ ...prev, type_payment: Adapter.responsePaymentToAutocomplete(newPaymentMethod) }));
    togglePanel('paymentMethod');
  }

	const gotBack = () => history.push('/vendors_list');
	return (
		<>
			<Panel open={panels.paymentMethod} anchor="right" togglePanel={() => togglePanel('paymentMethod')} headerTitle="Create Payment Method">
        <PaymentMethodForm handleBehavior={captureResponsePaymentMethodModal} />
      </Panel>
			<div className="contenedor container-fluid">
				<Grid className='mb-4' component={'form'} onSubmit={handleSubmit} noValidate autoComplete='off' container spacing={1}>
					{!!props.actionTittle && (<>
						<Grid item xs={12}>
							<div className="d-flex gap-2 align-items-center mb-1">
								{!!props.actionTittle && (
									<MaterialUI.Button.Dark onClick={gotBack}>
										<ArrowBackIosRoundedIcon className="me-1" style={{ fontSize: '1rem' }} /> Back
									</MaterialUI.Button.Dark>
								)}
								<div className="container-title">
									<h1 className="container__title mb-0">
										{props.actionTittle + " Vendor"}
									</h1>
								</div>
							</div>
						</Grid>
					</>)}
					{ !!state?.id && (
						<Grid item { ...gridPanel }>
							<MaterialUI.Input label={'ID'} state={form.id} disabled />
						</Grid>
					)}
					<Grid item { ...gridPanel }>
						<MaterialUI.Input
							label={'Name'}
							state={form.name}
							setState={(value) => handleChange('name', value)}
							error={errors.name}
						/>
					</Grid>
					<Grid item { ...gridPanel }>
						<MaterialUI.Input
							label={'Address'}
							state={form.address}
							setState={(value) => handleChange('address', value)}
							error={errors.address}
						/>
					</Grid>
					<Grid item { ...gridPanel }>
						<MaterialUI.Input
							label={'Address 2'}
							state={form.address2}
							setState={(value) => handleChange('address2', value)}
							error={errors.address2}
						/>
					</Grid>
					<Grid item { ...gridPanel }>
						<MaterialUI.Input
							label={'City'}
							state={form.city}
							setState={(value) => handleChange('city', value)}
							error={errors.city}
						/>
					</Grid>
					<Grid item { ...gridPanel }>
						<MaterialUI.Input
							label={'State'}
							state={form.state}
							setState={(value) => handleChange('state', value)}
							error={errors.state}
						/>
					</Grid>
					<Grid item { ...gridPanel }>
						<MaterialUI.Input
							label={'Zip Code'}
							state={form.zip_code}
							setState={(value) => handleChange('zip_code', value)}
							error={errors.zip_code}
						/>
					</Grid>
					<Grid item { ...gridPanel }>
						<MaterialUI.Input
							label={'Country'}
							state={form.country}
							setState={(value) => handleChange('country', value)}
							error={errors.country}
						/>
					</Grid>
					<Grid item { ...gridPanel }>
						<MaterialUI.Input
							label={'Phone Number'}
							state={form.phone_number}
							setState={(value) => handleChange('phone_number', value)}
							error={errors.phone_number}
						/>
					</Grid>
					<Grid item { ...gridPanel }>
						<MaterialUI.Input
							label={'Fax'}
							state={form.fax}
							setState={(value) => handleChange('fax', value)}
							error={errors.fax}
						/>
					</Grid>
					<Grid item { ...gridPanel }>
						<MaterialUI.Input
							label={'Email'}
							state={form.email}
							setState={(value) => handleChange('email', value)}
							error={errors.email}
						/>
					</Grid>
					<Grid item { ...gridPanel }>
						<MaterialUI.Input
							label={'Website'}
							state={form.website}
							setState={(value) => handleChange('website', value)}
							error={errors.website}
						/>
					</Grid>
					<Grid item { ...gridPanel }>
						<FormGroup className='flex-row flex-nowrap'>
							<MaterialUI.Autocomplete 
								label="Payment Method"
								optionsList={paymentList}
								state={form.type_payment_id}
								setState={(value) => handleChange('type_payment_id', value)}
								error={errors.type_payment_id}
								className="roundedSides"
							>
								<MaterialUI.Button.Primary type={'button'} style={{ inlineSize: '50px', minWidth: '50px' }} className="roundedSides" size='small' onClick={() => togglePanel('paymentMethod')} disableElevation>
									<AddCircleOutlineSharpIcon fontSize='small' />
								</MaterialUI.Button.Primary>
							</MaterialUI.Autocomplete>
						</FormGroup>
					</Grid>
					<Grid item xs={12} md={12} className='text-end'>
						<MaterialUI.Button.Success type={'submit'}>
							<SaveOutlinedIcon fontSize="small" className="me-2" />{ state?.id ? 'Update' : 'Save'}
						</MaterialUI.Button.Success>
					</Grid>
				</Grid>
			</div>
		</>
	);
}

export { VendorForm, BEHAVIOR };
