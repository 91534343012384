import createHttpV2 from "../lib/httpV2"

export const useItemsVariantsV2 = () => {
  const store = async (payload) => {
    try {
      const response = await createHttpV2.post(`api/item-variants`, payload)
      return response
    } catch (error) {
      throw error
    }
  }
  const update = async (id, payload) => {
    try {
      const response = await createHttpV2.put(`api/item-variants/${id}`, payload)
      return response
    } catch (error) {
      throw error
    }
  }
  const destroy = async (id) => {
    try {
      const response = await createHttpV2.delete(`api/item-variants/${id}`)
      return response
    } catch (error) {
      throw error
    }
  }
  return { store, update, destroy }
}