import axios from "axios";
import {
	setupCache,
	buildMemoryStorage,
	defaultKeyGenerator,
	defaultHeaderInterpreter,
} from "axios-cache-interceptor";

export default class HttpClient {
	constructor(path, time = 5000) {
		this.http = setupCache(
			axios.create({
				baseURL: process.env.REACT_APP_API_URL + path,
				headers: {
					"Content-Type":
						"application/json, application/x-www-form-urlencoded",
					Accept: "application/json",
					Authorization:
						"Bearer " +
						(localStorage.jwt_token !== undefined
							? JSON.parse(localStorage.jwt_token)
							: ""),
				},
			}),
			{
				ttl: time,
				storage: buildMemoryStorage(),
				generateKey: defaultKeyGenerator,
				// headerInterpreter: defaultHeaderInterpreter,
				headerInterpreter: () => {
					return "not enough headers";
				},
				interpretHeader: false,
				debug: console.info,
			},
		);
	}
}
