export const APP_NAME = "Tech4Inventory";
export const LOCAL_STORAGE_APP_CONTEXT_DATA_VAR_NAME = "tech4inventoryContextData";
export const LOCAL_STORAGE_NAVIGATION_EXPANDED_VAR_NAME = "navigationExpanded";
export const LOCAL_STORAGE_USER = "user";
export const S3_URL_PATH = "https://go2storage.s3.us-east-2.amazonaws.com/";
export const DEMO_USER = "test@test.com";
export const CKEDITOR_LICENSE_KEY = "eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3NjU2NzAzOTksImp0aSI6IjU2NTAyZmQzLTZhYjQtNDY5Yy05MmUyLThhYWEzNzMxMTY0ZSIsInVzYWdlRW5kcG9pbnQiOiJodHRwczovL3Byb3h5LWV2ZW50LmNrZWRpdG9yLmNvbSIsImRpc3RyaWJ1dGlvbkNoYW5uZWwiOlsiY2xvdWQiLCJkcnVwYWwiXSwiZmVhdHVyZXMiOlsiRFJVUCIsIkJPWCJdLCJ2YyI6IjQ3ZmYwNjA5In0.lhqX9kNuNox-BU8kA7VJnxjDCTzVBK5k59oM9xjJKD6PeZddODiQWcLRmRcbSIEYgbfAHcknqNETjndSOJ5qtw"

export const SweetColors = {
	confirmButtonColor: "#0C6EFC",
	cancelButtonColor: "#FF6262",
}

export const INITIAL_LOCAL_STORAGE_APP_STATE = {
	[LOCAL_STORAGE_NAVIGATION_EXPANDED_VAR_NAME]: false,
};
