import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import clsx from 'clsx'

import useProductCategory from '../../customHooks/useProductCategory';

import MaterialUI from '../../components/MaterialUI';
import AlertComponent from '../../components/Alert';

import FileUploadImg from "../../assets/img/file-upload.png";

const partTypes = [
	{ value: "PT", label: "Part" },
	{ value: "LB", label: "Labor" },
	{ value: "TR", label: "Tire" },
]

const serialNumbers = [
	{ value: true, label: "true" },
	{ value: false, label: "false" },
]

const CategoryForm = ({ handleBehavior }) => {
  const [showAlert, setShowAlert] = React.useState(false);

	// 👉 Category
	const {category, setCategory, errors, setErrors, isLoading, store, uploadImageCategory} = useProductCategory();
	const handleChange = (key, value) => {
    setErrors((prev) => ({ ...prev, [key]: '' }));
		setCategory((state) => ({ ...state, [key]: value }));
	};
	// 👉 File
  const $pwcf_fileImage = React.useRef();
	const $pwcf_inputImage = React.useRef();
	const [image, setImage] = React.useState();
	const [hasPreviewImage, setHasPreviewImage] = React.useState(false);

  const handleImageChange = (e) => {
		const [file] = e.target.files;
		if(file) {
			setImage(file);
			const reader = new FileReader(file);
			reader.onload = (_) => {
				setHasPreviewImage(true);
				$pwcf_fileImage.current.src = reader.result;
			};
			reader.readAsDataURL(file);
		} else {
			clearInputImage();
		}
	}

	const handleClose = (_, reason) => {
		if (reason === 'clickaway') return;
    setShowAlert(false);
	};

	const clearInputImage = () => {
		if($pwcf_inputImage.current) $pwcf_inputImage.current.value = "";
		if($pwcf_fileImage.current) $pwcf_fileImage.current.src = "";
		setImage();
		setHasPreviewImage(false);
	}

	const parseFormData = (object) => {
		const formData = new FormData();
		for (const key in object) {
			if (Object.hasOwnProperty.call(object, key)) {
				formData.append(key, object[key]);				
			}
		}
		return formData;
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const newCategory = await store(category);
			if(image) {
				const payloadImage = parseFormData({ file: image, category_id: newCategory.id });
				await uploadImageCategory(payloadImage);
			}
			if(newCategory) setShowAlert(true);
			if(handleBehavior && typeof handleBehavior === 'function')
        handleBehavior(newCategory);
		} catch (err) {}
	};
	
  return (<>
		<AlertComponent 
			open={showAlert} 
			handleClose={handleClose} 
			severity="success" 
			message="It has been successfully created"
		/>
		<Grid component="form" onSubmit={handleSubmit} container spacing={1}>
			<Grid item xs={6}>
				<MaterialUI.Input
					label={'Name'}
					state={category.name}
					setState={(value) => handleChange('name', value)}
					error={errors.name}
				/>
			</Grid>
			<Grid item xs={6}>
				<MaterialUI.Select
					label={'Part Type'}
					options={partTypes}
					state={category.partype}
					setState={(value) => handleChange('partype', value)}
					error={errors.partype}
				/>
			</Grid>
			<Grid item xs={6}>
				<MaterialUI.Select
					label={'Serial Number'}
					options={serialNumbers}
					state={category.serial_number}
					setState={(value) => handleChange('serial_number', value)}
					error={errors.serial_number}
				/>
			</Grid>
			<Grid item xs={12}>
				<div className="pwcf-file_container" id="pwcf-file_container">
					<img src={FileUploadImg} className={clsx('pwcf-icon_image_upload', hasPreviewImage && 'd-none' )} alt="Image upload image" />
					<input ref={$pwcf_inputImage} type="file" className="pwcf-file_input" id="file_input" accept="image/*" onChange={handleImageChange}/>
					<img ref={$pwcf_fileImage} id="file_image" className={clsx('pwcf-file_image', (!hasPreviewImage) && 'd-none')} />
					<small className={clsx('pwcf-file_letter', hasPreviewImage && 'd-none')}>Upload image</small>
				</div>
			</Grid>
			<Grid item xs={12}>
				<MaterialUI.Button.Success
					type="submit"
					fullWidth
					variant="contained"
					disabled={isLoading}
				>
					<SaveOutlinedIcon fontSize="small" className="me-2" />
					{ isLoading ? 'Saving...' : 'Save' }
				</MaterialUI.Button.Success>
			</Grid>
		</Grid>
	</>
	)
}

export default CategoryForm