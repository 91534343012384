import { useCallback, useState } from 'react';
import ProductService from '../services/ProductService';

export const useProducts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);

  const fetchProducts = useCallback(async({ params }) => {
    params = {
      per_page: params?.per_page || 15,
      page: params?.page || page,
      ...params
    }
    try {
      setErrors({})
      setIsLoading(true);
      const { status, data, pagination: _pagination } = await ProductService.fetchAll(params);
      if(status) {
        setProducts(data);
        setPagination(_pagination);
        return { data, _pagination };
      }
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, [page]);

  const fetchProductBySKU = useCallback(async({ params }) => {
    params = {
      per_page: 1,
      page: 1,
      ...params
    }
    try {
      const { status, data } = await ProductService.fetchAll(params);
      if(status) return { data };
      return;
    } catch (err) {
      throw err;
    }
  }, []);

  return {
    isLoading,

    setErrors,
    errors,

    fetchProducts,
    products,
    pagination,

    fetchProductBySKU,
    setPage,
    page,
  }
}