import React from "react";
import About from "./views/About/About";
import Layout from "./components/Layout/Layout";
import { UserProfile } from "./views/user_profile";
import { AddItem } from "./views/item";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Categories } from "./views/categories";
import { Vendor } from "./views/Vendor";
import { VendorForm } from "./views/Vendor/vendorForm/Form";
import { CostumerForm } from "./views/costumer/CostumerForm";
import { CostumerFormUpdate } from "./views/costumer/CostumerFormUpdate";
import { ListStockReceipt } from "./views/StockReceipt/Table";
import { AddNewStyledStockReceipt } from "./views/StockReceipt/FormMain";
import { Adjustment } from "./views/Adjustment";
import { AdjustmentForm } from "./views/Adjustment/AdjustmentForm/Form";
import { Invoice } from "./views/Invoice";
import { OnlineSales } from "./views/OnlineSales";
import { Receipt } from "./views/Receipt";
import { ItemInventory } from "./views/item/ItemInventory";
import { Dashboard } from "./views/Dashboard";
import { Settings } from './views/Settings/Settings';
import { Expensive } from "./views/Expensive";
import { ExpensiveNew } from "./views/Expensive/exponsiveNew";
import { ExpensiveForm } from "./views/Expensive/expensiveForm/expensiveForm";
import { UserManagment } from "./views/UserManagment";
import { EditUserManagment } from "./views/UserManagment/editUserManagment";
import { WareHouse } from "./views/WareHouse";
import { NewWareHouse } from "./views/WareHouse/newWareHouse";
import { EditWareHouse } from "./views/WareHouse/editWareHouse";
import { EditExpenseCategories } from "./views/Expensive/exponsiveCategories/editExpenseCategories";

import { WithAuthenticationListener } from "./components/HOCs/withAuthenticationListener";

import { ItemProvider } from "./components/ItemProviders";
import { FormAdd } from "./components/form_add";
import { FormUpdate } from "./components/form_update";
import { Email_been_send } from "./views/login/email_send/Email_been_send";
import { Signup } from "./views/login/signup/Signup";
import { ValidationCode } from "./views/login/signup/ValidationCode";
import { ValidationMethodSelection } from "./views/login/signup/ValidationMethodSelection";
import Login from "./views/login/Login";
import { ForgetPassword } from "./views/login/forget_password/ForgetPassword";
import { useAuthentication } from "./customHooks/useAuthentication";
import { ListComponent } from "./components/listComponent/ListComponent";
import { ListComponentWithPagination } from "./components/listComponent/ListComponentWithPagination";
import { InvoiceForm } from "./views/InvoiceForm/InvoiceForm";
import { EstimateForm } from "./views/InvoiceForm/EstimateForm";
import { InvoiceFormUpdate } from "./views/InvoiceForm/InvoiceFormUpdate";
import { EstimateFormUpdate } from "./views/InvoiceForm/EstimateFormUpdate";
import { PaymentForm } from "./views/InvoiceForm/PaymentForm";
import { PageNotFound } from "./components/pageNotFound/pageNotFound";
import { WorkOrderForm } from "./views/InvoiceForm/WorkOrderForm/WorkOrderForm";
import { WorkOrderFormUpdate } from "./views/InvoiceForm/WorkOrderForm/WorkOrderFormUpdate";
import { ProductForm } from "./views/ProductForm/ProductForm";
import { UpdateProductForm } from "./views/ProductForm/UpdateProductForm";
import { LaborForm } from "./views/ProductForm/LaborForm";
import { UpdateLaborForm } from "./views/ProductForm/UpdateLaborForm";
import { CreatePackageForm } from "./views/package/CreatePackageForm";
import { UpdatePackageForm } from "./views/package/UpdatePackageForm";
import { CreateCompanyForm } from "./views/companies/CreateCompanyForm";
import { UpdateCompanyForm } from "./views/companies/UpdateCompanyForm";
import { ProfileForm } from "./views/user_profile/updateProfile";
import {
	tableHeadersPaymentAccount,
	dataFieldsPaymentAccount,
	tableHeadersTypePayment,
	dataFieldsTypePayment,
	tableHeadersBrands,
	dataFieldsBrands,
	tableHeadersLocations,
	dataFieldsLocations,
	tableHeadersUom,
	dataFieldsUom,
	tableHeadersExpenseCategories,
	dataFieldsExpenseCategories,
	tableHeadersCostumer,
	dataFieldsCostumer,
	mapListCostumersResponse,
	filtersCostumer,
	tableHeadersTypeDevice,
	dataFieldsTypeDevice,
	tableHeadersLabors,
	dataFieldsLabors,
	tableHeadersPackage,
	dataFieldsPackage,
	tableHeadersCompanies,
	dataFieldsCompanies,
	mapListCompaniesList,
	tableHeadersUsers,
	dataFieldsUsers,
	tableHeadersPaymentMethods,
	dataFieldsPaymentMethods,
	tableHeadersToDataFieldsCustomer,
	dataFieldsToOrderFilterCustomer,
	tableHeadersToOrderFilterCustomer,
	tableHeadersToDataFieldsCompanies,
	dataFieldsToOrderFilterCompanies,
	tableHeadersToOrderFilterCompanies,
	tableHeadersToDataFieldsUserManagment,
	dataFieldsToOrderFilterUserManagment,
	tableHeadersToOrderFilterUserManagment,
	mapListUserManagment,
} from "./context/constants";

import {
	typesAndValues as typesAndValuesPaymentAccount,
	initData as initDataPaymentAccount,
} from "./formsDefinitions/paymentAccount";

import {
	typesAndValues as typesAndValuesTypePayment,
	initData as initDataTypePayment,
} from "./formsDefinitions/typePayment";

import {
	typesAndValues as typesAndValuesBrands,
	initData as initDataBrands,
} from "./formsDefinitions/brands";

import {
	typesAndValues as typesAndValuesLocations,
	initData as initDataLocations,
} from "./formsDefinitions/location";

import {
	typesAndValues as typesAndValuesUom,
	initData as initDataUom,
} from "./formsDefinitions/uom";

import {
	typesAndValues as typesAndValuesExpenseCategories,
	initData as initDataExpenseCategories,
} from "./formsDefinitions/expenseCategory";

import {
	typesAndValues as typesAndValuesTypeDevice,
	initData as initDataTypeDevice,
} from "./formsDefinitions/typeDevice";

import {
	typesAndValues as typesAndValuesPaymentMethods,
	initData as initDataPaymentMethods,
} from "./formsDefinitions/paymentMethods";
import { ExpensiveContextProvider } from "./context/ExpensiveContext";
import { PosDesktopContextProvider } from './context/PosDesktopContext';
import PosDesktop from "./views/PosDesktop";
import CashCounterList from "./views/CashCounter/CashCounterList";
import CashCounter from "./views/CashCounter";
import BarcodeGenerator from './views/BarcodeGenerator';
import { SaleContextProvider } from "./context/SaleContext";
import { CashCounterContextProvider } from "./context/CashCounterContext";
import SearchReceipt from "./views/SearchReceipt";
import { Reports } from './views/Reports';
import ErrorBoundary from "./components/ErrorBoundary";

const LayoutWithAuthenticaction = WithAuthenticationListener(Layout);
const ItemInventorWithAuthentication = WithAuthenticationListener(ItemInventory);
const ItemProviderWithAuthentication = WithAuthenticationListener(ItemProvider);

const Routes = (props) => {
	const { currentToken } = useAuthentication();

	return (
		<BrowserRouter token={props.token}>
			<Switch>
				<Route exact path={"/"}>
					<Login />
				</Route>

				<Route exact path={"/check_information/:code?"}>
					<ErrorBoundary>
						<SearchReceipt />
					</ErrorBoundary>
				</Route>
				<Route exact path={"/dashboard"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<Dashboard />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/pos"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<CashCounterContextProvider>
								<PosDesktopContextProvider>
									<PosDesktop />
								</PosDesktopContextProvider>
							</CashCounterContextProvider>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/barcode-generator"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<BarcodeGenerator />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/cash-counter/:id?"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<CashCounterContextProvider>
								<CashCounter />
							</CashCounterContextProvider>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/cash-counters"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<CashCounterContextProvider>
								<CashCounterList />
							</CashCounterContextProvider>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/about"}>
					{currentToken ? <About /> : <Login />}
				</Route>
				<Route exact path={"/create_expensive"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ExpensiveContextProvider>
								<ExpensiveNew />
							</ExpensiveContextProvider>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/expensive"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ExpensiveContextProvider>
								<Expensive />
							</ExpensiveContextProvider>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/expensive_detail"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ExpensiveContextProvider>
								<ExpensiveForm />
							</ExpensiveContextProvider>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/expenses_categories/:id"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<EditExpenseCategories />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/user_profile"}>
					{currentToken ? <UserProfile /> : <Login />}
				</Route>
				<Route exact path={"/product_list"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ItemProviderWithAuthentication>
								<AddItem />
							</ItemProviderWithAuthentication>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/create_item"}>
					{currentToken ? (
						<ErrorBoundary>
							<ItemProviderWithAuthentication>
								<FormAdd />
							</ItemProviderWithAuthentication>
						</ErrorBoundary>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/update_item"}>
					{currentToken ? (
						<ErrorBoundary>
							<ItemProviderWithAuthentication>
								<FormUpdate />
							</ItemProviderWithAuthentication>
						</ErrorBoundary>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/categories_list"}>
					{currentToken ? (
						<ErrorBoundary>
							<Categories showSimpleInputTextFilter={true} />
						</ErrorBoundary>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/vendors_list"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ExpensiveContextProvider>
								<Vendor showSimpleInputTextFilter={true} />
							</ExpensiveContextProvider>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/payment_account_list"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ListComponent
								model={"Payment Account"}
								apiUrl={"payment-account"}
								typesAndValues={typesAndValuesPaymentAccount}
								initData={initDataPaymentAccount}
								bodyInclude={["account"]}
								tableHeaders={tableHeadersPaymentAccount}
								dataFields={dataFieldsPaymentAccount}
								reload={"1"}
								showSimpleInputTextFilter={true}
								keyToFind={"account"}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/type_device_list"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ListComponent
								model={"Type Device"}
								apiUrl={"type-devices"}
								typesAndValues={typesAndValuesTypeDevice}
								initData={initDataTypeDevice}
								bodyInclude={["name"]}
								tableHeaders={tableHeadersTypeDevice}
								dataFields={dataFieldsTypeDevice}
								reload={"9"}
								showSimpleInputTextFilter={true}
								keyToFind={"name"}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/type_payment_list"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ListComponent
								model={"Type Payment"}
								apiUrl={"type-payment?show_inactive=true"}
								typesAndValues={typesAndValuesTypePayment}
								initData={initDataTypePayment}
								bodyInclude={["name", "active"]}
								tableHeaders={tableHeadersTypePayment}
								dataFields={dataFieldsTypePayment}
								reload={"2"}
								showSimpleInputTextFilter={true}
								convertBooleanValues
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				{/* 
        <Route exact path={"/roles"} >

          {currentToken ? (
            <LayoutWithAuthenticaction>
              <ListComponent
                model={'Role'}
                apiUrl={'roles'}
                typesAndValues={typesAndValuesRoles}
                initData={initDataRoles}
                bodyInclude={['name']}
                tableHeaders={tableHeadersRoles}
                dataFields={dataFieldsRoles}
                reload={'3'}
              />
            </LayoutWithAuthenticaction>
          ) : (<Login />)}
        </Route> */}
				<Route exact path={"/brand_list"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ListComponent
								model={"Brand"}
								apiUrl={"brands"}
								typesAndValues={typesAndValuesBrands}
								initData={initDataBrands}
								bodyInclude={["name"]}
								tableHeaders={tableHeadersBrands}
								dataFields={dataFieldsBrands}
								reload={"4"}
								showSimpleInputTextFilter={true}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/location_list"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ListComponent
								model={"Location"}
								apiUrl={"locations"}
								typesAndValues={typesAndValuesLocations}
								initData={initDataLocations}
								bodyInclude={["name"]}
								tableHeaders={tableHeadersLocations}
								dataFields={dataFieldsLocations}
								reload={"5"}
								showSimpleInputTextFilter={true}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/uom_list"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ListComponent
								model={"Size"}
								apiUrl={"uom"}
								typesAndValues={typesAndValuesUom}
								initData={initDataUom}
								bodyInclude={[
									"name",
									"symbol",
									"type",
									"system",
								]}
								tableHeaders={tableHeadersUom}
								dataFields={dataFieldsUom}
								reload={"6"}
								showSimpleInputTextFilter={true}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/expensive_categories"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ListComponent
								model={"Expense Categories"}
								apiUrl={"expense-category"}
								typesAndValues={typesAndValuesExpenseCategories}
								initData={initDataExpenseCategories}
								bodyInclude={["name"]}
								tableHeaders={tableHeadersExpenseCategories}
								dataFields={dataFieldsExpenseCategories}
								reload={"7"}
								showSimpleInputTextFilter={true}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/costumer_list"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ListComponentWithPagination
								model={"Costumers"}
								apiUrl={"clients"}
								typesAndValues={typesAndValuesExpenseCategories}
								initData={initDataExpenseCategories}
								bodyInclude={["name"]}
								tableHeaders={tableHeadersCostumer}
								dataFields={dataFieldsCostumer}
								reload={"8"}
								showSimpleInputTextFilter={true}
								onCreateUrl={"costumer_form"}
								pathname={"/costumer_form_update"}
								pathSearchId={"costumer_id"}
								parseData={true}
								parseFunction={mapListCostumersResponse}
								filtersArray={filtersCostumer}
								initParams={{}}
								tableHeadersToDataFields={
									tableHeadersToDataFieldsCustomer
								}
								dataFieldsToOrderFilter={
									dataFieldsToOrderFilterCustomer
								}
								tableHeadersToOrderFilter={
									tableHeadersToOrderFilterCustomer
								}
								showOrderArrows={true}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/labors_list"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ListComponentWithPagination
								model={"Labors"}
								apiUrl={"items"}
								typesAndValues={typesAndValuesExpenseCategories}
								initData={initDataExpenseCategories}
								bodyInclude={["name"]}
								tableHeaders={tableHeadersLabors}
								dataFields={dataFieldsLabors}
								reload={"10"}
								showSimpleInputTextFilter={true}
								onCreateUrl={"create_labor_form"}
								pathname={"/update_labor_form"}
								pathSearchId={"labor_id"}
								parseData={false}
								parseFunction={() => {}}
								filtersArray={[]}
								initParams={{ get_labors: 1 }}
								tableHeadersToDataFields={[]}
								dataFieldsToOrderFilter={[]}
								tableHeadersToOrderFilter={[]}
								showOrderArrows={false}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/create_vendor"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ExpensiveContextProvider>
								<VendorForm actionTittle={"Create"} />
							</ExpensiveContextProvider>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/invoice_form"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<SaleContextProvider>
								{/* <InvoiceFormIndex /> */}
								<InvoiceForm actionTittle={"Create"} />
							</SaleContextProvider>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/invoice_form_update"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<SaleContextProvider>
								{/* <InvoiceFormIndex /> */}
								<InvoiceFormUpdate actionTittle={"Edit"} />
							</SaleContextProvider>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/estimate_form"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<EstimateForm
								actionTittle={"Create"}
								model={"Estimate"}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/estimate_form_update"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<EstimateFormUpdate
								actionTittle={"Edit"}
								model={"Estimate"}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/payment_form"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<PaymentForm
								actionTittle={"Receive"}
								model={"Payment"}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/work_order_form"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<WorkOrderForm
								actionTittle={"Work"}
								model={"Order"}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/work_order_form_update"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<WorkOrderFormUpdate
								actionTittle={"Edit Work"}
								model={"Order"}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/vendor/:id?"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ExpensiveContextProvider>
								<VendorForm actionTittle={"Update"} />
							</ExpensiveContextProvider>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/costumer_form"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<CostumerForm
								actionTittle={"Create"}
								model={"Customer"}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/costumer_form_update"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<CostumerFormUpdate
								actionTittle={"Edit"}
								model={"Customer"}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/warehouse"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<WareHouse showSimpleInputTextFilter={true} />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/create/warehouse"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<NewWareHouse />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/warehouse/:id"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<EditWareHouse />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/stock_receipt"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ListStockReceipt />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/add_stock_receipt"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ExpensiveContextProvider>
								<AddNewStyledStockReceipt />
							</ExpensiveContextProvider>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/edit_stock_receipt"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<AddNewStyledStockReceipt />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/create_userManagment"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<EditUserManagment />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/user_managment_descontinued"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<UserManagment />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/profiles/:id"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<EditUserManagment />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/profiles/"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<EditUserManagment />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/adjustment"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<Adjustment />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/adjustment_form"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<AdjustmentForm />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/adjustment_form_update"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<AdjustmentForm />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/invoice"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<Invoice />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/sales-online"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<OnlineSales />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/receipt"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<Receipt />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				{/* <Route exact path={"/tax"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<Tax />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route> */}
				<Route exact path={"/item_location"}>
					{currentToken ? (
						<ItemInventorWithAuthentication />
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/forgetpassword"}>
					<Email_been_send />
				</Route>
				<Route exact path={"/sign_up"}>
					<Signup />
				</Route>
				<Route exact path={"/validation_code"}>
					<ValidationCode />
				</Route>
				<Route exact path={"/validation_selection"}>
					<ValidationMethodSelection />
				</Route>
				<Route exact path={"/forgot_password"}>
					<ForgetPassword />
				</Route>
				<Route exact path={"/create_item_form"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ProductForm />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/create_labor_form"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<LaborForm />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/update_labor_form"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<UpdateLaborForm />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/update_item_form"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<UpdateProductForm />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/create_package_form"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<CreatePackageForm />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/update_package_form"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<UpdatePackageForm />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/packages_list"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ListComponentWithPagination
								model={"Package"}
								apiUrl={"packages"}
								typesAndValues={[]}
								initData={{}}
								bodyInclude={["name"]}
								tableHeaders={tableHeadersPackage}
								dataFields={dataFieldsPackage}
								reload={"11"}
								showSimpleInputTextFilter={true}
								onCreateUrl={"/create_package_form"}
								pathname={"/update_package_form"}
								pathSearchId={"package_id"}
								parseData={false}
								parseFunction={() => {}}
								filtersArray={[]}
								initParams={{}}
								tableHeadersToDataFields={[]}
								dataFieldsToOrderFilter={[]}
								tableHeadersToOrderFilter={[]}
								showOrderArrows={false}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/create_company_form"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<CreateCompanyForm />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/company_list"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ListComponentWithPagination
								model={"Companies"}
								apiUrl={"companies"}
								typesAndValues={[]}
								initData={{}}
								bodyInclude={["name"]}
								tableHeaders={tableHeadersCompanies}
								dataFields={dataFieldsCompanies}
								reload={"12"}
								showSimpleInputTextFilter={true}
								onCreateUrl={"/create_company_form"}
								pathname={"/update_company_form"}
								pathSearchId={"company_id"}
								parseData={true}
								parseFunction={mapListCompaniesList}
								filtersArray={[]}
								initParams={{}}
								tableHeadersToDataFields={
									tableHeadersToDataFieldsCompanies
								}
								dataFieldsToOrderFilter={
									dataFieldsToOrderFilterCompanies
								}
								tableHeadersToOrderFilter={
									tableHeadersToOrderFilterCompanies
								}
								showOrderArrows={true}
								isCompanyList={true}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/update_company_form"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<UpdateCompanyForm />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/company_list_deleted"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ListComponentWithPagination
								model={"Deleted Company"}
								apiUrl={"companies"}
								typesAndValues={[]}
								initData={{}}
								bodyInclude={["name"]}
								tableHeaders={tableHeadersCompanies}
								dataFields={dataFieldsCompanies}
								reload={"13"}
								showSimpleInputTextFilter={true}
								onCreateUrl={"/create_company_form"}
								pathname={"/update_company_form"}
								pathSearchId={"company_id"}
								parseData={true}
								parseFunction={mapListCompaniesList}
								filtersArray={[]}
								initParams={{ with_deleted: 1 }}
								tableHeadersToDataFields={[]}
								dataFieldsToOrderFilter={[]}
								tableHeadersToOrderFilter={[]}
								showOrderArrows={false}
								isCompanyList={true}
								isDeleteCompanies={true}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/user_managment"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ListComponentWithPagination
								model={"User Management"}
								apiUrl={"profiles"}
								typesAndValues={[]}
								initData={{}}
								bodyInclude={["name"]}
								tableHeaders={tableHeadersUsers}
								dataFields={dataFieldsUsers}
								reload={"14"}
								showSimpleInputTextFilter={true}
								onCreateUrl={"/profiles"}
								pathname={"/profiles/"}
								pathSearchId={"company_id"}
								parseData={true}
								parseFunction={mapListUserManagment}
								filtersArray={[]}
								initParams={{}}
								tableHeadersToDataFields={
									tableHeadersToDataFieldsUserManagment
								}
								dataFieldsToOrderFilter={
									dataFieldsToOrderFilterUserManagment
								}
								tableHeadersToOrderFilter={
									tableHeadersToOrderFilterUserManagment
								}
								showOrderArrows={true}
								isCompanyList={false}
								isDeleteCompanies={false}
								typeRedirect={"User"}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/offline_payment_methods_list"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ListComponent
								model={"Payment Method"}
								apiUrl={"offline-payment-methods"}
								typesAndValues={typesAndValuesPaymentMethods}
								initData={initDataPaymentMethods}
								bodyInclude={["name", "status", "description"]}
								tableHeaders={tableHeadersPaymentMethods}
								dataFields={dataFieldsPaymentMethods}
								reload={"15"}
								showSimpleInputTextFilter={true}
								keyToFind={"name"}
							/>
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route exact path={"/update_profile"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<ProfileForm />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route path={"/settings"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<Settings />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route path={"/reports"}>
					{currentToken ? (
						<LayoutWithAuthenticaction>
							<Reports />
						</LayoutWithAuthenticaction>
					) : (
						<Login />
					)}
				</Route>
				<Route path="*">
					<PageNotFound />
				</Route>
				<Route exact path="/404">
					<PageNotFound />
				</Route>
				{/* list='item_search' */}
			</Switch>
		</BrowserRouter>
	);
};

export default Routes;
