import axios from "axios";
import React from "react";
import createHttp from "../../lib/http";
import { useHistory } from "react-router-dom";
import {
	enabledState,
	disabledState,
	amountPerPagePagination,
} from "../../context/constants";
import { resourcesHTTP } from "../../lib/tech4Inventory.client";
import NumberFormat from "../../utils/number.format";
import { getImageBySize } from "../../utils";
import { SIZES } from "../Product/ProductDetail";

const ItemContext = React.createContext();

function ItemProvider(props) {
	const [category_array, setCategoryArray] = React.useState([]);
	const [brand_array, setBrandArray] = React.useState([]);
	const [uom_array, setUomArray] = React.useState([]);
	const [vendors_array, setVendorsArray] = React.useState([]);
	const [locations_array, setLocationsArray] = React.useState([]);
	const [, setItemId] = React.useState("");
	const [in_response, setInResponse] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [successfullResponse, setSuccessfullResponse] = React.useState(false);
	const [showModalResponse, setShowModalResponse] = React.useState(false);
	const [responseMessageError, setResponseMessageError] = React.useState("");
	const [stock_receipt, setStockReceipt] = React.useState([]);
	const [sincronizedItems, setSincronizedItems] = React.useState(false);
	const [showSuccessButton, setShowSuccessButton] = React.useState(true);
	const [, setCurrentItemId] = React.useState(null);
	const [status_array] = React.useState([
		{ id: enabledState, name: "Active" },
		{ id: disabledState, name: "Inactive" },
	]);
	const [itemFilters, setItemFilters] = React.useState([]);
	const [itemImageArray, setItemImageArray] = React.useState([]);
	const [itemImageToRemove, setItemImageToRemove] = React.useState([]);
	const [itemImageToAdd, setItemImageToAdd] = React.useState([]);
	const [warehouse, setWareHouse] = React.useState([]);
	const [itemPagination, setItemPagination] = React.useState([]);
	const [skuNumber, setSkuNumber] = React.useState(null);
	const [, setSincronizewareWarehouse] = React.useState(false);

	React.useEffect(async () => {
		const getResponse = async () => {
			setIsLoading(true);

			let params = {
				page: "1",
				per_page: amountPerPagePagination,
			};

			itemFilters
				.filter((filter) => filter.value)
				.map((filter) => (params[filter.field_id] = filter.value));

			const getPrice = (itemVariants = []) => {
				if (itemVariants.length == 0) return NumberFormat.currency(0)
				if (itemVariants.length == 1) return NumberFormat.currency(itemVariants[0].price || 0)
				
				const prices = itemVariants.map(iv => iv.price)
				const minPrice = Math.min(...prices)
				const maxPrice = Math.max(...prices)
				if (minPrice == maxPrice) return NumberFormat.currency(maxPrice)
				return `${NumberFormat.currency(minPrice)} / ${NumberFormat.currency(maxPrice)}`				
			}

			const getCost = (itemVariants = []) => {
				if (itemVariants.length == 0) return NumberFormat.currency(0)
				if (itemVariants.length == 1) return NumberFormat.currency(itemVariants[0].cost || 0)
				
				const costs = itemVariants.map(iv => iv.cost)
				const minPrice = Math.min(...costs)
				const maxPrice = Math.max(...costs)
				if (minPrice == maxPrice) return NumberFormat.currency(maxPrice)
				return `${NumberFormat.currency(minPrice)} / ${NumberFormat.currency(maxPrice)}`				
			}

			if (!sincronizedItems) {
				await createHttp
					.get("api/items", { params })
					.then((response) => {
						if (response.data.status) {
							setItemPagination(response.data.pagination);
							const formatted = response.data.data.map((item) => {
								const price = getPrice(item.item_variants || [])
								const cost = getCost(item.item_variants || [])
								const images = getImageBySize(item.images, SIZES['275'], item?.name)
								return { ...item, cost, price, images }
							})
							setStockReceipt(formatted);
						}
						setSincronizedItems(true);
						setIsLoading(false);
					})
					.catch((response) => {
						setSincronizedItems(true);
						setIsLoading(false);
					});
			}
			setIsLoading(false);
		};

		getResponse();
	}, [sincronizedItems]);

	React.useEffect(async () => {
		const getInfo = async () => {
			// setIsLoading(true);
			resourcesHTTP
				.getResources()
				.then((response) => {
					if (response.status) {
						const { data } = response || {};
						if (data && Object.keys(data).length > 0) {
							const {
								brands,
								categories,
								locations,
								uoms,
								vendors,
								warehouses,
							} = data;

							setCategoryArray(categories || []);
							setBrandArray(brands || []);
							setUomArray(uoms || []);
							setVendorsArray(vendors || []);
							setLocationsArray(locations || []);
							setWareHouse(warehouses || []);
						}
					}
				})
				.catch((error) => {
					console.error("error", error);
				});
		};

		getInfo();

		return () => {
			setCategoryArray([]);
			setBrandArray([]);
			setUomArray([]);
			setLocationsArray([]);
		};
	}, []);

	const app_conf = {
		title: "Products",
	};

	const create_table_add_configuration = [
		{
			field_id: "name",
			field_name: "Name",
			type: "text",
			length: 15,
			value: "",
			required: true,
			have_error: false,
			update_field: true,
		},
		{
			field_id: "category_id",
			field_name: "Category ",
			type: "array",
			length: 100,
			value: "",
			required: true,
			have_error: false,
			update_field: true,
			array_values: category_array,
		},
		{
			field_id: "brand_id",
			field_name: "Brand",
			type: "array",
			length: 100,
			value: "",
			required: true,
			have_error: false,
			update_field: true,
			array_values: brand_array,
		},
		{
			field_id: "price",
			field_name: "Price",
			type: "number",
			length: 7,
			value: "",
			required: true,
			have_error: false,
			update_field: true,
		},
		{
			field_id: "cost",
			field_name: "Cost",
			type: "number",
			length: 7,
			value: "",
			required: true,
			have_error: false,
			update_field: true,
		},
		{
			field_id: "uom_id",
			field_name: "Uom",
			type: "array",
			length: 100,
			value: "",
			required: true,
			have_error: false,
			update_field: true,
			array_values: uom_array,
		},
		{
			field_id: "upc",
			field_name: "UPC",
			type: "text",
			length: 100,
			value: "",
			required: true,
			have_error: false,
			update_field: true,
		},
		{
			field_id: "sku",
			field_name: "SKU",
			type: "text",
			length: 100,
			value: "",
			required: true,
			have_error: false,
			update_field: true,
		},
		{
			field_id: "images",
			field_name: "Logo",
			type: "img",
			length: 100,
			value: "",
			required: false,
			have_error: false,
			update_field: false,
		},
		{
			field_id: "vendors",
			field_name: "Vendors",
			type: "array_checkbox",
			length: 100,
			value: [],
			required: false,
			have_error: false,
			update_field: true,
			array_values: vendors_array,
		},
		{
			field_id: "locations",
			field_name: "Location",
			type: "array_checkbox",
			length: 100,
			value: [],
			required: false,
			have_error: false,
			update_field: true,
			array_values: locations_array,
		},
		{
			field_id: "active",
			field_name: "Status",
			type: "array",
			length: 100,
			value: "",
			required: true,
			have_error: false,
			update_field: true,
			array_values: status_array,
		},
	];

	const table_id_configuration = [
		//{ field_id : 'id' , field_name : 'Id', type : 'text' , search : false },
		{ field_id: "name", field_name: "Name", type: "text", search: true },
		{
			field_id: "images",
			field_name: "Images",
			type: "arrays_img",
			keys: [{ name: "path" }],
			search: false,
		},
		//{ field_id : 'locations' , field_name : 'Locations', type : 'text' , search : false},
		{ field_id: "price", field_name: "Price", type: "text", search: false },
		{ field_id: "sku", field_name: "SKU", type: "text", search: true },
		{ field_id: "upc", field_name: "UPC", type: "text", search: true },
		{
			field_id: "vendors",
			field_name: "Vendors",
			type: "arrays",
			keys: [{ name: "name" }],
			search: false,
		},
		{
			field_id: "uom_id",
			field_name: "UOM",
			type: "array",
			keys: [{ name: "name" }, { name: "system" }],
			search: false,
		}, //INDEXES  id  name symbol system type
		//    { field_id : 'created_at' , field_name : 'Created At', type : 'date' , search : false },
		//    { field_id : 'updated_at' , field_name : 'Updated At', type : 'date' , search : false},
	];

	const api_configuration = {
		get_all_data: "/api/items",
		store_item: "/api/items",
		delete_item: "/api/items",
		update_item: "/api/items",
		get_item: "/api/items",
	};

	const [table_add_configuration, updateAddConfiguration] = React.useState(
		create_table_add_configuration,
	);

	function setFistState(array) {
		updateAddConfiguration(array);
	}

	const setEmptyValues = () => {
		let array = [...create_table_add_configuration];
		array.map((element) => {
			element.value = "";
			element.have_error = false;
			return element;
		});
		updateAddConfiguration(array);
	};

	const verifyErrors = () => {
		let new_array = [...table_add_configuration];

		let cont = 0;
		let have_changes = 0;

		for (let index = 0; index < new_array.length; index++) {
			if (
				(new_array[index].type === "text" ||
					new_array[index].type === "number" ||
					new_array[index].type === "array") &&
				new_array[index].required &&
				new_array[index].value.toString().trim() === ""
			) {
				new_array[index].have_error = true;
				cont++;
				have_changes++;
			} else if (
				(new_array[index].type === "text" ||
					new_array[index].type === "number" ||
					new_array[index].type === "array") &&
				new_array[index].required &&
				new_array[index].have_error &&
				new_array[index].value.toString().trim() !== ""
			) {
				new_array[index].have_error = false;
				have_changes++;
			}

			if (
				new_array[index].type === "img" &&
				new_array[index].required &&
				new_array[index].value === ""
			) {
				new_array[index].have_error = true;
				cont++;
				have_changes++;
			} else if (
				new_array[index].type === "img" &&
				new_array[index].required &&
				new_array[index].value !== ""
			) {
				new_array[index].have_error = false;
				have_changes++;
			}

			if (
				new_array[index].type === "array_checkbox" &&
				new_array[index].required &&
				new_array[index].value.length <= 0
			) {
				new_array[index].have_error = true;
				cont++;
				have_changes++;
			} else if (
				new_array[index].type === "array_checkbox" &&
				new_array[index].required &&
				new_array[index].value.length > 0
			) {
				new_array[index].have_error = false;
				have_changes++;
			}
		}

		if (cont > 0) {
			updateAddConfiguration(new_array);
			return false;
		}

		if (have_changes > 0) {
			updateAddConfiguration(new_array);
		}

		return true;
	};

	const storeNewItem = async () => {
		let form = new FormData();

		for (let index = 0; index < table_add_configuration.length; index++) {
			if (table_add_configuration[index].type === "array_checkbox") {
				for (
					let index_2 = 0;
					index_2 < table_add_configuration[index].value.length;
					index_2++
				) {
					form.append(
						table_add_configuration[index].field_id + "[]",
						table_add_configuration[index].value[index_2].id,
					);
				}
			} else if (
				table_add_configuration[index].type === "img" &&
				table_add_configuration[index].value
			) {
				if (itemImageToAdd.length > 0) {
					itemImageToAdd.map((itemFile) => {
						if (itemFile instanceof File) {
							form.append(
								table_add_configuration[index].field_id + "[]",
								itemFile,
							);
						}
					});
				}
				// form.append( table_add_configuration[index].field_id+'[]' , table_add_configuration[index].value);
			} else {
				if (table_add_configuration[index].field_id !== "active") {
					form.append(
						table_add_configuration[index].field_id,
						table_add_configuration[index].value,
					);
				} else {
					form.append(
						table_add_configuration[index].field_id,
						table_add_configuration[index].value === enabledState
							? true
							: false,
					);
				}
			}
		}

		if (in_response) {
			return;
		}

		setInResponse(true);

		setIsLoading(true);
		await createHttp
			.post(api_configuration.store_item, form, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.status) {
					changeStateInNewItem(
						res.data.data.id,
						table_add_configuration,
					);
					setInResponse(false);
					updateAddConfiguration(create_table_add_configuration);
					setSuccessfullResponse(true);
					setItemImageToRemove([]);
					setItemImageToAdd([]);
					setItemImageArray([]);
				}
				if (!res.data.status) {
					setSuccessfullResponse(false);
					setResponseMessageError(res.data.message);
				}
				setShowModalResponse(true);
				setInResponse(false);
			})
			.catch((res) => {
				setIsLoading(false);
				setInResponse(false);
			});

		return true;
	};

	const updateItemState = (productId) => {
		createHttp
			.post(
				api_configuration.store_item +
					"/" +
					productId +
					"/" +
					"toggleStatus",
			)
			.then((res) => res)
			.catch((res) => {});
	};

	const storeNewItemStockReceipt = () => {
		let form = new FormData();

		for (let index = 0; index < table_add_configuration.length; index++) {
			if (table_add_configuration[index].type === "array_checkbox") {
				for (
					let index_2 = 0;
					index_2 < table_add_configuration[index].value.length;
					index_2++
				) {
					form.append(
						table_add_configuration[index].field_id + "[]",
						table_add_configuration[index].value[index_2].id,
					);
				}
			} else if (table_add_configuration[index].type === "img") {
				form.append(
					table_add_configuration[index].field_id + "[]",
					table_add_configuration[index].value,
				);
			} else {
				if (table_add_configuration[index].field_id !== "active") {
					form.append(
						table_add_configuration[index].field_id,
						table_add_configuration[index].value,
					);
				} else {
					form.append(
						table_add_configuration[index].field_id,
						table_add_configuration[index].value === enabledState
							? true
							: false,
					);
				}
			}
		}

		if (in_response) {
			return;
		}
		setInResponse(true);

		createHttp
			.post(api_configuration.store_item, form, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				if (res.data.status) {
					props.getElementWhenAddNewItem();
					props.handleCloseAdd();
				}
				if (!res.data.status) {
				}
				setInResponse(false);
			})
			.catch((res) => {
				setInResponse(false);
			});
	};

	const updateItem = (productId) => {
		let form = new FormData();

		for (let index = 0; index < table_add_configuration.length; index++) {
			if (table_add_configuration[index].type === "array_checkbox") {
				for (
					let index_2 = 0;
					index_2 < table_add_configuration[index].value.length;
					index_2++
				) {
					form.append(
						table_add_configuration[index].field_id + "[]",
						table_add_configuration[index].value[index_2].id,
					);
				}
			} else if (
				table_add_configuration[index].type === "img" &&
				table_add_configuration[index].value
			) {
				if (itemImageToAdd.length > 0) {
					itemImageToAdd.map((itemFile) => {
						if (itemFile instanceof File) {
							form.append("new_images" + "[]", itemFile);
						}
					});
				}
				// if(table_add_configuration[index].value instanceof File) {
				//     form.append( 'new_images'+'[]' , table_add_configuration[index].value);
				// }
			} else {
				form.append(
					table_add_configuration[index].field_id,
					table_add_configuration[index].value,
				);
			}
		}

		if (itemImageToRemove.length > 0) {
			itemImageToRemove.map((imageItem) => {
				if (parseInt(imageItem.id)) {
					form.append(
						"removed_images" + "[]",
						imageItem.id.toString(),
					);
				}
			});
		}

		form.append("_method", "PUT");

		createHttp
			.post(api_configuration.update_item + "/" + productId, form, {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization:
						"Bearer " +
						(localStorage.jwt_token !== undefined
							? JSON.parse(localStorage.jwt_token)
							: ""),
				},
			})
			.then((response) => {
				checkStateItemAndUpdate(productId, table_add_configuration);
				setApiResponses(response, true, true);
			})
			.catch((res) => {});
	};

	const [initStateItem, setInitStateItem] = React.useState("");
	const setInitStateOfItem = (state) => {
		setInitStateItem(state);
	};

	const getNewStateValue = (table) => {
		return table[
			table.indexOf(
				table.find((tableItem) => tableItem.field_id === "active"),
			)
		].value;
	};

	const checkStateItemAndUpdate = (productId, table) => {
		const newStateValue = getNewStateValue(table);

		if (!(initStateItem === newStateValue)) {
			updateItemState(productId);
		}
	};

	const changeStateInNewItem = (productId, table) => {
		const newStateValue = getNewStateValue(table);

		if (newStateValue === disabledState) {
			updateItemState(productId);
		}
	};

	const setApiResponses = (response, showModal = false, isEdit = false) => {
		if (response.data.status) {
			setSuccessfullResponse(true);
			setShowModalResponse(showModal);
			setShowSuccessButton(true);
			setItemImageToRemove([]);
			setItemImageToAdd([]);
			setItemImageArray([]);
		}
		if (!response.data.status) {
			console.error("error", response);
			setSuccessfullResponse(false);
			setResponseMessageError(response.data.message);
			setShowSuccessButton(false);
			if (isEdit) setShowModalResponse(showModal);
		}
	};

	const getDeleteItemResponse = async (id) => {
		await createHttp
			.delete(api_configuration.update_item + "/" + id)
			.then((response) => {
				setApiResponses(response);
			})
			.catch((error) => {
				console.error("this is error", error);
			});
	};

	const deleteItem = async (productId) => {
		setIsLoading(true);
		await getDeleteItemResponse(productId);
		setSincronizedItems(false);
	};

	const getItemResponse = async (id) => {
		return await createHttp
			.get(api_configuration.update_item + "/" + id)
			.then((response) => response.data)
			.catch((error) => ({
				data: {
					error: true,
					message: "Item not found",
				},
			}));
	};

	const [itemValues, setItemValues] = React.useState([]);

	const textAndNumberValues = ["text", "number"];
	const selectValues = ["array"];
	const multipleSelectValues = ["array_checkbox"];
	const imgValues = ["img"];

	const parseObject = () => {
		let object = {};
		table_add_configuration.map((item, index) => {
			if (textAndNumberValues.includes(item.type)) {
				object[item.field_id] = item.value;
			}
			if (selectValues.includes(item.type)) {
				if (item.field_id !== "active") {
					object[item.field_id] = item.value;
				} else {
					object[item.field_id] =
						item.value === enabledState ? true : false;
				}
			}
			if (multipleSelectValues.includes(item.type)) {
				object[item.field_id] = item.value.map((item) => item.id);
			}
			if (imgValues.includes(item.type)) {
				object["new_images"] = [];
				object["new_images"].push(item.value);
			}
		});

		return object;
	};

	const getItem = async (productId) => {
		try {
			setIsLoading(true);
			const { data } = await getItemResponse(productId);

			if (!data || (data.hasOwnProperty("error") && data.error)) {
				// call error modal
				return;
			}

			const copyTableInit = [...create_table_add_configuration];

			create_table_add_configuration.map((tableItem, index) => {
				if (textAndNumberValues.includes(tableItem.type)) {
					copyTableInit[index].value = data[tableItem.field_id];
				}
				if (selectValues.includes(tableItem.type)) {
					if (tableItem.field_id !== "active") {
						copyTableInit[index].value =
							data[tableItem.field_id]?.id.toString() || "";
					} else {
						copyTableInit[index].value = data[tableItem.field_id]
							? enabledState
							: disabledState;
					}
				}
				if (multipleSelectValues.includes(tableItem.type)) {
					copyTableInit[index].value = data[tableItem.field_id].map(
						(item) => ({
							id: item.id,
						}),
					);
				}
				if (imgValues.includes(tableItem.type)) {
					copyTableInit[index].value =
						data[tableItem.field_id][0]?.path;
					setItemImageArray(data[tableItem.field_id]);
				}
			});

			setInitStateOfItem(
				copyTableInit[
					copyTableInit.indexOf(
						copyTableInit.find(
							(tableItem) => tableItem.field_id === "active",
						),
					)
				].value,
			);

			setItemValues(copyTableInit);
			setIsLoading(false);
		} catch (error) {
			console.error("Has error", error);
		}
	};

	const updateTable = (values) => {
		if (skuNumber) {
			values
				.filter((item) => ["sku", "upc"].includes(item.field_id))
				.map((item) => (item.value = skuNumber));
		}
		updateAddConfiguration(values);
	};

	React.useEffect(() => {
		const update = () => {
			const copy = [...create_table_add_configuration];
			if (itemValues.length > 0) {
				itemValues.map((itemValue, index) => {
					copy[index].value = itemValue.value;
				});
			}
			updateTable(copy);
		};

		update();
		return () => {
			updateAddConfiguration(create_table_add_configuration);
		};
	}, [
		category_array,
		brand_array,
		uom_array,
		vendors_array,
		locations_array,
		itemValues,
		skuNumber,
	]);

	return (
		<ItemContext.Provider
			value={{
				updateTable,
				app_conf,
				create_table_add_configuration,
				table_id_configuration,
				api_configuration,
				table_add_configuration,
				updateAddConfiguration,
				setFistState,
				setEmptyValues,
				verifyErrors,
				storeNewItem,
				storeNewItemStockReceipt,
				updateItem,
				setItemId,
				isLoading,
				setIsLoading,
				successfullResponse,
				showModalResponse,
				setShowModalResponse,
				responseMessageError,
				stock_receipt,
				setStockReceipt,
				sincronizedItems,
				setSincronizedItems,
				deleteItem,
				setResponseMessageError,
				showSuccessButton,
				setShowSuccessButton,
				getItem,
				setCurrentItemId,
				textAndNumberValues,
				selectValues,
				multipleSelectValues,
				imgValues,
				setItemFilters,
				itemFilters,
				setItemImageArray,
				itemImageArray,
				itemImageToRemove,
				setItemImageToRemove,
				itemImageToAdd,
				setItemImageToAdd,
				warehouse,
				setWareHouse,
				itemPagination,
				setItemPagination,
				setSkuNumber,
				setSincronizewareWarehouse,
			}}
		>
			{props.children}
		</ItemContext.Provider>
	);
}

export { ItemContext, ItemProvider };
