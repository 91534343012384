import React from "react";
import { useParams, useHistory } from 'react-router-dom'
import { makeStyles } from "@material-ui/core";
import clsx from 'clsx'

import Http from '../../lib/http';

import LogoT4 from '../../assets/img/logo-opacity-30.png'
import PageNotFound from '../../assets/img/page_not_found.png'
import Material from '../../components/MaterialUI'
import ReceiptPdf from "../Receipt/ReceiptPdf";

const useStyles = makeStyles((theme) => ({
  root: {
    inlineSize: '100vw',
    minBlockSize: '100vh',
    display: 'grid',
    placeItems: 'center',
    background: `var(--login-blue) url(${LogoT4})`,
    backgroundSize: '75px',
    fontFamily: 'Inter, sans-serif',
    position: 'relative',
    "& .receipt": {
      minBlockSize: "100vh",
      backgroundColor: "#FFF",
      inlineSize: "100%",
      paddingInline: ".75rem",
      [theme.breakpoints.up('md')]: {
        inlineSize: "450px",
      }
    }
  },
  contentCenter: {
    minBlockSize: '100vh',
    inlineSize: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  formSearchTitle: {
    textAlign: 'center',
    fontWeight: '700',
    color: '#DE7109',
    marginBottom: '0',
  },
  customInput: {
    '& .MuiInputBase-input': {
      textAlign: 'center',
      fontWeight: 'bold',
      color: 'var(--login-blue)',
      fontFamily: 'Inter',
      fontSize: '1rem',
      padding: '1rem',
      textTransform: 'uppercase',
    }
  },
  borderDouble: {
    '&::after': {
      content: '=' 
    }
  }
}));

const STEPS = {
  SEARCH_FORM: "SEARCH_FORM",
  RECEIPT_NOT_FOUND: "RECEIPT_NOT_FOUND",
  SHOW_RECEIPT: "SHOW_RECEIPT",
  LOADING_PAGE: "LOADING_PAGE",
}

const SearchReceipt = () => {
  const classes = useStyles();
  const history = useHistory();
  const {code} = useParams();
  const [search, setSearch] = React.useState("");
  const [receipt, setReceipt] = React.useState({});
  const [currentStep, setCurrentStep] = React.useState(STEPS.LOADING_PAGE);

  const handleSearch = async (_code) => {
    if(!_code) return;
    try {
      setCurrentStep(STEPS.LOADING_PAGE);
      const { data } = await Http.get(`/api/retrieve_document_info/${_code}`);
			if(data.status) {
        setReceipt(data.data);
        setCurrentStep(STEPS.SHOW_RECEIPT);
      } else {
        setCurrentStep(STEPS.RECEIPT_NOT_FOUND);
      }
    } catch (err) {
      setCurrentStep(STEPS.RECEIPT_NOT_FOUND);
    }
  }

  const cleanSearchForm = () => {
    setSearch("");
    setReceipt({});
    setCurrentStep(STEPS.SEARCH_FORM);
    if(code) history.replace('/search-receipt');
  }
  
  React.useEffect(() => {
    if(code) {
      setSearch(code);
      handleSearch(code);
    } else {
      setCurrentStep(STEPS.SEARCH_FORM)
    }
  }, [code]);
  
	return <div className={classes.root}>
    <div className="receipt">
      {currentStep === STEPS.RECEIPT_NOT_FOUND && <NotFoundPage showButtonBack={!code} cleanSearchForm={cleanSearchForm}/> }
      {currentStep === STEPS.LOADING_PAGE && <LoadingPage /> }
      {currentStep === STEPS.SEARCH_FORM && <FormSearch search={search} setSearch={setSearch} onSearch={handleSearch}/>}
      {currentStep === STEPS.SHOW_RECEIPT && <ReceiptPreview receipt={receipt} showButtonBack={!code} cleanSearchForm={cleanSearchForm}/> }
    </div>
  </div>;
};

const LoadingPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.contentCenter}>
      <div className="w-100 text-center">
        <i className="fa-pulse fa-spin fa-spinner fas fs-3 mb-2"></i>
        <p className="text-muted fs-small mb-0">Loading</p>
      </div>
    </div>
  );
}

const NotFoundPage = ({ showButtonBack, cleanSearchForm }) => {
  const classes = useStyles();
  return (
    <div className={classes.contentCenter}>
      <div className="w-100 text-center">
        <img src={PageNotFound} className="img-fluid mb-2" />
        <p className="text-muted fs-small mb-0">Receipt not found</p>
        {showButtonBack && (
          <Material.Button.Dark className="fs-small" onClick={cleanSearchForm}>
            <i className="fas fa-home me-2"></i>Search Again
          </Material.Button.Dark>
        )}
      </div>
    </div>
  );
}

const ReceiptPreview = ({ receipt, showButtonBack, cleanSearchForm }) => {
  return (<>
    <div style={{ fontSize: '12px' }} className="text-center">
      <ReceiptPdf receipt={receipt} />
      {showButtonBack && (
        <Material.Button.Dark className="fs-small mt-3" onClick={cleanSearchForm}>
          <i className="fas fa-home me-2"></i>Search Again
        </Material.Button.Dark>
      )}
    </div>
  </>)
}

const FormSearch = ({ search, setSearch, onSearch }) => {
  const classes = useStyles();
  const input = React.useRef();
  
  React.useEffect(() => {
    if(input.current) {
      input.current.focus();
    }
  }, []);
  
  return (
    <div className={classes.contentCenter}>
      <div className="text-center w-100">
        <i className="fas fa-receipt fa-4x mb-4" style={{ color: '#041731'}}></i>
        <h4 className={clsx(classes.formSearchTitle)}>Search Receipt</h4>
        <p className="mb-4 text-muted text-center fs-small">Enter the code to find your receipt</p>
        <div className="divider-double"></div>
        <Material.Input
          className={clsx('mb-2', classes.customInput)}
          size="medium"
          state={search}
          setState={setSearch}
          placeholder="Enter Code"
          inputRef={input}
        />
        <Material.Button.Darkblue disabled={!search} onClick={() => onSearch(search)}>
          <i className="fas fa-search me-2"></i> Search
        </Material.Button.Darkblue>
      </div>
    </div>
  );
}

export default SearchReceipt;
