import { FaArrowDown, FaArrowUp } from "react-icons/fa"

export const ArrowSort = ({ value, children, filterBy, field, className = '', handleChange }) => {
  const sortBy = value === "asc" ? "desc" : "asc"
  
  const handleClick = (sort) => {
    if (field == filterBy && value == sort) {
      handleChange({ field: "id", newSort: "asc" })
    } else {
      handleChange({ field, newSort: sort })
    }
  }
  
  const handleSortArrowClick = (ev, sort) => {
    ev.preventDefault()
    ev.stopPropagation()
    if (field == filterBy && sortBy != sort) return
    handleChange({ field, newSort: sort })
  }

   return (
    <button
      style={{ background: 'transparent', border: 'none', outline: 'none' }}
      className={`${className} d-flex gap-1 align-items-center user-select-none`}
      onClick={(ev) => {
        ev.stopPropagation()
        ev.preventDefault()
        handleClick(sortBy)
      }}
      type="button"
      role="button"
    >
      <span className="d-flex flex-column" style={{ gap: '0.2rem' }}>
        <FaArrowUp 
          className={`sort-arrow ${filterBy === field && value === "asc" ? "text-primary" : ""}`}
          onClick={(ev) => handleSortArrowClick(ev, "asc")}
          style={{ fontSize: 7 }}
        />
        <FaArrowDown 
          className={`sort-arrow ${filterBy === field && value === "desc" ? "text-primary" : ""}`}
          onClick={(ev) => handleSortArrowClick(ev, "desc")}
          style={{ fontSize: 7 }}
        />
      </span>
      <span className="sort-arrow">{children}</span>
    </button>
  )
}

export default ArrowSort