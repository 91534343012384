import { Checkbox, DialogTitle, FormControlLabel, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import LayersTwoToneIcon from '@material-ui/icons/LayersTwoTone';
import VisibilityIcon from '@material-ui/icons/Visibility'
import Pagination from '@material-ui/lab/Pagination'
import React, { useEffect, useState } from 'react'
import { FaSpinner, FaCheckCircle, FaClock } from "react-icons/fa";
import { useLocation } from 'react-router-dom'

import { useInvoiceOnlineSale, initialFilters, LOADING, OPTIONS } from '../../customHooks/useInvoiceOnlineSale'
import Dialog from '../../components/Dialog'
import MaterialUI from '../../components/MaterialUI'
import useDebounce from '../../hooks/useDebounce'
import ArrowSort from '../../components/ArrowSort'
import OnlineSalesCard from './OnlineSalesCard'


const useStyles = makeStyles({
  root: {'&.Mui-focused': { color: '#0b5ed7' }},
  table: { padding: 0 },
  container: { maxBlockSize: '78vh' },
  formControl: { minWidth: 120 }
})

export const OnlineSales = () => {
  const classes = useStyles()
  const { fetchAll, pagination, invoices, loading } = useInvoiceOnlineSale()
  const [selectedInvoice, setSelectedInvoice] = useState(undefined)
  const [showDialog, setShowDialog] = useState(false)
  const [filters, setFilters] = useState(initialFilters)
  const [selectedId, setSelectedId] = useState()
  const location = useLocation()
  
  const updateFilter = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }))
  }

  useDebounce(() => {
    fetchAll(filters)
  }, 500, [filters])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    setSelectedId(searchParams.get('saleId'))
  }, [location])

  const handlerOnChange = (newValue) => {
    setFilters((prev) => ({
      ...prev,
      field_order: newValue.field,
      type_order: newValue.newSort,
    }))
  }

  const onSelectInvoice = (selected) => {
    setSelectedInvoice(selected)
    setShowDialog(true)
  }
  
  const onCloseDetails = () => {
    setShowDialog(false)
    setSelectedInvoice(undefined)
  }
  
  return (
    <>
      <div className="box container-fluid">
        <div className="container-title mb-3">
          <h1 className="container__title mb-0">Invoices Online Sales</h1>
        </div>
        <Grid container spacing={0}>
          <Grid container spacing={1} className="mb-1">
            <Grid item xs={12} md={4} lg={2}>
              <MaterialUI.Input
                setState={(newValue) => updateFilter('search_text', newValue)}
                inputProps={{ maxLength: 100 }}
                state={filters.search_text}
                label="Search"
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2} xl={1}>
              <MaterialUI.Select
                options={OPTIONS}
                setState={(newStatus) => updateFilter('status', newStatus)}
                state={filters.status}
                label={'Status'}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <MaterialUI.Input
                setState={(newValue) => updateFilter('date_from', newValue)}
                inputProps={{ maxLength: 100 }}
                state={filters.date_from}
                label="From"
                type='date'
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <MaterialUI.Input
                setState={(newValue) => updateFilter('date_until', newValue)}
                inputProps={{ maxLength: 100 }}
                state={filters.date_until}
                label="To"
                type='date'
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2} xl={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!filters.only_not_paid}
                    onChange={(ev) => updateFilter('only_not_paid', Number(ev.target.checked))}
                    value={1}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Only Not Paid"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <TableContainer className={classes.container}>
                  <Table stickyHeader className={classes.table} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ maxInlineSize: 70, inlineSize: 70 }} className="fs-small" align="center">
                          <ArrowSort handleChange={handlerOnChange} field={'id'} filterBy={filters.field_order} value={filters.type_order}>ID</ArrowSort>
                        </TableCell>
                        <TableCell className="fs-small px-1">
                          <ArrowSort handleChange={handlerOnChange} field={'date'} filterBy={filters.field_order} value={filters.type_order}>Date</ArrowSort>
                        </TableCell>
                        <TableCell className="fs-small px-1">
                          <ArrowSort handleChange={handlerOnChange} field={'client'} filterBy={filters.field_order} value={filters.type_order}>Client</ArrowSort>
                        </TableCell>
                        <TableCell className="fs-small px-1">Email</TableCell>
                        <TableCell className="fs-small px-1">
                          <ArrowSort handleChange={handlerOnChange} field={'due_date'} filterBy={filters.field_order} value={filters.type_order}>Due date</ArrowSort>
                        </TableCell>
                        <TableCell style={{ maxInlineSize: 100, inlineSize: 100 }} className="fs-small px-1" align="right">
                          <ArrowSort className='ms-auto' handleChange={handlerOnChange} field={'balance'} filterBy={filters.field_order} value={filters.type_order}>Balance</ArrowSort>
                        </TableCell>
                        <TableCell style={{ maxInlineSize: 100, inlineSize: 100 }} className="fs-small px-1" align="right">
                          <ArrowSort className='ms-auto' handleChange={handlerOnChange} field={'total'} filterBy={filters.field_order} value={filters.type_order}>Total</ArrowSort>
                        </TableCell>
                        <TableCell style={{ maxInlineSize: 100, inlineSize: 100 }} className="fs-small px-3" align="left">Status</TableCell>
                        <TableCell className="fs-small" align="center">Details</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading === LOADING.FETCHING ? (
                        <>
                          <TableRow hover={false}>
                            <TableCell colSpan={9}>
                              <div className='text-center py-3'>
                                <FaSpinner fontSize='large' className='spinner' />
                                <span className="d-inline-block ml-3">Loading</span>
                              </div>
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <>
                          {!invoices.length && (
                            <TableRow hover={false}>
                              <TableCell colSpan={9}>
                                <div className='text-center py-3'>
                                  <LayersTwoToneIcon fontSize='large' />
                                  {!filters.search_text.length && (
                                    <span className="d-inline-block ml-3">No records</span>
                                  )}
                                  {!!filters.search_text.length && (
                                    <span className="d-inline-block ml-3">There are no records matching somewhere "{filters.search_text}"</span>
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                          {!!invoices.length && (<>
                            {invoices.map((invoice, index) => {
                              const styled = {}
                              if (invoice.id == selectedId) {
                                styled.backgroundColor = '#acf8b6'
                              }
                              return (
                                <TableRow key={index} style={styled}>
                                  <TableCell className="px-1 fs-small" align="center">{invoice.id}</TableCell>
                                  <TableCell className="px-1 fs-small">{invoice.date_receipt}</TableCell>
                                  <TableCell className="px-1 fs-small text-capitalize">{invoice.client?.name}</TableCell>
                                  <TableCell className="px-1 fs-small text-lowercase">{invoice.client?.email}</TableCell>
                                  <TableCell className="px-1 fs-small">{invoice.date_due}</TableCell>
                                  <TableCell className="px-1 fs-small" align='right'>{invoice.Balance}</TableCell>
                                  <TableCell className="px-1 fs-small" align='right'>{invoice.total}</TableCell>
                                  <TableCell className="px-3 fs-small text-capitalize" align="left">
                                    {invoice.status === 'Paid' ? (
                                      <span className='d-flex align-items-center gap-1 text-success'><FaCheckCircle /> Paid</span>
                                    ) : invoice.status === 'active' ? (
                                      <span className='d-flex align-items-center gap-1 text-secondary'><FaClock /> Active</span>
                                    ) : (
                                      <span className='text-black-50'>{invoice.status}</span>
                                    )}
                                  </TableCell>
                                  <TableCell align="center" scope="row" style={{ inlineSize: '70px', minInlineSize: '70px' }}>
                                    <MaterialUI.Button.Primary type="button" size="small" disabled={false} className="mw-auto" onClick={() => onSelectInvoice(invoice)}>
                                      <VisibilityIcon style={{ fontSize: 14 }} fontSize="small" />
                                    </MaterialUI.Button.Primary>
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </>)}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {(!!pagination && pagination.total_pages > 1) && (
                  <Pagination
                    onChange={(_, newPage) => updateFilter('page', newPage)}
                    disabled={loading === LOADING.FETCHING}
                    count={pagination.total_pages}
                    page={pagination.current_page}
                    className="mt-2"
                    color="primary"
                    shape="rounded"
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Dialog maxWidth={'sm'} fullWidth onClose={onCloseDetails} open={showDialog} title={<DialogTitle onClose={onCloseDetails} className='p-0' children={`Invoice Detail`} />} >
				{(onClose) => !selectedInvoice ? null : (<>
					<div>
            <div className="d-flex w-100 justify-content-between">
              <Typography variant="caption" className="text-secondary">Items</Typography>
              <Typography variant="caption" className="text-secondary">Invoice: {selectedInvoice.id}</Typography>
            </div>
            <div>
              {selectedInvoice.details.map((item, key) => <OnlineSalesCard key={`online-sale-card-item-${key}`} {...item} />)}
            </div>
            <div className='mt-4'>
              <Typography variant="h6" className="text-secondary">Total <span className='d-inline-block ms-3'>{selectedInvoice.total}</span></Typography>
            </div>
					</div>
					<div className="mt-2 text-end">
						<MaterialUI.Button.Secondary onClick={onClose}>Close details</MaterialUI.Button.Secondary>
					</div>
				</>)}
			</Dialog>
    </>
  )
}