import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { userClientHTTP } from "../../lib/tech4Inventory.client";
import createHttp from "../../lib/http";

// components
import { CustomForm } from "../../components/CustomForm/CustomForm";
import { CustomModal } from "../../components/CustomModal/CustomModal";

//custom hooks
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiCustomRequest } from "../../customHooks/useApiCustomRequest";

// form configuration
import {
	typesAndValues as typesAndValuesFields,
	typesAndValuesCompany as typesAndValuesCompanyFields,
	initData as initDataFiels,
	initDataCompany as initDataCompanyFiels,
} from "./initForm";

import "./user_profile.css";
import photo from "../../assets/img/photo.jpg";
import MaterialUI from "../../components/MaterialUI";

function ProfileForm() {
	const history = useHistory();

	const {
		manageResponseErrors,
		manageResponse,
		manageSuccessfullMesssage,
		// setCustomMessageModal,
		showSuccessButton,
		// setShowSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		// setCustomTittleText,
		customMessageModal,
		// customCurrentItem,
		// setCustomCurrentItem,
		// manageDeleteMessage,
		// cancelButtonText,
		// setCancelButtonText,
		// sucessButtonText,
		// setSucessButtonText
	} = useModalHook();

	const {
		isLoading,
		setIsLoading,
		updateItemForm,
	} = useApiCustomRequest(
		manageResponseErrors,
		"my-user",
		"User Info",
		manageResponse,
		manageSuccessfullMesssage,
		true,
		() => {},
	);

	const [preview_image, setImagePreview] = useState(photo);
	const [file_image, setFileImage] = useState(null);
	const [typesAndValues, setTypesAndValues] = useState([]);
	const [typesAndValuesCompany, setTypesAndValuesCompany] = useState([]);
	const [initData, setInitData] = useState({});
	const [initDataCompany, setInitDataCompany] = useState({});
	const [companyId, setCompanyId] = useState(null);

	const previewImage = (e) => {
		if (e.target.files.length > 0) {
			let preview = URL.createObjectURL(e.target.files[0]);
			setImagePreview(preview);
			setFileImage(e.target.files[0]);
		} else {
			setFileImage(null);
			setImagePreview(photo);
		}
	};

	const onChangeTypeInput = (currentType) => {
		let typesAndValuesFieldsCopy = [...typesAndValuesFields];
		typesAndValuesFieldsCopy = typesAndValuesFieldsCopy.map((item) => ({
			...item,
			type: currentType === "text" ? "password" : "text",
			eyeIcon:
				currentType === "password" ? item.closeEyeIcon : item.eyeIcon,
			onChangeTypeInput: onChangeTypeInput,
		}));
		setTypesAndValues(typesAndValuesFieldsCopy);
	};

	useEffect(async () => {
		let typesAndValuesFieldsCopy = [...typesAndValuesFields];
		let typesAndValuesCompanyFieldsCopy = [...typesAndValuesCompanyFields];
		let initDataFielsCopy = { ...initDataFiels };
		let initDataCompanyFielsCopy = { ...initDataCompanyFiels };

		typesAndValuesFieldsCopy = typesAndValuesFieldsCopy.map((item) => ({
			...item,
			onChangeTypeInput: onChangeTypeInput,
		}));

		setIsLoading(true);
		let infoItem = await createHttp
			.get(`api/my-user/`, {})
			.then((response) => {
				setIsLoading(false);
				if (response.status === 200) {
					return response.data.data;
				} else {
					manageResponse(
						{ data: { message: "An error has occurred" } },
						"updating",
						"Profile",
					);
				}
			})
			.catch((error) => {
				setIsLoading(false);
				manageResponseErrors(
					"An error has occurred",
					"Profile",
					"updating",
				);
			});

		const { name, email, company } = infoItem;

		if (company) {
			const {
				id,
				website,
				address,
				city,
				state,
				tax,
				contact_phone,
				zipcode,
				logo,
			} = company;

			setCompanyId(id);
			setImagePreview(logo);

			initDataCompanyFielsCopy = {
				...initDataCompanyFielsCopy,
				name,
				email,
				website,
				address,
				city,
				state,
				tax,
				contact_phone,
				zipcode,
			};
		}

		setInitData(initDataFielsCopy);
		setInitDataCompany(initDataCompanyFielsCopy);
		setTypesAndValues(typesAndValuesFieldsCopy);
		setTypesAndValuesCompany(typesAndValuesCompanyFieldsCopy);
	}, []);

	const onChangeFieldsInitData = (ev) => {
		setInitData({ ...ev });
	};

	const onChangeFieldsInitDataCompany = (ev) => {
		setInitDataCompany({ ...ev });
	};

	const childFunc2 = useRef(null);
	const onHandleSubmit = async (ev) => {
		if (isLoading) return;

		const errors = childFunc2.current(initDataCompany);
		if (!(errors.length > 0)) {
			const { name, ...exInitDataCompany } = initDataCompany;

			let baseRequest = {
				...exInitDataCompany,
				_method: "PUT",
			};

			if (file_image) {
				baseRequest = {
					...baseRequest,
					logo: file_image,
				};
			}

			let response = await updateItemForm(
				baseRequest,
				"Company",
				{},
				true,
				false,
				companyId,
				"companies",
			);
			await updateUser({ name });
			if (response && response.hasOwnProperty("id")) {
				// manageSuccessfullMesssage(
				//     'Profile',
				//     'updated',
				//     null
				// )
				history.push("/dashboard");
			}
		}
	};

	const updateUser = async (data) => {
		setIsLoading(true);
		await userClientHTTP
			.updateUser({ ...data })
			.then((response) => {
				setIsLoading(false);

				if (response.data) {
					// manageSuccessfullMesssage(
					//     'Profile',
					//     'updated',
					//     response.data || null
					// )

					localStorage.setItem(
						"user_data",
						JSON.stringify(response.data),
					);

					return;
				} else if (!response.status && !response.data) {
					manageResponse({ data: response }, "updating", "Profile");
				}
			})
			.catch((error) => {
				setIsLoading(false);
				manageResponseErrors(
					"An error has occurred",
					"Profile",
					"updating",
				);
			});
	};

	const childFunc = useRef(null);
	const onHandleSubmitChangePassword = async () => {
		if (isLoading) return;

		const errors = childFunc.current(initData);
		if (!(errors.length > 0)) {
			updateUser(initData);
		}
	};

	return (
		<>
			<div className="contenedor container-fluid">
				<div className="container-title mb-3">
					<h1 className="container__title mb-0">Profile</h1>
				</div>
				<div className="row mt-1">
					<div className="col-12 col-lg-8">
						{typesAndValuesCompany.length > 0 &&
							Object.keys(initDataCompany).length > 0 && (
								<CustomForm
									onHandleFormClose={() => {}}
									onHandleSubmit={() => {}}
									typesAndValuesFields={typesAndValuesCompany}
									initData={initDataCompany}
									formTittle={""}
									dontShowCancelButton={true}
									id={null}
									getUrlPath={""}
									inputConf={{
										marginTop: "2",
										marginBottom: "0",
										col:"12",
										colSmall:"6",
										colLarge:"4",

									}}
									buttonClick={() => {}}
									onChangeFields={
										onChangeFieldsInitDataCompany
									}
									initOnChangeFields={
										onChangeFieldsInitDataCompany
									}
									dontShowSubmitButton={true}
									childFunc={childFunc2}
									dontShowErrosinOnChange={true}
									getObjectInfoOnChange={() => {}}
									notifyChange={() => {}}
								/>
							)}
						<div className="text-end mb-3">
							<MaterialUI.Button.Success onClick={onHandleSubmit} className="mt-2">
								Update changes
							</MaterialUI.Button.Success>
						</div>
					</div>
					<div className="col-12 col-lg-4 mb-3">
						<form className="content-form">
							<div className="content-logo__profile mx-auto">
								<div className="content-logo__image">
									<label htmlFor="input_file_profile">
										<label className="form-label">
											LOGO
										</label>
										<div
											className="image-container"
											style={{
												height: "150px",
												border: "1px solid grey",
												margin: "auto",
											}}
											htmlFor="input_file_profile"
										>
											<input
												id="input_file_profile"
												type={"file"}
												onChange={(e) =>
													previewImage(e)
												}
												hidden={true}
											></input>
											<img
												src={preview_image}
												alt=""
											></img>
										</div>
									</label>
								</div>
							</div>
						</form>
					</div>
					<div className="col-12">
						<h6 className="mt-md-3 sub__title">Change Password</h6>
					</div>
					<div className="col-12 col-md-8">
						{typesAndValues.length > 0 &&
							Object.keys(initData).length > 0 && (
								<CustomForm
									onHandleFormClose={() => {}}
									onHandleSubmit={() => {}}
									typesAndValuesFields={typesAndValues}
									initData={initData}
									formTittle={""}
									dontShowCancelButton={true}
									id={null}
									getUrlPath={""}
									inputConf={{
										marginTop: "2",
										marginBottom: "0",
									}}
									buttonClick={() => {}}
									onChangeFields={onChangeFieldsInitData}
									initOnChangeFields={onChangeFieldsInitData}
									dontShowSubmitButton={true}
									childFunc={childFunc}
									dontShowErrosinOnChange={true}
									getObjectInfoOnChange={() => {}}
									notifyChange={() => {}}
								/>
						)}
					</div>
					<div className="col-12 col-md-8 text-end">
						<MaterialUI.Button.Primary onClick={onHandleSubmitChangePassword} className="mt-2">
							Change password
						</MaterialUI.Button.Primary>
					</div>
				</div>
			</div>

			<CustomModal
				show={isLoading}
				customTittleText={""}
				customMessageModal={""}
				onHandleClose={() => {}}
				isLoading={isLoading}
			/>

			<CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={() => setShowModal(false)}
				onHandleSuccess={() => {}}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
			/>
		</>
	);
}

export { ProfileForm };
