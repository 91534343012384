import createHttpV2 from "../lib/httpV2"

const headers = {
  'Content-Type': 'multipart/form-data'
}

export const useFile = () => {

  const storeFile = async (payload) => {
    try {
      const response = await createHttpV2.post(`api/images/create`, payload, { headers })
      return response
    } catch (error) {
      throw error
    }
  }

  const storeProductImage = async (payload) => {
    try {
      const response = await createHttpV2.post(`api/images-item`, payload, { headers })
      return response
    } catch (error) {
      throw error
    }
  }

  const destroyFile = async (fileId) => {
    try {
      const response = await createHttpV2.delete(`api/images/delete/${fileId}`)
      return response
    } catch (error) {
      throw error
    }
  }

  return { storeFile, storeProductImage, destroyFile }
}