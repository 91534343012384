import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, makeStyles, Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { RemoveRounded, AddRounded } from '@material-ui/icons';
import clsx from 'clsx'
import Swal from 'sweetalert2'

import SwalMessages from '../../utils/alerts'
import { PosDesktopContext } from "../../context/PosDesktopContext";

import MaterialUI from "../../components/MaterialUI";
import NoImage from '../../assets/img/no-image.jpg'
import { getFullPathImage, getImageBySize } from "../../utils";
import NumberFormat from "../../utils/number.format";
import { SIZES } from "../../components/Product/ProductDetail";

const useStyles = makeStyles({
  table: { padding: 0 },
	container: {}
});

const PosDesktopRefoundForm = ({ onClose }) => {
  const classes = useStyles();
	const {
    refoundForm,
    addQuantityToTheProductInTheRefoundForm,
    setRestockItems,
    restockItems,
    refundReason,
    setRefundReason,
    loadingRefoundForm,
    handleRefundItemsClick,
    fetchReceipts,
    cleanFormRefound,
    showPanelReceipts,
  } = React.useContext(PosDesktopContext);

  const totalQuantity = refoundForm.reduce((acc, item) => acc + Number(item.refoundQuantity), 0);
  const totalAmount = refoundForm.filter(c => !!c.refoundQuantity).reduce((acc, item) => acc + (Number(item.refoundQuantity) * Number(item.price)), 0);
  
  const handleCloseClick = () => {
    onClose();
  }

  const onSubmit = async () => {
    if(!totalQuantity || loadingRefoundForm) return;
    try {
      const { status, msg } = await handleRefundItemsClick();
      if(status) {
        Swal.fire(SwalMessages.success);
        fetchReceipts();
        onClose();
        cleanFormRefound();
      } else {
        Swal.fire(SwalMessages.warning(msg));
      }
    } catch (err) {}
  }

  React.useEffect(() => {
    if(showPanelReceipts) {
      fetchReceipts();
    }
  }, [showPanelReceipts]);
  
	return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TableContainer className={classes.container}>
          <Table stickyHeader className={clsx(classes.table)} size="small">
            <TableHead>
              <TableRow>
                <TableCell className="fs-small" align="left">Product</TableCell>
                <TableCell className="fs-small" align="center" style={{ width: '80px', paddingInline: '.75rem' }}>Unit. P.</TableCell>
                <TableCell className="fs-small" align="center" style={{ inlineSize: '80px' }}>Quantity</TableCell>
                <TableCell className="fs-small" align="center" style={{ width: '80px', paddingInline: '.75rem' }}>SubTotal</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className='no-bordered'>
              {refoundForm.map((rf) => (
                <TableRow key={rf.id}>
                  <TableCell className="px-1" align="left" component="th" scope="row">
                    <div className='d-flex gap-2' style={{ minBlockSize: '50px', alignItems: 'center' }}>
                      <figure className='table__product__image'>
                        <img src={getFullPathImage(getImageBySize(rf.images, SIZES['275'], rf?.item_name)[0], NoImage)} alt={rf.item_name} />
                      </figure>
                      <div className='w-100'>
                        <div style={{ lineHeight: 1 }}>
                          <span className="fs-xs text-uppercase">{String(rf?.item_name).toLowerCase()}</span>
                          <p className="mb-0 fs-xs text-secondary">{[String(rf?.color), String(rf.size)].join(' / ')}</p>
                        </div>
                        <div className='d-flex flex-column flex-wrap' style={{ lineHeight: '1.1' }}>
                          {/* <div className="card__pos__product__property text-uppercase">Sku: {item?.sku}</div> */}
                          {!!rf.brand_id?.name && <span className="card__pos__product__property text-uppercase">Brand: {rf.brand_id.name}</span>}
                          {!!rf.category_id?.name && <span className="card__pos__product__property text-uppercase">Cat.: {rf.category_id.name}</span>}
                        </div>
                      </div>
                      <div className="fs-xs">({rf.quantity})</div>
                    </div>
                  </TableCell>
                  <TableCell className="px-1 fs-small" align="center" component="th" scope="row">
                    <span className="fs-small text-capitalize">{Number(rf.price).toFixed(2)}</span>
                  </TableCell>
                  <TableCell className="px-1 fs-small" align="center">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <MaterialUI.Button.Dark size="small" onClick={() => addQuantityToTheProductInTheRefoundForm(rf.id, -1)} className="mw-auto p-1">
                        <RemoveRounded style={{ fontSize: '1rem' }} />
                      </MaterialUI.Button.Dark>
                      <span className="fs-small cart__counter">{rf.refoundQuantity}</span>
                      <MaterialUI.Button.Dark size="small" onClick={() => addQuantityToTheProductInTheRefoundForm(rf.id, 1)} className="mw-auto p-1">
                        <AddRounded style={{ fontSize: '1rem' }} />
                      </MaterialUI.Button.Dark>
                    </div>
                  </TableCell>
                  <TableCell className="px-1 fs-small" align="center">
                    <span className="fs-small">{Number(rf.subTotal).toFixed(2)}</span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter style={{ position: 'sticky', bottom: 0 }}>
              <TableRow hover={false} className="no-hover">
                <TableCell colSpan={2} className='table__text_footer'>General information</TableCell>
                <TableCell className="fs-xs px-1 table__text_footer font-inter fw-bold" align="center">{totalQuantity}</TableCell>
                <TableCell className="fs-xs px-1 table__text_footer font-inter fw-bold" align="center">{NumberFormat.currency(totalAmount)}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} className="pt-0">
        <FormControlLabel
          control={
            <Checkbox
              checked={restockItems}
              size="small"
              onChange={() => setRestockItems((prev) => !prev)}
              name="restockItems"
              color="primary"
            />
          }
          label={<span className="fs-small">Restock Items</span>}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialUI.Input
          label={"Reason (Optional)"}
          state={refundReason}
          setState={setRefundReason}
          rows={3}
          multiline
        />
      </Grid>
      <Grid item xs={12} className="text-end">
        <MaterialUI.Button.Danger onClick={handleCloseClick} className="me-2">Close</MaterialUI.Button.Danger>
        <MaterialUI.Button.Primary disabled={!totalQuantity || loadingRefoundForm} onClick={onSubmit}>Refund</MaterialUI.Button.Primary>
      </Grid>
    </Grid>
  );
};

export default PosDesktopRefoundForm;
