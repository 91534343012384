// *https://www.registers.service.gov.uk/registers/country/use-the-api*
// import fetch from 'cross-fetch';
import React, { useCallback, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import createHttp from "../../lib/http";
import "./AsyncInput.css";
import { debounce } from 'lodash';

import {
	alpha,
	withStyles,
} from "@material-ui/core/styles";

import InputBase from "@material-ui/core/InputBase";
import MaterialUI from "../MaterialUI";

const BootstrapInput = withStyles((theme) => ({
	root: {
		// 'label + &': {
		//   marginTop: theme.spacing(3),
		// },
	},
	input: {
		margin: "0",
		position: "relative",
		backgroundColor: theme.palette.common.white,
		border: "1px solid #ced4da",
		fontSize: "1rem",
		transition: theme.transitions.create(["border-color", "box-shadow"]),
		borderRadius: "0.25rem",
		border: "1px solid #ced4da",
		padding: "0.375rem 2.25rem 0.750rem 0.75rem",
		fontFamily: [
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		"&:focus": {
			boxShadow: `none`,
			borderColor: "#86b7fe",
		},
		"&::placeholder": {
			fontStyle: "italic !important",
			opacity: "0.6 !important",
		},
	},
}))(InputBase);

function AsyncInput(props) {
	const {initOptions = []} = props;
	const {nextSiblingConfig = {}, hasNextSibling = false, handlerSiblings = () => {}} = props;
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState(initOptions);
	const [inputValue, setInputValue] = React.useState(props.filter.inputValue || "");
	const [loading, setIsLoading] = React.useState(false);
	const [doRequest, setDoRequest] = React.useState(
		props.filter.doRequest || false,
	);
	const [allowRedefine, setAllowRedefine] = React.useState(false);

	React.useEffect(() => {
		if(props.filter.inputValue) {
			setInputValue(props.filter.inputValue);
		}
	}, [props.filter.inputValue]);
 
	React.useEffect(() => {
    if(initOptions.length) {
      setOptions(initOptions);
			setInputValue(initOptions[0]['name']);
		}
  }, [initOptions]);

	React.useEffect(() => {
		let active = true;
		let timeoutId;
	  
		if (!inputValue || !doRequest) {
		  return undefined;
		}
	  
		const fetchData = async () => {
		  setIsLoading(true);
	  
		  let params = {
			[props.filter.queryParam]: inputValue,
		  };
	  
		  if (props.filter.queryParam2) {
			params = {
			  ...params,
			  [props.filter.queryParam2]: props.filter.queryValue2,
			};
		  }
	  
		  try {
			const response = await createHttp.get(`api/${props.filter.apiRequest}`, { params });
			if (active && response.data.status) {
			  setOptions(response.data.data);
			}
		  } catch (error) {
			console.error("Error fetching data:", error);
			setOptions([]);
		  }
	  
		  setIsLoading(false);
		};
	  
		const fetchDataWithDelay = () => {
		  clearTimeout(timeoutId);
		  timeoutId = setTimeout(fetchData, 350);
		};
	  
		fetchDataWithDelay();
 
		return () => {
		  active = false;
		  clearTimeout(timeoutId);
		};
	  }, [inputValue, doRequest]);
	  

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	const renderOption = (props, option) => {
		return (
			<>
				{props.name || ""}
				{props.sku ? ", " : ""}
				<span style={{ opacity: "0.4" }}>
					&nbsp;&nbsp;&nbsp;{props.sku && "sku:"}
					{props.city || props.sku || ""}
					{props.city ? "," : ""}
					{props.state || ""}
				</span>
			</>
		);
	};

	let style = {}	
	if (hasNextSibling) {
		style.borderTopRightRadius = '0'
		style.borderBottomRightRadius = '0'
	}

	return (
		<>
			<div className={!props.selectCol ? "col-6" : props.selectCol}>
				<div className="input-group" style={{ flexWrap: hasNextSibling ? 'nowrap' : 'wrap' }}>
					<Autocomplete
						onChange={(event, newValue) => {
							setDoRequest(false);
							props.onChangeAsyncFilter(
								newValue?.id || "",
								props.filter.field_id,
							);
							if (props.hasOwnProperty("getObjectInfoOnChange")) {
								props.getObjectInfoOnChange(newValue);
							}
						}}
						inputValue={inputValue}
						onInputChange={(event, newInputValue) => {
							if (
								event &&
								props.hasOwnProperty("handleOnInputChange") &&
								event.type === "change"
							) {
								props.handleOnInputChange(newInputValue);
							}

							setDoRequest(true);
							if (
								(event &&
									event.hasOwnProperty("type") &&
									event.type === "change") ||
								(!event && newInputValue)
							) {
								if (!props.filter.dontInitData || allowRedefine) {
									setInputValue(newInputValue);
								}
							}
							setAllowRedefine(true);
						}}
						id="asynchronous-demo"
						style={{ width: "100%", margin: "0" }}
						open={open}
						onOpen={() => {
							if (inputValue.length >= 2) {
								setOpen(true);
							}
						}}
						onClose={() => {
							setOpen(false);
}}
						options={options}
						loading={loading}
						getOptionSelected={(option, value) =>
							option.name === value.name
						}
						filterOptions={(x) => x}
						getOptionLabel={(option) => option?.name || ""}
						renderOption={renderOption}
						renderInput={(params) => (
							<div
								ref={params.InputProps.ref}
								style={{
									display: "grid",
									gridTemplateRows: props.filter.labelText
										? "1fr 2.3em"
										: "1fr",
								}}
							>
								{props.filter.labelText && (
									<label
										htmlFor={props.filter.field_id}
										className={`form-label ${
											!props.selectCol
												? "col-6"
												: props.selectCol
										}`}
										style={{
											fontSize: "14px",
											fontWeight: "bold",
										}}
									>
										{props.filter.labelText}
									</label>
								)}
								<BootstrapInput
									className="async-input bg-dark"
									name={props.filter.field_id}
									type="text"
									id="bootstrap-input"
									inputProps={{ style }}
									placeholder={props.filter.defaultOptionText}
									disabled={!!props.filter.disabled}
									{...params.inputProps}
								/>
							</div>
						)}
					/>
					{hasNextSibling && (
						<MaterialUI.Button.Primary 
							disableElevation
							type="button"
							style={{ maxInlineSize: '100px !important', minWidth: 'auto'}}
							onClick={() => handlerSiblings({
								field_name: props.filter.field_name,
								...nextSiblingConfig.data
							})}
							tabIndex="-1"
						>
							{!!nextSiblingConfig.iconStart && <i className={nextSiblingConfig.iconStart}></i>}
							{!!nextSiblingConfig.text && <span className="px-1">{nextSiblingConfig.text}</span>}
							{!!nextSiblingConfig.iconEnd && <i className={nextSiblingConfig.iconEnd}></i>}
						</MaterialUI.Button.Primary>
					)}
				</div>
			</div>
		</>
	);
}

export { AsyncInput };
