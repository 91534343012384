import React from "react";
import { ItemContext } from "../ItemProviders";
import { TextInput } from "../text_input";
import { ImageInput } from "../image_input";
import { ArrayInput } from "../array_input";
import Layout from "../../components/Layout/Layout";
import { CheckBoxInput } from "../checkbox_input";
import { useLocation } from "react-router-dom";

function FormUpdate() {
	const {
		app_conf,
		table_add_configuration,
		updateAddConfiguration,
		verifyErrors,
		updateItem,
		setItemId,
	} = React.useContext(ItemContext);

	const location = useLocation();

	React.useEffect(() => {
		setUpdateData(location.state.data);
		setItemId(location.state.data.id);
	}, [location]);

	const setUpdateData = (data) => {
		let array = [...table_add_configuration];

		for (let index = 0; index < array.length; index++) {
			if (array[index].update_field) {
				if (array[index].type === "array_checkbox") {
					array[index].value =
						data[table_add_configuration[index].field_id];
				} else if (array[index].type === "array") {
					array[index].value =
						data[
							table_add_configuration[index].field_id
						].id.toString();
				} else {
					array[index].value =
						data[table_add_configuration[index].field_id];
				}
			}
		}

		updateAddConfiguration(array);
	};

	function replaceLetters(event) {
		let new_value = event.target.value;
		event.target.value = new_value.replace(/[^\d]/g, "");
		return event;
	}

	const setItemToUpdate = (event, index) => {
		let new_array = [...table_add_configuration];
		new_array[index].value = event.target.value;
		updateAddConfiguration(new_array);
	};

	const setItemToUpdateCheckBox = (current_array, index) => {
		let new_array = [...table_add_configuration];

		if (new_array[index].value !== undefined) {
			new_array[index].value = current_array;
			updateAddConfiguration(new_array);
		}
	};

	const setItemNumberToUpdate = (event, index) => {
		setItemToUpdate(replaceLetters(event), index);
	};

	const setItemImageToUpdate = (event, index) => {
		if (event.target.files.length > 0) {
			let new_array = [...table_add_configuration];
			new_array[index].value = event.target.files[0];
			updateAddConfiguration(new_array);
		}
	};

	const sendAUpdateRequest = () => {
		if (verifyErrors()) {
			updateItem();
		}
	};

	return (
		<Layout>
			<div className="container-fluid">
				<div className="d-flex justify-content-center">
					<div className="card col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-7 ms-2 me-2">
						<div className="card-header">
							<h3> Update {app_conf.title} </h3>
						</div>

						<div className="card-body">
							<div className="row">
								{table_add_configuration.map(
									(element, index) => {
										if (element.type === "text") {
											return (
												<TextInput
													className="form-group mt-2 mb-2 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
													element_index={index}
													onElementChange={
														setItemToUpdate
													}
													key={index}
													element={element}
												/>
											);
										}

										if (element.type === "number") {
											return (
												<TextInput
													className="form-group mt-2 mb-2 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
													element_index={index}
													onElementChange={
														setItemNumberToUpdate
													}
													key={index}
													element={element}
												/>
											);
										}

										if (element.type === "array") {
											return (
												<ArrayInput
													className="form-group mt-2 mb-2 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
													element_index={index}
													onElementChange={
														setItemToUpdate
													}
													key={index}
													element={element}
												/>
											);
										}

										if (element.type === "array_checkbox") {
											return (
												<CheckBoxInput
													className="form-group mt-2 mb-2 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
													element_index={index}
													onElementChange={
														setItemToUpdateCheckBox
													}
													key={index}
													element={element}
												/>
											);
										}
									},
								)}
							</div>
						</div>
					</div>
					<div
						hidden={
							table_add_configuration.find(
								(element) =>
									element.type === "img" &&
									element.update_field,
							) !== undefined
								? false
								: true
						}
						className="card col-12 col-sm-12 col-md-12 col-lg-4 col-xl-5 col-xxl-5 ms-2 me-2"
					>
						<div className="card-header">
							<h3> Image </h3>
						</div>

						<div className="card-body">
							<div className="row">
								{table_add_configuration.map(
									(element, index) => {
										if (element.type === "img") {
											return (
												<ImageInput
													from_form={true}
													className="form-group mt-2 mb-2 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
													element_index={index}
													onElementChange={
														setItemImageToUpdate
													}
													key={index}
													element={element}
												/>
											);
										}
									},
								)}
							</div>
						</div>
					</div>
				</div>
				<div className=" float-end">
					<button
						onClick={() => sendAUpdateRequest()}
						className="btn btn-outline-success mt-3"
					>
						Save
					</button>
				</div>
			</div>
		</Layout>
	);
}

export { FormUpdate };
