import React, { createContext, useState, useEffect, useMemo, useReducer, useContext } from "react";
import Swal from "sweetalert2";

import { useApiRequestTypePayment } from "../customHooks/useApiRequestTypePayment";
import Http from '../lib/http'
import SwalAlerts from '../utils/alerts'

import { useReceipts, Adapter as AdapterReceipts } from '../customHooks/useReceipts'
import { useApiRequestCategories } from "../customHooks/useApiRequestCategories"
import { posDesktopInitialState } from "../views/PosDesktop/posDesktop.state"
import { actions  as posDesktopActions } from "../views/PosDesktop/actions"
import { posDesktopReducer } from '../views/PosDesktop/reducers'
import { useProducts } from '../customHooks/useProducts'

import BeepCashRegister01 from '../assets/media/beep-cash-register-01.mp3'

export class InitState {
  static defaultPaymentId = "";
  static creditId = 1;
  static cashId = 3;

  static get holdOrder() {
    return {};
  }
  
  static formPayment() {
    return {
      id: crypto.randomUUID(),
      type_payment_id: this.defaultPaymentId,
      total_payment: "",
      ref: React.createRef(),
    };
  }
}

export const PosDesktopContext = createContext({
  addProductToCartClick: () => {},
  updateQuantityCart: () => {},
  removeProductCart: () => {},
  clearProductsCart: () => {},
  onSelectProduct: () => {},
  onSelectProduct: () => {},
  updateQuantity: () => {},
  posDesktopState: {},
});
export const MIN_PER_PAGE = 21;
export const MAX_PER_PAGE = 28;

export function PosDesktopContextProvider({ children }) {
  const {
    isLoading: loadingProducts,
    fetchProductBySKU,
    fetchProducts,
    pagination,
    products,
    setPage,
    page,
  } = useProducts();
  const {
    // 👉 Hold Receipts
    fetchHoldReceipts,
    storeReceipt,
    incrementHoldReceiptsTotal,
    setHoldReceiptsPagination,
    destroyReceipt,
    setHoldReceiptErrors,
    holdReceipts,
    holdReceiptsPagination,
    loadingHoldReceipts,
    loadingReceipt,
    holdReceiptErrors,
    errors,
    setErrors,
    refundItems,
    // 👉 Receipts
    fetchReceipts,
    loadingReceipts,
    receiptsPagination,
    receipts,
  } = useReceipts();
	const {
    filtered_data: payments,
    setFilteredData: setPayments,
    fetchTypesPayment
  } = useApiRequestTypePayment(() => {}, false);
  const [cart, setCart] = useState([]);
  const [receiptId, setReceiptId] = useState(undefined);
  const [initStateClients, setInitStateClients] = useState([]);
  const [note, setNote] = useState("");
  const [productsId, setProductsId] = useState(new Set());
  const [searchType, setSearchType] = useState(() => localStorage.settingsPOSSearchType || 'NAME');
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(() => localStorage.settingsPOSPerPage || MIN_PER_PAGE);
  const [_search, _setSearch] = useState("");
  const [client, setClient] = useState(null);
  const [billNumber, setBillNumber] = useState("");
  const [sendingForm, setSendingForm] = useState(false);
  const [showVoucherOptions, setShowVoucherOptions] = useState(false);
  const [showPanelHoldOrders, setShowPanelHoldOrders] = useState(false);
  const [holdOrders, setHoldOrders] = useState([]);
  const [holdOrder, setHoldOrder] = useState(InitState.holdOrder);
  const toggleShowHoldOrders = () => setShowPanelHoldOrders((state) => !state);
  const [showFormPayment, setShowFormPayment] = useState(false);
  const [dynamicForm, setDynamicForm] = useState([InitState.formPayment()]);
  const [isReceiptRegister, setIsReceiptRegister] = useState({});
  // 👉 Receipts
  const [currentReceipt, setCurrentReceipt] = useState({});
  const [showPanelReceipts, setShowPanelReceipts] = useState(false);
  // 👉 Categories
  const {filtered_data: categories} = useApiRequestCategories(() => {});
  const [categoryFilter, setCategoryFilter] = useState();  
  // 👉 Refound
  const [showRefoundForm, setShowRefoundForm] = useState(false);
  const [currentRefound, setCurrentRefound] = useState({});
  const [loadingRefoundForm, setLoadingRefoundForm] = useState(false);
  const [restockItems, setRestockItems] = useState(false);
  const [refundReason, setRefundReason] = useState("");
  const [refoundForm, setRefoundForm] = useState([]);
  // 👉 Global store
  const [posDesktopState, dispatchPosDesktop] = useReducer(posDesktopReducer, posDesktopInitialState)
  const addProductToCart = (productDetail) => {
    audio.currentTime = 0;
    audio.play();
    dispatchPosDesktop(posDesktopActions.ADD_CART(productDetail))
    if(!productsId.has(productDetail.productId)) {
      setProductsId((prev) => {
        prev.add(productDetail.productId)
        return prev
      })
    }
  }
  const updateQuantity = (quantity) => dispatchPosDesktop(posDesktopActions.UPDATE_QUANTITY(quantity))
  const onSelectProduct = (product) => dispatchPosDesktop(posDesktopActions.SELECT_PRODUCT(product))
  const removeProductCart = (payload) => {
    setProductsId((prev) => {
      prev.delete(payload.id);
      return prev;
    });
    dispatchPosDesktop(posDesktopActions.REMOVE_CART(payload))
  }
  const updateQuantityCart = (payload) => dispatchPosDesktop(posDesktopActions.UPDATE_QUANTITY_DIRECTLY(payload))
  const clearProductsCart = () => {
    setProductsId(new Set())
    dispatchPosDesktop(posDesktopActions.CLEAR_CART())
  }
  
  const loadCartFromHold = (products) => {
    dispatchPosDesktop(posDesktopActions.LOAD_CART_FROM_HOLD(products))
  }

  const globalShare = {
    dispatchPosDesktop,
    addProductToCart,
    onSelectProduct,
    posDesktopState,
    updateQuantity,
    removeProductCart,
    updateQuantityCart,
    clearProductsCart,
    loadCartFromHold,
  }
  
  const handleSetRefoundFormClick = (details) => {
    setRefoundForm(details.map((item) => ({
      ...item.item,
      quantity: item.quantity,
      refoundQuantity: 0,
      subTotal: item.quantity * item.price,
    })));
  }
  const addQuantityToTheProductInTheRefoundForm = (productId, _quantity) => {
    const index = refoundForm.findIndex(item => item.id === productId);
    const refoundQuantity = Number(refoundForm[index]['refoundQuantity']) + _quantity;
    if(refoundQuantity > Number(refoundForm[index]['quantity'])) return;
    if(refoundQuantity >= 0) {
      setRefoundForm((prev) => {
        return prev.map((item) => {
          if(item.id === productId)
            return {
              ...item,
              refoundQuantity
            };
          return item;
        });
      });
    }
  }
  const cleanFormRefound = () => {
    setCurrentRefound({});
    setRestockItems(false);
    setRefundReason("");
    setRefoundForm([]);
  }

  const getDetailIds = (item) => ({
    item_variant_id: item.id,
    item_id: item.item_id,
  })
  
  const handleRefundItemsClick = async () => {
    try {
      setLoadingRefoundForm(true);
      const items = refoundForm.filter((c) => c.refoundQuantity).map((rf) => ({
        ...getDetailIds(rf),
        quantity: rf.refoundQuantity,
        refund_quantity: restockItems ? rf.refoundQuantity : 0,
        cost: rf.price,
        note: refundReason || 'Refund',
      }));
      return await refundItems({ items, receipt_id: currentRefound.id });
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      setLoadingRefoundForm(false);
    }
  }
  
  // 👉 Others
  useEffect(() => {
    if(payments.length) {
      const cash = payments.find((c) => String(c.name).toLowerCase() === 'cash');
      if(cash) {
        InitState.defaultPaymentId = cash.id;
        setDynamicForm([InitState.formPayment()]);
      }
    }
  }, [payments])
  
	const [audio] = useState(() => {
    const _audio = new Audio(BeepCashRegister01);
    _audio.type = 'audio/mp3'
    _audio.volume = 0.05;
    return _audio;
  });

  const addQuantityToTheProductInTheShoppingCart = (productId, _quantity) => {
    const index = cart.findIndex(item => item.id === productId);
    const quantity = Number(cart[index]['quantity']) + _quantity;
    if(quantity <= 0) {
      removeFromCart(productId);
    } else {
      setCart((prev) => {
        return prev.map((item) => {
          if(item.id === productId)
            return {
              ...item,
              quantity,
              subTotal: item.price * quantity,
            };
          return item;
        });
      });
    }
  }

  const removeFromCart = (productId) => {
    setCart((prev) => {
      setProductsId((prev) => {
        prev.delete(productId);
        return prev;
      });
      return prev.filter((item) => item.id !== productId);
    })
  }

  const removeFromHoldOrders = async (receiptId) => {
    const { isConfirmed } = await Swal.fire(SwalAlerts.questionDestroy);
    if(!isConfirmed) return;
    try {
      await destroyReceipt(receiptId);
      setHoldOrder(InitState.holdOrder);
      fetchHoldReceipts();
      Swal.fire(SwalAlerts.confirmationDestroy);
    } catch (error) {}
  }
  
  const setCartFromHoldOrder = (id) => {
    const item = holdReceipts.find((c) => c.id === id);
    if(item) {
      setReceiptId(item.id);
      setHoldOrder(InitState.holdOrder);
      loadCartFromHold(item.details.map(AdapterReceipts.holdOrderDetailsToProductCart))
      if(item.client && item.status !== 'Hold') {
        setInitStateClients([item.client]);
        setClient(item.client);
      }
      toggleShowHoldOrders();
    }
  }


  
  const addToCart = (product) => {
    // audio.currentTime = 0;
    // audio.play();
    if(productsId.has(product.id)) {
      addQuantityToTheProductInTheShoppingCart(product.id, 1);
    } else {
      setProductsId((prev) => { prev.add(product.id); return prev; });
      const newItem = { ...product, quantity: 1, subTotal: product.price }
      setCart((prev) => [newItem, ...prev]);
    }
  }

  const clearCart = () => {
    clearProductsCart()
    setReceiptId(undefined)
  }

  const togglePerPage = () => {
    const newPerPage = Number(perPage) === MIN_PER_PAGE ? MAX_PER_PAGE : MIN_PER_PAGE;
    setPerPage(newPerPage);
    localStorage.settingsPOSPerPage = newPerPage;
  }

  const resetState = () => {
    clearCart();
    setIsReceiptRegister(false);
    setClient(null);
    setBillNumber("");
    setNote("");
    setDynamicForm({});
    setCurrentReceipt({});
    setHoldReceiptErrors({});
  }
  
  const handleStoreReceiptClick = async (is_on_hold = false, _note = note) => {
    if(loadingReceipt) return;
    try {
      const productsCart = posDesktopState.productsCart
      const payload = {
        client_id: (client ?? {}).id,
        items: productsCart.map((pc) => ({
          item_id: pc.id,
          item_variant_id: pc.selectedItemVariantId,
          quantity: pc.quantityPurchase,
          cost: pc.selectedPrice,
        })),
        payments_arr: dynamicForm.map((c) => ({
          type_payment_id: c.type_payment_id,
          total_payment: c.total_payment,
        })),
        note: _note,
      }
      if(is_on_hold) {
        delete payload.payments_arr;
        delete payload.note;
        payload.is_on_hold = 1;
        payload.reference_name = _note;
      }
      if(receiptId) payload.receipt_id = receiptId;
      const { status, data = {} } = await storeReceipt(payload);
      if(status && String(data?.status).toLowerCase() !== 'hold') {
        setCurrentReceipt(data);
        setIsReceiptRegister(true);
        setBillNumber(data.bill_number);
        setShowVoucherOptions(true);
        setShowFormPayment(false);
      }
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  const findReceipt = async (search) => Http.post(`/api/search_by_BLT`, { search });
  
  const processSale = () => {
    setShowFormPayment(true);
    setShowVoucherOptions(true);
    setHoldReceiptErrors({});
  }

  const closeProcessSale = () => {
    setIsReceiptRegister(false);
    setShowFormPayment(false);
    setShowVoucherOptions(false);
  }
  
  useEffect(() => {
    const fetchAllProducts = async () => {
      try {
        if(searchType === 'SKU' && search) {
          const params = { search_text: search }
          const { data } = await fetchProductBySKU({ params });
          const [currentProduct] = data;

          const itemVariants = currentProduct?.item_variants || []
          const toSearch = String(search).toLowerCase()
          if(
            currentProduct && Object.keys(currentProduct).length &&
            searchType === 'SKU' && /\S/.test(search) &&
            itemVariants.some((item) => String(item.sku).toLowerCase() == toSearch)
          ) {
            onSelectProduct({
              ...currentProduct,
              selectedItemVariantId: undefined,
              selectedColor: undefined,
              selectedSize: undefined,
              quantityPurchase: 1
            })
            setTimeout(() => { _setSearch("") }, 150);
          }
        } else {
          const params = { per_page: perPage, search_text: search, category_id: categoryFilter?.id ?? '' };
          const { _pagination } = await fetchProducts({ params });
          if(_pagination.current_page > _pagination.total_pages) {
            setPage(1);
          }
        } 
      } catch (error) {
        console.error(error);
      }
    }
    fetchAllProducts();
  }, [perPage, page, search, searchType, categoryFilter])
  
  useEffect(() => {
    if(searchType !== localStorage.settingsPOSSearchType) {
      localStorage.settingsPOSSearchType = searchType;
    }
  }, [searchType]);
  
  const totalQuantity = useMemo(() => posDesktopState.productsCart.reduce((acc, item) => acc + Number(item.quantityPurchase), 0), [posDesktopState.productsCart]);
  const totalAmount = useMemo(() => posDesktopState.productsCart.reduce((acc, item) => acc + (Number(item.selectedPrice) * Number(item.quantityPurchase)), 0), [posDesktopState.productsCart]);
  
  const share = {
    togglePerPage,
    addToCart,
    setSearch,
    addQuantityToTheProductInTheShoppingCart,
    removeFromCart,
    resetState,
    setPage,
    setPerPage,
    setSearchType,
    _setSearch,
    setClient,
    clearCart,
    setShowVoucherOptions,
    handleStoreReceiptClick,
    toggleShowHoldOrders,
    showPanelHoldOrders,
    totalQuantity,
    totalAmount,
    search, 
    loadingProducts,
    products,
    pagination,
    productsId,
    cart,
    searchType,
    _search,
    client,
    perPage,
    showVoucherOptions,
    billNumber,
    // 👉 Hold Orders
    holdOrder, setHoldOrder,
    holdOrders, setHoldOrders,
    showFormPayment, setShowFormPayment,
    removeFromHoldOrders,
    setCartFromHoldOrder,
    processSale,
    // 👉 Form Payment
    payments, setPayments,
    sendingForm, setSendingForm,
    note, setNote,
    errors, setErrors,
    dynamicForm, setDynamicForm,
    currentReceipt, setCurrentReceipt,
    fetchTypesPayment,
    closeProcessSale,
    findReceipt,
    // 👉 Hold Receipts
    fetchHoldReceipts,
    incrementHoldReceiptsTotal,
    setHoldReceiptsPagination,
    holdReceipts,
    loadingHoldReceipts,
    holdReceiptsPagination,
    isReceiptRegister,
    setIsReceiptRegister,
    holdReceiptErrors,
    setHoldReceiptErrors,
    receiptId,
    // 👉 Client
    initStateClients,
    setInitStateClients,
    // 👉 Refound
    refoundForm,
    setRefoundForm,
    addQuantityToTheProductInTheRefoundForm,
    handleSetRefoundFormClick,
    showRefoundForm,
    setShowRefoundForm,
    restockItems,
    setRestockItems,
    refundReason,
    setRefundReason,
    cleanFormRefound,
    loadingRefoundForm,
    setLoadingRefoundForm,
    handleRefundItemsClick,
    currentRefound,
    setCurrentRefound,
    showPanelReceipts,
    setShowPanelReceipts,
    // 👉 Categories
    categories,
    categoryFilter,
    setCategoryFilter,
    // 👉 Receipts
    fetchReceipts,
    loadingReceipts,
    receiptsPagination,
    receipts,
    ...globalShare,
  };
  
	return (
		<PosDesktopContext.Provider value={share}>
			{children}
		</PosDesktopContext.Provider>
	);
}

export const usePos = () => {
  const context = useContext(PosDesktopContext)
  if (!context) {
    throw new Error('usePos not must be use within PosDesktopContext')
  }
  return context
}