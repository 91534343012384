import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from 'clsx'
// import Barcode from 'react-barcode';

import LogoT4 from '../../assets/img/LogoT4.png';

const useStyles = makeStyles({
	root: {
		inlineSize: "350px",
		margin: "auto",
	},
	tableRow: {
		display: "grid",
		gridTemplateColumns: "1fr 70px 55px 70px",
	},
	tableRowFooter: {
		display: "grid",
		gridTemplateColumns: "1fr 70px",
		textAlign: "end",
	},
});

const ReceiptPdf = React.forwardRef(({ receipt = {} }, ref) => {
  // const [origin] = React.useState(`${window.origin}/check_information`);
	const classes = useStyles();
  
  const parseFormatPhone = (str = '') => {
    let newStr = str.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    return newStr;
  }
  
	return <div ref={ref} className={clsx(classes.root, 'mt-5')}>
    <div className="text-center text-muted font-sans-serif" style={{ overflow: 'hidden' }}>
      <img src={LogoT4} className="mb-2" height={60} alt="Logo" />
      <p className="mb-1 fs-semi-small fw-bold">Tech4Inventory</p>
      <p className="mb-0 fs-semi-small">{receipt?.created_user?.company?.address ?? '-'}</p>
      <p className="mb-0 fs-semi-small">{receipt?.created_user?.company?.address_additional ?? '-'}</p>
      <p className="mb-2 fs-semi-small">{parseFormatPhone(receipt?.created_user?.company?.contact_phone) ?? '-'}</p>
      <p className="mb-0 fs-semi-small">=======================================================================</p>
      <p className="mb-0 fs-semi-small">Receipt</p>
      <h4 className="mb-0 fw-bold">{receipt.receipt_nro ?? '-'}</h4>
      <p className="mb-0 fs-semi-small">{receipt.date_receipt}</p>
      <p className="mb-2 fs-semi-small">=======================================================================</p>
      {!!receipt.client && (<>
        <div className="mb-2 text-start">
          <p className="mb-1 fs-semi-small fw-bolder text-start">CLIENT</p>
          <p className="mb-0 fs-semi-small"><b>FullName:</b> {receipt.client?.name}</p>
          <p className="mb-0 fs-semi-small"><b>Email:</b> {receipt.client?.email}</p>
          <p className="mb-0 fs-semi-small"><b>Address:</b> {receipt.client?.address_1}</p>
        </div>
        <p className="mb-2 fs-semi-small">=======================================================================</p>
      </>)}
      {!!receipt.details?.length && (
        <>
          <p className="mb-1 fs-semi-small text-start">
            <span className="fw-bolder">LIST ITEMS</span>
          </p>
          <div className="mb-2 fs-semi-small">
            <div className={`${classes.tableRow} mb-1`}>
              <div className="fw-bold text-start">ITEM</div>
              <div className="fw-bold text-end">PRICE</div>
              <div className="fw-bold">QTY.</div>
              <div className="fw-bold text-end">TOTAL</div>
            </div>
            {receipt.details.map((item, index) => (
              <div key={index} className={classes.tableRow} style={{ marginBottom: 10 }}>
                <div className="text-start text-capitalize" style={{ lineHeight: 1 }}>
                  <p className="mb-0">{item.item?.item_name}</p>
                  <p className="mb-0">{[item.item?.color, item.item?.size].join(' / ')}</p>
                </div>
                <div style={{ alignContent: 'end' }} className="text-end">$ {Number(item.price).toFixed(2)}</div>
                <div style={{ alignContent: 'end' }}>{item.quantity}</div>
                <div style={{ alignContent: 'end' }} className="text-end">$ {(Number(item.price) * Number(item.quantity)).toFixed(2)}</div>
              </div>
            ))}
            <div className={clsx(classes.tableRowFooter, "border-top mt-2")}>
              <div className="text-start"><span>Number of item sold ({receipt.details.reduce((acc, item) => acc + Number(item.quantity), 0)})</span></div>
              <div></div>
            </div>
            <div className={classes.tableRowFooter}>
              <div>Subtotal</div>
              <div>{receipt.total}</div>
            </div>
            <div className={classes.tableRowFooter}>
              <div>Tax</div>
              <div>$ 0.00</div>
            </div>
            <div className={classes.tableRowFooter}>
              <div>Total</div>
              <div>{receipt.total}</div>
            </div>
          </div>
          <p className="mb-2 fs-semi-small">=======================================================================</p>
        </>
      )}
      <p className="mb-1 fs-small">Thank you for choosing us for your purchases. We value your preference and hope to see you again soon. Have a great day!</p>
      {receipt.created_user && <p className="mb-0 fs-semi-small">Assisted by: {receipt.created_user?.name}</p>}
      {/* <Barcode className="w-75" value={origin} /> */}
    </div>
  </div>;
})

export default ReceiptPdf;
