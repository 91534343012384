import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, makeStyles, Grid } from '@material-ui/core';
import clsx from 'clsx'

import { PosDesktopContext } from "../../context/PosDesktopContext";

import MaterialUI from "../../components/MaterialUI";
import Pagination from '../../components/Pagination';
import Dialog from '../../components/Dialog'

import NoImage from '../../assets/img/no-image.jpg'
import PosDesktopRefoundForm from "./PosDesktopFormRefound";
import { getFullPathImage, getImageBySize } from "../../utils";
import NumberFormat from "../../utils/number.format";
import { SIZES } from "../../components/Product/ProductDetail";

const useStyles = makeStyles({
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '.25rem',
  },
  item: {
    display: 'flex',
    gap: '.25rem',
    border: '1px solid var(--bs-gray-300)',
    padding: '.5rem .35rem',
    borderRadius: '.15rem',
    position: 'relative',
    transition: 'all 200ms ease',
    '&:hover': {
      borderColor: 'rgb(255 152 0)',
      backgroundColor: 'rgb(255 152 0 / 10%)',
      '& > i.far': {
        color: 'rgb(255 152 0) !important',
      }
    },
    '&.active': {
      borderColor: 'rgb(255 152 0)',
      backgroundColor: 'rgb(255 152 0 / 10%)',
      '& > i.far': {
        color: 'rgb(255 152 0) !important',
      }
    }
  },
  itemIcon: {
    position: 'absolute',
    right: '.25rem',
    fontSize: '53px !important',
    opacity: '.2'
  },
  table: {
		padding: 0,
  },
	containerChild: {
    overflow: 'auto',
    maxBlockSize: '45vh',
    background: 'white',
	},
  paginationContainer: {
		'& .MuiPaginationItem-page.Mui-selected': {
      color: 'rgb(13 110 253)',
			backgroundColor: 'rgb(13 110 253 / 20%)',
			borderColor: 'rgb(13 110 253)'
    },
		'& .MuiPaginationItem-page': {
      borderRadius: '50%',
      minWidth: '28px',
      height: '28px',
      fontSize: '12px',
      display: 'inline-grid',
      placeContent: 'center',
      padding: '0',
    },
    '& .MuiPaginationItem-page .MuiPaginationItem-icon': {
      fontSize: '1rem',
    },
	}
});

const PosDesktopHoldOrders = () => {
  const classes = useStyles();
	const {
    receipts,
    fetchReceipts,
    receiptsPagination,
    handleSetRefoundFormClick,
    showRefoundForm,
    setShowRefoundForm,
    cleanFormRefound,
    currentRefound,
    setCurrentRefound,
    findReceipt,
  } = React.useContext(PosDesktopContext);

  const handleRefoundClick = async (receiptCode) => {
    try {
      cleanFormRefound();
      const { data } = await findReceipt(receiptCode);
      if(data.status) {
        setCurrentRefound(data.data);
        handleSetRefoundFormClick(data.data.details);
      }
    } catch (error) {}
  }

  const changeRefoundSelected = () => {
    setShowRefoundForm(true);
  }

  const setNewPage = (page) => {
    fetchReceipts(page);
    cleanFormRefound();
  }
  
	return (<>
    <Dialog
      open={showRefoundForm}
      onClose={() => setShowRefoundForm(false)}
      title={'Refund Items'}
      children={(onClose) => <PosDesktopRefoundForm onClose={onClose} />}
    />
    <Grid container spacing={2} className={classes.root}>
      {(!receipts.length) ? (
        <Grid item xs={12} className="text-muted text-center">
          <i className="fas fa-list-ol fa-2x mb-2"></i>
          <p className="mb-0 fs-small">Orders empty</p>
        </Grid>
      ) : (<>
        <Grid item xs={12} className={classes.containerChild}>
          <Pagination pagination={receiptsPagination} changePage={setNewPage} />
          <div className={clsx('text-muted', classes.list)}>
            {receipts.map((item) => (
              <div key={item.id} className={clsx(classes.item, (currentRefound.id === item.id) && 'active')} role="button" onClick={() => handleRefoundClick(item.receipt_nro)}>
                <i className={clsx(classes.itemIcon, 'text-muted', String(item.status).toLowerCase() === 'paid' && 'fas fa-file-invoice-dollar')}></i>
                <div className="d-flex flex-column gap-0 position-relative w-100">
                  <span className="fs-xs text-primary position-absolute font-inter fw-bold" style={{ right: '.25rem', top: '.25rem' }}>#{item.id}</span>
                  <span className="fs-xs">Code: <b>{item.receipt_nro ?? '-'}</b></span>
                  <span className="fs-xs">Date: <b>{item.date_receipt}</b></span>
                  <span className="fs-xs">Amount: <b>{item.total}</b></span>
                </div>
              </div>
            ))}
          </div>
        </Grid>
        {!!currentRefound.id && (
          <>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={6}>
                <MaterialUI.Button.Secondary className="w-100 fs-small" onClick={cleanFormRefound}>
                  Cancel
                </MaterialUI.Button.Secondary>
              </Grid>
              <Grid item xs={6}>
                <MaterialUI.Button.Primary className="w-100 fs-small" onClick={changeRefoundSelected}>
                  <i className="fas fa-undo me-2"></i>Refund
                </MaterialUI.Button.Primary>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TableContainer className={classes.container}>
                <Table stickyHeader className={clsx(classes.table)} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className="fs-xs" align="left">Product</TableCell>
                      <TableCell className="fs-xs" align="center" style={{ width: '80px', paddingInline: '.75rem' }}>Unit. P.</TableCell>
                      <TableCell className="fs-xs" align="center" style={{ inlineSize: '50px' }}>Qty</TableCell>
                      <TableCell className="fs-xs" align="center" style={{ width: '80px', paddingInline: '.75rem' }}>SubTotal</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className='no-bordered'>
                    {currentRefound.details.map((item, index) => !!item.item && (
                      <TableRow key={index}>
                        <TableCell className="px-1" align="left" component="th" scope="row">
                          <div className='d-flex gap-2' style={{ minBlockSize: '50px', alignItems: 'center' }}>
                            <figure className='table__product__image'>
                              <img src={getFullPathImage(getImageBySize(item.item?.images, SIZES['275'], item.item?.item_name)[0], NoImage)} alt={item?.item?.name} onError={(ev) => ev.target.src = NoImage} />
                            </figure>
                            <div className='w-100'>
                              <div style={{ lineHeight: 1 }}>
                                <span className="fs-xs text-uppercase">{String(item.item.item_name).toLowerCase()}</span>
                                <p className="mb-0 fs-xs text-secondary">{[String(item.item.color), String(item.item.size)].join(' / ')}</p>
                              </div>
                              <div className='d-flex flex-column flex-wrap' style={{ lineHeight: '1.1' }}>
                                {/* <div className="card__pos__product__property text-uppercase">Sku: {item.item?.sku}</div> */}
                                {!!item.item.brand_id?.name && <span className="card__pos__product__property text-uppercase">Brand: {item.item.brand_id.name}</span>}
                                {!!item.item.category_id?.name && <span className="card__pos__product__property text-uppercase">Cat.: {item.item.category_id.name}</span>}
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className="px-1 fs-xs" align="center" component="th" scope="row">
                          <span className="text-capitalize">{NumberFormat.currency(item.price)}</span>
                        </TableCell>
                        <TableCell className="px-1 fs-xs" align="center">
                          <span className="cart__counter">{item.quantity}</span>
                        </TableCell>
                        <TableCell className="px-1 fs-xs" align="center">
                          <span>{NumberFormat.currency(item.price * item.quantity)}</span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter style={{ position: 'sticky', bottom: 0 }}>
                    <TableRow className='no-hover'>
                      <TableCell colSpan={2} className='table__text_footer'>General information</TableCell>
                      <TableCell className="fs-xs px-1 table__text_footer font-inter fw-bold" align="center">{currentRefound.details?.length}</TableCell>
                      <TableCell className="fs-xs px-1 table__text_footer font-inter fw-bold" align="center">{currentRefound.total}</TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          </>
        )}
      </>)} 
    </Grid>
  </>);
};

export default PosDesktopHoldOrders;
