import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import React from 'react'
import clsx from 'clsx'

import { getFullPathImage } from '../../../utils'
import NumberFormat from '../../../utils/number.format'
import NoImage from '../../../assets/img/no-image.jpg'

const useStyles = makeStyles({
  root: {
    marginBlockStart: 6,
    minWidth: 275,
  },
  title: {
    fontSize: 12,
  },
  pos: {
    marginBottom: 0,
  },
  groupButtons: {
    alignItems: 'center',
    display: 'flex',
  },
  button: {
    border: '1px solid #cdcdcd',
    textAlign: 'center',
    background: 'white',
    borderRadius: '4px',
    height: '30px',
    width: '30px',
  },
  borderRightNone: {
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
  },
  borderLeftNone: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  },
  borderBlock: {
    borderBottom: '1px solid #cdcdcd',
    borderTop: '1px solid #cdcdcd',
    paddingInline: '20px',
    alignItems: 'center',
    fontFamily: "Inter",
    userSelect: 'none',
    fontSize: '14px',
    display: 'flex',
    height: 30,
  }
})

export default function PosDesktopProductDetail({ item_name, color = 'Standard', size = 'Standard', images = [] }) {
  const classes = useStyles()
  
  return (
    <Card className={classes.root} variant="outlined">
      <div className="d-flex justify-content-between" style={{ minHeight: '30px' }}>
        <CardContent className='p-2 w-100'>
          <div className="d-flex justify-content-between w-100 h-100 align-items-center">
            <div>
              <Typography className={classes.title} color="textSecondary" gutterBottom>{size}</Typography>
              <Typography className={classes.title} color="textSecondary" gutterBottom>{color}</Typography>
            </div>
            <div className="d-flex gap-4">
              <div className={classes.groupButtons}>
                <button className={clsx(classes.button, classes.borderRightNone)}>+</button>
                <div className={clsx(classes.borderBlock)}>0</div>
                <button className={clsx(classes.button, classes.borderLeftNone)}>-</button>
              </div>
              <figure className='align-items-center d-flex mb-0'>
                <img src={getFullPathImage(images[0], NoImage)} height={50} alt="Image Not found" />
              </figure>
            </div>
          </div>
        </CardContent>
      </div>
    </Card>
  )
}
