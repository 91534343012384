import React from "react";
import axios from "axios";
import moment from "moment";
import { Modal, Button, DropdownButton } from "react-bootstrap";
import { ModalAdd } from "../modal_add";
import { ModalDelete } from "../modal_delete";
import { ModalUpdate } from "../modal_update";
import "./style.css";
import createHttp from "../../lib/http";
import { useHistory } from "react-router-dom";

import { S3_URL_PATH } from "../../utils/constants";

import {
	faEdit,
	faMinusCircle,
	faTrash,
	faSortDown,
	faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SpinnerLoading } from "../spinner";

function TableCrud({
	table_id_configuration,
	table_add_configuration,
	api_configuration,
	updateAddConfiguration,
	table_first_conf,
	app_conf,
	use_modal,
}) {
	const [data, setData] = React.useState([]);

	const [filtered_data, setFilteredData] = React.useState([]);
	const [order_by_field, setOrderByField] = React.useState("");
	const [order_by_asc_desc, setOrderAscDesc] = React.useState("DESC");
	const [filter_input, setFilterInput] = React.useState("");
	const [loading_spinner, setLoadingSpinner] = React.useState(false);

	const history = useHistory();

	const getData = () => {
		createHttp
			.get(api_configuration.get_all_data)
			.then((res) => {
				if (res.data.status) {
					setData(res.data.data);
					setFilteredData(res.data.data);
				}
			})
			.catch((res) => {});
	};

	const setSearchValue = (event) => {
		setFilterInput(event.target.value);
	};

	const filterItems = () => {
		if (filter_input.trim() === "") {
			let no_search_array = [...data];

			no_search_array = no_search_array.sort((a, b) => {
				//return b.name - a.name;

				if (order_by_asc_desc == "DESC") {
					if (
						b[order_by_field].toString().toLowerCase() >
						a[order_by_field].toString().toLowerCase()
					) {
						return -1;
					}
					if (a[order_by_field] > b[order_by_field]) {
						return 1;
					}
					return 0;
				}

				if (order_by_asc_desc == "ASC") {
					if (
						b[order_by_field].toString().toLowerCase() >
						a[order_by_field].toString().toLowerCase()
					) {
						return 1;
					}
					if (
						a[order_by_field].toString().toLowerCase() >
						b[order_by_field].toString().toLowerCase()
					) {
						return -1;
					}
					return 0;
				}
			});

			setFilteredData([...no_search_array]);
		} else {
			let array = data.filter((element, index) => {
				for (
					let index = 0;
					index < table_id_configuration.length;
					index++
				) {
					if (
						element[table_id_configuration[index].field_id] !==
							undefined &&
						element[table_id_configuration[index].field_id] !==
							null &&
						table_id_configuration[index].search
					) {
						if (
							element[table_id_configuration[index].field_id]
								.toLowerCase()
								.includes(filter_input.toLowerCase())
						) {
							return element;
						}
					}
				}
			});

			array = array.sort((a, b) => {
				//return b.name - a.name;

				if (order_by_asc_desc == "DESC") {
					if (
						b[order_by_field].toString().toLowerCase() >
						a[order_by_field].toString().toLowerCase()
					) {
						return -1;
					}
					if (
						a[order_by_field].toString().toLowerCase() >
						b[order_by_field].toString().toLowerCase()
					) {
						return 1;
					}
					return 0;
				}

				if (order_by_asc_desc == "ASC") {
					if (
						b[order_by_field].toString().toLowerCase() >
						a[order_by_field].toString().toLowerCase()
					) {
						return 1;
					}
					if (
						a[order_by_field].toString().toLowerCase() >
						b[order_by_field].toString().toLowerCase()
					) {
						return -1;
					}
					return 0;
				}
			});

			setFilteredData([...array]);
		}
	};

	const storeNewItem = () => {
		let form = new FormData();

		for (let index = 0; index < table_add_configuration.length; index++) {
			form.append(
				table_add_configuration[index].field_id,
				table_add_configuration[index].value,
			);
		}

		createHttp
			.post(api_configuration.store_item, form)
			.then((res) => {
				if (res.data.status) {
					getData();
					handleClose();
				}
				if (!res.data.status) {
					handleClose();
				}
			})
			.catch((res) => {});
	};

	const returnCorrectValue = (element, key_id) => {
		let value = element[key_id.field_id];

		switch (key_id.type) {
			case "text":
				value = value;
				break;
			case "date":
				value = moment(value).format("YYYY-MM-DD HH:mm:ss");
				break;
			case "array":
				let string = "";

				for (let index = 0; index < key_id.keys.length; index++) {
					if (
						element[key_id.field_id][key_id.keys[index].name] !==
						undefined
					) {
						/*                        
								element[key_id.field_id] = EL OBJETO QUE CONTIENE EL ELEMENTO COMPLETO SELECCIONADO
								[ key_id.keys[index].name ] = EL INDICE DENTRO DEL OBJETO ES DECIR { NAME : 'GEREMY' LAST_NAME : 'NAVARRETE' }
								element[0].name o element[0][indice que se configuro anteriormente] 
								
						*/

						string =
							string +
							element[key_id.field_id][key_id.keys[index].name];
						string = string + " ";
					}
				}

				value = string;

				break;
			case "arrays":
				let string_arrays = "";

				let get_all_data = element[key_id.field_id];

				/*
						recorremos el arreglo para tener todos los valores es decir recorremos [  { name : 'ejemplo 1' } , { name : 'ejemplo 2' } ]   
						luego recorremos los objetos para obtener los indices que se configuran previamente por ejemplo el indice name quedaria algo asi
						
						ejemplo 1 
						ejemplo 2 

				*/

				for (let index = 0; index < get_all_data.length; index++) {
					if (string_arrays.trim() !== "") {
						string_arrays = "\n" + string_arrays;
					}

					for (
						let index_2 = 0;
						index_2 < key_id.keys.length;
						index_2++
					) {
						if (
							element[key_id.field_id][index][
								key_id.keys[index_2].name
							] !== undefined
						) {
							/*                        
									element[key_id.field_id] = EL OBJETO QUE CONTIENE EL ELEMENTO COMPLETO SELECCIONADO
									[index]  = EL INDICE DEL OBJETO PRINCIPAL
									[ key_id.keys[index].name ] = EL INDICE DENTRO DEL OBJETO ES DECIR { NAME : 'GEREMY' LAST_NAME : 'NAVARRETE' }
									element[0].name o element[0][indice que se configuro anteriormente] 
									
							*/

							string_arrays =
								string_arrays +
								element[key_id.field_id][index][
									key_id.keys[index_2].name
								];
							string_arrays = string_arrays + " ";
						}
					}
				}

				return string_arrays;

				break;
			case "arrays_img":
				let string_arrays_2 = "";

				let get_all_data_2 = element[key_id.field_id];

				/*
						recorremos el arreglo para tener todos los valores es decir recorremos [  { name : 'ejemplo 1' } , { name : 'ejemplo 2' } ]   
						luego recorremos los objetos para obtener los indices que se configuran previamente por ejemplo el indice name quedaria algo asi
						
						ejemplo 1 
						ejemplo 2 

				*/

				for (let index = 0; index < get_all_data_2.length; index++) {
					for (
						let index_2 = 0;
						index_2 < key_id.keys.length;
						index_2++
					) {
						if (index_2 > 0) {
							break;
						}

						if (
							element[key_id.field_id][index][
								key_id.keys[index_2].name
							] !== undefined
						) {
							/*                        
									element[key_id.field_id] = EL OBJETO QUE CONTIENE EL ELEMENTO COMPLETO SELECCIONADO
									[index]  = EL INDICE DEL OBJETO PRINCIPAL
									[ key_id.keys[index].name ] = EL INDICE DENTRO DEL OBJETO ES DECIR { NAME : 'GEREMY' LAST_NAME : 'NAVARRETE' }
									element[0].name o element[0][indice que se configuro anteriormente] 
									
							*/

							string_arrays_2 =
								string_arrays_2 +
								element[key_id.field_id][index][
									key_id.keys[index_2].name
								];
							string_arrays_2 = string_arrays_2 + " ";
						}
					}
				}

				value = S3_URL_PATH + string_arrays_2;

				break;

			default:
				value = "";

				break;
		}

		return value;
	};

	React.useEffect(() => {
		getData();
	}, []);

	React.useEffect(() => {
		if (order_by_field === "") {
			let get_first_value = table_id_configuration.find((element) => {
				if (element.search) return element;
			});

			if (get_first_value !== undefined) {
				setOrderByField(get_first_value.field_id);
			}
		}
	}, []);

	React.useEffect(() => {
		filterItems();
	}, [filter_input, order_by_asc_desc, order_by_field, data]);

	const [use_item_id, setItemId] = React.useState("");

	const [show, setShow] = React.useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [show_update, setShowUpdate] = React.useState(false);
	const handleCloseUpdate = () => setShowUpdate(false);
	const handleShowUpdate = () => setShowUpdate(true);

	const [show_delete, setShowDelete] = React.useState(false);
	const handleCloseDelete = () => setShowDelete(false);
	const handleShowDelete = () => setShowDelete(true);

	const prepareToDelete = (element) => {
		setItemId(element.id);
		handleShowDelete();
	};

	const ConfirmDelete = () => {
		let form = new FormData();
		form.append("id", use_item_id);
		createHttp
			.delete(api_configuration.delete_item + "/" + use_item_id, {
				data: { id: use_item_id },
			})
			.then((res) => {
				if (res.data.status) {
					getData();
					handleCloseDelete();
				}
			})
			.catch((res) => {});
	};

	const prepareToAdd = () => {
		setEmptyValues(table_first_conf);
		handleShow();
	};

	const setEmptyValues = () => {
		let array = [...table_first_conf];
		array.map((element) => {
			element.value = "";
			element.have_error = false;
			return element;
		});
		updateAddConfiguration(array);
	};

	const prepareToUpdate = (element) => {
		let new_array = [...table_add_configuration];

		for (let index = 0; index < new_array.length; index++) {
			if (element[new_array[index].field_id] !== null) {
				new_array[index].value = element[new_array[index].field_id];
			}
		}

		updateAddConfiguration(new_array);
		setItemId(element.id);
		handleShowUpdate();
	};

	const ConfirmUpdate = () => {
		// let form = new FormData();

		var averctm = {};

		for (let index = 0; index < table_add_configuration.length; index++) {
			averctm[table_add_configuration[index].field_id] =
				table_add_configuration[index].value;
			// form.append( table_add_configuration[index].field_id , table_add_configuration[index].value);
		}

		createHttp
			.put(api_configuration.update_item + "/" + use_item_id, averctm)
			.then((res) => {
				if (res.data.status) {
					getData();
					handleCloseUpdate();
				}
			})
			.catch((res) => {});
	};

	const setValueToOrder = (e, field_id, search) => {
		//DEFINIMOS QUE EL VALUE ES VACIO PARA QUE REALIZE EL SEARCH

		if (search) {
			if (field_id === order_by_field && order_by_asc_desc === "DESC") {
				setOrderAscDesc("ASC");
			}

			if (field_id === order_by_field && order_by_asc_desc === "ASC") {
				setOrderAscDesc("DESC");
			}
			//const [order_by_field , setOrderByField ] = React.useState( "" );
			//const [order_by_asc_desc , setOrderAscDesc ] = React.useState('DESC');

			setOrderByField(field_id);
		}
	};

	const goToUrl = () => {
		if (use_modal !== undefined) {
			setEmptyValues(table_first_conf);
			history.push(use_modal.url);
		}
	};

	const prepareToUpdateForm = (element) => {
		if (use_modal !== undefined) {
			history.push({
				pathname: use_modal.url_update,
				state: { data: element },
			});
		}
	};

	React.useEffect(() => {
		setLoadingSpinner(true);

		setTimeout(() => {
			setLoadingSpinner(false);
		}, 1500);
	}, []);

	return (
		<div className="">
			<div className="col-12 ">
				<div className="d-flex justify-content-between">
					<div className="">
						<div className="row g-3 align-items-center">
							<div className="col-auto">
								<label
									htmlFor="inputPassword6"
									className="col-form-label"
								>
									Filter
								</label>
							</div>
							<div className="col-auto">
								<input
									onChange={(e) => setSearchValue(e)}
									type="text"
									id="inputPassword6"
									className="form-control form-control-sm"
									aria-describedby="passwordHelpInline"
								/>
							</div>
							<div className="col-auto">
								<span
									id="passwordHelpInline"
									className="form-text"
								>
									Enter a letter to filter in items
								</span>
							</div>
						</div>
					</div>

					<div className=" mt-2 mb-2">
						<button
							className="btn btn-primary btn-sm"
							onClick={goToUrl}
							hidden={use_modal !== undefined ? false : true}
						>
							<i
								className="fas fa-plus"
								style={{ marginRight: "5px" }}
							></i>
							Add
						</button>

						<Button
							hidden={use_modal !== undefined ? true : false}
							variant="primary"
							size="sm"
							onClick={() => prepareToAdd()}
						>
							<i
								className="fas fa-plus"
								style={{ marginRight: "5px" }}
							></i>
							Add
						</Button>
					</div>
				</div>
			</div>
			<div hidden={!loading_spinner}>
				<SpinnerLoading></SpinnerLoading>
			</div>

			<div hidden={loading_spinner} className="table-responsive w-100">
				<table className="w-100">
					<thead className="">
						<tr>
							{table_id_configuration.map((element, index) => (
								<th
									className="text-nowrap    "
									key={index}
									onClick={(e) =>
										setValueToOrder(
											e,
											element.field_id,
											element.search,
										)
									}
								>
									<div className="d-flex">
										{element.field_name}

										<div className="d-flex align-items-start flex-column">
											<FontAwesomeIcon
												hidden={!element.search}
												style={{
													color:
														order_by_field ===
															element.field_id &&
														order_by_asc_desc ===
															"ASC"
															? "red"
															: "black",
													marginLeft: "10",
													marginRight: "10",
													marginTop: "0",
													marginBottom: "-9",
												}}
												icon={faSortUp}
											/>

											<FontAwesomeIcon
												hidden={!element.search}
												style={{
													color:
														order_by_field ===
															element.field_id &&
														order_by_asc_desc ===
															"DESC"
															? "red"
															: "black",
													marginLeft: "10",
													marginRight: "10",
													marginTop: "1",
													marginBottom: "1",

													//order_by_field
													//order_by_asc_desc
												}}
												icon={faSortDown}
											/>
										</div>
									</div>
								</th>
							))}
							<th className="text-nowrap  "> Actions</th>
						</tr>
					</thead>
					<tbody>
						{filtered_data.map((element, index) => (
							<tr key={"table_" + index}>
								{table_id_configuration.map(
									(element_2, index_2) => (
										<td
											className=""
											key={"data_" + index + index_2}
										>
											{" "}
											{/* hay que analizar el diseño text-nowrap  */}
											{
												<span
													hidden={
														element_2.type ===
														"text"
															? false
															: true
													}
												>
													{" "}
													{returnCorrectValue(
														element,
														element_2,
													)}{" "}
												</span>
											}
											{
												<span
													hidden={
														element_2.type ===
														"arrays"
															? false
															: true
													}
												>
													{" "}
													{returnCorrectValue(
														element,
														element_2,
													)}{" "}
												</span>
											}
											{
												<span
													hidden={
														element_2.type ===
														"array"
															? false
															: true
													}
												>
													{" "}
													{returnCorrectValue(
														element,
														element_2,
													)}{" "}
												</span>
											}
											{
												<img
													width={50}
													height={50}
													src={returnCorrectValue(
														element,
														element_2,
													)}
													alt="none"
													hidden={
														element_2.type ===
														"arrays_img"
															? false
															: true
													}
												/>
											}
										</td>
									),
								)}

								<td className="text-nowrap">
									{/* <DropdownButton
                                            style={{padding: '2px'}}
                                            variant="outline-primary btn-sm"
                                            title="Actions"
                                            id="input-group-dropdown-1">
                                                <div className='items-option' style={{borderBottom: '1px solid grey'}} onClick={ () => prepareToDelete(element)}>
                                                    <FontAwesomeIcon style={{margin: '4px', marginRight: '10px'}}  icon={faTrash}/><p>Delete</p>
                                                    <hr/>
                                                </div>
                                                <div className='items-option' style={{borderBottom: '1px solid grey'}} onClick={  () => prepareToUpdateForm(element) }>
                                                    <FontAwesomeIcon style={{margin: '4px', marginRight: '10px'}}  icon={faEdit}/><p>Edit</p>
                                                    <hr/>
                                                </div>
                                        </DropdownButton>  */}

									<button
										hidden={
											use_modal !== undefined
												? false
												: true
										}
										onClick={() =>
											prepareToUpdateForm(element)
										}
										className="btn btn-sm btn-outline-info ms-2 me-2"
									>
										<FontAwesomeIcon icon={faEdit} />
									</button>
									<button
										hidden={
											use_modal !== undefined
												? true
												: false
										}
										className="btn btn-sm btn-outline-info ms-2 me-2"
										onClick={() => prepareToUpdate(element)}
									>
										<FontAwesomeIcon icon={faEdit} />
									</button>
									<button
										className="btn btn-sm btn-outline-danger ms-2 me-2"
										onClick={() => prepareToDelete(element)}
									>
										<FontAwesomeIcon icon={faMinusCircle} />
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			<ModalAdd
				app_conf={app_conf}
				table_add_configuration={table_add_configuration}
				updateAddConfiguration={updateAddConfiguration}
				onAddItem={storeNewItem}
				onHandleClose={handleClose}
				show={show}
			/>
			<ModalUpdate
				app_conf={app_conf}
				table_add_configuration={table_add_configuration}
				updateAddConfiguration={updateAddConfiguration}
				onConfirmUpdate={() => ConfirmUpdate()}
				onHandleClose={handleCloseUpdate}
				show={show_update}
			/>
			<ModalDelete
				app_conf={app_conf}
				onConfirmDelete={() => ConfirmDelete}
				onHandleClose={handleCloseDelete}
				show={show_delete}
			/>
		</div>
	);
}

export { TableCrud };
