import React from "react";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import Barcode from 'react-barcode';
import ReactToPrint from 'react-to-print';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TouchAppIcon from '@material-ui/icons/TouchApp';

import MaterialUI from "../../components/MaterialUI";
import './style.css';
import Adapter from "../../utils/global.adapter";
import NoImage from '../../assets/img/no-image.jpg';
import { getFullPathImage } from "../../utils";

const useStyles = makeStyles({
	table: {
		maxHeight: '100px',
		width: '100%',
	},
	thAction: {
		width: '40px',
		minWidth: '40px',
		maxWidth: '40px',
	},
	rowActive: {
		background: '#ffebcd !important'
	}
});

const BarcodeGenerator = () => {
	const [itemVariantSelected, setItemVariantSelected] = React.useState(undefined);
	const [product, setProduct] = React.useState(null);
	const [showPreview, setShowPreview] = React.useState(false);
	const $container = React.useRef(null);
	const classes = useStyles();


	const [options] = React.useState({
		width: 2,
		height: 60,
		format: "CODE39",
		font: "monospace",
		textAlign: "center",
		fontSize: 14,
	})
	
	const [paperConfiguration, setPaperConfiguration] = React.useState({
		columns: 3,
		perPage: 21
	});
	
	const changePaperConfiguration = (prop) => (value) => setPaperConfiguration((prev) => ({ ...prev, [prop]: value }));

	React.useEffect(() => {
		if(!product) {
			setShowPreview(false);
		}
	}, [product])

	const onOptionsSelect = (newValue) => {
		setItemVariantSelected(undefined)
		if(!!newValue?.id) {
			setProduct(newValue)
		} else {
			setProduct(null)
		}
	}

	const selectItemVariantHandler = (newValue) => {
		setItemVariantSelected((prev) => prev?.id == newValue?.id ? undefined : newValue)
	}
	
	return (<>
		<Grid container spacing={2} className="p-2">
			<Grid item xs={12}>
				<div className="container-title mb-2">
					<h1 className="container__title mb-0">
						<i className="fas fa-barcode me-2"></i>Barcode Generator
					</h1>
				</div>
			</Grid>
			<Grid item xs={12} md={5} lg={4} xl={3}>
				<Grid container spacing={1} style={{ position: 'sticky', top: '1rem' }}>
					<Grid item xs={12}>
						<MaterialUI.AutocompleteAsync
							uri={'api/items'}
							params={['search_text']}
							label="Search Product by SKU or Name"
							state={product}
							setState={onOptionsSelect}
							getOptionSelected={(option, value) => option?.id == value?.id}
							getOptionLabel={(option) => option.name ?? ''}
							adapterOptions={Adapter.responseProductSearchByAutocomplete}
						/>
					</Grid>
					{!!product && (
						<Grid item xs={12}>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small">
									<TableHead>
										<TableRow>
											<TableCell>Variant</TableCell>
											<TableCell align="center" className={classes.thAction}><TouchAppIcon fontSize="small" /></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{(product.item_variants || []).map((row, key) => (
											<TableRow key={key} className={row.id == itemVariantSelected?.id ? classes.rowActive : ''}>
												<TableCell component="th" scope="row">
													<div className="d-flex gap-1">
														<figure className='table__product__image'>
															<img src={getFullPathImage((row?.images || [])[0], NoImage)} alt={row.name} />
														</figure>
														<p className="mb-0 ">
															<Typography variant="caption" className="d-block">SKU: {row.sku}</Typography>
															<Typography variant="caption" className="d-block">UPC: {row.upc}</Typography>
														</p>
													</div>
												</TableCell>
												<TableCell align="center">
													<MaterialUI.Button.Primary size="small" className="mw-auto" onClick={() => selectItemVariantHandler(row)}>
														<TouchAppIcon fontSize="small" />
													</MaterialUI.Button.Primary>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					)}
					<Grid item xs={12}>
						<p className="sub__title fs-small">Paper configuration</p>
					</Grid>
					<Grid item xs={12} md={6} lg={5} xl={4}>
						<MaterialUI.Input
							type="number"
							label="Columns"
							state={paperConfiguration.columns}
							setState={changePaperConfiguration('columns')}
						/>
					</Grid>
					<Grid item xs={12} md={6} lg={5} xl={4}>
						<MaterialUI.Input
							type="number"
							label="Quantity"
							state={paperConfiguration.perPage}
							setState={changePaperConfiguration('perPage')}
						/>
					</Grid>
					<Grid item xs={12}>
						<Box className="d-flex flex-wrap gap-1 h-100">
							<MaterialUI.Button.Dark className="fs-small h-100"
								disabled={!product || !paperConfiguration.columns || !paperConfiguration.perPage} onClick={() => setShowPreview((prev) => !prev)}
							>
								{showPreview ? <i className="fas fa-eye-slash me-2"></i> : <i className="fas fa-eye me-2"></i>}Preview
							</MaterialUI.Button.Dark>
							<ReactToPrint
								trigger={() => (
									<MaterialUI.Button.Primary className="fs-small h-100" disabled={!product || !paperConfiguration.columns || !paperConfiguration.perPage || !showPreview}>
										<i className="fas fa-print me-2"></i>Print
									</MaterialUI.Button.Primary>
								)}
								content={() => $container.current}
							/>
						</Box>
					</Grid>
				</Grid>
			</Grid>
			{(showPreview && product && itemVariantSelected && paperConfiguration.columns && paperConfiguration.perPage) && (
				<Grid item xs={12} md={7} lg={8} xl={9}>
					<Paper variant="outlined" className="p-2">
						<Grid ref={$container} container spacing={2} className="d-grid p-2" justifyContent="space-around" style={{ gridTemplateColumns: `repeat(${paperConfiguration.columns}, 1fr)` }}>
							{Array(Number(paperConfiguration.perPage) || 0).fill({}).map((_, index) => (
								<div key={`barcode-${index}`}>
									<Barcode className="w-100" value={itemVariantSelected?.sku || 'SKU INVALID'} {...options} />
								</div>
							))}
						</Grid>
					</Paper>
				</Grid>
			)}
		</Grid>
	</>);
};

export default BarcodeGenerator;
