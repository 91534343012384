import { useState, useEffect } from "react";
import createHttp from "../lib/http";

function useApiRequestCategories(manageResponseErrors) {
	const [isLoading, setIsLoading] = useState(false);
	const [sincronizedItems, setSincronizedItems] = useState(false);
	const [filtered_data, setFilteredData] = useState([]);

	useEffect(() => {
		let params = {};
		// itemFilters
		//   .filter((filter) => filter.value)
		//   .map((filter) => params[filter.field_id] = filter.value)
		if (!sincronizedItems) {
			setIsLoading(true);
			createHttp
				.get("/api/categories", { params })
				.then((response) => {
					if (response.data.status) {
						setFilteredData(response.data.data);
					} else {
						// manage error
					}
					setIsLoading(false);
					setSincronizedItems(true);
				})
				.catch((error) => {
					setIsLoading(false);
					setSincronizedItems(true);
					return { data: [] };
				});
		}
	}, [sincronizedItems]);

	const deleteCategory = async (use_item_id) => {
		await createHttp
			.delete("/api/categories" + "/" + use_item_id, {
				data: { id: use_item_id },
			})
			.then((res) => {
				if (res.data.status) {
					setSincronizedItems(false);
				} else {
					manageResponseErrors(
						res.data.message,
						"Category",
						"deleting",
					);
				}
			})
			.catch((error) => {
				manageResponseErrors(
					"An error has occurred",
					"Category",
					"deleting",
				);
			});
	};

	const uploadImageCategory = async (payload) => {
		try {
			await createHttp.post("/api/categories_change_img", payload);
		} catch (error) {
			// manageResponseErrors("An error has occurred", "Category", "Update Image");
		}
	};

	const updateCategory = async (use_item_id, data) => {
		await createHttp
			.put("/api/categories" + "/" + use_item_id, data)
			.then((res) => {
				if (res.data.status) {
					setSincronizedItems(false);
				} else {
					manageResponseErrors(
						res.data.message,
						"Category",
						"updating",
					);
				}
			})
			.catch((error) => {
				manageResponseErrors(
					"An error has occurred",
					"Category",
					"updating",
				);
			});
	};

	const createCategory = async (data, reload = true) => {
		return await createHttp
			.post("/api/categories", data)
			.then((res) => {
				if (res.data.status) {
					if (reload) setSincronizedItems(false);
					return res.data.data
				} else {
					manageResponseErrors(
						res.data.message,
						"Category",
						"creating",
					);
				}
			})
			.catch((error) => {
				manageResponseErrors(
					"An error has occurred",
					"Category",
					"creating",
				);
			});
	};

	return {
		isLoading,
		setIsLoading,
		sincronizedItems,
		setSincronizedItems,
		filtered_data,
		deleteCategory,
		updateCategory,
		createCategory,
		setFilteredData,
		uploadImageCategory,
	};
}

export { useApiRequestCategories };
