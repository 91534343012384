import axios from "axios";

const createHttpV2 = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		"Content-Type": "application/json, application/x-www-form-urlencoded",
		Accept: "application/json"
	},
});

const errorRequestHandler = (error) => Promise.reject(error)
const successRequestHandler = (config) => {
  const token = localStorage.jwt_token
  if (token !== undefined) config.headers.Authorization = `Bearer ${JSON.parse(token)}`
  return config
}

createHttpV2.interceptors.request.use(successRequestHandler, errorRequestHandler)

export default createHttpV2;
