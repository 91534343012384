import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import clsx from 'clsx';
import { PosDesktopContext, MIN_PER_PAGE } from "../../context/PosDesktopContext";

import PosDesktopCardProduct from './PosDesktopCardProduct';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  loader: {
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'grid',
    placeContent: 'center',
    position: 'absolute',
    background: 'rgb(255 255 255 / 80%)',
    zIndex: '1',
  },
  loaderTitle: {
    margin: '0',
    borderRadius: '4rem',
    fontWeight: '600',
    color: 'rgb(255 152 0)',
  },
  pagination: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
  paginationContainer: {
		'& .MuiPaginationItem-page.Mui-selected': {
      color: 'rgb(13 110 253)',
			backgroundColor: 'rgb(13 110 253 / 20%)',
			borderColor: 'rgb(13 110 253)'
    },
		'& .MuiPaginationItem-page': {
      borderRadius: '50%',
      minWidth: '28px',
      height: '28px',
      fontSize: '12px',
      display: 'inline-grid',
      placeContent: 'center',
      padding: '0',
    },
    '& .MuiPaginationItem-page .MuiPaginationItem-icon': {
      fontSize: '1rem',
    },
	},
  categories: {
    display: 'flex',
    columnGap: '.5rem',
    rowGap: '.3rem',
    flexWrap: 'wrap',
    minBlockSize: '45px',
    alignItems: 'center',
    '& .categories__item': {
      display: 'flex',
      padding: '.3rem .65rem',
      position: 'relative',
      fontSize: '.8rem',
      '--size-icon': '26px',
      borderRadius: '.3rem',
      textTransform: 'capitalize',
      border: '1px solid var(--bs-gray-400)',
      cursor: 'pointer',
      userSelect: 'none',
      '& .category__icon': {
        inlineSize: 'var(--size-icon)',
        blockSize: 'var(--size-icon)',
        position: 'absolute',
        left: '5%',
        objectFit: 'cover',
        top: '1px',
      },
      '& .category__text': {
        paddingInlineStart: 'calc(var(--size-icon) - .3rem)',
        fontWeight: '500'
      },
      '&.active': {
        borderColor: 'rgb(4 23 49)',
        background: 'rgb(4 23 49 / 10%)',
        color: 'rgb(4 23 49)',
        '& .category__icon': {
          filter: 'drop-shadow(1px 1px 1px rgb(4 23 49))'
        }
      },
      '&:hover': {
        borderColor: 'rgb(4 23 49)',
        background: 'rgb(4 23 49 / 10%)',
        color: 'rgb(4 23 49)',
        '& .category__icon': {
          filter: 'drop-shadow(1px 1px 1px rgb(4 23 49))'
        }
      },
      '&.disabled': {
        color: 'var(--bs-gray-500)',
        borderColor: 'var(--bs-gray-500)',
        cursor: 'not-allowed !important',
        background: 'var(--bs-gray-200)',
        '& .category__icon': {
          filter: 'grayscale(1)'
        }
      },
      '&.disabled.active': {
        color: 'var(--bs-gray-500)',
        borderColor: 'var(--bs-gray-500)',
        cursor: 'not-allowed !important',
        background: 'var(--bs-gray-200)',
        '& .category__icon': {
          filter: 'grayscale(1)'
        }
      },
    },
  },
  empty: {
    inlineSize: '100%',
    minBlockSize: '400px',
    display: 'grid',
    placeContent: 'center'
  }
}));

const PosDesktopProducts = () => {
  const classes = useStyles();
  const {setPage, products, pagination, perPage, categories, categoryFilter, setCategoryFilter, loadingProducts, search} = useContext(PosDesktopContext);
  const [columns, setColumns] = React.useState();

  React.useEffect(() => {
    setColumns(Number(perPage) === MIN_PER_PAGE ? 4 : 3);
  }, [perPage])
  
  const selectCategory = (item) => {
    if(loadingProducts) return;
    if(item.id === categoryFilter?.id) return setCategoryFilter();
    setCategoryFilter(item);
  }
  
	return (
    <Grid container className={classes.root} spacing={1}>
      <Grid item xs={12}>
        <div className={clsx(classes.root, classes.categories)}>
          {categories.map((item) => (
            <div key={item.id} role="button" className={clsx('categories__item', loadingProducts && 'disabled', (categoryFilter?.id === item.id) && 'active')} onClick={() => selectCategory(item)}>
              {!!item.document_path && <img className="category__icon" src={item.document_path} />}
              <div className={clsx(!!item.document_path && 'category__text')}>{String(item.name).toLowerCase()}</div>
            </div>
          ))}
        </div>
      </Grid>
      {!loadingProducts && (<>
        {!!products.length ? (<>
          {pagination.total_pages > 1 && (
            <Grid item xs={12}>
              <div className={clsx(classes.root, classes.paginationContainer)}>
                <Pagination
                  onChange={(_, value) => setPage(value)}
                  count={pagination.total_pages}
                  page={pagination.current_page}
                  variant="outlined"
                  shape="rounded"
                  color="primary"
                />
              </div>
            </Grid>
          )}
          {products.map((item) => (
            <Grid item xs={12} sm={6} md={columns} key={item.id}>
              <PosDesktopCardProduct { ...item } />
            </Grid>
          ))}
        </>) : (<>
          <Grid item xs={12}>
            <div className={clsx(classes.empty, 'text-muted text-center')}>
              {!!categoryFilter && (
                <div className="text-center">
                  <img src={categoryFilter.document_path} alt={categoryFilter.name} height={100} className="mb-1"/>
                  <p className="mb-0 text-capitalize fs-small">"<b>{String(categoryFilter.name).toLowerCase()}</b>"</p>
                </div>
              )}
              {!!search
                ? <p className="mb-0 fs-small">Product "<b>{search}</b>" not found</p>
                : <p className="mb-0 fs-small">No records</p>}
            </div>
          </Grid>
        </>)}
      </>)}
    </Grid>
	);
};

export default PosDesktopProducts;
