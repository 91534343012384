import { RemoveRounded, AddRounded } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import { getFullPathImage, groupBy, toUpper } from '../../../utils'
import NumberFormat from '../../../utils/number.format'
import NoImage from '../../../assets/img/no-image.jpg'
import MaterialUI from '../../../components/MaterialUI'

const useStyles = makeStyles({
  root: {
    marginBlockStart: 6,
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 0,
    fontSize: 12,
  },
  pos: {
    marginBottom: 0,
  },
  productContainer: {
    border: '1px solid transparent',
    flexDirection: 'column',
    cursor: 'pointer',
    display: 'flex',
    minWidth: 80,
    '&:not(.active):hover': {
      borderColor: '#afafaf8a',
    }
  },
  productImage: {
    width: '100%',
    objectFit: 'cover',
  },
  productName: {
    paddingInline: '8px !important',
    paddingBlock: '4px !important',
    fontSize: '11px !important',
  },
  sizeContainer: {
    border: '1px solid transparent',
    justifyContent: 'center',
    background: '#efefef',
    alignItems: 'center',
    borderRadius: '50%',
    fontFamily: 'Inter',
    fontWeight: 'bold',
    cursor: 'pointer',
    fontSize: '12px',
    display: 'flex',
    height: 40,
    width: 40,
  },
  sizeContainerLarge: {
    paddingInline: 7,
    minWidth: 'max-content',
    borderRadius: 5,
  },
  active: {
    borderColor: '#212529 !important',
  },
  groupButtons: {
    alignItems: 'center',
    display: 'flex',
  },
  button: {
    border: '1px solid #cdcdcd',
    textAlign: 'center',
    background: 'white',
    borderRadius: '4px',
    height: 35,
    width: 45,
  },
  borderRightNone: {
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
  },
  borderLeftNone: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  },
  borderBlock: {
    borderBottom: '1px solid #cdcdcd',
    borderTop: '1px solid #cdcdcd',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: "Inter",
    userSelect: 'none',
    fontSize: '14px',
    display: 'flex',
    width: 45,
    height: 35,
  }
})

export default function InvoiceProductDetail({ onClose, onAddItem, selectedProduct }) {
  const classes = useStyles()
  const {
    name,
    item_name,
    images = [],
    item_variants = [],
    category_id = {},
    brand_id = {},
    initialQuantityPurchase = 1,
    initialAvailableSize,
    initialActiveColor,
    initialDescription,
    recordId,
  } = selectedProduct
  const [quantityPurchase, setQuantityPurchase] = useState(initialQuantityPurchase)
  const [description, setDescription] = useState(initialDescription)
  
  const changeDescription = (ev) => setDescription(ev.target.value)
  
  const itemVariants = item_variants
  const grouped = Object.entries(groupBy(itemVariants, 'color'))

  const updateQuantity = (quantity) => {
    setQuantityPurchase((prev) => prev + Number(quantity))
  }
  
  const [availableSizes, setAvailableSizes] = useState([])
  const [activeColor, setActiveColor] = useState(() => {
    if (grouped.length == 1) {
      const [color] = grouped[0]
      return color
    }
    return ''
  })

  const [activeSize, setActiveSize] = useState(() => {
    if (grouped.length == 1) {
      const [newActiveColor] = grouped[0]
      const found = grouped.find(([color]) => color == newActiveColor)
      let newSizes = []
      if (found) {
        const records = found[1]
        const sizes = records.flatMap((r) => r.size)
        newSizes = Array.from(new Set(sizes.map((s) => s.toLocaleUpperCase().trim()).filter(Boolean)))
      }
      setAvailableSizes(newSizes)
      if (newSizes.length == 1) return newSizes[0]
      return ''
    }
    return ''
  })

  useEffect(() => {
    if (initialActiveColor) {
      setTimeout(() => {
        handleActiveColor(initialActiveColor, initialAvailableSize)
      }, 100);
    }
  }, [])

  const handleActiveColor = (newActiveColor, newSize) => {
    const found = grouped.find(([color]) => color == newActiveColor)
    let newSizes = []
    if (found && activeColor != newActiveColor) {
      const records = found[1]
      const sizes = records.flatMap((r) => r.size)
      newSizes = Array.from(new Set(sizes.map((s) => s.toLocaleUpperCase().trim()).filter(Boolean)))
    }
    setActiveColor((prev) => prev == newActiveColor ? '' : newActiveColor)
    setAvailableSizes(newSizes)
    if (newSize) {
      setActiveSize(newSize)
    } else {
      setActiveSize('')
    }
  }

  const indexedPrices = itemVariants.reduce((acc, iv) => {
    const key = `${iv.color}-${iv.size}`.toLocaleUpperCase()
    acc[key] ||= Number(iv.price)
    return acc
  }, {})
  
  const maxValueProduct = Math.max(...itemVariants.map((iv) => Number(iv.price)))
  const minValueProduct = Math.min(...itemVariants.map((iv) => Number(iv.price)))

  let priceRender = NumberFormat.currency(minValueProduct)
  if (minValueProduct != maxValueProduct) priceRender += ` - ${NumberFormat.currency(maxValueProduct)}`
  
  const keyValuePriceIndex = `${activeColor}-${activeSize}`.toLocaleUpperCase()
  let currentPriceSelectedProduct = 0
  if (indexedPrices[keyValuePriceIndex]) currentPriceSelectedProduct = indexedPrices[keyValuePriceIndex]
  
  const handleActiveSize = (newSize) => {
    setActiveSize((prev) => prev === newSize ? '' : newSize)
  }

  const onChangeQuantity = (quantity) => {
    updateQuantity(quantity)
  }

  const getOriginItemVariant = () => {
    const colorMatching = selectedProduct.item_variants.filter((iv) => toUpper(iv.color) == toUpper(activeColor))
    const sizesMatching = colorMatching.find((cm) => toUpper(cm.size) == toUpper(activeSize))
    return sizesMatching
  }
  
  const handleAddItemClick = () => {
    const itemVariant = getOriginItemVariant()
    if (!activeColor || !activeSize) {
      return
    }
    onAddItem({
      id: recordId,
      item_id: itemVariant.item_id,
      item_variant_id: itemVariant.id,
      quantity: quantityPurchase,
      images: itemVariant.images || [],
      selected_price: itemVariant.price,
      selected_color: activeColor,
      selected_size: activeSize,
      description: description,
    })
    onClose()
  }
  
  if (!selectedProduct) return null

  return (
    <>
      <div className="d-flex gap-2" style={{ minHeight: '120px' }}>
        <figure className='align-items-center d-flex mb-0'>
          <img src={getFullPathImage(images[0], NoImage)} height={100} alt="Image Not found" />
        </figure>
        <div className="d-flex flex-column justify-content-between h-100">
          <div>
            <div className="d-flex gap-1 align-items-center">
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {category_id?.name}
              </Typography>
              {!!brand_id && (
                <>
                  <span>|</span>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>{brand_id?.name}</Typography>
                </>
              )}
            </div>
            <Typography variant="body1" component="h2" className='mb-2'>{name || item_name}</Typography>
            <Typography variant="h5" color="textSecondary" className='fw-bold'>
              {activeColor && activeSize ? (
                <div className="product-price heading5">{NumberFormat.currency(currentPriceSelectedProduct)}</div>
              ) : (
                <div className="product-price heading5">{priceRender}</div>
              )}
            </Typography>
          </div>
        </div>
      </div>
      <Typography variant='body2' color='textSecondary' className='my-1'>Color: <strong>{activeColor}</strong></Typography>
      <div className="d-flex gap-1 mb-4" style={{ overflow: 'hidden', flexWrap: 'wrap' }}>
        {grouped.map((group, index) => {
          const [color, items] = group
          const firstRecord = (items.find((c) => !!c.images?.length) || { images: [] })
          return (
            <div
              key={index}
              className={clsx(classes.productContainer, activeColor === color ? classes.active : null)}
              onClick={() => handleActiveColor(color)}
            >
              <img src={getFullPathImage(firstRecord.images[0], NoImage)} className={classes.productImage} width={70} height={70} alt='color' />
              <div className={clsx("bg-dark fs-small text-center text-white", classes.productName)}>{color}</div>
            </div>
          )
        })}
      </div>
      <Typography variant='body2' color='textSecondary' className='my-1'>Size: <strong>{activeSize}</strong></Typography>
      <div className="d-flex gap-1 mt-2" style={{ minHeight: 70 }}>
        {availableSizes.map((size, index) => (
          <div key={`size-${index}`} className={clsx(classes.sizeContainer, size.length > 2 ? classes.sizeContainerLarge : null, activeSize == size ? classes.active : null)} onClick={() => handleActiveSize(size)}>{size}</div>
        ))}
      </div>
      <Typography variant='body2' color='textSecondary' className='my-1'>Quantity</Typography>
      <div className={classes.groupButtons}>
        <button className={clsx(classes.button, classes.borderRightNone)} onClick={() => onChangeQuantity(-1)} disabled={quantityPurchase <= 1}>
          <RemoveRounded style={{ fontSize: '1rem' }} />
        </button>
        <div className={clsx(classes.borderBlock)}>{quantityPurchase}</div>
        <button className={clsx(classes.button, classes.borderLeftNone)} onClick={() => onChangeQuantity(1)} disabled={quantityPurchase >= 20}>
          <AddRounded style={{ fontSize: '1rem' }} />
        </button>
      </div>
      <div className='mt-3'>
        <Typography variant='body2' color='textSecondary' className='my-1'>Comment</Typography>
        <textarea className='form-control' style={{ maxHeight: '100px' }} maxLength={255} onChange={changeDescription} value={description}></textarea>
      </div>
      <div className="d-flex justify-content-end mt-4 gap-1">
        <MaterialUI.Button.Secondary className="fs-small" onClick={onClose}>Cancel</MaterialUI.Button.Secondary>
        <MaterialUI.Button.Primary className="fs-small" onClick={handleAddItemClick}>
          {!!recordId ? 'Update' : 'Add'} Item
        </MaterialUI.Button.Primary>
      </div>
    </>
  )
}
