import createHttp from "../lib/http";

const ProductService = {};

ProductService.fetchAll = async (params) => {
  try {
    const resp = await createHttp.get(`/api/items`, { params });
    const { data } = resp
    return data
  } catch (error) {}
}

export default ProductService;