import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import createHttp from "../../../lib/http";
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

import { ModalErrorMessage } from "../../../components/modal_error_message";
import { SpinnerLoading } from "../../../components/spinner";
import { CustomModal } from "../../../components/CustomModal/CustomModal";

//CustomHooks
import { useModalHook } from "../../../customHooks/useModalHook";

//styles
import "./editUserManagment.css";
import { CustomButton } from "../../../components/CustomButton/CustomButton";
import MaterialUI from "../../../components/MaterialUI";

function EditUserManagment() {
	const {
		showModal,
		setShowModal,
		customTittleText,
		customMessageModal,
		showSuccessButton,
		manageResponseErrors,
		manageResponse,
	} = useModalHook();

	const history = useHistory();
	const location = useLocation();

	// Array the status
	const optionStatus = [
		{ value: "active", label: "Active" },
		{ value: "inactive", label: "Inactive" },
	];

	const [loading_spinner, setLoadingSpinner] = React.useState(false);

	// message the error.
	const [modal_conf] = React.useState({
		title: "Modal Title",
		message: "MODAL ERROR MESSSAGE PLEASE CHECK THANS",
	});
	const [show, setShow] = React.useState(false);
	const handleClose = () => setShow(false);

	// field the entry
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone_number, setPhoneNumber] = useState("");

	const [status, setStatus] = useState("active");
	const [role_id, setRole] = useState(1);
	const [warehouse_id, setWareHouse] = React.useState("");
	const [WareHouses, setWareHouses] = useState([]);
	const [password, setPassword] = useState("");
	const [confirm_password, setConfirmPassword] = useState("");
	const [title_page, setTitlePage] = useState("");
	const [isUpdate, setIsUpdate] = useState(false);

	const [isError, setIsError] = useState("");

	// data from list the users
	React.useEffect(() => {
		if (location.state == null) {
			setTitlePage("Create User");
			setWareHouse(1);
			setRole("2");
			setIsUpdate(false);
			return;
		}
		if (location.state.titulo) {
			setTitlePage(location.state.titulo);
		}

		if (location.state.user) {
			setName(location?.state?.user?.name);
			setEmail(location?.state?.user?.email);
			setPhoneNumber(location?.state?.user?.phone_number);
			setStatus(location?.state?.user?.status);
			setRole(location?.state?.user?.roles[0]?.id);
			setWareHouse(location?.state?.user?.warehouse?.id || "");
			setIsUpdate(true);
		} else {
			setWareHouse(1);
			setRole("2");
			setIsUpdate(false);
		}
	}, [location]);
	// take all Warehouses
	React.useEffect(() => {
		createHttp
			.get("api/warehouse")
			.then((response) => {
				setWareHouses(response.data.data);
			})
			.catch((response) => {
				console.error("don't received information");
			});
	}, []);

	// update the users
	function updateUser() {
		setLoadingSpinner(true);
		var item = { name, phone_number, warehouse_id, status, email, role_id };
		if (password != "") {
			item = {
				name,
				email,
				phone_number,
				warehouse_id,
				status,
				password,
				email,
				confirm_password,
				role_id,
			};
		}
		if (isUpdate) {
			updateUserEndPoint(item);
		} else {
			addUserEndpoint(item);
		}
	}

	function addUserEndpoint(item) {
		initialError();
		createHttp
			.post("api/profiles", item)
			.then((res) => {
				if (!res.data.status) {
					if (res.data.errors) {
						setIsError(res.data.errors);
						setLoadingSpinner(false);

						// manageResponse(res, "updating", "User");
						setLoadingSpinner(false);
						return;
					}

					manageResponseErrors(res.data.message, "User", "updating");

					setLoadingSpinner(false);

					return;
				}
				history.push("/user_managment");
			})
			.catch((err) => {
				setLoadingSpinner(false);
			});
	}

	function initialError() {
		setIsError("");
	}

	function updateUserEndPoint(item) {
		initialError();
		createHttp
			.put("api/profiles/" + location.state.user.id, item)
			.then((res) => {
				if (!res.data.status) {
					if (res.data.errors) {
						setIsError(res.data.errors);
						manageResponse(res, "updating", "User");
						setLoadingSpinner(false);
						return;
					}

					manageResponseErrors(res.data.message, "User", "updating");

					setLoadingSpinner(false);
					return;
				}
				history.push("/user_managment");
			})
			.catch((err) => {
				setLoadingSpinner(false);
			});
	}

	const gotBack = () => history.push('/user_managment');
	
	return (
		<div className="contenedor container-fluid">
			<CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={() => setShowModal(false)}
				onHandleSuccess={() => {}}
				isLoading={false}
				showSuccessButton={showSuccessButton}
			/>
			<div className="d-flex gap-2 align-items-center">
				<MaterialUI.Button.Dark onClick={gotBack}>
					<ArrowBackIosRoundedIcon className="me-1" style={{ fontSize: '1rem' }} /> Back
				</MaterialUI.Button.Dark>
				<div className="container-title">
					<h1 className="container__title mb-0">{title_page}</h1>
				</div>
			</div>
			<div className="">
				<div className="row mb-2 mt-3">
					<div className="col-12 col-md-6 col-lg-4">
						<label className="form-label mb-0">Name <span className="text-danger">*</span></label>
						<input
							value={name}
							onChange={(e) => {
								setName(e.target.value);
								setIsError({...isError, name:null});
							}}
							maxLength="100"
							type="text"
							className={
								"form-control " +
								(isError.name ? "is-invalid" : "")
							}
						/>
						<span className="text-danger float end">
							{" "}
							{!isError.name ? "" : isError.name}
						</span>
					</div>
					<div className="col-12 col-md-6 col-lg-2">
						<label className="form-label mb-0">Phone number <span className="text-danger">*</span></label>
						<input
							value={phone_number}
							onChange={(e) => {
								setPhoneNumber(e.target.value);
								setIsError({...isError, phone_number:null});
							}}
							maxLength="40"
							type="text"
							className={
								"form-control " +
								(isError.phone_number ? "is-invalid" : "")
							}
						/>
						<span className="text-danger float end">
							{" "}
							{!isError.phone_number
								? ""
								: isError.phone_number}
						</span>
					</div>
					<div className="col-12 col-md-6 col-lg-3">
						<label className="form-label mb-0">Status</label>

						<select
							value={status}
							className="form-select"
							onChange={(e) => {
								setStatus(e.target.value);
								setIsError({...isError, status:null});
							}}
						>
							{optionStatus.map((item) => (
								<option key={item.value} value={item.value}>
									{/* selected={item.value == status ? "selected" : "false"} */}
									{item.label}
								</option>
							))}
						</select>
					</div>
					<div className="col-12 col-md-6 col-lg-3">
						<label className="form-label mb-0">Warehouse</label>
						<select
							value={warehouse_id}
							className="form-select"
							onChange={(e) => {
								setWareHouse(e.target.value);
								setIsError({...isError, warehouse_id:null});
							}}
						>
							<option value="">Select a warehouse</option>
							{WareHouses.map((item, i) => (
								<option key={item.id} value={item.id}>
									{/* selected={item.id == warehouse_id ? "selected" : "false"} */}{" "}
									{item.name}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-md-4 mb-2">
						<h6 className="sub__title mt-4">Roles and Permissions</h6>
						<div>
							<label className="form-label mb-0">Role</label>
							<select
								value={role_id}
								className="form-select"
								onChange={(e) => {
									setRole(e.target.value);
									setIsError({...isError, role_id:null});
								}}
							>
								<option value="1"> Admin</option>
								<option value="2">Employee</option>
							</select>
						</div>
					</div>
					<div className="col-12 col-md-12">
						<h6 className="sub__title mt-4">Credentials</h6>
						<div className="row">
							<div className="mb-3 col-12 col-md-4">
								<label className="form-label mb-0">Email <span className="text-danger">*</span></label>
								<input
									value={email}
									onChange={(e) => {
										setEmail(e.target.value);
										setIsError({...isError, email:null});
									}}
									maxLength="50"
									type="text"
									className={
										"form-control " +
										(isError.email ? "is-invalid" : "")
									}
									readOnly={isUpdate ? "readOnly" : ""}
								/>
								<span className="text-danger float end">
									{" "}
									{!isError.email ? "" : isError.email}
								</span>
							</div>
							<div className="mb-3 col-12 col-md-4 mb-3">
								<label className="form-label mb-0">Password <span className="text-danger">*</span></label>
								<input
									type="password"
									className={
										"form-control " +
										(isError.password ? "is-invalid" : "")
									}
									maxLength="20"
									onChange={(e) => {
										setPassword(e.target.value);
										setIsError({...isError, password:null});
									}}
								/>
								<span className="text-danger float end">
									{" "}
									{!isError.password ? "" : isError.password}
								</span>
							</div>
							<div className="mb-3 col-12 col-md-4 mb-3">
								<label className="form-label mb-0">
									Confirm Password <span className="text-danger">*</span>
								</label>
								<input
									type="password"
									className="form-control"
									maxLength="20"
									onChange={(e) => {
										setConfirmPassword(e.target.value);
										setIsError({...isError, confirm_password:null});
									}}
								/>
								<span className="text-danger float end">
									{" "}
									{!isError.confirm_password
										? ""
										: isError.confirm_password}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="d-flex justify-content-end">
						<CustomButton
								className="btn btn-success"
								buttonName={<><SaveOutlinedIcon fontSize="small" className="me-2" /> {isUpdate ? 'Update' : 'Save'} </>}
								type={"-"}
								icon={"-"}
								margins={"mt-0 mb-0"}
								disabled={false}
								dontShowIcon={true}
								buttonOnClick={() => updateUser()}
							/>
					</div>
					<div hidden={!loading_spinner}>
						<SpinnerLoading></SpinnerLoading>
					</div>
				</div>
			</div>
			<ModalErrorMessage
				modal_conf={modal_conf}
				onHandleClose={handleClose}
				show={show}
			/>
		</div>
	);
}

export { EditUserManagment };
