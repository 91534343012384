import { useState, useEffect, useCallback } from "react";
import createHttp from "../lib/http";
import { customClientHTTP } from "../lib/tech4Inventory.client";

import { amountPerPagePagination } from "../context/constants";

function useApiCustomRequest(
	manageResponseErrors,
	getUrl,
	model,
	manageResponse,
	manageSuccessfullMesssage = false,
	dontGetList = false,
	setGoToMainPage = false,
	mapResponse = false,
	mapFunction = (data) => data,
	setCustomCurrentItem = false,
	initParams = {},
) {
	const [isLoading, setIsLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [sincronizedItems, setSincronizedItems] = useState(false);
	const [filtered_data, setFilteredData] = useState([]);
	const [itemFilters, setItemFilters] = useState([
		{ field_id: "page", value: "1" },
		{ field_id: "per_page", value: amountPerPagePagination },
	]);
	const [itemPagination, setItemPagination] = useState([]);
	
	const fetchResource = useCallback(async () => {
		if(sincronizedItems) return;

		setIsLoading(true);
		let params = { ...initParams };
		itemFilters
			.filter((filter) => filter.value)
			.map((filter) => (params[filter.field_id] = filter.value));

		await createHttp
			.get(`api/${getUrl}`, { params })
			.then((response) => {
				if (response.hasOwnProperty("data") && response.data) {
					if (mapResponse) {
						let data = mapFunction(response.data.data);
						setFilteredData(data);
					} else {
						setFilteredData(response.data.data);
					}

					if (response.data.hasOwnProperty("pagination")) {
						setItemPagination(response.data.pagination);
					}
				} else {
					manageResponseErrors(
						"An error has occurred",
						model,
						"listing",
					);
				}
				setSincronizedItems(true);
				setIsLoading(false);
			})
			.catch((response) => {
				setSincronizedItems(true);
				setIsLoading(false);
				manageResponseErrors(
					"An error has occurred",
					model,
					"listing",
				);
			});
		setIsLoading(false);
	}, [sincronizedItems]);

	useEffect(() => {
		if (!dontGetList) fetchResource();
	}, [sincronizedItems]);

	const getFile = async (getUrl, model, params = {}) => {
		setIsLoading(true);
		return await createHttp
			.get(`api/${getUrl}`, { params }, { responseType: "blob" })
			.then((response) => {
				setIsLoading(false);
				if (response.status == 200) {
					return response.data;
				} else {
					manageResponseErrors(
						"An error has occurred",
						model,
						"listing",
					);
				}
			})
			.catch((error) => {
				setIsLoading(false);
				manageResponseErrors("An error has occurred", model, "listing");
			});
	};

	const getListItemWithCache = async (
		getUrl,
		model,
		params = {},
		returnAllData = false,
	) => {
		setIsLoading(true);
		const responseList = await customClientHTTP
			.getList(`api/${getUrl}`, params)
			.then((response) => {
				setIsLoading(false);
				if (response.status || response.status === 200) {
					if (returnAllData) {
						return response.data;
					}
					return response.data;
				} else {
					manageResponseErrors(
						"An error has occurred",
						model,
						"listing",
					);
				}
			})
			.catch((error) => {
				setIsLoading(false);
				manageResponseErrors("An error has occurred", model, "listing");
			});

		return responseList;
	};

	const getListItem = async (
		getUrl,
		model,
		params = {},
		returnAllData = false,
	) => {
		setIsLoading(true);
		const responseList = await createHttp
			.get(`api/${getUrl}`, { params })
			.then((response) => {
				setIsLoading(false);


				// if (response.status || response.data.status) {
				if (response.data.status || response.status === 200) {
					if (returnAllData) {
						return response.data;
					}
					return response.data.data;
				} else {
					manageResponseErrors(
						"An error has occurred",
						model,
						"listing",
					);
				}
				// setSincronizedItems(true);
			})
			.catch((error) => {
				console.error("error", error);
				setIsLoading(false);
				manageResponseErrors("An error has occurred", model, "listing");
			});
		return responseList;
	};

	const deleteItem = async (id) => {
		setIsLoading(true);
		await createHttp
			.delete(`api/${getUrl}/${id}`)
			.then((response) => {
				if (response.data.status) {
					setSincronizedItems(false);
				} else {
					manageResponse(response, "deleting", model);
				}
			})
			.catch((response) => {
				manageResponseErrors(
					"An error has occurred",
					model,
					"deleting",
				);
			});
		setIsLoading(false);
	};

	const deleteItemByUrl = async (
		id,
		getUrl,
		showActivityIndicator = true,
	) => {
		if (showActivityIndicator) {
			setIsLoading(true);
		}
		return await createHttp
			.delete(`api/${getUrl}/${id}`)
			.then((response) => {
				if (showActivityIndicator) {
					setIsLoading(false);
				}
				if (response.data.status) {
					setSincronizedItems(false);
					return response.data.status;
				} else {
					if (showActivityIndicator) {
						setIsLoading(false);
					}
					manageResponse(response, "deleting", model);
				}
			})
			.catch((response) => {
				if (showActivityIndicator) {
					setIsLoading(false);
				}
				manageResponseErrors(
					"An error has occurred",
					model,
					"deleting",
				);
			});
	};

	const updateItem = async (
		id,
		data,
		model = null,
		itemDetailData = {},
		returnData = false,
		showActivityIndicator = true,
	) => {
		if (showActivityIndicator) {
			setIsLoading(true);
		}
		return await createHttp
			.put(`api/${getUrl}/${id}`, data)
			.then(async (response) => {
				if (response.data.status) {
					if (showActivityIndicator) {
						setIsLoading(false);
					}

					let showSuccessfullMessage = false;

					if (returnData) {
						return response.data.data;
					}

					setSincronizedItems(false);
					let exist = Object.keys(itemDetailData).length > 0;

					if (exist) {
						const itemDetailDataCopy = {
							adjustment_id: response.data.data.id,
							...itemDetailData,
						};

						showSuccessfullMessage = await createItemDetail(
							itemDetailDataCopy,
							"Adjustment",
							"adjustment-details",
						);
					}

					if (!exist || (exist && showSuccessfullMessage)) {
						if (manageSuccessfullMesssage && model) {
							manageSuccessfullMesssage(model, "updated");
						}
					}

					if (setGoToMainPage) {
						setGoToMainPage(
							!exist || (exist && showSuccessfullMessage),
						);
					}
				} else {
					if (showActivityIndicator) {
						setIsLoading(false);
					}

					manageResponse(response, "updating", model);
					if (setGoToMainPage) {
						setGoToMainPage(false);
					}
				}
			})
			.catch((response) => {
				if (showActivityIndicator) {
					setIsLoading(false);
				}

				manageResponseErrors(
					"An error has occurred",
					model,
					"updating",
				);
				if (setGoToMainPage) {
					setGoToMainPage(false);
				}
			});

		// setIsLoading(false);
	};

	const createItem = async (
		data,
		model = null,
		itemDetailData = {},
		returnData = false,
		returnError = false,
		returnAllObject = false,
	) => {
		setIsLoading(true);
		let form = new FormData();

		Object.keys(data)
			.filter((key) => {
				if (data[key] === 1 || data[key] === 0) {
					return true;
				}
				return data[key];
			})
			.map((key) => {
				form.append(key, data[key]);
			});

			if (form.get('name') && form.get('name').trim() === '') {
				setIsLoading(false);
				manageResponseErrors(
					"Name field cannot be empty ",
					"Brand",
					"creating",
				);
				return { error: true };
			}

		return await createHttp
			.post(`api/${getUrl}`, form)
			.then(async (response) => {
				if (response.data.status) {
					setSuccess(true);
					setIsLoading(false);
					if (setCustomCurrentItem) {
						setSincronizedItems(false);
						setCustomCurrentItem(response.data.data);
					}

					// if (returnData) {
					//     if (returnAllObject) {
					//         return response;
					//     }

					//     return response.data.data;
					// }

					let showSuccessfullMessage = false;
					let exist = Object.keys(itemDetailData).length > 0;

					if (manageSuccessfullMesssage && model) {
						if (exist) {
							const itemDetailDataCopy = {
								adjustment_id: response.data.data.id,
								...itemDetailData,
							};

							showSuccessfullMessage = await createItemDetail(
								itemDetailDataCopy,
								"Adjustment",
								"adjustment-details",
							);
						}

						if (!exist || (exist && showSuccessfullMessage)) {
							if (returnData) {
								if (returnAllObject) {
									return response;
								}
								return response.data.data;
							}

							if (manageSuccessfullMesssage && model) {
								manageSuccessfullMesssage(
									model,
									"created",
									response.data.data || null,
								);
							}
						}
					}

					if (setGoToMainPage) {
						setGoToMainPage(
							!exist || (exist && showSuccessfullMessage),
						);
					}

					if (returnData) {
						if (returnAllObject) {
							return response;
						}

						return response.data.data;
					}

					setSincronizedItems(false);
				} else {
					setIsLoading(false);

					if (returnError) {
						return { error: true };
					}

					manageResponse(response, "creating", model);
					if (setGoToMainPage) {
						setGoToMainPage(false);
					}
				}
			})
			.catch((response) => {
				setIsLoading(false);

				if (returnError) {
					return { error: true };
				}

				manageResponseErrors(
					"An error has occurred",
					model,
					"creating",
				);
				if (setGoToMainPage) {
					setGoToMainPage(false);
				}
			});
	};

	const storeItem = async (
		data,
		model = null,
		itemDetailData = {},
		returnData = false,
		returnError = false,
		returnAllObject = false,
		asFormData = false,
		disabledLoadingAfterResponse = true,
	) => {
		setIsLoading(true);
		let form = data
		if (form.name && form.name.trim() === '') {
			setIsLoading(false);
			manageResponseErrors(
				"Name field cannot be empty ",
				"Brand",
				"creating",
			);
			return { error: true };
		}
		if (asFormData) {
			form = new FormData()
			for (const [key, value] of Object.entries(data)) {
				if (value instanceof File) {
					form.append(key, value)
				} else if (typeof value === 'object') {
					form.append(key, JSON.stringify(value))
				} else {
					form.append(key, value)
				}
			}
		}
		
		return await createHttp
			.post(`api/${getUrl}`, form)
			.then(async (response) => {
				if (response.data.status) {
					setSuccess(true);
					if (disabledLoadingAfterResponse) {
						setIsLoading(false)
					}
					if (setCustomCurrentItem) {
						setSincronizedItems(false);
						setCustomCurrentItem(response.data.data);
					}
					let showSuccessfullMessage = false;
					let exist = Object.keys(itemDetailData).length > 0;

					if (manageSuccessfullMesssage && model) {
						if (exist) {
							const itemDetailDataCopy = {
								adjustment_id: response.data.data.id,
								...itemDetailData,
							};

							showSuccessfullMessage = await createItemDetail(
								itemDetailDataCopy,
								"Adjustment",
								"adjustment-details",
							);
						}

						if (!exist || (exist && showSuccessfullMessage)) {
							if (returnData) {
								if (returnAllObject) {
									return response;
								}
								return response.data.data;
							}

							if (manageSuccessfullMesssage && model) {
								manageSuccessfullMesssage(
									model,
									"created",
									response.data.data || null,
								);
							}
						}
					}

					if (setGoToMainPage) {
						setGoToMainPage(
							!exist || (exist && showSuccessfullMessage),
						);
					}

					if (returnData) {
						if (returnAllObject) {
							return response;
						}

						return response.data.data;
					}

					setSincronizedItems(false);
				} else {
					setIsLoading(false);

					if (returnError) {
						return { error: true };
					}

					manageResponse(response, "creating", model);
					if (setGoToMainPage) {
						setGoToMainPage(false);
					}
				}
			})
			.catch((response) => {
				setIsLoading(false);

				if (returnError) {
					return { error: true };
				}

				manageResponseErrors(
					"An error has occurred",
					model,
					"creating",
				);
				if (setGoToMainPage) {
					setGoToMainPage(false);
				}
			});
	};

	const updateItemForm = async (
		data,
		model = null,
		itemDetailData = {},
		returnData = false,
		returnError = false,
		id,
		newUrl = null,
		returnAllData = false,
	) => {
		setIsLoading(true);
		let form = new FormData();
		Object.keys(data)
			.filter((key) => data[key])
			.map((key) => {
				form.append(key, data[key]);
			});

		const url = newUrl || getUrl;
		return await createHttp
			.post(`api/${url}/${id}`, form)
			.then(async (response) => {
				if (response.data.status) {
					setIsLoading(false);
					if (setCustomCurrentItem) {
						setCustomCurrentItem(response.data.data);
					}

					if (returnData) {
						if (returnAllData) return response.data;

						return response.data.data;
					}

					let showSuccessfullMessage = false;
					let exist = Object.keys(itemDetailData).length > 0;

					if (manageSuccessfullMesssage && model) {
						if (exist) {
							const itemDetailDataCopy = {
								adjustment_id: response.data.data.id,
								...itemDetailData,
							};

							showSuccessfullMessage = await createItemDetail(
								itemDetailDataCopy,
								"Adjustment",
								"adjustment-details",
							);
						}

						if (!exist || (exist && showSuccessfullMessage)) {
							if (manageSuccessfullMesssage && model) {
								manageSuccessfullMesssage(
									model,
									"created",
									response.data.data || null,
								);
							}
						}
					}

					if (setGoToMainPage) {
						setGoToMainPage(
							!exist || (exist && showSuccessfullMessage),
						);
					}
					setSincronizedItems(false);
				} else {
					setIsLoading(false);

					if (returnError) {
						return { error: true };
					}

					manageResponse(response, "creating", model);
					if (setGoToMainPage) {
						setGoToMainPage(false);
					}
				}
			})
			.catch((response) => {
				setIsLoading(false);

				if (returnError) {
					return { error: true };
				}

				manageResponseErrors(
					"An error has occurred",
					model,
					"creating",
				);
				if (setGoToMainPage) {
					setGoToMainPage(false);
				}
			});
	};

	const getItem = async (
		itemId,
		params = {},
		showActivityIndicator = true,
		returnError = false,
	) => {
		if (showActivityIndicator) {
			setIsLoading(true);
		}
		return await createHttp
			.get(`api/${getUrl}/` + (itemId ? itemId : ""), { params })
			.then((response) => {
				if (showActivityIndicator) {
					setIsLoading(false);
				}
				if (response.data.status) {
					return response.data.data;
				} else {
					if (returnError) {
						return { error: true };
					}
					manageResponse(response, "getting", model);
				}
			})
			.catch((err) => {
				if (returnError) {
					return { error: true };
				}
				if (showActivityIndicator) {
					setIsLoading(false);
				}
				manageResponseErrors("An error has occurred", model, "getting");
			});
	};

	const getItemByUrl = async (paramUrl, itemId, params = {}) => {
		setIsLoading(true);
		return await createHttp
			.get(`api/${paramUrl}/` + (itemId ? itemId : ""), { params })
			.then((response) => {
				setIsLoading(false);
				if (response.data.status) {
					return response.data.data;
				} else {
					manageResponse(response, "getting", model);
				}
			})
			.catch((err) => {
				setIsLoading(false);
				manageResponseErrors("An error has occurred", model, "getting");
			});
	};

	const createItemDetail = async (
		data,
		model = null,
		getUrl,
		returnData = false,
		action = "creating",
		returnAllData = false,
		returnError = false,
	) => {
		setIsLoading(true);
		let form = new FormData();
		Object.keys(data)
			.filter((key) => {
				if (data[key] === 1 || data[key] === 0) {
					return true;
				}
				return data[key];
			})
			.map((key) => {
				form.append(key, data[key]);
			});

		return await createHttp
			.post(`api/${getUrl}`, form)
			.then((response) => {
				setIsLoading(false);
				if (response.data.status) {
					if (returnData) {
						if (returnAllData) return response.data;

						return response.data.data;
					}

					return true;
				} else {
					setIsLoading(false);

					if (returnError) {
						return response.data;
					}

					manageResponse(response, action, model);
					if (setGoToMainPage) {
						setGoToMainPage(false);
					}
					return false;
				}
			})
			.catch((response) => {
				setIsLoading(false);

				if (returnError) {
					return response;
				}

				manageResponseErrors("An error has occurred", model, action);
				if (setGoToMainPage) {
					setGoToMainPage(false);
				}
				return false;
			});
	};

	const createImageItem = async (
		getUrl,
		id,
		file,
		model,
		defaultParam = "image",
		locationId = "expense_id",
	) => {
		let document_form = new FormData();
		document_form.append(locationId, id);
		document_form.append(defaultParam, file);
		const createImageResponse = await createHttp
			.post(`api/${getUrl}`, document_form, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				if (response.data.status) {
					return { error: false };
				} else {
					manageResponse(response, "creating", model);
				}
			})
			.catch((err) => {
				manageResponseErrors(
					"An error has occurred",
					model,
					"creating",
				);
			});
		return createImageResponse;
	};

	const deleteImageItem = async (getUrl, id, model = "File") => {
		return await createHttp
			.delete(`api/${getUrl}/` + id)
			.then((response) => {
				if (response.data.status) {
					return { error: false };
				} else {
					manageResponse(response, "deleting", model);
				}
			})
			.catch((err) => {
				manageResponseErrors(
					"An error has occurred",
					model,
					"deleting",
				);
			});
	};

	const updateModel = async (
		id,
		data,
		model = null,
		itemDetailData = {},
		getUrl,
		returnData = false,
	) => {
		setIsLoading(true);
		return await createHttp
			.put(`api/${getUrl}/${id}`, data)
			.then(async (response) => {
				if (response.data.status) {
					setIsLoading(false);
					let showSuccessfullMessage = false;

					setSincronizedItems(false);
					let exist = Object.keys(itemDetailData).length > 0;

					if (returnData) {
						return response.data.data;
					}

					if (exist) {
						const itemDetailDataCopy = {
							adjustment_id: response.data.data.id,
							...itemDetailData,
						};

						showSuccessfullMessage = await createItemDetail(
							itemDetailDataCopy,
							"Adjustment",
							"adjustment-details",
						);
					}

					if (!exist || (exist && showSuccessfullMessage)) {
						if (manageSuccessfullMesssage && model) {
							manageSuccessfullMesssage(model, "updated");
						}
					}

					if (setGoToMainPage) {
						setGoToMainPage(
							!exist || (exist && showSuccessfullMessage),
						);
					}
				} else {
					setIsLoading(false);
					manageResponse(response, "updating", model);
					if (setGoToMainPage) {
						setGoToMainPage(false);
					}
				}
			})
			.catch((response) => {
				setIsLoading(false);
				manageResponseErrors(
					"An error has occurred",
					model,
					"updating",
				);
				if (setGoToMainPage) {
					setGoToMainPage(false);
				}
			});
		// setIsLoading(false);
	};

	return {
		isLoading,
		success,
		setSuccess,
		setIsLoading,
		sincronizedItems,
		setSincronizedItems,
		filtered_data,
		setFilteredData,
		deleteItem,
		updateItem,
		createItem,
		getListItem,
		createImageItem,
		deleteImageItem,
		updateModel,
		itemFilters,
		setItemFilters,
		itemPagination,
		getItem,
		getItemByUrl,
		deleteItemByUrl,
		createItemDetail,
		getFile,
		updateItemForm,
		getListItemWithCache,

		fetchResource,
		storeItem,
	};
}

export { useApiCustomRequest };
