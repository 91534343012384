import { buildProductId } from "../../../utils"

export const posDesktopReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case "SELECT_PRODUCT": {
      return {
        ...state,
        selectedProduct: payload
      }
    }
    case "UPDATE_QUANTITY": {
      const selectedProduct = { ...state.selectedProduct }
      selectedProduct.quantityPurchase += payload.quantity
      return { ...state, selectedProduct }
    }
    case "ADD_CART": {
      const selectedProduct = { ...state.selectedProduct, ...payload }
      let productsCart = [selectedProduct, ...state.productsCart]
      const { productId, selectedColor, selectedSize } = payload || {}

      const uniqueProductId = buildProductId(productId, selectedColor, selectedSize)
      const index = state.productsCart.findIndex((pc) => {
        const currentUniqueProductId = buildProductId(pc.id, pc.selectedColor, pc.selectedSize)
        return currentUniqueProductId == uniqueProductId
      })

      if (index === -1) {        
        return {
          ...state,
          selectedProduct: undefined,
          selectedProductIds: (new Set(state.selectedProductIds)).add(productId),
          productsCart,
        }
      }

      productsCart = [...state.productsCart]
      productsCart[index].quantityPurchase += selectedProduct.quantityPurchase

      return {
        ...state,
        selectedProduct: undefined,
        selectedProductIds: (new Set(state.selectedProductIds)).add(productId),
        productsCart
      }
    }
    case "REMOVE_CART": {
      const { productId, selectedColor, selectedSize } = payload || {}
      const uniqueProductId = buildProductId(productId, selectedColor, selectedSize)

      const newProductsCart = state.productsCart.filter((pc) => {
        const { id, selectedColor, selectedSize } = pc
        const currentUniqueProductId = buildProductId(id, selectedColor, selectedSize)
        return currentUniqueProductId != uniqueProductId
      })

      const newSelectedProductIds = new Set(state.selectedProductIds)
      if (!newProductsCart.some((npc) => `${npc.id}` == `${productId}`)) {
        newSelectedProductIds.delete(productId)
      }
      
      return {
        ...state,
        productsCart: newProductsCart,
        selectedProductIds: newSelectedProductIds,
      }
    }
    case "UPDATE_QUANTITY_DIRECTLY": {
      const { productId, selectedColor, selectedSize, quantity } = payload || {}
      const uniqueProductId = buildProductId(productId, selectedColor, selectedSize)

      const productsCart = state.productsCart.map((pc) => {
        const { id, selectedColor, selectedSize } = pc
        const currentUniqueProductId = buildProductId(id, selectedColor, selectedSize)
        if (currentUniqueProductId == uniqueProductId) pc.quantityPurchase += quantity
        return pc
      })

      return { ...state, productsCart }
    }
    case "CLEAR_CART": {
      return {
        ...state,
        selectedProductIds: new Set(),
        selectedProduct: undefined,
        productsCart: []
      }
    }
    case "LOAD_CART_FROM_HOLD": {
      const productsCart = payload || []

      const ids = productsCart.map((npc) => npc.id)
      const selectedProductIds = new Set(ids)
      
      return {
        ...state,
        productsCart,
        selectedProductIds,
      }
    }
    default: {
      console.info(`The %c${type}%c event cannot be managed because it is unknown.`, `color: #d2c057`, 'color: #ffffff')
      return state
    }
  }
}