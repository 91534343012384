import React, { useEffect, useState } from "react";
import createHttp from "../../../lib/http";
import { useHistory } from "react-router-dom";
import "../stockReceipt.css";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CustomSelect } from "../../../components/customSelect/customSelect";
import { CustomDataList } from "../../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../../components/CustomDataListItem/CustomDataListItem";
import { CustomFilterComponent } from "../../../components/CustomFiltersComponent/CustomFiltersComponent";
import { PositionedMenu } from "../../../components/CustomMenu/CustomMenu";
import MenuItem from "@material-ui/core/MenuItem";

// custom Hooks
import { usePaginationHook } from "../../../customHooks/usePaginationHook";
import { useModalHook } from "../../../customHooks/useModalHook";
import { useApiCustomRequest } from "../../../customHooks/useApiCustomRequest";

import {
	received,
	complete,
	posted,
	styles,
	tableHeadersStockReceipt as tableHeaders,
	dataFieldsStockReceipt as dataFields,
	composedFieldsStockReceipt as composedFields,
	amountPerPagePagination,
	tableHeadersToDataFieldsStockReceipt as tableHeadersToDataFields,
	dataFieldsToOrderFilterEStockReceipt as dataFieldsToOrderFilter,
	tableHeadersToOrderFilterStockReceipt as tableHeadersToOrderFilter,
} from "../../../context/constants";
import { PaginationControlled } from "../../../components/CustomPagination/CustomPaginacion";
import { FaCaretDown, FaLongArrowAltRight } from "react-icons/fa";
import { ModalDeleteFromTable } from "../../../components/ModalDeleteFromTable";

var _ = require("lodash");

function ListStockReceipt() {
	const history = useHistory();

	const [anchorEl, setAnchorEl] = useState(null);
	const [stock_receipt, setStockReceipt] = useState([]);
	const [currentElement, setCurrentElement] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [sincronizedItems, setSincronizedItems] = useState(false);
	const [itemPagination, setItemPagination] = useState([]);

	const [itemFilters, setItemFilters] = useState([
		{ field_id: "page", value: "1" },
		{ field_id: "per_page", value: amountPerPagePagination },
	]);

	const {
		filterInputChange,
		selectOnChange,
		onChangeNumberSelect,
		handlePagination,
		filtersDefinition,
		setFiltersDefition,
	} = usePaginationHook(setSincronizedItems, setItemFilters, itemFilters);

	const { manageResponseErrors, manageResponse, manageSuccessfullMesssage } =
		useModalHook();

	const { getListItem, getListItemWithCache } = useApiCustomRequest(
		manageResponseErrors,
		"work-orders",
		"Work Order",
		manageResponse,
		manageSuccessfullMesssage,
		true,
		() => {},
	);

	const onCloseModal = () => {
		setAnchorEl(null);
	};

	const onMenuActions = (event, element) => {
		setCurrentElement(element);
		setAnchorEl(event.currentTarget);
	};

	React.useEffect(async () => {
		setIsLoading(true);

		let params = {};

		itemFilters
			.filter((filter) => filter.value)
			.map((filter) => (params[filter.field_id] = filter.value));

		if (!sincronizedItems) {
			createHttp
				.get("api/stock-receipts", { params })
				.then((response) => {
					if (response.data.status) {
						setItemPagination(response.data.pagination);
						setStockReceipt(response.data.data);
					}
					setIsLoading(false);
					setSincronizedItems(true);
				})
				.catch(() => {
					setIsLoading(false);
					setSincronizedItems(true);
				});
		}
		setIsLoading(false);
	}, [sincronizedItems]);

	const prepareToUpdate = () => {
		setAnchorEl(null);
		history.push({
			pathname: "/edit_stock_receipt",
			state: { data: currentElement },
		});
	};
	
	const prepareToUpdateTableMobile = (element) => {
		setAnchorEl(null);
		history.push({
			pathname: "/edit_stock_receipt",
			state: { data: element },
		});
	};

	const [show_delete, setShowDelete] = useState(false);
	const handleCloseDelete = () => setShowDelete(false);
	const handleShowDelete = () => setShowDelete(true);

	const [delete_id, setDeleteId] = useState(null);

	const ConfirmDelete = () => {
		setIsLoading(true);
		let form = new FormData();
		form.append("id", delete_id);
		createHttp
			.delete("api/stock-receipts/" + delete_id, {
				data: { id: delete_id },
			})
			.then((res) => {
				if (res.data.status) {
					setIsLoading(false);
					// getData();
					setSincronizedItems(false);
					handleCloseDelete();
				}
			})
			.catch((res) => {
				setIsLoading(false);
			});
	};

	const prepareToDelete = (element) => {
		setDeleteId(currentElement.id);
		handleShowDelete();
		setAnchorEl(null);
	};
	const prepareToDeleteItemMobileTable = (element) => {
		setDeleteId(element.id);
		handleShowDelete();
		setAnchorEl(null);
	};

	const actionsDefinition = [
		{
			name: "Edit",
			func: prepareToUpdate,
			icon: faEdit,
		},
		{
			name: "Delete",
			func: prepareToDelete,
			icon: faTrashAlt,
		},
	];

	const onChangeDateFilter = (value, field_id) => {
		selectOnChange({
			field_id: field_id,
			value: value,
		});
	};

	React.useEffect(async () => {
		let params = { without_data: 0 };
		// const resources = await getListItem('get_resource_data', 'Resources', params);
		const resources = await getListItemWithCache(
			"get_resource_data",
			"Resources",
			params,
		);

		if (resources && Object.keys(resources).length > 0) {
			const { locations, warehouses } = resources;

			const filters = [
				{
					field_id: "location_id",
					selectTittle: "Location",
					styles: styles,
					defaultOptionText: "Select a location",
					itemArray: locations || [],
					defaultValue: "",
				},
				{
					field_id: "warehouse_id",
					selectTittle: "Warehouse",
					styles: styles,
					defaultOptionText: "Select a warehouse",
					itemArray: warehouses || [],
					defaultValue: "",
				},
			];

			setFiltersDefition([...filters]);
		}
	}, []);

	const delelefilterFromFilters = (currentFilters, filterId) => {
		const copyFilters = [...currentFilters];
		const hasFilter = copyFilters.findIndex(
			(filterItem) => filterItem.field_id === filterId,
		);
		if (hasFilter != -1) {
			copyFilters.splice(hasFilter, 1);
		}
		return copyFilters;
	};

	const onChangeOrderArrow = (
		dataFieldsToOrderFilter = [],
		tableHeadersToDataFields = [],
		orderField,
		typeOrder,
	) => {
		const orderFieldFilter =
			dataFieldsToOrderFilter[tableHeadersToDataFields[orderField]];

		const filters = [
			{
				field_id: "type_order",
				value: typeOrder,
			},
			{
				field_id: "field_order",
				value: orderFieldFilter,
			},
			{
				field_id: "page",
				value: 1,
			},
		];

		let copyFilters = delelefilterFromFilters(itemFilters, "type_order");
		copyFilters = delelefilterFromFilters(copyFilters, "field_order");
		copyFilters = delelefilterFromFilters(copyFilters, "page");

		copyFilters = [...copyFilters, ...filters];
		setItemFilters([...copyFilters]);
		setSincronizedItems(false);
	};


	const [optionListIndex, setOptionListIndex] = useState(null); // Estado para almacenar el índice del elemento con lista de opciones abierto

    const handleOptionListToggle = (index) => {
        setOptionListIndex(index === optionListIndex ? null : index); // Alternar la apertura y cierre de la lista de opciones
    };

	return (
		<div className="box container-fluid">
			<div className="container-title mb-3">
				<h1 className="container__title mb-0">Stock Receipt</h1>
			</div>

			<CustomFilterComponent
				filtersDefinition={filtersDefinition}
				onClickfilter={() => setSincronizedItems(false)}
				filterInputChange={filterInputChange}
				onCreateNewItem={() => history.push("/add_stock_receipt")}
				showDateFilters={true}
				onChangeDateFilter={onChangeDateFilter}
				styles={styles}
				onChangePageEvent={onChangeNumberSelect}
				renderFilter={(filter, index) => (
					<CustomSelect
						key={index}
						field_id={filter.field_id}
						selectTittle={filter.selectTittle}
						styles={filter.styles}
						defaultOptionText={filter.defaultOptionText}
						itemArray={filter.itemArray}
						onChangeEvent={selectOnChange}
						defaultValue={filter.defaultValue}
					/>
				)}
			/>

			<div className="stockReceipt-dataList-desktop-wrapper">
				<CustomDataList
					onChangePageEvent={onChangeNumberSelect}
					isLoading={isLoading}
					dataList={stock_receipt}
					tableHeaders={tableHeaders}
					handlePagination={handlePagination}
					itemPagination={itemPagination}
					dontShowPerPageSelect={true}
					showOrderArrows={true}
					onChangeOrderArrow={onChangeOrderArrow}
					tableHeadersToDataFields={tableHeadersToDataFields}
					dataFieldsToOrderFilter={dataFieldsToOrderFilter}
					tableHeadersToOrderFilter={tableHeadersToOrderFilter}
					itemFilters={itemFilters}
					showLinearIndicator={true}
					showDataInLoading={true}
					render={(element, index) => (
						<CustomDataListItem
							key={index}
							dataFields={dataFields}
							imageFields={[]}
							composedFields={composedFields}
							arrayFields={[]}
							element={element}
							index={index}
							no_image={null}
							renderActions={() => (
								<>
									<PositionedMenu
										title={"Actions"}
										anchorEl={anchorEl}
										setAnchorEl={setAnchorEl}
										handleClose={onCloseModal}
										handleClick={(event) =>
											onMenuActions(event, element)
										}
										actionsDefinition={actionsDefinition}
										renderActions={(action, index) => (
											<MenuItem
												key={index}
												onClick={() => action.func()}
											>
												<div
													className="items-option"
													style={{
														padding: "0 5px",
														color: "grey",
													}}
												>
													<FontAwesomeIcon
														style={{
															fontSize: '.8rem',
															margin: "4px",
															marginRight: "10px",
														}}
														icon={action.icon}
														className={
															action.name == "Edit" 
																? "text-primary"
																: action.name == "Delete"
																	? 'text-danger'
																	: 'inherit'
														}
													/>
													<p className="mb-0 fs-small">{action.name}</p>
												</div>
											</MenuItem>
										)}
									></PositionedMenu>
								</>
							)}
							posted={posted}
							complete={complete}
							received={received}
						/>
					)}
				/>
			</div>

			<div className="stockReceipt-dataList-mobile-wrapper">
				<div
					style={{ width:"100%", fontSize:"12.8px", border:"1px solid gray"}}
				>
					{
						stock_receipt.length > 0 && stock_receipt.map((stockReceipt, index)=>(
						<div
						key={index}
						style={{
							display: "block",
							padding: "5px 10px",
							backgroundColor: index % 2 !== 0 ? "#f2f2f2" : "transparent",
						}}
					>
						<div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
							<div>
								<strong>Description:{stockReceipt.description}</strong>
								<p>Date due: {stockReceipt.date_receipt}</p>
								<p>Status: {stockReceipt.status}</p>
								<strong>Total: {stockReceipt.total}</strong>
							</div>
							<div
								style={{display:"flex", flexDirection:"column", alignItems:"flex-end"}}
							>
								<strong>Bill Number:{stockReceipt.bill_number}</strong>
								<p>Date Receipt: {stockReceipt.date_receipt}</p>
								<p>type: {stockReceipt.type}</p>
							</div>
						</div>
						<div
						style={{display:"flex", justifyContent:"flex-end", width:"100%", fontWeight:"bold", color:"#2767c4"}}
						>
							<small
							onClick={() => prepareToUpdateTableMobile(stockReceipt)}
							style={{borderRight:"1px solid lightgrey", padding:"0 5px"}}
							>Edit</small>

							<small
							onClick={() => prepareToDeleteItemMobileTable(stockReceipt)}
							style={{borderRight:"1px solid lightgrey", padding:"0 5px"}}
							>Delete</small>

							<small onClick={() => handleOptionListToggle(index)} style={{ padding: "0 5px", position:"relative" }}>
								<FaCaretDown size={"20px"} />
									{optionListIndex === index &&(
									<div style={{ right:"0", borderRadius:"6px", padding: "5px 15px", position:"absolute" , zIndex:"10", background:"white", color:"black", boxShadow:" rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
									<p style={{padding:"5px 0", display:"flex", alignItems:"center" , gap:"10px"}}><FaLongArrowAltRight size={"18px"} /> View</p>
									<p style={{padding:"5px 0", display:"flex", alignItems:"center" , gap:"10px"}}><FaLongArrowAltRight size={"18px"} /> Copy</p>
									<p style={{padding:"5px 0", display:"flex", alignItems:"center" , gap:"10px"}}><FaLongArrowAltRight size={"18px"} /> Print</p>
									</div>
								)}
							</small>
						</div>
					</div>
						))
					}
					
				</div>
			</div>
			<div className="mobile-pagination-wrapper">
				{stock_receipt.length > 0 && !isLoading ? (
						<PaginationControlled
							itemPagination={itemPagination}
							handlePagination={handlePagination}
						/>
				): <div style={{height:"85px"}}></div>
				}
			</div>

			{/* <CustomModal
				show={show_delete}
				customTittleText={"Delete stock receipt"}
				customMessageModal={`Are you sure you want to delete item with bill number: ${currentElement?.bill_number}`}
				onHandleClose={handleCloseDelete}
				showSuccessButton={true}
				onHandleSuccess={ConfirmDelete}
			/> */}
			<ModalDeleteFromTable
				isOpen={show_delete}
				handleClose={() => handleCloseDelete()}
				title={"Delete stock receipt ?"}
				warningMessage={`Are you sure you want to delete item with bill number: ${currentElement?.bill_number}`}
				onHandleSuccess={ConfirmDelete}

			/>
		</div>
	);
}

export { ListStockReceipt };
