import React, { useContext, useRef } from "react";
import imglogo from "../../assets/img/logo_t4i.png";
import DesktopMacOutlinedIcon from '@material-ui/icons/DesktopMacOutlined';
import {ProSidebar, Menu, MenuItem, SubMenu, SidebarContent } from "react-pro-sidebar";

import {
	FaListAlt,
	FaCog,
	FaDollarSign,
	FaChartLine,
	FaLongArrowAltRight,
	FaStackOverflow,
	FaCashRegister,
	FaWarehouse,
	FaUserTag,
	FaPlus,
	FaListOl,
	FaBoxOpen,
	FaBoxes,
	FaUsersCog,
	FaTags,
	FaShapes,
	FaMapMarkedAlt,
	FaCogs,
	FaUsers,
	FaSlidersH,
	FaLayerGroup,
	FaCcMastercard,
	FaBarcode,
	FaFileSignature,
} from "react-icons/fa";

import { IoIosClose } from "react-icons/io";
import { FiPackage } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useAuthentication } from "../../customHooks/useAuthentication";
import "../../assets/css/App.css";

import { routes as SettingsRoutes } from '../../views/Settings/settings.route'

const stock_receipt = "stock_receipt";
const adjustment = "adjustment";
const sales = "sales";
const customer = "customer";
const expensive = "expensive";
const products = "products";
const userManagment = "userManagment";
const warehouse = "warehouse";
const vendor = "vendor";
const configuration = "configuration";
const pos = "POS";
const reports = "Reports";
const cashCounter = "Cash Counter";

const mapModule = {
	[userManagment]: "User Management",
	[products]: "Products",
	[warehouse]: "WareHouse",
	[vendor]: "Vendor",
	[stock_receipt]: "Stock Receipt",
	[adjustment]: "Adjustment",
	[customer]: "Costumers",
	[sales]: "Sales",
	[expensive]: "Expensive",
	[configuration]: "Configuration",
	[reports]: "Reports",
	[pos]: "POS",
	[cashCounter]: "Cash Counter",
};

const superAdminRoles = ["Super Admin"];

const pathnames = {
	userManagment: ["/user_managment", "/create_userManagment"],
	cashCounters: ["/cash-counter", "/cash-counters"],
	packages: ["/packages_list", "/create_package_form"],
	companies: ["/company_list", "/company_list_deleted", "/create_company_form", "/offline_payment_methods_list"],
	products: ["/product_list", "/create_item_form", "/labors_list", "/create_labor_form", "/brand_list", "/categories_list", "/location_list", "/uom_list", "/barcode-generator"],
	warehouses: ["/warehouse", "/create/warehouse"],
	vendors: ["/vendors_list", "/create_vendor"],
	stockReceipts: ["/stock_receipt", "/add_stock_receipt"],
	adjustments: ["/adjustment", "/adjustment_form"],
	customers: ["/costumer_list", "/costumer_form"],
	sales: ["/invoice", "/type_device_list"],
	expenses: ["/expensive", "/create_expensive", "/expensive_categories", "/payment_account_list", "/type_payment_list"],
	reports: ["/reports"],
}

const SidebarItem = ({ children, routesActivate = [], ...props }) => {
	const location = useLocation();
	const [open, setOpen] = React.useState(false);

	React.useEffect(() => {
		setOpen(routesActivate.includes(location.pathname));
	}, [location]);
	
	return (
		<SubMenu open={open} onClick={() => setOpen((prev) => !prev)} {...props}>
			{children}
		</SubMenu>
	);
}

const Aside = ({ rtl }) => {
	let allowedModulesArray = [];
	const location = useLocation();
	const { userInfo } = useAuthentication();
	const { navigationExpanded, changeNavigationVisible } = useContext(AppContext);
	const proSidebarContainerRef = useRef();

	const allowedModules = userInfo?.company?.package?.module_in_package || [];
	allowedModules.map((module) => {
		if (mapModule.hasOwnProperty(module.module_name)) {
			allowedModulesArray = [
				...allowedModulesArray,
				mapModule[module.module_name],
			];
		}
	});

	return (
		<ProSidebar rtl={rtl} collapsed={navigationExpanded} ref={proSidebarContainerRef}>
			<div className="logo-header-container">
				<div className="logo-header">
					<NavLink exact to={"/dashboard"} >
						<img src={imglogo} alt="" />
					</NavLink>
					<p style={{ display: !navigationExpanded ? "block" : "none" }}>Tech4Inventory</p>
				</div>
				<IoIosClose className="close-icon"  color="white" onClick={() => changeNavigationVisible(true)}/>
			</div>
			<SidebarContent>
				<Menu iconShape="circle">
					<MenuItem icon={<FaChartLine />}>
						<NavLink exact to={"/dashboard"}>Dashboard</NavLink>
					</MenuItem>
					{allowedModulesArray.includes("POS") && (
						<MenuItem icon={<DesktopMacOutlinedIcon fontSize="small" />}>
							<NavLink exact to={"/pos"}>POS</NavLink>
						</MenuItem>
					)}
					{allowedModulesArray.includes("Cash Counter") && (
						<SidebarItem title={"Cash counters"} icon={<FaCashRegister fontSize="small" />} routesActivate={pathnames.cashCounters}>
							<MenuItem icon={<FaLongArrowAltRight />}>
								<NavLink exact to={"/cash-counter"}>Cash counter</NavLink>
							</MenuItem>
							<MenuItem icon={<FaListOl />}>
								<NavLink exact to={"/cash-counters"}>Cash counters list</NavLink>
							</MenuItem>
						</SidebarItem>
					)}
					{superAdminRoles.includes(userInfo.roles[0].name) && (
						<SidebarItem title={"Packages"} icon={<FiPackage />} routesActivate={pathnames.packages}>
							<MenuItem icon={<FaListOl />}>
								<NavLink exact to={"/packages_list"}>Packages</NavLink>
							</MenuItem>
							<MenuItem icon={<FaPlus />}>
								<NavLink exact to={"/create_package_form"}>Create Package</NavLink>
							</MenuItem>
						</SidebarItem>
					)}

					{superAdminRoles.includes(userInfo.roles[0].name) && (
						<SidebarItem title={"Companies"} icon={<FaStackOverflow />} routesActivate={pathnames.companies}>
							<MenuItem icon={<FaListOl />}>
								<NavLink exact to={"/company_list"}>Companies</NavLink>
							</MenuItem>
							<MenuItem icon={<FaListOl />}>
								<NavLink exact to={"/company_list_deleted"}>Deleted Companies</NavLink>
							</MenuItem>
							<MenuItem icon={<FaPlus />}>
								<NavLink exact to={"/create_company_form"}>Create Company</NavLink>
							</MenuItem>
							<MenuItem icon={<FaListOl />}>
								<NavLink exact to={"/offline_payment_methods_list"}>Payment Methods</NavLink>
							</MenuItem>
						</SidebarItem>
					)}

					{allowedModulesArray.includes("User Management") && (
						<SidebarItem title={"User Management"} icon={<FaUsersCog style={{ fontSize: '1.05rem' }}/>} routesActivate={pathnames.userManagment}>
							<MenuItem icon={<FaListOl />}>
								<NavLink exact to={"/user_managment"}>Users</NavLink>
							</MenuItem>
							<MenuItem icon={<FaPlus />}>
								<NavLink exact to={"/create_userManagment"}>Create User</NavLink>
							</MenuItem>
						</SidebarItem>
					)}

					{allowedModulesArray.includes("Products") && (
						<SidebarItem title={"Products"} icon={<FaBoxOpen style={{ fontSize: '1.05rem' }}/>} routesActivate={pathnames.products}>
							<MenuItem icon={<FaListOl />}>
								<NavLink exact to={"/product_list"}>Products</NavLink>
							</MenuItem>
							<MenuItem icon={<FaPlus />}>
								<NavLink exact to={"/create_item_form"}>Create Product</NavLink>
							</MenuItem>
							<MenuItem icon={<FaUsersCog style={{ fontSize: '1.05rem' }}/>}>
								<NavLink exact to={"/labors_list"}>Labors</NavLink>
							</MenuItem>
							<MenuItem icon={<FaPlus />}>
								<NavLink exact to={"/create_labor_form"}>Create Labor</NavLink>
							</MenuItem>
							<MenuItem icon={<FaTags style={{ fontSize: '1.05rem' }}/>}>
								<NavLink exact to={"/brand_list"}>Brand</NavLink>
							</MenuItem>
							<MenuItem icon={<FaShapes style={{ fontSize: '1.05rem' }}/>}>
								<NavLink exact to={"/categories_list"}>Categories</NavLink>
							</MenuItem>
							<MenuItem icon={<FaMapMarkedAlt style={{ fontSize: '1.05rem' }}/>}>
								<NavLink exact to={"/location_list"}>Location</NavLink>
							</MenuItem>
							<MenuItem icon={<FaCogs style={{ fontSize: '1.05rem' }} />}>
								<NavLink exact to={"/uom_list"}>Sizes</NavLink>
							</MenuItem>
							<MenuItem icon={<FaBarcode style={{ fontSize: '1.05rem' }} />}>
								<NavLink exact to={"/barcode-generator"} >Barcode Generator</NavLink>
							</MenuItem>
						</SidebarItem>
					)}

					{allowedModulesArray.includes("WareHouse") && (
						<SidebarItem title={"WareHouse"} icon={<FaWarehouse />} routesActivate={pathnames.warehouses}>
							<MenuItem icon={<FaListOl />}>
								<NavLink exact to={"/warehouse"}>Warehouse</NavLink>
							</MenuItem>
							<MenuItem icon={<FaPlus />}>
								<NavLink exact to={"/create/warehouse"}>Create Warehouse</NavLink>
							</MenuItem>
						</SidebarItem>
					)}

					{allowedModulesArray.includes("Vendor") && (
						<SidebarItem title={"Vendor"} icon={<FaUserTag style={{ fontSize: '1.1rem' }} />} routesActivate={pathnames.vendors}>
							<MenuItem icon={<FaListOl />}>
								<NavLink exact to={"/vendors_list"}>Vendors</NavLink>
							</MenuItem>
							<MenuItem icon={<FaPlus />}>
								<NavLink exact to={"/create_vendor"}>Create Vendor</NavLink>
							</MenuItem>
						</SidebarItem>
					)}

					{allowedModulesArray.includes("Stock Receipt") && (
						<SidebarItem title={"Stock Receipt"} icon={<FaBoxes />} routesActivate={pathnames.stockReceipts}>
							<MenuItem icon={<FaListOl />}>
								<NavLink exact to={"/stock_receipt"}>Stock Receipt</NavLink>
							</MenuItem>
							<MenuItem icon={<FaPlus />}>
								<NavLink exact to={"/add_stock_receipt"}>Create Stock Receipt</NavLink>
							</MenuItem>
						</SidebarItem>
					)}

					{allowedModulesArray.includes("Adjustment") && (
						<SidebarItem title={"Adjustment"} icon={<FaSlidersH />} routesActivate={pathnames.adjustments}>
							<MenuItem icon={<FaListOl />}>
								<NavLink exact to={"/adjustment"}>Adjustments</NavLink>
							</MenuItem>
							<MenuItem icon={<FaPlus />}>
								<NavLink exact to={"/adjustment_form"}>Create Adjustment</NavLink>
							</MenuItem>
						</SidebarItem>
					)}

					{allowedModulesArray.includes("Costumers") && (
						<SidebarItem title={"Costumers"} icon={<FaUsers style={{ fontSize: '1.05rem' }}/>} routesActivate={pathnames.customers}>
							<MenuItem icon={<FaListOl />}>
								<NavLink exact to={"/costumer_list"}>Costumers</NavLink>
							</MenuItem>
							<MenuItem icon={<FaPlus />}>
								<NavLink exact to={"/costumer_form"}>Create Costumer</NavLink>
							</MenuItem>
						</SidebarItem>
					)}

					{allowedModulesArray.includes("Sales") && (
						<SidebarItem
							title={"Sales"}
							icon={<FaDollarSign style={{ fontSize: '1.05rem' }}/>}
							routesActivate={pathnames.sales}
						>
							<MenuItem icon={<FaListOl />}>
								<NavLink exact to={"/invoice"}>Sales</NavLink>
							</MenuItem>
							<MenuItem icon={<FaListOl />}>
								<NavLink exact to={"/sales-online"}>Online</NavLink>
							</MenuItem>
							<MenuItem icon={<FaLayerGroup />}>
								<NavLink exact to={"/type_device_list"}>Device types</NavLink>
							</MenuItem>
							<MenuItem className="d-none"><NavLink exact to={"/invoice_form_update"}></NavLink></MenuItem>
						</SidebarItem>
					)}

					{allowedModulesArray.includes("Expensive") && (
						<SidebarItem title={"Expensive"} icon={<FaDollarSign />} routesActivate={pathnames.expenses}>
							<MenuItem icon={<FaListOl />}>
								<NavLink exact to={"/expensive"}>Expenses</NavLink>
							</MenuItem>
							<MenuItem icon={<FaPlus />}>
								<NavLink exact to={"/create_expensive"}>Create Expense</NavLink>
							</MenuItem>
							<MenuItem icon={<FaListOl />}>
								<NavLink exact to={"/expensive_categories"}>Expense Categories</NavLink>
							</MenuItem>
							<MenuItem icon={<FaListAlt />}>
								<NavLink exact to={"/payment_account_list"}>Payment Account</NavLink>
							</MenuItem>
							<MenuItem icon={<FaCcMastercard />}>
								<NavLink exact to={"/type_payment_list"}>Type Payment</NavLink>
							</MenuItem>
						</SidebarItem>
					)}

					{allowedModulesArray.includes("Reports") && (
						<SidebarItem title={"Reports"} icon={<FaFileSignature style={{ fontSize: '1.05rem' }} />} routesActivate={pathnames.reports}>
							<MenuItem icon={<FaListOl />}>
								<NavLink exact to={"/reports"}>Report 01</NavLink>
							</MenuItem>
						</SidebarItem>
					)}
					
					{allowedModulesArray.includes("Configuration") && (
						<MenuItem icon={<FaCog />}>
							{/* <NavLink exact to={"/settings/tax"} >Settings</NavLink> */}
							<NavLink exact to={SettingsRoutes[0].path} >Settings</NavLink>
						</MenuItem>
					)}
				</Menu>
			</SidebarContent>
		</ProSidebar>
	);
};

export default Aside;
