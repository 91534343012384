import { HiShoppingCart } from "react-icons/hi";
import React from 'react'
import clsx from 'clsx';

import { usePos } from "../../context/PosDesktopContext";
import NumberFormat from "../../utils/number.format";
import { getFullPathImage, getImageBySize } from '../../utils';

import NoImage from '../../assets/img/no-image.jpg';
import { SIZES } from "../../components/Product/ProductDetail";

const PosDesktopCardProduct = (product) => {
  const { onSelectProduct, posDesktopState } = usePos()
  const { selectedProductIds } = posDesktopState
  const { id, name, images = [], brand_id, category_id, item_variants = [] } = product;

  const handleSelectProductClick = (selectedProduct) => {
    onSelectProduct({
      ...selectedProduct,
      selectedItemVariantId: undefined,
      selectedColor: undefined,
      selectedSize: undefined,
      quantityPurchase: 1
    })
  }

  const itemVariants = item_variants

  const maxValueProduct = Math.max(...itemVariants.map((iv) => Number(iv.price)))
  const minValueProduct = Math.min(...itemVariants.map((iv) => Number(iv.price)))
  
  let priceRender = NumberFormat.currency(minValueProduct)
  if (minValueProduct != maxValueProduct) priceRender += ` - ${NumberFormat.currency(maxValueProduct)}`
  
  return (
    <>
      <div onClick={() => handleSelectProductClick(product)} className={clsx('card__pos__product', selectedProductIds.has(id) && 'active')} role="button">
        <figure className="card__pos__product__img">
          <img src={getFullPathImage(getImageBySize(images, SIZES['275'], name)[0], NoImage)} alt={name} onError={(ev) => ev.target.src = NoImage} />
        </figure>
        <div className="card__pos__product__information">
          <div className="card__pos__product__description">
            <div className="card__pos__product__name">
              {String(name).toLowerCase()}
            </div>
            <div style={{ lineHeight: '1.1', marginBlockStart: '.25rem' }}>
              {/* <div className="card__pos__product__code">Sku: {sku}</div> */}
              {!!brand_id?.name && (
                <div className="card__pos__product__brand">
                  <span className="card__pos__product__property">
                    Brand: {String(brand_id.name).toUpperCase()}
                  </span>
                </div>
              )}
              {!!category_id?.name && (
                <div className="card__pos__product__brand">
                  <span className="card__pos__product__property">
                    Category: {String(category_id.name).toUpperCase()}
                  </span>
                </div>
              )}
            </div>
            <div className="card__pos__product__price">{priceRender}</div>
          </div>
        </div>
        <div className="card__pos__product__icon">
          <HiShoppingCart />
        </div>
      </div>
    </>
  )
}

export default PosDesktopCardProduct