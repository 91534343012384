export const PanelNames = {
	PANEL_CUSTOMER: 'PANEL_CUSTOMER',
	PANEL_PRODUCT: 'PANEL_PRODUCT',
}

export const typesAndValues = [
	{
		field_name: "client_id",
		type_field: "searchInput",
		text_label: "Customer",
		disabled: false,
		regexPattern: /^\d+$/,
		field_id: "client_id",
		labelText: "",
		defaultOptionText: "Select a customer",
		col: "col-4",
		typeFilter: "searchInput",
		apiRequest: "clients",
		queryParam: "search_text",
		defaultValue: "",
		selectCol: "col-12 d-flex align-items-start",
		inputValue: "",
		doRequest: false,
		dontInitData: false,
		initOptions: [],
		inputConf: {
			className: 'col-12 col-md-4'
		},
		nextSiblingConfig: {
			iconStart: 'fas fa-plus',
			data: {
				panel: PanelNames.PANEL_CUSTOMER
			}
		},
	},
	{
		field_name: "client_send_email",
		type_field: "text_input",
		text_label: "Customer email",
		disabled: false,
		regexPattern: /^$|[a-z0-9]+@[a-z0-9]+\.[a-z]{2,3}/,
		maxLength: 128,
		col: "col-2",
		inputConf: {
			className: 'col-12 col-md-4 col-lg-2'
		},
	},
	{
		field_name: "pay_term_id",
		type_field: "select_input",
		text_label: "Terms",
		placeHolder: "select a term day",
		dontDefaultOption: true,
		values: [{ id: "30", name: "30 days" }],
		selectCol: "col-12",
		regexPattern: /^[a-z0-9]+$/i,
		col: "col-2",
		inputConf: {
			className: 'col-12 col-md-4 col-lg-2'
		},
	},
	{
		field_name: "date_receipt",
		type_field: "text_input",
		text_label: "Invoice date",
		disabled: false,
		regexPattern: /^\d{4}[./-]\d{2}[./-]\d{2}$/,
		type: "date",
		col: "col-2",
		inputConf: {
			className: 'col-12 col-md-4 col-lg-2'
		},
	},
	{
		field_name: "due_date",
		type_field: "text_input",
		text_label: "Due date",
		disabled: false,
		regexPattern: /^\d{4}[./-]\d{2}[./-]\d{2}$/,
		type: "date",
		col: "col-2",
		inputConf: {
			className: 'col-12 col-md-4 col-lg-2'
		},
		dateGraterThan: "date_receipt",
	},
];

export const initData = {
	// id: '',
	client_id: "",
	client_send_email: "",
	date_receipt: "",
	due_date: "",
	pay_term_id: "4",
	// part_number: ''
};

export const typesAndValuesPartNumber = [
	{
		field_name: "item_id",
		type_field: "searchInput",
		text_label: "Part Number",
		disabled: false,
		regexPattern: /^$|^\d+$/,
		field_id: "item_id",
		labelText: "",
		defaultOptionText: "Search/Scan Part number",
		col: "col-6",
		typeFilter: "searchInput",
		apiRequest: "items",
		queryParam: "search_text",
		defaultValue: "",
		selectCol: "col-12 d-flex align-items-start",
		inputValue: "",
		doRequest: false,
		dontInitData: false,
		inputConf: {
			className: 'col-12 col-md-4'
		},
		nextSiblingConfig: {
			iconStart: 'fas fa-plus',
			data: {
				panel: PanelNames.PANEL_PRODUCT
			}
		},
	},
	{
		field_name: "onSubmit",
		type_field: "button",
		text_label: "Select Variant",
		type: "dark",
		icon: "",
		margins: "mb-0",
		disabled: false,
		iconStart: 'fas fa-plus',
		className: "col-12 btn btn-md",
		dotShowLabel: true,
		dontSeeLabel: true,
		inputConf: {
			className: 'col-12 col-md-2'
		},
		onclickHandler: () => {},
		col: "col-1",
	},
];

export const initDataPartNumber = {
	item_id: "",
};

export const typesAndValuesBillingAddress = [
	{
		field_name: "comments",
		type_field: "textArea",
		text_label: "Billing Address",
		rows: "6",
		cols: "50",
		// regexPattern: /^$|^[A-Za-z0-9."',@; ]*$/,
		col: "col-6",
		// maxLength: 150,
		transformText: true,
		disabled: true,
	},
];

export const initDataBillingAddress = {
	comments: "",
};

export const typesAndValuesMessageOnInvoice = [
	{
		field_name: "message",
		type_field: "textArea",
		text_label: "Message on Invoice",
		rows: "2",
		// regexPattern: /^$|^[A-Za-z0-9."',@; ]*$/,
		col: "col-12",
		maxLength: 150,
		transformText: false,
		disabled: false,
		inputConf: {
      className: 'col-12 col-md-12'
    },
	},
];

export const initDataMessageOnInvoice = {
	message: "",
};

export const typesAndValuesUpdateDetailInvoice = [
	{
		field_name: "id",
		type_field: "text_input",
		text_label: "Id",
		disabled: true,
		regexPattern: /^[A-Za-z0-9]*$/,
		maxLength: 15,
		hidden: true,
	},
	{
		field_name: "part_number",
		type_field: "text_input",
		text_label: "Part Number",
		disabled: true,
		regexPattern: /^[A-Za-z0-9]*$/,
		maxLength: 15,
		col: "col-4",
	},
	{
		field_name: "rate",
		type_field: "text_input",
		text_label: "Rate",
		disabled: false,
		regexPattern: /^$|^[#.0-9a-zA-Z\s,-,',",@,;]+$/,
		maxLength: 100,
		// minLength: 3,
		col: "col-4",
		required: false,
		type: "number",
	},
	{
		field_name: "quantity",
		type_field: "text_quantity",
		text_label: "Quantity",
		regexPattern: /^$|^[0-9]*$/,
		col: "col-6",
	},
	{
		field_name: "description",
		type_field: "text_input",
		text_label: "Description",
		disabled: false,
		regexPattern: /^$|^[A-Za-z0-9.@'"; ]*$/,
		maxLength: 100,
		col: "col-12",
	},
];

export const initDataUpdateDetailInvoice = {
	part_number: "",
	rate: "",
	quantity: "",
	description: "",
};
