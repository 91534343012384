import { useState } from "react";
import Http from "../lib/http";
import { LaravelErrorsToObject } from '../utils/errors.laravel'

function useTransactions() {
	const [isLoading, setIsLoading] = useState(false);
	const [transactions, setTransactions] = useState([]);
	const [transaction, setTransaction] = useState(InitState.transaction());
	const [errors, setErrors] = useState({});

	const fetchTransactions = async (cash_counter_id) => {
		try {
			setIsLoading(true);
			const { data } = await Http.post(`/api/cash_counter_transactions`, { cash_counter_id });
			if (data.status) {
				setTransactions(data.data);
			}
			return data;
		} catch (error) {
			throw error;
		} finally {
			setIsLoading(false);
		}
	};

	const fetchTransaction = async (cashCounterId) => {
		try {
			setIsLoading(true);
			const { data } = await Http.get(`/api/cash_counter_transaction/${cashCounterId}`);
			if (data.status) {
				setTransaction(data.data);
			}
			return data;
		} catch (error) {
			throw error;
		} finally {
			setIsLoading(false);
		}
	};

	const storeTransaction = async () => {
		try {
			setErrors({});
			setIsLoading(true);
			const { data } = await Http.post(`/api/create_transaction`, transaction);
			if (data.status) {
				setTransactions((prev) => {
					return [data.data, ...prev];
				});
				setTransaction(InitState.transaction());
			} else {
				setErrors(LaravelErrorsToObject(data.msg));
			}
			return data;
		} catch (error) {
			throw error;
		} finally {
			setIsLoading(false);
		}
	};

	const updateTransaction = async () => {
		try {
			setErrors({});
			setIsLoading(true);
      const id = transaction.cash_count_transaction_id;
			const { data } = await Http.post(`/api/update_transaction`, transaction);
			if (data.status) {
				const index = transactions.findIndex((c) => Number(c.id) === Number(id));
				if (index !== -1) {
					const newList = [...transactions];
					newList.splice(index, 1, data.data)
					setTransactions(newList);
				}
				setTransaction(InitState.transaction());
			} else {
				setErrors(LaravelErrorsToObject(data.msg));
			}
			return data;
		} catch (error) {
			throw error;
		} finally {
			setIsLoading(false);
		}
	};

	const destroyTransaction = async (cash_count_transaction_id) => {
		try {
			setIsLoading(true);
			const { data } = await Http.post(`/api/delete_cash_counter_transaction`, { cash_count_transaction_id });
			if (data.status) {
				setTransactions((prev) => prev.filter((c) => c.id !== cash_count_transaction_id));
			}
			return data;
		} catch (error) {
			throw error;
		} finally {
			setIsLoading(false);
		}
	};

	const onChangeTransaction = (prop) => (value) => {
		setTransaction((prev) => ({ ...prev, [prop]: value }))
		setErrors((prev) => ({ ...prev, [prop]: '' }));
	};

	return {
		isLoading,
		setIsLoading,

		transactions,
		setTransactions,
		transaction,
		setTransaction,
		onChangeTransaction,

		fetchTransactions,
		fetchTransaction,
		storeTransaction,
		updateTransaction,
		destroyTransaction,

		setErrors,
		errors,
	};
}

class InitState {
  static transaction() {
    return {
      cash_count_transaction_id: null,
      note: "",
      amount: "",
      cash_in: true,
    }
  }
}

class Adapter {
	static fromRowToForm(row) {
		return {
      cash_count_transaction_id: row.id,
      note: row.note,
      amount: Math.abs(row.amount),
      cash_in: row.positive_transaction,
    }
	}
}

export { useTransactions, InitState, Adapter };
