import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import React from 'react'

import { getFullPathImage } from '../../utils'
import NumberFormat from '../../utils/number.format'
import NoImage from '../../assets/img/no-image.jpg'

const useStyles = makeStyles({
  root: {
    marginBlockStart: 6,
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 12,
  },
  pos: {
    marginBottom: 0,
  },
})

export default function OnlineSalesCard({ item, quantity, price }) {
  const classes = useStyles()
  
  return (
    <Card className={classes.root} variant="outlined">
      <div className="d-flex justify-content-between" style={{ minHeight: '120px' }}>
        <CardContent>
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <Typography variant="body1" component="h2">
                {item.item_name}
              </Typography>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {item.size}/{item.color}
              </Typography>
            </div>
            <div className='d-flex gap-5'>
              <Typography className={classes.title} color="textSecondary">
                Qty: <strong>{quantity}</strong>
              </Typography>
              <Typography className={classes.title} color="textSecondary">
                Price Unit: <strong>{NumberFormat.currency(price)}</strong>
              </Typography>
            </div>
          </div>
        </CardContent>
        <figure className='align-items-center d-flex mb-0'>
          <img src={getFullPathImage(item.images[0], NoImage)} height={100} alt="Image Not found" />
        </figure>
      </div>
    </Card>
  )
}
