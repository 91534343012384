import { useState, useEffect } from "react";
import createHttp from "../lib/http";
import moment from "moment";

import { useApiCustomRequest } from "./useApiCustomRequest";
import GlobalAdapter from '../utils/global.adapter';

import {
	ENTRY_STATE,
	COMPLETE_STATE,
	CANCEL_STATE,
	// POSTED_STATE
	formatDate,
} from "../context/constants";

function useApirequestStockReceipt(
	setCustomTittleText,
	setCustomMessageModal,
	setShowSuccessButton,
	setShowModal,
) {
	const { getListItemWithCache } = useApiCustomRequest(
		() => {},
		"items",
		"Item",
		() => {},
		() => {},
		true,
		() => {},
	);

	const [isLoading, setIsLoading] = useState(false);
	const [stock_receipt_details, setStockReceiptDetails] = useState([]);
	const [stockReceiptId, setStockReceiptId] = useState(null);
	const [sincronizedItems, setSincronizedItems] = useState(false);
	const [receipt_stock_number, setReceiptStockNumber] = useState("");
	const [bill_number, setBillNumber] = useState("");
	const [vendor_id, setVendorId] = useState("");
	const [vendor_name, setVendorName] = useState("");
	const [date, setDate] = useState("");
	const [date_due, setDateDue] = useState("");
	const [description, setDescription] = useState("");
	const [payment, setPayment] = useState("");
	const [payment_number, setPaymentNumber] = useState("");
	const [part_number_id, setPartNumberId] = useState("");
	const [part_number_name, setPartNumberName] = useState("");
	const [quantity, setQuantity] = useState(1);
	const [cost, setCost] = useState(0);
	const [location_id, setLocationId] = useState("");
	const [location_name, setLocationName] = useState("");
	const [notes, setNotes] = useState("");
	const [atached_document, setAtachedDocument] = useState("");
	const [total_receipt, setTotalReceipt] = useState(0);
	const [tax_receipt, setTaxReceipt] = useState(0);
	const [vendors, setVendors] = useState([]);
	const [location_list, setLocationList] = useState([]);
	const [disabledActions, setDisabledActions] = useState(true);
	const [stockReceiptStatus, setStockReceiptStatus] = useState("");
	const [skuNumber, setSkuNumber] = useState("");
	const [currentStockReceiptCost, setCurrentStockReceipt] = useState(0);
	const [selectedVender, setSelectedVender] = useState(null);
	const [details, setDetails] = useState([]);
	
	const getDocuments = async (stockReceiptId) => {
		return await createHttp
			.get("api/stockReceipts/" + stockReceiptId + "/documents")
			.then((response) => response.data)
			.catch((err) => ({ data: [] }));
	};

	const deleteDocuments = async (stockReceiptDocument) => {
		return await createHttp
			.delete("api/stockReceipts/documents/" + stockReceiptDocument)
			.then((response) => response)
			.catch((err) => ({ data: [] }));
	};

	const addDocuments = async (stockReceiptId, file) => {
		let document_form = new FormData();
		document_form.append("file", file);
		return await createHttp
			.post(
				"/api/stockReceipts/" + stockReceiptId + "/documents/create",
				document_form,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				},
			)
			.then((response) => response)
			.catch((err) => ({ data: [] }));
	};

	const addImages = async (file, itemId) => {
		let document_form = new FormData();

		document_form.append("file", file);
		document_form.append("item_id", itemId);

		return await createHttp
			.post("/api/images/create", document_form, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => response)
			.catch((err) => ({ data: [] }));
	};

	const getDataFromUser = ({ id, returnResponse = false }) => {
		setIsLoading(true);
		return createHttp
			.get("/api/stock-receipts/" + id)
			.then((response) => {
				if (response.data.status) {
					if(returnResponse) return response.data.data;
					setIsLoading(false);
					const data = response.data.data;

					if (
						[ENTRY_STATE, COMPLETE_STATE, CANCEL_STATE].includes(
							data.status,
						)
					) {
						setDisabledActions(false);
					} else {
						setDisabledActions(true);
					}

					setStockReceiptStatus(data.status);

					setBillNumber(data.bill_number);
					setReceiptStockNumber(data.id);
					setVendorName(data.vendorName);
					setVendorId(data.vendor_id);
					if(data.vendor_data) {
						const adapted = GlobalAdapter.responseVendorToAutocomplete(data.vendor_data);
						setSelectedVender(adapted);
					}
					let newDateReceipt = formatDate(
						`${data.date_receipt.split("/")[2]}-${
							data.date_receipt.split("/")[1]
						}-${data.date_receipt.split("/")[0]}`,
					);
					let newDueDate = formatDate(
						`${data.date_due.split("/")[2]}-${
							data.date_due.split("/")[1]
						}-${data.date_due.split("/")[0]}`,
					);

					// setDate(moment(data.date_receipt).format('YYYY-MM-DD'));
					setDate(newDateReceipt);
					// setDateDue(moment(data.date_due).format('YYYY-MM-DD') );
					setDateDue(newDueDate);

					setDescription(data.description);
					setPaymentNumber(data.payment_info);
					setNotes(data.notes);
					setPayment(data.type_payment_id.toString());
					setTaxReceipt(data.tax1 || 0.0);
					setCost(data.cost || 0.0);
					setCurrentStockReceipt(data.cost || 0.0);

					let sr_details = [];

					if (data.details.length > 0) {
						let details = data.details;

						for (let index = 0; index < details.length; index++) {
							let item = {
								id: details[index].id,
								stock_receipt_id:
									details[index].stock_receipt_id,
								item_id: details[index].item.id,
								item_name: details[index].item.name,
								quantity: parseInt(details[index].quantity),
								cost: parseFloat(details[index].cost).toFixed(
									2,
								),
								location_id:
									details[index].location !== null
										? details[index].location.id
										: "",
								location_name:
									details[index].location !== null
										? details[index].location.name
										: "",
								total: parseFloat(details[index].total).toFixed(
									2,
								),
								mainImage:
									details[index].item?.images[
										details[index].item?.images?.length - 1
									]?.path,
								postStatus: details[index].posted
									? "Posted"
									: "Unposted",
								sku: details[index]?.item?.sku || "",
							};
							sr_details.push(item);
						}
					}
					setStockReceiptDetails(sr_details);
				} else {
					setMessageErrorModal();
				}
			})
			.catch((response) => {
				setMessageErrorModal();
				setIsLoading(false);
			});
	};

	const setMessageErrorModal = () => {
		setCustomTittleText("Error in Stock Receipt Detail");
		setCustomMessageModal("");
		setShowSuccessButton(false);
		setShowModal(true);
	};

	const insertVendor = (e) => {
		const vendor = vendors.find((element) => element.id == e.value);

		if (vendor) {
			setVendorId(vendor.id.toString());
			setVendorName(vendor.name);
		}
		// else{
		// 	setVendorId('');
		// 	setVendorName('');
		// }

		// validateStockReceipt();
	};

	const saveStockReceiptModel = async (
		description,
		date,
		vendor_id,
		bill_number,
		date_due,
		notes,
		payment_number,
		payment,
		tax_receipt,
		cost,
	) => {
		let form = new FormData();
		form.append("description", description);
		form.append("date_receipt", date);
		form.append("type", "Receipt");
		form.append("vendor_id", vendor_id);
		form.append("bill_number", bill_number);
		form.append("date_due", date_due);
		form.append("notes", notes);
		form.append("payment_info", payment_number);
		form.append("type_payment_id", payment);
		form.append("tax1", tax_receipt);
		form.append("cost", cost);

		return await createHttp.post("/api/stock-receipts", form);
	};

	const saveStockReceiptItem = async (payload) => {
		let formData = new FormData();
		for (const key in payload) {
			formData.append(key, payload[key]);
		}
		return createHttp.post("/api/stock-receipt-details", formData);
	};

	const saveStockReceiptItemOld = async (
		item,
		returned_data_id,
		currentCost,
	) => {
		let formData = new FormData();

		formData.append("stock_receipt_id", parseInt(returned_data_id));
		formData.append("item_id", parseInt(item.item_id));
		formData.append("quantity", parseInt(item.quantity));
		formData.append("cost", parseFloat(item?.cost || currentCost).toFixed(2));
		formData.append("location_id", item.location_id);

		return await createHttp.post("/api/stock-receipt-details", formData);
	};

	const updateStockReceiptItem = async (payload) => {
		let body = {
			stock_receipt_id: parseInt(payload.stockReceiptId),
			item_id: parseInt(payload.item_id),
			quantity: parseInt(payload.quantity),
			cost: parseFloat(payload.cost).toFixed(2),
			location_id: parseInt(payload.location_id) || "",
		};
		// let body = {
		// 	stock_receipt_id: parseInt(stockReceiptId),
		// 	item_id: parseInt(item.item_id),
		// 	quantity: parseInt(item.quantity),
		// 	cost: parseFloat(item.cost).toFixed(2),
		// 	location_id: parseInt(item.location_id) || "",
		// };

		return await createHttp.put("/api/stock-receipt-details/" + payload.itemId,
			body,
		);
	};

	useEffect(() => {
		if (stockReceiptId) {
			getDataFromUser({ id: stockReceiptId });
		}
		setSincronizedItems(true);
	}, [sincronizedItems, stockReceiptId]);

	useEffect(async () => {
		let params = { without_data: 0 };
		const resources = await getListItemWithCache(
			"get_resource_data",
			"Resources",
			params,
		);

		if (resources && Object.keys(resources).length > 0) {
			const { locations, vendors } = resources;

			setLocationList(locations);
			setVendors(vendors);
		}
	}, []);

	const updateStockReceiptItemPostStatus = async (
		stockReceiptItemId,
		postStatus,
	) => {
		setIsLoading(true);

		const requestBody = {
			id: stockReceiptItemId,
			posted: postStatus,
		};

		await createHttp
			.post("/api/stock-receipt-details_posted", requestBody)
			.then((response) => {
			})
			.catch((error) => {});

		setIsLoading(false);
		setSincronizedItems(false);
	};

	const updateStockReceiptPostStatus = async (stockReceiptId, postStatus) => {
		setIsLoading(true);

		const requestBody = {
			status: postStatus,
		};

		await createHttp
			.post(
				`api/change_status/stock_receipts/${stockReceiptId}`,
				requestBody,
			)
			.then((response) => {
				if ([ENTRY_STATE, COMPLETE_STATE, CANCEL_STATE].includes(postStatus)) {
					setDisabledActions(false);
				} else {
					setDisabledActions(true);
				}
				setStockReceiptStatus(postStatus)
			})
			.catch((error) => {});

		setIsLoading(false);
		setSincronizedItems(false);
	};

	return {
		getDocuments,
		deleteDocuments,
		addDocuments,
		isLoading,
		setIsLoading,
		addImages,
		stock_receipt_details,
		setStockReceiptDetails,
		getDataFromUser,
		setStockReceiptId,
		setSincronizedItems,
		receipt_stock_number,
		setReceiptStockNumber,
		bill_number,
		setBillNumber,
		vendor_id,
		setVendorId,
		vendor_name,
		setVendorName,
		date,
		setDate,
		date_due,
		setDateDue,
		description,
		setDescription,
		payment,
		setPayment,
		payment_number,
		setPaymentNumber,
		part_number_id,
		setPartNumberId,
		part_number_name,
		setPartNumberName,
		quantity,
		setQuantity,
		cost,
		setCost,
		location_id,
		setLocationId,
		location_name,
		setLocationName,
		notes,
		setNotes,
		atached_document,
		setAtachedDocument,
		total_receipt,
		setTotalReceipt,
		tax_receipt,
		setTaxReceipt,
		vendors,
		setVendors,
		location_list,
		setLocationList,
		insertVendor,
		disabledActions,
		setDisabledActions,
		updateStockReceiptItemPostStatus,
		updateStockReceiptPostStatus,
		stockReceiptStatus,
		skuNumber,
		setSkuNumber,
		saveStockReceiptModel,
		saveStockReceiptItem,
		updateStockReceiptItem,
		currentStockReceiptCost,

		selectedVender,
		setSelectedVender,
		setDetails,
		details,
		setStockReceiptStatus,
	};
}

export { useApirequestStockReceipt };
