import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import Swal from 'sweetalert2'
// components
import { CustomForm } from "../../components/CustomForm/CustomForm";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { CustomDataList } from "../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../components/CustomDataListItem/CustomDataListItem";
import { PositionedMenu } from "../../components/CustomMenu/CustomMenu";
import MenuItem from "@material-ui/core/MenuItem";
import no_image from "../../assets/img/no_image.png";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import editLogo from "../../assets/img/edit.jpg";
import "./InvoiceForm.css";

// custom Hooks
// import { useApiRequest } from '../../../customHooks/useApiRequest'
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiCustomRequest } from "../../customHooks/useApiCustomRequest";
import { useMenuActionHook } from "../../customHooks/useMenuActionHook";
import { ModalAddItem } from "../../components/modal_item_add";
import { useApirequestStockReceipt } from "../../customHooks/useApirequestStockReceipt";
import { useApiRequestItems } from "../../customHooks/useApiRequestItems";

// form configuration
import {
	typesAndValues,
	typesAndValuesUpdateDetailInvoice,
	typesAndValuesMessageOnInvoice,
	initData as initDataValues,
	initDataMessageOnInvoice as initDataMessageOnInvoiceValues,
	initDataBillingAddress as initDataBillingAddressValues,
	typesAndValuesPartNumber,
	initDataPartNumber as initDataPartNumberValues,
	PanelNames,
} from "./initForm";

import {
	styles,
	tableHeadersInvoiceDetail as tableHeaders,
	dataFieldsInvoiceDetail as dataFields,
	blockInvalidChar,
	addDaysToCurrentDay,
} from "../../context/constants";
import MaterialUI from "../../components/MaterialUI";
import Panel from "../../components/Panel";
import { CostumerForm } from "../costumer/CostumerForm";
import { AppContext } from "../../context/AppContext";
import InvoiceProductDetail from "./components/InvoiceProductDetail";
import Dialog from "../../components/Dialog";

function InvoiceForm(props) {
	const userData = JSON.parse(localStorage.getItem("user_data"));
	const location = useLocation();
	const history = useHistory();
  const { changeNavigationVisible } = React.useContext(AppContext);

	const [selectedProduct, setSelectedProduct] = React.useState();
	const selectedProductRef = useRef()
	const [isUpdateSelectedProduct, setIsUpdateSelectedProduct] = React.useState(false)
	
	React.useEffect(() => {
    setTimeout(() => {
      changeNavigationVisible(true);
    }, 250);
  }, []);

	const { anchorEl, setAnchorEl, onCloseModal, currentId, setCurrentId } =
		useMenuActionHook();

	const {
		setCustomMessageModal,
		showSuccessButton,
		setShowSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		setCustomTittleText,
		customMessageModal,
		manageResponseErrors,
		customCurrentItem,
		setCustomCurrentItem,
		manageResponse,
		manageSuccessfullMesssage,
	} = useModalHook();

	const {
		setIsLoading:stock_setIsLoading,
		stock_receipt_details,
		setSincronizedItems: setSincronizedItemsList,
		receipt_stock_number,
		quantity,
		cost,
		location_id,
		location_name,
		setPaymentNumber,
		setPartNumberId,
		part_number_name,
		setPartNumberName,
		skuNumber,
	} = useApirequestStockReceipt(
		setCustomTittleText,
		setCustomMessageModal,
		showSuccessButton,
		setShowModal,
	);

	const {
		filteredData: item_list,
		setSincronizedItems,
		getSkuItemInformation,
	} = useApiRequestItems(
		stock_setIsLoading,
		setPaymentNumber,
		setPartNumberId,
		setPartNumberName,
		setPartNumberId,
		setPartNumberName,
	);

	const setGoToMainPageRedirect = (redirect) => {
		if (redirect) {
			setTimeout(() => {
				if (
					customCurrentItem &&
					customCurrentItem.hasOwnProperty("id")
				) {
					history.push({
						pathname: "/invoice_form_update",
						search: `?invoice_id=${customCurrentItem.id}`,
					});
				} else {
					history.push("/invoice");
				}
			}, 500);
		}
	};

	const { isLoading, createItem, getListItem } = useApiCustomRequest(
		manageResponseErrors,
		"invoices",
		"Invoice",
		manageResponse,
		manageSuccessfullMesssage,
		true,
		// setGoToMainPage
		setGoToMainPageRedirect,
		false,
		() => {},
		setCustomCurrentItem,
	);

	const onHandleResponseModal = () => {
		setShowModal(false);
	};

	const onHandleResponseModalSuccess = () => {
		setShowModal(false);
		history.push("/invoice");
	};

	const [formAmountValues, setFormAmountValues] = React.useState({
		discount_percentage: 0.0,
		deposit: 0.0,
		discount_total: 0.0,
		tax: userData.company.tax || 0.0,
	});

	const onChangeFormAmountValues = (ev) => {
		let regex = /^[0-9]*\.?[0-9]*$/;

		if (!regex.test(ev.target.value)) {
			return;
		}

		if (
			Number(ev.target.value) > 200 &&
			ev.target.name === "discount_percentage"
		) {
			return;
		}

		setFormAmountValues({
			...formAmountValues,
			[ev.target.name]: ev.target.value,
		});
	};

	const [typesAndValuesFields, setTypesAndValuesFields] = React.useState([]);
	const [initData, setInitData] = React.useState({});
	const [changeInForm, setChangeInForm] = React.useState(false);
	const [payTerms, setPayTerms] = useState([]);
	const [currentSkuFromInput, setCurrentSkuFromInput] = useState("");

	//->
	const [initDataMessageOnInvoice, setInitDataMessageOnInvoice] =
		React.useState({});

	const onChangeFieldsInitDataMessageOnInvoice = async (event) => {
		setInitDataMessageOnInvoice({ ...event });
	};

	useEffect(() => {
		const initDataMessageOnInvoiceValuesCopy = {
			...initDataMessageOnInvoiceValues,
		};
		setInitDataMessageOnInvoice({ ...initDataMessageOnInvoiceValuesCopy });
	}, []);

	//->

	//->
	const [initDataBillingAddress, setInitDataBillingAddress] = React.useState(
		{},
	);

	useEffect(() => {
		const initDataBillingAddressCopy = { ...initDataBillingAddressValues };
		setInitDataBillingAddress({ ...initDataBillingAddressCopy });
	}, []);

	const onChangeSearchInputCustomer = (ev) => {
		let initDataCopy = { ...initData };
		let fieldsAndValuesCopy = [...typesAndValuesFields];

		setInitDataBillingAddress({});

		const initDataBillingAddressCopy = { ...initDataBillingAddressValues };
		if (ev) {
			setTimeout(() => {
				const {
					name,
					address_1,
					address_2,
					city,
					state,
					postal_code,
					country,
					email,
					id,
				} = ev;
				initDataCopy = {
					...initDataCopy,
					initOptions: [ev],
					client_send_email: email,
					client_id: id,
					inputValue: ``,
					doRequest: false,
					dontInitData: true,
					inputValue: `${name}`,
				};

				const idFieldIndex = fieldsAndValuesCopy.findIndex(
					(item) => item.field_name === "client_id",
				);
				let fieldId = fieldsAndValuesCopy[idFieldIndex];
				fieldId.inputValue = ``;
				fieldId.doRequest = false;
				fieldId.dontInitData = true;
				fieldId.inputValue = `${name}`;

				const newMessage = `${name},${address_1},${address_2},${city} ${state} ${postal_code},${country}`;
				initDataBillingAddressCopy.comments = newMessage;
				setInitDataBillingAddress({ ...initDataBillingAddressCopy });
				setInitData({ ...initDataCopy });
				setTypesAndValuesFields([...fieldsAndValuesCopy]);
			}, 0);
		}

		if (!ev) {
			setTimeout(() => {
				initDataBillingAddressCopy.comments =
					initDataBillingAddressValues.comments;
				setInitDataBillingAddress({ ...initDataBillingAddressCopy });
			}, 1);
		}
	};
	//->

	//->
	const [initDataPartNumber, setInitDataPartNumber] = React.useState({});

	useEffect(() => {
		let typesAndValuesPartNumberCopy = [...typesAndValues];

		// Para que los campos queden habilitados despues de hacer un edit
		typesAndValuesPartNumberCopy.forEach((item) => {
			item.disabled = false;
		});

	},[])

	const onChangeFieldsInitDataPartNumber = async (event) => {
		setInitDataPartNumber({ ...event });
	};

	useEffect(() => {
		const initDataPartNumberValuesCopy = { ...initDataPartNumberValues };
		setInitDataPartNumber({ ...initDataPartNumberValuesCopy });
	}, []);
	//->

	const [evaluateDates, setEvaluateDates] = useState(false);
	const onChangeFieldsInitData = async (event) => {
		setInitData({ ...event });
		setEvaluateDates(true);
	};

	const validateLength = (array) => {
		return array.length > 0;
	};

	const childFunc = useRef(null);
	const childFunc2 = useRef(null);
	const childFunc3 = useRef(null);
	const onHandleSubmit = async (event) => {
		if (isLoading) return;

		if (
			event === "Add" &&
			!initDataPartNumber.item_id &&
			!currentSkuFromInput
		) {
			return;
		}

		const errorsOne = childFunc.current(initData);
		const errorsTwo = childFunc2.current(initDataMessageOnInvoice);
		const errorsThree = childFunc3.current(initDataPartNumber);

		if (
			!validateLength(errorsOne) &&
			!validateLength(errorsTwo) &&
			!validateLength(errorsThree)
		) {
			let bodyRequest = {
				...initData,
				...initDataMessageOnInvoice,
				// ...initDataPartNumber,
				...formAmountValues,
				type: "Invoice",
				tax_por: formAmountValues.tax,
			};

			let finalRequest = {
				...bodyRequest,
			};

			if (initDataPartNumber.item_id) {
				finalRequest = {
					...finalRequest,
					...initDataPartNumber,
				};
			}

			if (!initDataPartNumber.item_id && currentSkuFromInput) {
				finalRequest = {
					...finalRequest,
					sku: currentSkuFromInput,
				};
			}

			let response = await createItem(finalRequest, "Invoice", {}, true);
			setShowModal(false);
			if (response && response.hasOwnProperty("id")) {
				history.push({
					pathname: "/invoice_form_update",
					search: `?invoice_id=${response.id}`,
				});
			}
		}
	};

	const onHandleSubmitV2 = async ({ event, product }) => {
		if (isLoading) return;
		const { item_id, item_variant_id, quantity, description } = product
		if (event === "Add" && !item_id) return

		const errorsOne = childFunc.current(initData);
		const errorsTwo = childFunc2.current(initDataMessageOnInvoice);
		const errorsThree = childFunc3.current(initDataPartNumber);
		const itemValues = { item_id, item_variant_id, quantity, comment: description };
		
		if (!validateLength(errorsOne) && !validateLength(errorsTwo) && !validateLength(errorsThree)) {
			let bodyRequest = {
				...initData,
				...initDataMessageOnInvoice,
				// ...initDataPartNumber,
				...formAmountValues,
				type: "Invoice",
				tax_por: formAmountValues.tax,
			};

			let finalRequest = {
				...bodyRequest,
			};

			if (itemValues.item_id) {
				finalRequest = {
					...finalRequest,
					...itemValues,
				};
			}

			if (!itemValues.item_id && currentSkuFromInput) {
				finalRequest = {
					...finalRequest,
					sku: currentSkuFromInput,
				};
			}

			let response = await createItem(finalRequest, "Invoice", {}, true);
			setShowModal(false);
			if (response && response.hasOwnProperty("id")) {
				history.push({
					pathname: "/invoice_form_update",
					search: `?invoice_id=${response.id}`,
				});
			}
		} else {
			onCloseSelectedProductHandler()
		}
	};

	useEffect(() => {
		let initDataValuesCopy = { ...initDataValues };

		if (
			location.hasOwnProperty("data") &&
			location?.data.hasOwnProperty("customerData") &&
			Object.keys(location.data.customerData).length > 0
		) {
			const {
				id,
				name,
				address_1,
				address_2,
				city,
				state,
				postal_code,
				country,
				email,
			} = location.data.customerData;

			initDataValuesCopy = {
				...initDataValuesCopy,
				client_id: id,
				invoice_date: new Date().toLocaleDateString("en-US", {
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
				}),
				client_send_email: email,
			};

			setInitDataBillingAddress({});

			setTimeout(() => {
				const initDataBillingAddressCopy = {
					...initDataBillingAddressValues,
				};
				const newMessage = `${name},${address_1},${address_2},${city} ${state} ${postal_code},${country}`;
				initDataBillingAddressCopy.comments = newMessage;
				setInitDataBillingAddress({ ...initDataBillingAddressCopy });
			}, 1);
		}

		initDataValuesCopy = {
			...initDataValuesCopy,
			date_receipt: new Date().toLocaleDateString("en-CA", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			}),
			due_date: addDaysToCurrentDay(new Date(), 30),
		};

		setInitData({ ...initDataValuesCopy });
	}, []);

	const [currentPayTermId, setCurrentPayTermId] = useState("4");
	const [currentDateReceipt, setCurrentDateReceipt] = useState(
		new Date().toLocaleDateString("en-CA", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
		}),
	);

	useEffect(() => {
		if (evaluateDates) {
			let initDataCopy = { ...initData };

			if (Object.keys(initData).length > 0) {
				const { pay_term_id, date_receipt } = initData;

				if (
					pay_term_id !== currentPayTermId ||
					date_receipt !== currentDateReceipt
				) {
					const daysToAdd =
						payTerms[
							payTerms.findIndex((term) => term.id == pay_term_id)
						]?.days;
					const dateReceipt = new Date(date_receipt);

					initDataCopy = {
						...initDataCopy,
						due_date: addDaysToCurrentDay(dateReceipt, daysToAdd),
					};

					setCurrentPayTermId(pay_term_id);
					setCurrentDateReceipt(date_receipt);
				}
			}

			setInitData(initDataCopy);
			setEvaluateDates(false);
		}
	}, [evaluateDates]);

	useEffect(async () => {
		let fieldsAndValuesCopy = [...typesAndValues];

		// Para evitar que se queden deshabilitados los inputs al hacer un pago
		fieldsAndValuesCopy.forEach((item) => {
			item.disabled = false;
		});

		const idFieldIndex = fieldsAndValuesCopy.findIndex(
			(item) => item.field_name === "client_id",
		);
		let fieldId = fieldsAndValuesCopy[idFieldIndex];
		fieldId.inputValue = ``;
		fieldId.doRequest = false;

		if (
			location.hasOwnProperty("data") &&
			location?.data.hasOwnProperty("customerData") &&
			Object.keys(location.data.customerData).length > 0
		) {
			const { name } = location.data.customerData;
			fieldId.inputValue = `${name}`;
			fieldId.dontInitData = true;
		}

		let termValues = await getListItem("pay-terms", "Pay terms", {});
		setPayTerms(termValues);
		const idFieldPayTermsIndex = fieldsAndValuesCopy.findIndex(
			(item) => item.field_name === "pay_term_id",
		);
		let pyayTerms = fieldsAndValuesCopy[idFieldPayTermsIndex];
		pyayTerms.values = termValues;

		setTypesAndValuesFields([...fieldsAndValuesCopy]);
	}, []);

	//redirect module
	const buttonClick = (field_id, typeSave = "Add") => {
		if (field_id === "createCustomer") {
			history.push({
				pathname: `/costumer_form`,
				data: { redirectTo: "/invoice_form" },
			});
		}
		if (field_id === "onSubmit") {
			onHandleSubmitPrevious()
			// onHandleSubmit(typeSave);
		}
		if (field_id === "Save") {
			onHandleSubmit(typeSave);
		}
	};

	const onHandleSubmitPrevious = () => {
		if (!selectedProductRef.current) return
		setSelectedProduct(selectedProductRef.current)
	}

	const onDelete = (event) => {};

	const onEdit = () => {};

	const actionsDefinition = [
		{
			name: "Edit",
			func: onEdit,
		},
		{
			name: "Delete",
			func: onDelete,
		},
	];

	//adjustement detail implementation
	const onMenuActions = async (event, element) => {};

	const testData = [];

	const [show, setShow] = React.useState(false);
	const prepareDetailToUpdate = (index) => {
		const item = testData.findIndex(
			(itemData) => itemData.part_number == index,
		);
		setCurrentId(item);
		setShow(true);
	};

	const cancelInvoice = () => {
		if (changeInForm) {
			setShowModal(true);
			setCustomTittleText(`Leave invoice creating`);
			setCustomMessageModal(`Do you want to leave without saving?`);
			setShowSuccessButton(true);
		} else {
			history.push("/invoice");
		}
	};

	const handleOnInputChange = (ev) => {
		setCurrentSkuFromInput(ev);
	};

	const [show_add, setShowAdd] = useState(false);

	const handleShowAdd = () => setShowAdd(true);

	const [modal_add_conf, setModalAddConf] = React.useState({
		title: "New Product",
		message: "",
	});

	const [template_errors, setTemplateErrors] = React.useState([]);
	const [template_errors_all, setTemplateErrorsAll] = React.useState([]);
	
	function validateFields() {
		setTemplateErrors([]);

		//validamos que existan espacios vacios

		let nedd_to_validate = false;

		if (skuNumber.toString().trim() !== "") {
			nedd_to_validate = true;
		}
		if (location_name.toString().trim() !== "") {
			nedd_to_validate = true;
		}
		if (parseFloat(cost) > 0 || cost.toString().trim() !== "") {
			nedd_to_validate = true;
		}

		if (!nedd_to_validate) {
			return false;
		}

		let falidate_fields_errors = [];

		// if(part_number_id.toString().trim() === '' ){

		// 		falidate_fields_errors.push({
		// 				'field_id' : 'part_number_id',
		// 				'message'  : 'Part Number need to be valid'
		// 		});

		// }

		if (skuNumber.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "part_number_name",
				message: "Part Number need to be valid",
			});
		}

		if (item_list.length > 0) {
			let exists_item = item_list.find(
				(element) => element.name === part_number_name.toString(),
			);

			if (exists_item === undefined) {
				falidate_fields_errors.push({
					field_id: "part_number_name",
					message: "Part number entered not exists",
				});
			}
		}

		if (quantity <= 0) {
			falidate_fields_errors.push({
				field_id: "quantity",
				message: "Quantity need to be greater than 0",
			});
		}

		if (parseFloat(cost) <= 0 || cost.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "cost",
				message: "Cost need to be greater than 0",
			});
		}

		if (falidate_fields_errors.length > 0) {
			setTemplateErrors(falidate_fields_errors);

			return false;
		}

		return true;
	}

	
	function addNewStockReceiptDetail(
		itemId,
		itemName,
		validateField = true,
		currentCost = null,
	) {
		if (validateField) {
			if (!validateFields()) {
				return false;
			}

			let exists_same_item = stock_receipt_details.find(
				(element) =>
					element?.item_id === itemId &&
					element?.location_id === location_id &&
					element?.cost === cost &&
					element?.quantity === quantity,
			);

			if (exists_same_item) {
				manageResponseErrors(
					"The item is already added",
					"Stock Receipt Items",
					"updating",
				);

				return false;
			}
		}
	}

	const createNewStockReceipt = async (
		dataId,
		dataName,
		stockReceiptId,
		errorCreating = false,
		currentCost = null,
		validateField = true,
	) => {
		let newStockReceiptItem = addNewStockReceiptDetail(
			dataId,
			dataName,
			validateField,
			currentCost,
		);
	}
	
	const handleCloseAdd = async (currentSku, currentCost) => {
		setShowAdd(false);

		if (skuNumber || currentSku) {
			// setIsLoading(true);
			const { data } = await getSkuItemInformation(skuNumber ? skuNumber : currentSku);

			if (!(Object.keys(data).length === 0)) {
				await createNewStockReceipt(
					data.id,
					data.name,
					receipt_stock_number,
					false,
					currentCost,
					false, //validate fields
				);
				setSincronizedItemsList(false);
			}
			stock_setIsLoading(false);
		}
	};

	const getElementWhenAddNewItem = () => {
		stock_setIsLoading(true);
		setSincronizedItems(false);
	};

	const [showPanelCustomer, setShowPanelCustomer] = useState(false);
  const toggleShowPanelCustomer = () => setShowPanelCustomer((state) => !state);
	const handleResponseCreateCustomer = (customer) => {
		onChangeSearchInputCustomer(customer);
		toggleShowPanelCustomer();
	}

	const onResponseCreateProduct = (response) => {
		Swal.fire('Success', 'Product created', 'success')
		setShowAdd(false)
	}
	
	const handleCustomerSiblings = (prop = {}) => {
		switch (prop.panel) {
			case PanelNames.PANEL_CUSTOMER:
				toggleShowPanelCustomer();
				break;
			case PanelNames.PANEL_PRODUCT:
				handleShowAdd()
				break;
			default:
				break;
		}
	}

	const onCloseSelectedProductHandler = () => {
		selectedProductRef.current = undefined
		setIsUpdateSelectedProduct(false)
		setSelectedProduct(undefined)
	}

	// TODO
	const onAddItemHandler = async (product) => {
		onCloseSelectedProductHandler()
		onHandleSubmitV2({ event: 'Add', product })
	}

	const detailSelectedHandler = (selected) => {
		if (!selected) {
			selectedProductRef.current = undefined
			return 
		}
		selectedProductRef.current = selected
	}

	const onCloseModalProduct = () => {
		setShowAdd(false)
		onCloseSelectedProductHandler()
	}

	return (
		<>
			<Panel open={showPanelCustomer} anchor="right" togglePanel={toggleShowPanelCustomer} headerTitle="New Client">
        <CostumerForm handleResponse={handleResponseCreateCustomer} />
      </Panel>
			<Dialog onClose={onCloseSelectedProductHandler} maxWidth="sm" fullWidth={true} title={`Selected product`} open={!!selectedProduct}>
				{(onClose) => !!selectedProduct && <InvoiceProductDetail selectedProduct={selectedProduct} onAddItem={onAddItemHandler} onClose={onClose} />}
			</Dialog>
			<div className="contenedor container-fluid" style={{ position: "relative" }}>
				<div className="container-title mb-3">
					<h1 className="container__title mb-0">
						{props.actionTittle + " Invoice"}
					</h1>
					<div className="action-buttons-container">
						<MaterialUI.Button.Success size="small" onClick={() => buttonClick("Save", "save")}>
							<SaveRoundedIcon fontSize="small" className="me-1"/>Save
						</MaterialUI.Button.Success>
						<MaterialUI.Button.Secondary size="small" onClick={cancelInvoice}>
							Cancel
						</MaterialUI.Button.Secondary>
					</div>
				</div>
				<div className="row">
					{typesAndValuesFields.length > 0 &&
						Object.keys(initData).length > 0 && (
							<>
								<div className="col-12">
									<CustomForm
										onHandleFormClose={() => {}}
										onHandleSubmit={onHandleSubmit}
										typesAndValuesFields={typesAndValuesFields}
										initData={initData}
										formTittle={""}
										dontShowCancelButton={true}
										id={null}
										getUrlPath={""}
										inputConf={{
											marginTop: "1",
											marginBottom: "0",
											stringCol:"col-12 col-sm-6 col-md-4 col-lg-2"
										}}
										buttonClick={buttonClick}
										onChangeFields={onChangeFieldsInitData}
										initOnChangeFields={onChangeFieldsInitData}
										dontShowSubmitButton={true}
										childFunc={childFunc}
										dontShowErrosinOnChange={true}
										getObjectInfoOnChange={onChangeSearchInputCustomer}
										notifyChange={() => setChangeInForm(true)}
										handlerSiblings={handleCustomerSiblings}
									/>
								</div>
							</>
						)}
				</div>
				<div className="row justify-content-between">
					<div className="col-12 col-md-4">
						<label>Billing Address</label>
						<address
							className="address-container border-lightgray"
							style={{
								height:
									Object.keys(initDataBillingAddress).length >
										0 &&
									initDataBillingAddress.comments.length > 0
										? "auto"
										: "131px",
							}}
						>
							{Object.keys(initDataBillingAddress).length > 0 &&
								initDataBillingAddress.comments.length > 0 &&
								initDataBillingAddress.comments
									.split(",")
									.map((comment, key) => (
										<React.Fragment key={key}>
											{comment}
											<br />
										</React.Fragment>
									))}
						</address>
					</div>
					<div className="balance-table col-12 col-md-4">
						<label className="balance-table-balance-due pt-2">
							Balance Due
						</label>
						<div className="balance-table-balance-total">
							<span>$ 0.00</span>
						</div>
					</div>
				</div>
				{/* TODO */}
				<div className="row">
					<div className="customForm-customButton-container">
						<CustomForm
							onHandleFormClose={() => {}}
							onHandleSubmit={onHandleSubmit}
							typesAndValuesFields={typesAndValuesPartNumber}
							initData={initDataPartNumber}
							formTittle={""}
							dontShowCancelButton={true}
							id={null}
							getUrlPath={""}
							inputConf={{
								marginTop: "1",
								marginBottom: "0",
								stringCol:"col-12 col-md-3"
							}}
							buttonClick={buttonClick}
							onChangeFields={onChangeFieldsInitDataPartNumber}
							initOnChangeFields={onChangeFieldsInitDataPartNumber}
							dontShowSubmitButton={true}
							dontShowErrosinOnChange={true}
							childFunc={childFunc3}
							getObjectInfoOnChange={detailSelectedHandler}
							notifyChange={() => setChangeInForm(true)}
							handleOnInputChange={handleOnInputChange}
							handlerSiblings={handleCustomerSiblings}
						/>
					</div>
				</div>
				
				<div className="row">
					<div className="col-12 col-md-8">
						<CustomDataList
							onChangePageEvent={() => {}}
							style={styles}
							isLoading={isLoading}
							dataList={testData}
							tableHeaders={tableHeaders}
							itemPagination={null}
							handlePagination={() => {}}
							dontShowPerPageSelect={true}
							render={(element, index) => (
								<CustomDataListItem
									key={index}
									dataFields={dataFields}
									imageFields={[]}
									composedFields={[]}
									arrayFields={[]}
									element={element}
									index={index}
									no_image={no_image}
									dontShowActions={true}
									showActionsButtons={true}
									deleteRowTable={() => {}}
									renderActions={() => (
										<>
											<PositionedMenu
												title={"Actions"}
												anchorEl={anchorEl}
												setAnchorEl={setAnchorEl}
												handleClose={(onCloseLocal) => {
													onCloseModal();
													onCloseLocal();
												}}
												handleClick={(event) =>
													onMenuActions(event, element)
												}
												actionsDefinition={
													actionsDefinition
												}
												renderActions={(action, index, handleCloseLocal) => (
													<MenuItem
														sx={{ boxShadow: "none" }}
														key={index}
														onClick={action.func}
													>
														<div
															className="items-option"
															style={{
																padding: "0 5px",
																color: "grey",
															}}
														>
															<FontAwesomeIcon
																style={{
																	margin: "4px",
																	marginRight:
																		"10px",
																	fontSize:
																		"12px",
																}}
																icon={faTrash}
															/>
															<p>{action.name}</p>
															<hr />
														</div>
													</MenuItem>
												)}
											></PositionedMenu>
										</>
									)}
									renderComponentInField={(data) => (
										<>
											{data}
											<img
												className={``}
												onClick={() =>
													prepareDetailToUpdate(data)
												}
												src={editLogo}
												alt=""
											/>
										</>
									)}
								/>
							)}
						/>
					</div>
					<div className="col-12 col-md-4">
						<div className="row">
							<div className="col-12">
								<div className="amount-table">
									<div className="letter amount-table-subtotal fs-small">
										Subtotal
									</div>
									<div className="letter amount-table-item fs-small font-inter amount-table-subtotal-value">
										$ 0.00
									</div>
									<div className="letter amount-table-discount fs-small">
										Discount percent
									</div>
									<input
										style={{ textAlign: "right" }}
										name="discount_percentage"
										onChange={onChangeFormAmountValues}
										value={formAmountValues.discount_percentage}
										disabled={false}
										className="form-control form-control-sm custom-input input-type-number h-75 align-self-center"
										onKeyDown={blockInvalidChar}
									/>
									<div className="letter amount-table-item fs-small font-inter">
										$ 0.00
									</div>
									<div className="letter amount-table-deposit fs-small">
										Deposit
									</div>
									<input
										style={{ textAlign: "right" }}
										name="deposit"
										onChange={onChangeFormAmountValues}
										value={formAmountValues.deposit}
										disabled={false}
										className="form-control form-control-sm custom-input input-type-number amount-table-deposit-value h-75 align-self-center"
										onKeyDown={blockInvalidChar}
									/>
									<div className="letter amount-table-balance-due fs-small">
										Tax %
									</div>
									<input
										style={{ textAlign: "right" }}
										name="tax"
										onChange={onChangeFormAmountValues}
										value={formAmountValues.tax}
										disabled={false}
										className="form-control form-control-sm custom-input input-type-number amount-table-deposit-value h-75 align-self-center"
										onKeyDown={blockInvalidChar}
									></input>
									<div className="letter amount-table-balance-due fs-small">
										Balance due
									</div>
									<div className="letter amount-table-item fs-small font-inter amount-table-balance-due-value">
										$ 0.00
									</div>
								</div>
							</div>
							<div className="col-12">
								{typesAndValuesFields.length > 0 &&
									Object.keys(initData).length > 0 && (
										<div className="row mt-1">
											<div className="col-12">
												<CustomForm
													onHandleFormClose={() => {}}
													onHandleSubmit={onHandleSubmit}
													typesAndValuesFields={typesAndValuesMessageOnInvoice}
													initData={initDataMessageOnInvoice}
													formTittle={""}
													dontShowCancelButton={true}
													id={null}
													getUrlPath={""}
													inputConf={{
														marginTop: "1",
														marginBottom: "0",
														stringCol:"col-1"
													}}
													buttonClick={buttonClick}
													onChangeFields={onChangeFieldsInitDataMessageOnInvoice}
													initOnChangeFields={onChangeFieldsInitDataMessageOnInvoice}
													dontShowSubmitButton={true}
													childFunc={childFunc2}
													dontShowErrosinOnChange={true}
													notifyChange={() => setChangeInForm(true)}
												/>
											</div>
										</div>
									)}
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* loading modal */}
			<CustomModal
				show={isLoading}
				customTittleText={""}
				customMessageModal={""}
				onHandleClose={() => {}}
				isLoading={isLoading}
			/>

			{/* response modal */}
			<CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={onHandleResponseModal}
				onHandleSuccess={onHandleResponseModalSuccess}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
			/>

			{/* modal form */}
			<CustomModal
				show={show}
				customTittleText={""}
				customMessageModal={""}
				onHandleClose={() => setShow(false)}
				onHandleSuccess={() => {}}
				isLoading={false}
				showSuccessButton={true}
			>
				<CustomForm
					onHandleFormClose={() => setShow(false)}
					location_list={[]}
					onHandleSubmit={() => setShow(false)}
					typesAndValuesFields={typesAndValuesUpdateDetailInvoice}
					initData={testData[currentId]}
					formTittle={""}
				/>
			</CustomModal>

			<ModalAddItem
				handleCloseAdd={onCloseModalProduct}
				getElementWhenAddNewItem={getElementWhenAddNewItem}
				modal_conf={modal_add_conf}
				onHandleClose={handleCloseAdd}
				show={show_add}
				skuNumber={skuNumber}
				onResponse={onResponseCreateProduct}
			></ModalAddItem>
		</>
	);
}

export { InvoiceForm };
