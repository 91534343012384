import { useState, useEffect, useCallback } from "react";
import createHttp from "../lib/http";

var _ = require("lodash");

function useApiRequestWareHouse(manageResponseErrors, requestWarehouses = true) {
	const [isLoading, setIsLoading] = useState(false);
	const [warehouse, setWareHouse] = useState([]);
	const [sincronizedItems, setSincronizedItems] = useState(false);

	const fetchWarehouses = useCallback(async (disabledSincronize = false) => {
		if (!disabledSincronize) {
			if(sincronizedItems) return;
		}
		setIsLoading(true);
		await createHttp
			.get("api/warehouse")
			.then((response) => {
				if (response.hasOwnProperty("data") && response.data) {
					setWareHouse(response.data.data.filter(Boolean));
				} else {
					manageResponseErrors(
						"An error has occurred",
						"WareHouse",
						"listing",
					);
				}
				setSincronizedItems(true);
				setIsLoading(false);
			})
			.catch((response) => {
				setSincronizedItems(true);
				setIsLoading(false);
				manageResponseErrors(
					"An error has occurred",
					"WareHouse",
					"listing",
				);
			})
			.finally(() => setIsLoading(false));
	}, [sincronizedItems]);
	
	useEffect(async () => {
		if (requestWarehouses) fetchWarehouses();
	}, []);

	const getWareHouse = async (wareHouseId) => {
		return await createHttp
			.get("api/warehouse/" + wareHouseId)
			.then((response) => response.data)
			.catch((err) => ({ data: [] }));
	};

	const deleteWareHouse = async (use_item_id) => {
		await createHttp
			.delete("/api/warehouse/" + use_item_id)
			.then((res) => {
				if (res.data.status) {
					setSincronizedItems(false);
					fetchWarehouses(true);
				} else {
					manageResponseErrors(
						res.data.message,
						"WareHouse",
						"deleting",
					);
				}
			})
			.catch((error) => {
				manageResponseErrors(
					"An error has occurred",
					"WareHouse",
					"deleting",
				);
			});
	};

	return {
		warehouse,
		setWareHouse,
		getWareHouse,
		isLoading,
		setIsLoading,
		sincronizedItems,
		setSincronizedItems,
		deleteWareHouse,
		setWareHouse,
		fetchWarehouses,
	};
}

export { useApiRequestWareHouse };
