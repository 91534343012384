import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

const Summernote = ({ state = '' }, containerRef) => {
  const editorRef = useRef(null)

  useEffect(() => {
    const $ = window.$
    $(editorRef.current).summernote({
      placeholder: 'Write here',
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
      ],
      maxHeight: 300,
      height: 100,
    });
    $(editorRef.current).summernote('code', state)
  }, [])

  useImperativeHandle(containerRef, () => ({
    getValue: () => {
      if(editorRef.current) {
        const $ = window.$
        return $(editorRef.current).summernote('code')
      }
      console.warn("Ref type node is needed")
    }
  }), [editorRef.current]);
  
  return <div style={{ zIndex: 1000 }} ref={editorRef}></div>
}


export default forwardRef(Summernote)