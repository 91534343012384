import React from "react";
import Layout from "../../components/Layout/Layout";
import { CustomDataList } from "../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../components/CustomDataListItem/CustomDataListItem";
import { PositionedMenu } from "../../components/CustomMenu/CustomMenu";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { CustomForm } from "../../components/CustomForm/CustomForm";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';
import clsx from 'clsx'

import MenuItem from "@material-ui/core/MenuItem";
import no_image from "../../assets/img/no_image.png";
import FileUploadImg from "../../assets/img/file-upload.png";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//custom Hooks
import { useApiRequestCategories } from "../../customHooks/useApiRequestCategories";
import { useModalHook } from "../../customHooks/useModalHook";
import { useMenuActionHook } from "../../customHooks/useMenuActionHook";

import {
	styles,
	tableHeadersCategories as tableHeaders,
	dataFieldsCategories as dataFields,
} from "../../context/constants";

import { typesAndValues, initData } from "./initForm";
import MaterialUI from "../../components/MaterialUI";

const app_conf = {
	title: "Categories",
};

function Categories(props) {
	const { anchorEl, setAnchorEl, onCloseModal, currentId, setCurrentId } =
		useMenuActionHook();

	const {
		showModal,
		setShowModal,
		customTittleText,
		setCustomTittleText,
		customMessageModal,
		showSuccessButton,
		manageResponseErrors,
		manageDeleteMessage,
		showCustomFormModal,
		setShowCustomFormModal,
		cancelButtonText,
		sucessButtonText,
		setCustomMessageModal,
	} = useModalHook();

	const {
		isLoading,
		filtered_data,
		setFilteredData,
		deleteCategory,
		updateCategory,
		createCategory,
		setSincronizedItems,
		uploadImageCategory,
	} = useApiRequestCategories(manageResponseErrors);

	const deleteItem = async () => {
		setShowModal(false);
		return await deleteCategory(currentId);
	};

	const onMenuActions = async (event, id) => {
		setCurrentId(id);
		setAnchorEl(event.currentTarget);
	};

	const onDelete = (event) => {
		setAnchorEl(null);
		manageDeleteMessage("Category");
		setCustomMessageModal(
			`Are you sure you want to delete item #${currentId}?`,
		);
		setShowModal(true);
	};

	const onEdit = () => {
		setCustomTittleText("Edit Category");
		setAnchorEl(null);
		setShowCustomFormModal(true);
	};

	const actionsDefinition = [
		{
			name: "Edit",
			func: onEdit,
			icon: faEdit,
		},
		{
			name: "Delete",
			func: onDelete,
			icon: faTrashAlt,
		},
	];

	const [typesAndValuesFields, setTypesAndValuesFields] = React.useState([]);

	React.useEffect(() => {
		const fieldsAndValuesCopy = [...typesAndValues];
		const idFieldIndex = fieldsAndValuesCopy.findIndex(
			(item) => item.field_name === "id",
		);
		let field = fieldsAndValuesCopy[idFieldIndex];
		field.hidden = !currentId ? true : false;
		fieldsAndValuesCopy[idFieldIndex] = field;
		setTypesAndValuesFields(fieldsAndValuesCopy);
	}, [currentId]);

	const stringToBoolean = (value) => {
		return value === "true" ? true : false;
	};

	const stringToInt = (value) => {
		return value === "true" ? 1 : 0;
	};

	const parseFormData = (object) => {
		const formData = new FormData();
		for (const key in object) {
			if (Object.hasOwnProperty.call(object, key)) {
				formData.append(key, object[key]);				
			}
		}
		return formData;
	}
	
	const onHandleSubmit = async (event) => {
		setShowCustomFormModal(false);
		const { id, serial_number, ...bodyExt } = event;
		if (currentId) {
			let bodyRequest = {
				serial_number: stringToBoolean(serial_number),
				...bodyExt,
			};
			const resp = await updateCategory(id, bodyRequest);
			if(image) {
				const payloadImage = parseFormData({
					file: image,
					category_id: id,
				});
				await uploadImageCategory(payloadImage);
			}
			return resp;
		} else {
			let bodyRequest = {
				serial_number: stringToInt(serial_number),
				...bodyExt,
			};
			const payload = parseFormData(bodyRequest);
			const newCategory = await createCategory(payload, false)
			if(image) {
				const payloadImage = parseFormData({
					file: image,
					category_id: newCategory.id,
				});
				await uploadImageCategory(payloadImage);
			}
			setSincronizedItems(false)
		}
	};

	const onCreateCategory = () => {
		clearInputImage();
		setCustomTittleText("Create Category");
		setAnchorEl(null);
		setShowCustomFormModal(true);
		setCurrentId(null);
	};

	const filterInputChange = (ev) => {
		const value = ev;
		const key = props.keyToFind || "name";

		if (!value) {
			setSincronizedItems(false);
		}

		const updatedList = filtered_data.filter((item) => {
			return item[key].toLowerCase().search(value.toLowerCase()) !== -1;
		});

		setFilteredData([...updatedList]);
	};

	const $pwcf_fileImage = React.useRef();
	const $pwcf_inputImage = React.useRef();
	const [image, setImage] = React.useState();
	const [hasPreviewImage, setHasPreviewImage] = React.useState(false);

	const handleImageChange = (e) => {
		const [file] = e.target.files;
		if(file) {
			setImage(file);
			const reader = new FileReader(file);
			reader.onload = (_) => {
				setHasPreviewImage(true);
				$pwcf_fileImage.current.src = reader.result;
			};
			reader.readAsDataURL(file);
		} else {
			clearInputImage();
		}
	}

	const clearInputImage = () => {
		if($pwcf_inputImage.current) $pwcf_inputImage.current.value = "";
		if($pwcf_fileImage.current) $pwcf_fileImage.current.src = "";
		setImage();
		setHasPreviewImage(false);
	}

	return (
		<Layout>
			<CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={() => setShowModal(false)}
				onHandleSuccess={deleteItem}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
				actionsFooterStyle={true}
				cancelText={cancelButtonText}
				successText={sucessButtonText}
			/>
			{/* modal form */}

			<CustomModal
				show={showCustomFormModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={() => setShowCustomFormModal(false)}
				onHandleSuccess={() => {}}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
			>
				<CustomForm
					onHandleFormClose={() => setShowCustomFormModal(false)}
					location_list={[]}
					onHandleSubmit={onHandleSubmit}
					typesAndValuesFields={typesAndValuesFields}
					initData={initData}
					formTittle={""}
					id={(currentId && currentId.toString()) || null}
					getUrlPath={"api/categories/"}
					recordFound={(record) => {
						const newPath = record.document_path ?? '';
						$pwcf_fileImage.current.src = newPath;
						setHasPreviewImage(!!newPath);
					}}
					childrenBeforeActions={<>
						<div className="row mt-2">
							<div className="col-12">
								<div className="pwcf-file_container" id="pwcf-file_container">
									<img src={FileUploadImg} className={clsx('pwcf-icon_image_upload', hasPreviewImage && 'd-none' )} alt="Image upload image" />
									<input ref={$pwcf_inputImage} type="file" className="pwcf-file_input" id="file_input" accept="image/*" onChange={handleImageChange}/>
									<img ref={$pwcf_fileImage} id="file_image" className={clsx('pwcf-file_image', (!hasPreviewImage) && 'd-none')} />
									<small className={clsx('pwcf-file_letter', hasPreviewImage && 'd-none')}>Upload image</small>
								</div>
							</div>
						</div>
					</>}
				/>
				
			</CustomModal>

			<div className="box container-fluid">
				<div className="container-title mb-3">
					<h1 className="container__title mb-0">{app_conf.title}</h1>
				</div>
				<div
					className={` 
                    ${
						!!props.showSimpleInputTextFilter &&
						"d-flex justify-content-between"
					}
                    ${!props.showSimpleInputTextFilter && "row"}
                `}
				>
					{!!props.showSimpleInputTextFilter && (
						<CustomInput
							inputId={"inputPassword6"}
							describedByArea={"passwordHelpInline"}
							spanText={"Enter a letter to filter in items"}
							labelText={"Filter"}
							inputOnChange={filterInputChange}
						/>
					)}
					{/* <div className='col-12'> */}
					<div
						className={`
                            ${!!props.showSimpleInputTextFilter && ""}
                            ${!props.showSimpleInputTextFilter && "col-12"}
                        `}
					>
						<MaterialUI.Button.Primary size="small" onClick={onCreateCategory}>
							<AddTwoToneIcon fontSize="small" className="me-1"/>Add
						</MaterialUI.Button.Primary>
					</div>
				</div>

				<div className="">
					<CustomDataList
						onChangePageEvent={() => {}}
						style={styles}
						isLoading={isLoading}
						dataList={filtered_data}
						tableHeaders={tableHeaders}
						itemPagination={null}
						handlePagination={() => {}}
						dontShowPerPageSelect={true}
						showLinearIndicator={true}
						showDataInLoading={true}
						render={(element, index) => (
							<CustomDataListItem
								key={index}
								dataFields={dataFields}
								imageFields={[]}
								composedFields={[]}
								arrayFields={[]}
								element={element}
								index={index}
								no_image={no_image}
								renderActions={() => (
									<>
										<PositionedMenu
											title={"Actions"}
											anchorEl={anchorEl}
											setAnchorEl={setAnchorEl}
											handleClose={onCloseModal}
											handleClick={(event) =>
												onMenuActions(event, element.id)
											}
											actionsDefinition={
												actionsDefinition
											}
											renderActions={(action, index) => (
												<MenuItem
													sx={{
														boxShadow: "none",
													}}
													key={index}
													onClick={() =>
														action.func()
													}
												>
													<div
														className="items-option"
														style={{
															padding: "0 5px",
															color: "grey",
														}}
													>
														<FontAwesomeIcon
															style={{
																fontSize: '.8rem',
																margin: "4px",
																marginRight: "10px"
															}}
															icon={action.icon}
															className={
																action.name == "Edit" 
																	? "text-primary"
																	: action.name == "Delete"
																		? 'text-danger'
																		: 'inherit'
															}
														/>
														<p className="mb-0 fs-small">{action.name}</p>
													</div>
												</MenuItem>
											)}
										></PositionedMenu>
									</>
								)}
							/>
						)}
					/>
				</div>
				{/* <TableCrud
                    table_id_configuration={table_id_configuration}

                    api_configuration={api_configuration}

                    token={token_api[0]}

                    table_add_configuration={table_add_configuration}

                    updateAddConfiguration={updateAddConfiguration}

                    setFistState={setFistState}

                    table_first_conf={create_table_add_configuration}

                    app_conf={app_conf}

                /> */}
			</div>
		</Layout>
	);
}

export { Categories };
