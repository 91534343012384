import { useState, useEffect } from "react";
import createHttp from "../lib/http";

var _ = require("lodash");

function useApiRequestUser() {
	const [isLoading, setIsLoading] = useState(false);
	const [sincronizedItems, setSincronizedItems] = useState(false);
	const [filtered_data, setFilteredData] = useState([]);
	const [user, setUser] = useState([]);
	const [itemFilters, setItemFilters] = useState([]);
	const [filtersDefinition, setFiltersDefinition] = useState([]);

	const selectOnChange = (filter) => {
		const copyFilters = [...itemFilters];
		const hasFilter = copyFilters.findIndex(
			(filterItem) => filterItem.field_id === filter.field_id,
		);
		if (hasFilter !== -1) {
			copyFilters.splice(hasFilter, 1);
		}
		setItemFilters([...copyFilters, filter]);
	};

	const onChangeDateFilter = (value, field_id) => {
		selectOnChange({
			field_id: field_id,
			value: value,
		});
	};

	const requestBySearchText = _.debounce(function () {
		setSincronizedItems(false);
	}, 2500);

	const filterInputChange = (value) => {
		const copyFilters = [...itemFilters];
		setItemFilters([
			...copyFilters,
			{
				field_id: "search_text",
				value: value,
			},
		]);
		requestBySearchText();
	};

	useEffect(() => {
		let params = {};
		itemFilters
			.filter((filter) => filter.value)
			.map((filter) => (params[filter.field_id] = filter.value));

		if (!sincronizedItems) {
			setIsLoading(true);
			createHttp
				.get("api/profiles", { params })
				.then((response) => {
					setUser(response.data.data);
					setFilteredData(response.data.data);
					setSincronizedItems(true);
					setIsLoading(false);
				})
				.catch((response) => {
					setIsLoading(false);
				});
		}
	}, [sincronizedItems]);

	const deleteUser = (id) => {
		setIsLoading(true);
		createHttp
			.delete("api/profiles/" + id)
			.then((res) => {
				if (!res.data.status) {
					return;
				} else {
					setSincronizedItems(false);
				}
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
			});
	};

	return {
		isLoading,
		setIsLoading,
		sincronizedItems,
		setSincronizedItems,
		filtered_data,
		user,
		setFilteredData,
		setUser,
		deleteUser,
		filtersDefinition,
		setFiltersDefinition,
		selectOnChange,
		onChangeDateFilter,
		filterInputChange,
	};
}

export { useApiRequestUser };
